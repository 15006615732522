import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export interface LanguageStoreType {
  currentFormState: Record<string, any>
  updateFormState: (name: string, value: any) => void
  resetCurrentForm: () => void
  currentFormula: Record<string, any>
  prompt: string
  setCurrentFormula: (values: any) => void
  updatePrompt: (value: string) => void
  updateCurrentFormula: (name: string, value: any) => void
  updateCurrentFormulaKey: (name: string, key: any, value: any) => void
}

export const useCreateTemplateStore = create<LanguageStoreType>()(
  persist(
    (set) => ({
      resetCurrentForm: () => {
        set({
          currentFormState: {
            title: '',
            description: '',
            category: '',
            cost: 0,
            info: '',
            hint: '',
            isPublic: '',
            context: '',
            input: {},
            tags: [],
            prompt: '',
            currentStep: 0,
            lastMaxStep: 0,
          },
        })
      },
      currentFormState: {
        title: '',
        description: '',
        cost: 0,
        category: '',
        isPublic: '',
        info: '',
        hint: '',
        context: '',
        input: {},
        tags: [],
        currentStep: 0,
        prompt: '',
        lastMaxStep: 0,
      },
      prompt: '',
      updatePrompt: (value) => {
        set(() => ({
          prompt: value,
        }))
      },
      currentFormula: {},
      setCurrentFormula: (values) => {
        set(() => ({
          currentFormula: values,
        }))
      },
      updateFormState: (name: string, value: any) => {
        set((state) => ({
          currentFormState: {
            ...state.currentFormState,
            [name]: value,
          },
        }))
      },
      updateCurrentFormulaKey: (name: string, key: any, value: any) => {
        set((state) => ({
          currentFormula: {
            ...state.currentFormula,
            [key]: {
              ...state.currentFormula[key],
              [name]: value,
            },
          },
        }))
      },
      updateCurrentFormula: (name: string, value: any) => {
        set((state) => ({
          currentFormula: {
            ...state.currentFormula,
            [name]: value,
          },
        }))
      },
    }),
    {
      name: 'createFormStore',
    },
  ),
)
