import { Link } from '@tanstack/react-location'
import { useEffect, useState } from 'react'
import { Button } from 'src/core/ds/button'
import { ButtonVariants } from 'src/core/ds/button/types'
import { IconNames } from 'src/core/ds/icon'
import { Input } from 'src/core/ds/input'
import { Loader } from 'src/core/ds/loader'
import { useDebounce } from 'src/hooks/useDebounce'
import { extractInput } from 'src/modules/asideHistory/components/ChatItem'
import { DateFormats, formatDate } from 'src/utils/dates'
import { api } from './api'
import styles from './index.module.scss'
import { useGetHistoryList } from './query'

function wrapSearchInput(inputText, apiOutputText) {
  // escape special characters in search input text
  const escapedInput = inputText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

  // use regex to find the index of input text in api output text
  const regex = new RegExp(escapedInput, 'i')
  const match = apiOutputText.match(regex)

  if (!match) {
    return apiOutputText
  }

  const startIndex = Math.max(match.index - 120, 0)
  const endIndex = Math.min(match.index + match[0].length + 120, apiOutputText.length)

  // wrap the matching text with <span> tag
  const beforeText = apiOutputText.slice(startIndex, match.index)
  const afterText = apiOutputText.slice(match.index + match[0].length, endIndex)
  const highlightedText = `<span>${match[0]}</span>`

  return `${beforeText}${highlightedText}${afterText}`
}

export function HistorySearchPage() {
  const [searchInput, setSearchInput] = useState('')
  const [searchResults, setSearchResults] = useState<any>(null)
  const debouncedValue = useDebounce(searchInput, 500)

  const { data, isLoading } = useGetHistoryList()

  const onReset = () => {
    setSearchInput('')
    setSearchResults(null)
  }

  useEffect(() => {
    if (debouncedValue.length < 3) {
      setSearchResults(null)

      return
    }

    api
      .searchItems({ searchInput: debouncedValue })
      .then((res: any) => {
        const [error, response] = res
        // firePlausibleEvent(PLAUSIBLE_EVENTS.START_SEARCH)

        if (error) {
          console.log('error', error)
        }

        if (response?.items) {
          setSearchResults(response.items)
        }

        if (response?.length === 0) {
          setSearchResults([])
        }
      })
      .catch((err) => {
        console.log('error', err)
      })
  }, [debouncedValue])

  const isSearchView = debouncedValue.length > 2

  return (
    <div className={styles.dashboard}>
      <div className={styles.content}>
        <h1>History search</h1>

        <div className={styles.form}>
          <Input
            icon={IconNames.search}
            type="text"
            onChange={(e) => setSearchInput(e.target.value)}
            placeholder="Search history"
            value={searchInput}
            autofocus
            action={
              // eslint-disable-next-line
              searchInput.length > 2 ? (
                <Button
                  onClick={onReset}
                  variant={ButtonVariants.unstyled}
                  iconColor="var(--color-secondary)"
                  type="button"
                  icon={IconNames.close}
                />
              ) : null
            }
          />
        </div>

        {!isSearchView && <p className={styles.subtitle}>50 recent items</p>}
        {isSearchView && (
          <p className={styles.subtitle}>
            {searchResults?.length}
            &nbsp;items found
          </p>
        )}

        {isLoading && <Loader isBig />}

        {!isSearchView && (
          <div className={styles.list}>
            {data?.map((item) => (
              <Link
                key={item.id.toString()}
                to={`/marketplace/task/${item.task.id.toString()}/history/${
                  item.context_id ? item.context_id?.toString() : item.id?.toString()
                }`}
                className={styles.item}
              >
                <div className={styles.itemHeader}>
                  <Link to={`/marketplace/task/${item.task.id.toString()}`}>
                    <span>{item.task.title}</span>
                  </Link>
                  <p>{item.task.description}</p>
                </div>

                <div className={styles.itemUserInput}>
                  {item?.input?.data || item?.input?.text || extractInput(item?.input)}
                </div>
                {item?.task?.ai?.name?.startsWith('DALL') && (
                  <div className={styles.itemContentImages}>
                    {JSON.parse(item?.response || '[]').map((image) => (
                      <img key={image} src={image} alt="" />
                    ))}
                  </div>
                )}
                {item?.task?.ai?.name?.startsWith('ChatGPT') && (
                  <div className={styles.itemContent}>{item?.response}</div>
                )}

                <div className={styles.itemDate}>
                  {/* eslint-disable-next-line */}
                  {/* @ts-ignore */}
                  {formatDate(Number(item?.created_at * 1000) as Date, DateFormats.datePickerFullDate)}
                </div>
              </Link>
            ))}
          </div>
        )}

        {isSearchView && (
          <div className={styles.list}>
            {searchResults?.map((item) => (
              <Link
                key={item.id.toString()}
                to={`/marketplace/task/${item.task.id.toString()}/history/${
                  item.context_id ? item.context_id?.toString() : item.id?.toString()
                }`}
                className={styles.item}
              >
                <div className={styles.itemHeader}>
                  <Link to={`/marketplace/task/${item.task.id.toString()}`}>
                    <span>{item.task.title}</span>
                  </Link>
                  <p>{item.task.description}</p>
                </div>

                <div
                  className={styles.itemUserInput}
                  // eslint-disable-next-line
                  dangerouslySetInnerHTML={{
                    __html: wrapSearchInput(
                      debouncedValue,
                      item?.input?.data || item?.input?.text || extractInput(item?.input),
                      // item.response,
                    ),
                  }}
                />
                {item?.task?.ai?.name?.startsWith('DALL') && (
                  <div className={styles.itemContentImages}>
                    {JSON.parse(item?.response || []).map((image) => (
                      <img key={image} src={image} alt="" />
                    ))}
                  </div>
                )}
                {item?.task?.ai?.name?.startsWith('ChatGPT') && (
                  <div
                    className={styles.itemContent}
                    // eslint-disable-next-line
                    dangerouslySetInnerHTML={{
                      __html: wrapSearchInput(debouncedValue, item.response),
                    }}
                  />
                )}

                <div className={styles.itemDate}>
                  {/* eslint-disable-next-line */}
                  {/* @ts-ignore */}
                  {formatDate(Number(item?.created_at * 1000) as Date, DateFormats.datePickerFullDate)}
                </div>
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
