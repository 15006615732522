import { Link, Navigate, useMatch } from '@tanstack/react-location'
import { AsideTeamSpaces } from 'src/components/AsideZenSpaces/AsideTeamSpaces'
import { Stream } from 'src/components/Stream'
import { StreamHistory } from 'src/components/StreamHistory'
// import { TaskDetails } from 'src/components/TaskDetails'
import { breaks } from 'src/constants/breaks'
import { Button } from 'src/core/ds/button'
import { Icon, IconNames } from 'src/core/ds/icon'
import { Loader } from 'src/core/ds/loader'
import { useMedia } from 'src/hooks/useMedia'
import { AsideTeamHistory } from 'src/modules/asideHistory/AsideTeamHistory'
import { useGetCurrentUser } from 'src/modules/currentUser/query'
import { useGetTeamDetails, useGetTeams } from 'src/modules/teams/query'
import styles from './index.module.scss'

export function TeamPage() {
  const {
    params: { taskId, historyId, teamId },
  } = useMatch()

  const screenWidth = useMedia()
  const isVisibleOnDesktop = screenWidth > breaks.tablet

  const { data: teamsList, isLoading } = useGetTeams()
  const { data: currentUser } = useGetCurrentUser()
  const { data: teamDetails } = useGetTeamDetails(teamId)

  const selectedTeam = teamsList && teamsList?.items?.find((team) => team?.id?.toString() === teamId)

  const teamCreatorId = teamDetails?.account?.id?.toString() || ''
  const isTeamAdmin = teamCreatorId === currentUser?.id?.toString()
  const isTeamSubscription = currentUser?.sub_plan === 'team'

  if (isLoading) {
    return (
      <div className={styles.dashboard}>
        <div>
          <Loader isBig />
        </div>
      </div>
    )
  }

  if (!isLoading && !teamsList) {
    return <Navigate to="/teams/create" />
  }

  return (
    <div className={styles.dashboard}>
      {isVisibleOnDesktop && (
        <AsideTeamHistory teamsList={teamsList?.items} teamId={teamId} taskId={taskId} historyId={historyId} />
      )}

      {historyId && (
        <div className={styles.content}>
          <StreamHistory historyId={historyId} />
        </div>
      )}

      {!historyId && taskId && (
        <div className={styles.content}>
          <Stream taskId={taskId} />
        </div>
      )}

      {!historyId && !taskId && !teamId && teamsList?.items && (
        <div className={styles.content}>
          <div className={styles.teamBox}>
            <h1 className={styles.wizardTitle}>Select team to start</h1>
            <div className={styles.teamList}>
              {teamsList?.items?.map((team) => (
                <Link key={team.id.toString()} to={`/teams/${team.id.toString()}`} className={styles.teamItem}>
                  <span>{team.name}</span>
                  <p>
                    <Icon name={IconNames.user} color="var(--color-secondary)" />
                    {team.member_count}
                    &nbsp;member(s)
                  </p>
                </Link>
              ))}
            </div>

            {isTeamSubscription && (
              <div className={styles.teamList}>
                <Link to="/teams/create">
                  <Button label="Create new team" type="button" />
                </Link>
              </div>
            )}
          </div>
        </div>
      )}

      {!historyId && !taskId && !teamId && teamsList?.length === 0 && (
        <div className={styles.content}>
          <div className={styles.teamBox}>
            <h1 className={styles.wizardTitle}>You have not been added to any team.</h1>

            {isTeamSubscription && (
              <div className={styles.teamList}>
                <Link to="/teams/create">
                  <Button label="Create new team" type="button" />
                </Link>
              </div>
            )}
          </div>
        </div>
      )}

      {!historyId && !taskId && teamId && (
        <div className={styles.content}>
          <div className={styles.teamBoxHidden}>
            <h1 className={styles.wizardTitle}>
              {selectedTeam?.name}
              &nbsp;workspace
            </h1>
            <p className={styles.teamStartDescription}>Select a prompt to start with.</p>
          </div>
        </div>
      )}

      {isVisibleOnDesktop && (
        <AsideTeamSpaces isTeamAdmin={isTeamAdmin} currentUser={currentUser} teamId={teamId} key="zen" />
      )}
      {/* {isVisibleOnDesktop && <TaskDetails taskId={taskId} />} */}
    </div>
  )
}
