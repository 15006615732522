import { useNavigate } from '@tanstack/react-location'
import { useDeferredValue, useState } from 'react'
import { IconNames } from 'src/core/ds/icon'
import { Input } from 'src/core/ds/input'
import { useGetSpaceDetails, useGetSpaceDetailsList } from 'src/pages/SelectedSpacePage/query'
import { queryClient } from 'src/queries/reactQueryClient'
import { getSpaceDetailsList } from 'src/queries/reactQueryKeys'
import { pushNotification } from 'src/services/notifications/notificationService'
import { useUIStore } from 'src/services/store/UIStore'
import { useAddTaskToSpace } from '../AddToSpaceModal/query'
import { SingleTask } from '../SingleTask/SingleTask'
import styles from './index.module.scss'

export function AsideSpaceList({ spaceId, currentUser }) {
  const navigate = useNavigate()
  const openAddToSpaceModal = useUIStore((state) => state.openAddToSpaceModal)
  const [searchInput, setSearchInput] = useState('')
  const deferredSearchInput = useDeferredValue(searchInput)

  const onPrivate = () => {
    navigate({ to: `/spaces` })
  }
  const { data: spaceDetails, isLoading } = useGetSpaceDetails(spaceId.toString())
  const { data: tasksRaw } = useGetSpaceDetailsList(spaceId.toString(), onPrivate)
  const tasks: any = tasksRaw?.pages?.flatMap((page) => page?.items ?? page) ?? []

  const onSuccessRemoveFromSpace = () => {
    pushNotification({
      type: 'success',
      title: 'Task removed from space',
    })

    queryClient.invalidateQueries({ queryKey: getSpaceDetailsList(spaceId) })
  }

  const { mutate } = useAddTaskToSpace(onSuccessRemoveFromSpace)

  const onRemoveTaskFromSpace = async (taskId) => {
    await mutate({
      DTO: {
        remove: [taskId.toString()],
      },
      spaceId: spaceId.toString(),
    })
  }

  const isSearchView = deferredSearchInput.length > 2

  const foundTasks = tasks?.filter((task) => task.title.toLowerCase().includes(deferredSearchInput.toLowerCase()))
  const isRemovableFromSpace = spaceDetails?.account?.id.toString() === currentUser?.id.toString()

  return (
    <div className={styles.wrapper}>
      <div className={styles.catsList}>
        <div className={styles.stickyHeader}>
          <h1>{spaceDetails?.title}</h1>
          <div className={styles.filters}>
            {/* <Selector hasDefault={false} onChange={onSortChange} options={sortOptions} selected={personalSpaceSorting} /> */}
            <Input
              icon={IconNames.search}
              type="text"
              onChange={(e) => setSearchInput(e.target.value)}
              placeholder="Search"
              value={searchInput}
            />
          </div>
        </div>

        <div className={styles.list}>
          <div className={styles.categoriesSection}>
            {isSearchView && (
              <div className={styles.list}>
                {(foundTasks || []).map((item: any) => (
                  <SingleTask
                    isRemovableFromSpace={isRemovableFromSpace}
                    key={item.id.toString()}
                    withStatus={false}
                    spaceLink={`/spaces/${spaceId}/${item.id.toString()}`}
                    onRemoveFavorite={() => {
                      console.log('1')
                    }}
                    onAddFavorite={() => {
                      console.log('1')
                    }}
                    isAuth={currentUser}
                    onAddToSpace={openAddToSpaceModal}
                    removeFromSpace={onRemoveTaskFromSpace}
                    task={item}
                  />
                ))}
                {foundTasks?.length === 0 && <div>Nothing found.</div>}
              </div>
            )}

            {!isLoading && !isSearchView && (
              <div className={styles.list}>
                {(tasks || []).map((item: any) => (
                  <SingleTask
                    isRemovableFromSpace={isRemovableFromSpace}
                    key={item.id.toString()}
                    spaceLink={`/spaces/${spaceId}/${item.id.toString()}`}
                    isAuth={currentUser}
                    withStatus={false}
                    onRemoveFavorite={() => {
                      console.log('1')
                    }}
                    onAddFavorite={() => {
                      console.log('1')
                    }}
                    onAddToSpace={openAddToSpaceModal}
                    removeFromSpace={onRemoveTaskFromSpace}
                    task={item}
                  />
                ))}
                {tasks?.length === 0 && <div>Nothing to show.</div>}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
