import { Button } from 'src/core/ds/button'
import { ButtonVariants } from 'src/core/ds/button/types'
import { pushNotification } from 'src/services/notifications/notificationService'
import { useUIStore } from 'src/services/store/UIStore'
import { useCreateNewSpace } from '../createSpaceModal/query'
import styles from './index.module.scss'

export function RemoveSpaceModal() {
  const updateUIStore = useUIStore((state) => state.updateUIStore)
  const deleteSpaceBuffer = useUIStore((state) => state.deleteSpaceBuffer)

  const onSuccess = () => {
    updateUIStore('isDeleteSpaceModalOpen', false)
    updateUIStore('deleteSpaceBuffer', null)
    pushNotification({
      type: 'success',
      title: 'Space removed.',
    })
  }

  const { mutate, isLoading } = useCreateNewSpace(onSuccess)

  const onDeny = () => {
    updateUIStore('isDeleteSpaceModalOpen', false)
    updateUIStore('deleteSpaceBuffer', null)
  }

  const onConfirm = async () => {
    await mutate({
      DTO: {
        id: deleteSpaceBuffer.toString(),
        status: 'hidden',
      },
    })
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.action}>
        <Button label="Deny" disabled={isLoading} onClick={onDeny} variant={ButtonVariants.secondary} type="button" />
        <Button label="Confirm" disabled={isLoading} onClick={onConfirm} type="button" />
      </div>
    </div>
  )
}
