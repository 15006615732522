import { useEffect, useState } from 'react'
import { Button } from 'src/core/ds/button'
import { ButtonVariants } from 'src/core/ds/button/types'
import { Input } from 'src/core/ds/input'
import { pushNotification } from 'src/services/notifications/notificationService'
import { useUIStore } from 'src/services/store/UIStore'
import { DateFormats, formatDate } from 'src/utils/dates'
import styles from './index.module.scss'
import { useGetCouponeInfo, useGetCouponeSubmit } from './query'

export function Coupone() {
  const [couponInfo, setCouponInfo] = useState<any>(null)
  const updateUIStore = useUIStore((state) => state.updateUIStore)
  const [couponCode, setCouponeCode] = useState('')
  const onSuccess = () => {
    pushNotification({
      type: 'success',
      title: 'Coupone successfully activated',
    })
    updateUIStore('isCouponModalOpen', false)
  }
  const { isLoading: isLoadingInfo, data, mutate } = useGetCouponeInfo()
  const { isLoading, mutate: activateMutation } = useGetCouponeSubmit(onSuccess)

  useEffect(() => {
    if (!data) return

    setCouponInfo(data[1])
  }, [data])

  const onSubmitCoupon = async () => {
    if (!couponCode) {
      return pushNotification({
        type: 'error',
        title: 'Invalid coupon code',
      })
    }

    return activateMutation({ couponCode })
  }

  const onGetCouponInfo = async () => {
    if (!couponCode) {
      return pushNotification({
        type: 'error',
        title: 'Invalid coupon code',
      })
    }

    const res = await mutate({
      couponCode,
    })

    return res
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.form}>
        <div className={styles.formControl}>
          <Input
            description="Coupon code"
            onChange={(e) => setCouponeCode(e.target.value)}
            value={couponCode}
            placeholder="PD01-06-00000001"
          />
        </div>

        {couponInfo && (
          <div className={styles.formControl}>
            {/* // eslint-disable-next-line
                // @ts-ignore */}
            <p className={styles.couponInfo}>
              <span>Coupon plan:</span>
              {couponInfo?.sub_plan?.toUpperCase()}
            </p>
            <p className={styles.couponInfo}>
              <span>Sub till:</span>
              {formatDate(
                // eslint-disable-next-line
                // @ts-ignore
                Number((couponInfo?.sub_till || 1) * 1000) as Date,
                DateFormats.monthWithDay,
              )}
            </p>
          </div>
        )}
        <div className={styles.formControl}>
          <Button
            variant={ButtonVariants.secondary}
            onClick={onGetCouponInfo}
            isLoading={isLoadingInfo}
            label="Check coupon status"
            type="button"
          />
        </div>

        {couponInfo && (
          <div className={styles.formControl}>
            <Button onClick={onSubmitCoupon} isLoading={isLoading} label="Activate coupon" type="button" />
          </div>
        )}
      </div>
    </div>
  )
}
