import { useMutation } from 'react-query'
import { queryClient } from 'src/queries/reactQueryClient'
import { getPersonalSpacesList, getSpaceDetailsList } from 'src/queries/reactQueryKeys'
import { pushNotification } from 'src/services/notifications/notificationService'
import { api } from './api'

export const useAddTaskToSpace = (onSuccess) =>
  useMutation(api.addTaskToSpace, {
    onSuccess: (response: any, variables) => {
      const [error, data] = response

      if (error) {
        pushNotification({
          type: 'error',
          title: 'Error with task',
        })

        throw Error(error)
      }

      onSuccess && onSuccess()
      queryClient.invalidateQueries({ queryKey: getSpaceDetailsList(variables.spaceId) })
      queryClient.invalidateQueries({ queryKey: getPersonalSpacesList() })
      // queryClient.invalidateQueries({ queryKey: getTeamsList() })

      return data
    },
    onError: (err: any) => {
      if (err.toString().includes('e_space_permissions_denied')) {
        pushNotification({
          type: 'error',
          title: 'You do not have permissions.',
        })
      } else {
        pushNotification({
          type: 'error',
          title: 'Error with task',
        })
      }

      return err
    },
  })
