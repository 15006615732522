import { CreateSolutionWizard } from './components/CreateSolutionWizard'
import styles from './index.module.scss'

export function SolutionCreatorPage() {
  return (
    <div className={styles.dashboard}>
      <CreateSolutionWizard />
    </div>
  )
}
