import { useInfiniteQuery } from 'react-query'
import { queryClient } from 'src/queries/reactQueryClient'
import { getTaskHistoryList } from 'src/queries/reactQueryKeys'
import { api } from './api'

export const useGetTaskHistory = (id) =>
  useInfiniteQuery(
    getTaskHistoryList(id),
    async ({ pageParam = 1 }) => {
      if (!id) return []

      const token = localStorage.getItem('token')

      if (!token) return []

      try {
        const response = await api.getTaskHistory({ id, page: pageParam })
        const [error, data] = response as any[]

        if (error) {
          if (error.toString().includes('e_account_token_empty')) {
            return []
          }

          throw new Error(error)
        }

        return data
      } catch (error) {
        // eslint-disable-next-line
        // @s-ignore
        // throw new Error(error?.message)
        return error
      }
    },

    {
      getNextPageParam: (lastPage: any) =>
        // lastPage?.length >= 20 ? pages.length * 20 : false

        lastPage?.pagination?.next_page ?? undefined,
      onError: (error: any) => console.log('WTF', error),
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: 0,
      keepPreviousData: false,
    },
  )

export const invalidateTaskHistory = (id) => queryClient.invalidateQueries({ queryKey: getTaskHistoryList(id) })
