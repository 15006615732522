export const createPromptUrlParams = (params) => {
  const searchParams = new URLSearchParams(params)

  return `${searchParams.toString()}`
}

export const createPromptString = (formData) => {
  let promptString = ''
  // eslint-disable-next-line
  for (const key in formData) {
    if (typeof formData[key] !== 'boolean' && formData[key]) {
      promptString += `input[${key}]=${encodeURIComponent(formData[key])}&`
    }

    if (typeof formData[key] === 'boolean') {
      promptString += `input[${key}]=${Number(formData[key])}&`
    }
  }

  return promptString.slice(0, -1)
}

export const createPromptStringSimple = (input) => {
  let promptString = ''
  promptString += `input[data]=${encodeURIComponent(input)}&`

  return promptString.slice(0, -1)
}

export const createSelectOptionsFromArray = (array = []) =>
  array.map((item) => ({
    value: item,
    label: item,
  }))

export const copyToClipboard = async (text: string, cb: any) => {
  navigator.clipboard
    .writeText(text)
    .then(
      () => {
        cb()
      },
      (err) => {
        console.error('Async: Could not copy text: ', err)
      },
    )
    .catch((error: any) => console.error('can not copy text', error))
}
