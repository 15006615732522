import { useNavigate, useSearch } from '@tanstack/react-location'
import { useEffect } from 'react'
import { Loader } from 'src/core/ds/loader'
import { useSendCode } from 'src/modules/magicLink/query'
import { pushNotification } from 'src/services/notifications/notificationService'
import styles from './index.module.scss'

export function MagicLinkPage() {
  const { email, code } = useSearch()
  const navigate = useNavigate()

  const { isLoading, isError, mutate } = useSendCode()

  const onLoginSuccess = () => {
    pushNotification({
      type: 'success',
      title: 'Success Login',
      subtitle: 'You will be redirected.',
    })
    navigate({ to: `/zen` })
  }

  const onRedirect = () => {
    onLoginSuccess()
  }

  useEffect(() => {
    mutate({ email, code, onRedirect } as any)
  }, [])

  if (isLoading) {
    return (
      <div className={styles.centered}>
        <Loader isBig />
      </div>
    )
  }

  if (isError) {
    return (
      <div className={styles.centered}>
        <div>The link is not valid anymore.</div>
        <a href="/">Go home</a>
      </div>
    )
  }

  return (
    <div className={styles.centered}>
      <Loader isBig />
    </div>
  )
}
