import { useMutation } from 'react-query'
import { pushNotification } from '../notifications/notificationService'
import { PLAUSIBLE_EVENTS } from '../plausible/consts'
import { firePlausibleEvent } from '../plausible/plausibleService'
import { saveEmail } from './api'

export const useEmailService = () => {
  const useSubscribeEmail = () =>
    useMutation(saveEmail, {
      onMutate: (variables: any) => {
        if (!variables.token) {
          throw Error('No token provided')
        }
      },
      onSuccess: (data: any) => {
        const [error] = data

        if (error) {
          pushNotification({
            type: 'error',
            title: 'Error creating request.',
            subtitle: 'Token is expired after 5 mins on website. Refresh the page and try again.',
          })
          throw Error(error)
        }

        pushNotification({
          type: 'success',
          title: 'You successfully subscribed.',
          subtitle: 'You will receive an email soon.',
        })
        // Insert Twitter Event ID
        window?.twq('event', 'tw-od9qx-oeg6h', {})
        firePlausibleEvent(PLAUSIBLE_EVENTS.SUBSCRIBE)
      },
      onError: (err: any) => {
        pushNotification({
          type: 'error',
          title: 'Cant send email',
          subtitle: 'Try again later.',
        })

        return err
      },
    })

  return {
    useSubscribeEmail,
  }
}
