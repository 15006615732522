import { Button } from 'src/core/ds/button'
import { ButtonVariants } from 'src/core/ds/button/types'
import { Icon, IconNames, IconSize } from 'src/core/ds/icon'
import { Loader } from 'src/core/ds/loader'
import { useGetTags } from 'src/modules/tags/query'
import styles from './index.module.scss'

const MAX_TAGS_AMOUNT = 3

export function PickTags({ currentFormState, updateFormState, onNext, onPrev }) {
  const { data: tagsList, isLoading } = useGetTags()
  const selectedTags = currentFormState?.tags?.map(String) ?? []

  const addOrRemoveTag = (tagId) => {
    let existedTags = [...selectedTags]

    if (selectedTags.includes(tagId)) {
      existedTags = existedTags.filter((tag) => tag.toString() !== tagId.toString())
    } else {
      existedTags.push(tagId.toString())
    }

    updateFormState('tags', existedTags)
  }

  if (isLoading) {
    return (
      <div>
        <Loader isBig />
      </div>
    )
  }

  const isNextButtonDisabled = selectedTags.length > MAX_TAGS_AMOUNT || selectedTags.length === 0

  return (
    <div>
      <h2 className={styles.wizardTitle}>Pick Relevant Tags</h2>
      <div className={styles.wizardDescription}>
        The tags field helps potential customers find your prompt more easily by organizing it into relevant tags.
        Choose the tag that best fits the prompts&apos;s purpose and intended audience.
      </div>

      <div className={styles.left}>
        Max tags: &nbsp;
        {MAX_TAGS_AMOUNT}
      </div>

      <div className={styles.tagsList}>
        {tagsList.map((tag) => (
          <button
            className={selectedTags.includes(tag.id.toString()) ? styles.activeTag : undefined}
            onClick={() => addOrRemoveTag(tag.id.toString())}
            key={tag.id.toString()}
            type="button"
            disabled={!selectedTags.includes(tag.id.toString()) && selectedTags.length === MAX_TAGS_AMOUNT}
          >
            <span>{tag.emoji}</span>
            <p>{tag.name}</p>
            {selectedTags.includes(tag.id.toString()) && (
              <div className={styles.activeCheck}>
                <Icon size={IconSize.xs} name={IconNames.check} color="var(--color-primary)" />
              </div>
            )}
          </button>
        ))}
      </div>

      <div className={styles.actionFooter}>
        <Button variant={ButtonVariants.secondary} onClick={onPrev} type="button" label="Go back" />
        <Button onClick={onNext} disabled={isNextButtonDisabled} type="button" label="Continue" />
      </div>
    </div>
  )
}
