import DALLELogo from '../../assets/images/dallelogo.svg'
import GPTLogo from '../../assets/images/gptlogo.svg'
import styles from './index.module.scss'

const AILogoMap = {
  ChatGPT: <img src={GPTLogo} alt="GPT" />,
  'DALL·E 2': <img src={DALLELogo} alt="DALL-E 2" />,
}

export const AINamesMap = {
  ChatGPT: 'Text AI',
  'DALL·E 2': 'Image AI',
}

export function AIModelsLogo({ aiModel }: any) {
  return (
    <div className={styles.wrapper}>
      {AILogoMap[aiModel]}
      <span>{AINamesMap[aiModel]}</span>
    </div>
  )
}
