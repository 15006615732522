import { useQuery } from 'react-query'
import { getAccountStatsById, getAccountStatsSummaryById, getTaskStatsById } from 'src/queries/reactQueryKeys'
import { api } from './api'

export const useGetTaskStats = (id) =>
  useQuery(
    getTaskStatsById(id),
    async () => {
      if (!id) return []

      const response = await api.getTaskStats({ id })
      const [error, data] = response as any[]

      if (error) {
        throw new Error(error)
      }

      return data
    },
    {
      enabled: !!id,
    },
  )

export const useGetAccountStats = (id) =>
  useQuery(
    getAccountStatsById(id),
    async () => {
      if (!id) return []

      const response = await api.getAccountStats({ id })
      const [error, data] = response as any[]

      if (error) {
        throw new Error(error)
      }

      return data
    },
    {
      enabled: !!id,
    },
  )

export const useGetAccountStatsSummary = (id) =>
  useQuery(
    getAccountStatsSummaryById(id),
    async () => {
      if (!id) return []

      const response = await api.getAccountStatsSummary({ id })
      const [error, data] = response as any[]

      if (error) {
        throw new Error(error)
      }

      return data
    },
    {
      enabled: !!id,
    },
  )
