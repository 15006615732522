import { useState } from 'react'
import { Avatar } from 'src/core/ds/avatar'
import { Button } from 'src/core/ds/button'
import { Input } from 'src/core/ds/input'
import { Textarea } from 'src/core/ds/textarea'
import { useUpdateProfile } from '../currentUser/query'
import styles from './index.module.scss'

export function UpdateProfileModal({ currentUser }) {
  const [formData, setFormData] = useState({
    full_name: currentUser?.full_name,
    description: currentUser?.description,
    username: currentUser?.username,
  })

  const { mutate, isLoading } = useUpdateProfile()
  const avatarEmailHash = currentUser?.avatar_url.split('/')[4] ?? null

  const onSubmit = async (event) => {
    event.preventDefault()

    // if (!formData.full_name && !formData.description) {
    //   return
    // }

    // const params = createParamsString(formData)
    await mutate({
      update: formData
    })
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }))
  }

  return (
    <div className={styles.wrapper}>
      <h1>Update profile</h1>

      <form onSubmit={onSubmit}>
        {avatarEmailHash && (
          <div className={styles.avatarControl}>
            <Avatar url={currentUser?.avatar_url} />
            <a target="_blank" href={`https://en.gravatar.com/account/edit/${avatarEmailHash}`} rel="noreferrer">
              Update on Gravatar
            </a>
          </div>
        )}
        <div className={styles.formControl}>
          <Input
            onChange={handleChange}
            type="text"
            id="username"
            label="Username (letters only)"
            value={formData.username}
          />
        </div>
        <div className={styles.formControl}>
          <Input onChange={handleChange} type="text" id="full_name" label="Full name" value={formData.full_name} />
        </div>
        <div className={styles.formControl}>
          <Textarea
            onChange={handleChange}
            name="description"
            id="description"
            label="Description"
            value={formData.description}
          />
        </div>

        <div className={styles.actions}>
          <Button isLoading={isLoading} type="submit" label="Update" />
        </div>
      </form>
    </div>
  )
}
