import { config } from 'src/config/config'
import { apiCall } from 'src/utils/networking'

export const api = {
  getPersonalSpaces: async ({ userId, type = 'public' }) =>
    apiCall(`${config.baseAPIUrl}/space/list/${userId}/${type}`, {
      method: 'POST',
    }),

  getPersonalSpacesAll: async ({ userId }) =>
    apiCall(`${config.baseAPIUrl}/space/list/${userId}`, {
      method: 'POST',
    }),
}
