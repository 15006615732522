import { AIModelsLogo, AINamesMap } from 'src/components/AIModelsLogo/AIModelsLogo'
import { Button } from 'src/core/ds/button'
import { Loader } from 'src/core/ds/loader'
import { useGetAIModels } from 'src/modules/ai/query'
import styles from './index.module.scss'

export const AIDescriptions = {
  'DALL·E 2': 'AI that generates images from textual inputs.',
  ChatGPT: 'AI language model for conversational responses.',
}

export function PickAIModel({ currentFormState, updateFormState, onNext, onPrev }) {
  console.log(onPrev)
  const { data: AIModels, isLoading } = useGetAIModels()
  const selectedModelId = currentFormState?.model

  const onPickModel = (modelId) => {
    updateFormState('model', modelId)
  }

  if (isLoading) {
    return (
      <div>
        <Loader isBig />
      </div>
    )
  }

  return (
    <div>
      <h2 className={styles.wizardTitle}>Pick AI Model</h2>
      <div className={styles.wizardDescription}>
        Choose Images AI for image generation from text or Text AI for text-content generation.
      </div>
      <div className={styles.aiModels}>
        {AIModels.map((model) => (
          <button
            onClick={() => onPickModel(model.id.toString())}
            key={model.id.toString()}
            type="button"
            className={`${styles.aiModel} ${
              selectedModelId?.toString() === model.id.toString() ? styles.activeModel : undefined
            }`}
          >
            <div className={styles.aiLogo}>
              <AIModelsLogo aiModel={model.name} />
            </div>
            <div className={styles.aiName}>{AINamesMap[model.name]}</div>
            <div className={styles.aiDescription}>{AIDescriptions[model.name]}</div>
          </button>
        ))}
      </div>

      <div className={styles.actionFooter}>
        <Button onClick={onNext} disabled={!selectedModelId} type="button" label="Continue" />
      </div>
    </div>
  )
}
