import { useQuery } from 'react-query'
import { getSelectedTaskKey } from 'src/queries/reactQueryKeys'
import { pushNotification } from 'src/services/notifications/notificationService'
import { api } from './api'

export const useGetTaskInfo = ({ id, onError }: any) =>
  useQuery(getSelectedTaskKey(id), async () => {
    try {
      if (!id) return null

      const response = await api.getTaskById({ id })
      const [error, data] = response as any[]

      if (error) {
        console.log(onError)

        throw new Error(error)
      }

      return data
    } catch (error) {
      if (error?.toString().includes('e_task_not_published')) {
        // show notification
        return pushNotification({
          type: 'error',
          title: 'This task is not published yet.',
        })
      }

      if (error?.toString().includes('e_task_not_found')) {
        // show notification
        pushNotification({
          type: 'error',
          title: 'This task is not found.',
        })

        return { notFound: true }
      }

      pushNotification({
        type: 'error',
        title: 'Error occured.',
      })
    }

    return null
  })
