import { Link } from '@tanstack/react-location'
import styles from './index.module.scss'

export function NotAuthorized() {
  return (
    <div className={styles.wrapper}>
      <h1>You are not Authorized.</h1>
      <p>Please, Sign in.</p>
      <p>
        <Link style={{ color: 'var(--color-primary)' }} to="/marketplace">
          Go to marketplace
        </Link>
      </p>
    </div>
  )
}
