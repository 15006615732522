import { useDeferredValue, useState } from 'react'
import { SpaceCard } from 'src/components/SpaceCard/SpaceCard'
import { Button } from 'src/core/ds/button'
import { ButtonVariants } from 'src/core/ds/button/types'
import { IconNames } from 'src/core/ds/icon'
import { Input } from 'src/core/ds/input'
import { Loader } from 'src/core/ds/loader'
import { TabsLinks } from 'src/core/ds/tabsLinks'
import { useGetCurrentUser } from 'src/modules/currentUser/query'
import { useUIStore } from 'src/services/store/UIStore'
import VideoSrc from '../../assets/images/spaces.webm'
import styles from './index.module.scss'
import { useGetPersonalSpacesAll } from './query'

const tabs = [
  { link: '/spaces', label: 'My spaces' },
  { link: '/spaces/public', label: 'Public spaces' },
]

// const sortOptions = [
//   { value: 'popular', label: 'Popular' },
//   { value: 'recent', label: 'Recent' },
//   { value: 'alphabet', label: 'Alphabet' },
// ]

export function MyPersonalSpacePage() {
  const updateUIStore = useUIStore((state) => state.updateUIStore)
  // const personalSpaceSorting = useUIStore((state) => state.personalSpaceSorting)
  const [searchInput, setSearchInput] = useState('')
  const deferredSearchInput = useDeferredValue(searchInput)
  const { data: currentUser, isLoading: isLoadingUser } = useGetCurrentUser()
  const isPROAccount = useUIStore((state) => state.isPROAccount)

  const { data: personalSpaces, isLoading } = useGetPersonalSpacesAll(currentUser?.id.toString())
  const data: any = personalSpaces?.pages?.flatMap((page) => page?.items ?? page) ?? []

  // const onSortChange = (e) => {
  //   updateUIStore('personalSpaceSorting', e.target.value)
  // }

  const openCreateNewSpaceModal = () => {
    updateUIStore('isCreateNewSpaceOpen', true)
  }

  const onEdit = (spaceId) => {
    updateUIStore('isEditSpaceModalOpen', true)
    updateUIStore('editSpaceBuffer', spaceId)
  }

  const onRemove = async (spaceId) => {
    updateUIStore('isDeleteSpaceModalOpen', true)
    updateUIStore('deleteSpaceBuffer', spaceId)
  }

  const isSearchView = deferredSearchInput.length > 2

  const foundTasks = data?.filter((task) => task.title.toLowerCase().includes(deferredSearchInput.toLowerCase()))

  if (isLoadingUser) {
    return (
      <div className={styles.wrapper}>
        <Loader isBig />
      </div>
    )
  }

  if (!isLoading && !currentUser) {
    return <div className={styles.wrapper}>You need to be authorized to see this page</div>
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1>My Zen Spaces</h1>
        <p>Manage your pesonal tasks with ease.</p>
      </div>

      <div className={styles.controls}>
        <div className={styles.filters}>
          {/* <Selector hasDefault={false} onChange={onSortChange} options={sortOptions} selected={personalSpaceSorting} /> */}
          <Input
            icon={IconNames.search}
            type="text"
            onChange={(e) => setSearchInput(e.target.value)}
            placeholder="Search"
            value={searchInput}
          />
        </div>
        <div className={styles.listTabs}>
          <TabsLinks currentIndex={0} tabs={tabs} />
        </div>
      </div>

      {!isLoading && data.length === 0 && currentUser && (
        <div className={styles.onboarding}>
          <h2>Introducing Zen Spaces.</h2>
          <div className={styles.contentBox}>
            <div className={styles.video}>
              <video playsInline loop muted autoPlay>
                <source src={VideoSrc} type="video/webm" />
              </video>
            </div>
            <div className={styles.content}>
              <p>
                We are excited to introduce you to a new way of organizing all your tasks. Zen Spaces allow you to
                create personalized spaces to store and manage your tasks based on your needs.
              </p>
              <p>
                Creating a Zen Space is easy, simply give it a name and start adding prompts to it. You can create
                spaces for different purposes such as &quot;My Everyday Tasks,&quot; &quot;Marketing Tools,&quot; or
                &quot;Creative Writing.&quot;
              </p>
              <p>
                You have the option to make your spaces public or private. Public spaces are visible to everyone and can
                be shared on social networks or with friends. If you prefer to keep your spaces private, only you can
                access them.
              </p>
              <p>
                With Zen Spaces, you can easily find and access the tasks you need, saving time and increasing
                productivity. We hope this new feature helps you stay organized and focused on what matters most.
              </p>
              {!isPROAccount && <p>Available only for PRO accounts.</p>}
              {isPROAccount && (
                <div className={styles.onboardingAction}>
                  <Button
                    onClick={openCreateNewSpaceModal}
                    variant={ButtonVariants.secondary}
                    type="button"
                    label="Create first space"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {data.length !== 0 && currentUser && (
        <div className={styles.newSpace}>
          <Button
            disabled={!isPROAccount}
            onClick={openCreateNewSpaceModal}
            icon={isPROAccount ? IconNames.plus : IconNames.pro}
            type="button"
            label="Create space"
          />
        </div>
      )}

      {isLoading && (
        <div className={styles.list}>
          <Loader isBig />
        </div>
      )}

      {!isSearchView && (
        <div className={styles.list}>
          {data.length === 0 && <p>No spaces created.</p>}

          {data.map((space) => (
            <SpaceCard onRemove={onRemove} showControls onEdit={onEdit} key={space.id.toString()} space={space} />
          ))}
        </div>
      )}

      {isSearchView && (
        <div className={styles.list}>
          {foundTasks.length === 0 && <p>Nothing to show</p>}

          {foundTasks.map((space) => (
            <SpaceCard onRemove={onRemove} showControls onEdit={onEdit} key={space.id.toString()} space={space} />
          ))}
        </div>
      )}
    </div>
  )
}
