import { useEffect, useState } from 'react'
import { Button } from 'src/core/ds/button'
import { Icon, IconNames } from 'src/core/ds/icon'
import { Input } from 'src/core/ds/input'
import { useAuthStore } from 'src/services/store/AuthStore'
import { useSendAuthEmailRequest, useSendCode } from '../query'
import styles from './index.module.scss'

export function MagicLinkForm({ onLoginSuccess }) {
  const email = useAuthStore((state) => state.email)
  const setEmail = useAuthStore((state) => state.setEmail)
  const isCodeStep = useAuthStore((state) => state.isCodeStep)
  const setIsCodeStep = useAuthStore((state) => state.setIsCodeStep)

  // const [email, setEmail] = useState('')
  // const [isCodeStep, setIsCodeStep] = useState(false)
  const [loginCode, setLoginCode] = useState('')

  const onChange = (e) => {
    setEmail(e.target.value)
  }

  const onChangeLoginCode = (e) => {
    setLoginCode(e.target.value)
  }

  const onRedirect = () => {
    onLoginSuccess()
  }

  const { isLoading, data, mutate } = useSendAuthEmailRequest()

  const { isLoading: isLoadingSendCode, isSuccess: isSuccessSendCode, mutate: mutateCode } = useSendCode()

  const isFormDisabled = isLoading || isCodeStep

  const ifCodeFormDisabled = isLoadingSendCode || isSuccessSendCode

  useEffect(() => {
    if (!data) return

    setIsCodeStep(true)
  }, [data])

  const onSubmit = async (e) => {
    e.preventDefault()

    const refId = localStorage.getItem('refId')
    // eslint-disable-next-line
    // @ts-ignore
    const adsDetails = JSON.parse(localStorage.getItem('adsSource')) || null

    await mutate({ email, refId, utmDetails: adsDetails } as any)
  }

  const onSubmitCode = async (e) => {
    e.preventDefault()

    await mutateCode({ email, code: loginCode, onRedirect } as any)
  }

  const onReset = () => {
    // setEmail('')
    setIsCodeStep(false)
  }

  return (
    <div className={styles.formContainer}>
      <span className={styles.title}>Enter with email</span>
      <p>
        No password is required.
        <br />
        You need to obtain a new one-time code each time you log in.
      </p>

      <form onSubmit={onSubmit}>
        <Input
          label="Email"
          onChange={onChange}
          value={email}
          type="email"
          inputMode="email"
          autofocus
          id="email-code"
          placeholder="Enter your email address..."
          action={
            isCodeStep && (
              // eslint-disable-next-line
              <button className={styles.action} onClick={onReset} type="button">
                <Icon name={IconNames.close} />
              </button>
            )
          }
        />
        {!isCodeStep && (
          <Button isLoading={isLoading} disabled={isFormDisabled} type="submit" label="Get one-time login code" />
        )}
      </form>

      {isCodeStep && (
        <div className={styles.temp}>
          <p>
            We just sent you a one-time login code. Please, check your inbox or spam. May take a few minutes. Code
            expires in 5 mins.
          </p>

          <form onSubmit={onSubmitCode}>
            <Input
              isDisabled={ifCodeFormDisabled}
              label="One-time code"
              onChange={onChangeLoginCode}
              value={loginCode}
              // eslint-disable-next-line
              inputMode="numeric"
              pattern="[0-9]*"
              type="number"
              autofocus
              id="email-code"
              placeholder="Paste 5-digits login code"
            />
            <Button
              disabled={ifCodeFormDisabled || loginCode.length !== 5}
              isLoading={isLoadingSendCode}
              type="submit"
              label="Continue with one-time code"
            />
          </form>
        </div>
      )}
    </div>
  )
}
