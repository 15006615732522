import { useMatch } from '@tanstack/react-location'
import { AsideZenSpaces } from 'src/components/AsideZenSpaces/AsideZenSpaces'
import { Stream } from 'src/components/Stream'
import { StreamHistory } from 'src/components/StreamHistory'
// import { TaskDetails } from 'src/components/TaskDetails'
import { breaks } from 'src/constants/breaks'
import { useMedia } from 'src/hooks/useMedia'
import { AsideHistory } from 'src/modules/asideHistory/asideHistory'
import styles from './index.module.scss'

export function DashboardPage() {
  const {
    params: { taskId, historyId },
  } = useMatch()

  const screenWidth = useMedia()
  const isVisibleOnDesktop = screenWidth > breaks.tablet

  return (
    <div className={styles.dashboard}>
      {isVisibleOnDesktop && <AsideHistory taskId={taskId} historyId={historyId} />}
      <div className={styles.content}>
        {historyId ? <StreamHistory historyId={historyId} /> : <Stream taskId={taskId} />}
      </div>
      {isVisibleOnDesktop && <AsideZenSpaces key="zen" />}
      {/* {isVisibleOnDesktop && <TaskDetails taskId={taskId} />} */}
    </div>
  )
}
