import { config } from 'src/config/config'
import { apiCall } from 'src/utils/networking'

export const api = {
  getCategoriesList: async () =>
    apiCall(`${config.baseAPIUrl}/category/list`, {
      method: 'POST',
    }),

  getBlogList: async () =>
    apiCall(`${config.baseAPIUrl}/blog/list`, {
      method: 'POST',
    }),
}
