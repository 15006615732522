import styles from './index.module.scss'

export const stepsDictionary = {
  ChatGPT: ['Create team', 'Add members', 'Add spaces'],
}

export function ProgressBar({ type, step }: any) {
  if (typeof step === undefined || !type) return null

  return (
    <div className={styles.progressBox}>
      <div className={styles.wizardSteps}>
        {stepsDictionary[type].map((currStep, index) => (
          <div className={`${styles.wizardStepsItem} ${index <= step ? styles.activeStep : undefined}`}>
            <div className={styles.wizardStepsItemIndex}>{index + 1}</div>
            <div className={styles.wizardStepsItemName}>{currStep}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

// pick model - pick category - public card - info card
// price block - context - prompts and variables
