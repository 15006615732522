import { Link } from '@tanstack/react-location'
import { AIModelsLogo, AINamesMap } from 'src/components/AIModelsLogo/AIModelsLogo'
import { config } from 'src/config/config'
import { useGetCurrentUser } from 'src/modules/currentUser/query'
import { useGetTags } from 'src/modules/tags/query'
import { PLAUSIBLE_EVENTS } from 'src/services/plausible/consts'
import { firePlausibleEvent } from 'src/services/plausible/plausibleService'
import styles from './index.module.scss'

const AIModels = [
  {
    name: 'DALL·E 2',
    description: 'test',
    id: 1,
    link: '/text-to-image',
    img: 'https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/7ae98480-1bce-4f3f-26b1-2aec68249800/small',
  },
  {
    name: 'ChatGPT',
    description: 'test2',
    id: 2,
    link: '/marketplace/task/18786889070804993',
    img: 'https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/84ec1c53-fef7-48ee-ea6c-b668d4879d00/small',
  },
]

const starterPack = [
  {
    title: 'Generate SEO-Optimized Article',
    description: 'Unleash the power of a top-notch SEO copywriter and create content that outranks any competition.',
    id: 1,
    link: '/marketplace/task/63588371699597313',
    img: 'https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/88165d65-6d4d-4ca7-7dea-234e0bf67300/small',
    event: PLAUSIBLE_EVENTS.CLICK_FAST_ARTICLE,
  },
  {
    title: 'Topic-Based Twitter Thread Generator',
    description:
      'Generate a series of emotionally-driven, human-like thread of tweets on any given topic to captivate your audience.',
    id: 2,
    link: '/marketplace/task/17434461146710017',
    img: 'https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/03eac647-fba4-46cf-0ce5-6f60c79dc700/small',
    event: PLAUSIBLE_EVENTS.CLICK_FAST_TWITTER,
  },
  {
    title: 'Generate SEO keywords',
    description: 'Generate effective SEO keywords to help increase your online visibility.',
    id: 3,
    link: '/marketplace/task/18259766736322561',
    img: 'https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/f97ba971-6005-4643-f3e8-2dd059353900/small',
    event: PLAUSIBLE_EVENTS.CLICK_FAST_SEO,
  },
  {
    title: 'Persuasive Cold Email Generator',
    description: 'Create compelling cold emails in seconds with this powerful prompt.',
    id: 4,
    link: '/marketplace/task/54325473085227009',
    img: 'https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/2dbd1bd6-a26c-46ab-0f6b-26d180bfe500/small',
    event: PLAUSIBLE_EVENTS.CLICK_FAST_EMAIL,
  },
  {
    title: 'Code in Any Language with Detailed Instructions',
    description:
      'Get guidance on coding projects in any programming language, complete with detailed working code and explanations.',
    id: 5,
    link: '/marketplace/task/56523463996735489',
    img: 'https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/e5c932ff-66a9-49c0-85c1-66cbe667d400/small',
    event: PLAUSIBLE_EVENTS.CLICK_FAST_CODE,
  },
  {
    title: 'Instant Essay Composer for Any Topic',
    description:
      'Effortlessly generate essays on any topic using our intelligent AI writer. Impress your readers with high-quality content in seconds.',
    id: 6,
    link: '/marketplace/task/54330740678066177',
    img: 'https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/771ce487-69fb-4846-ce59-d03a12d73300/small',
    event: PLAUSIBLE_EVENTS.CLICK_FAST_ESSEY,
  },
]

export const AIDescriptions = {
  'DALL·E 2': 'AI that generates images from textual inputs.',
  ChatGPT: 'AI language model for conversational responses.',
}

export function ConsumerDashboardPage() {
  const { data: currentUser } = useGetCurrentUser()

  const { data: tagsList } = useGetTags()

  return (
    <div className={styles.dashboard}>
      <div className={styles.wrapper}>
        <div className={styles.wizardContent}>
          <div>
            <h2 className={styles.wizardTitle}>Quick Start</h2>
            <div className={styles.wizardDescription}>
              Choose Images AI for image generation from text or Text AI for text-content generation.
            </div>
            <div className={styles.aiModels}>
              {AIModels.map((model) => (
                <Link
                  onClick={
                    () =>
                      firePlausibleEvent(
                        model.id.toString() === '1' ? PLAUSIBLE_EVENTS.QUICK_IMAGE : PLAUSIBLE_EVENTS.QUICK_TEXT,
                      )
                    // eslint-disable-next-line
                  }
                  to={model.link}
                  key={model.id.toString()}
                  className={styles.quickItem}
                >
                  <div className={styles.quickItemIcon}>
                    <img loading="lazy" src={model.img} alt={model.name} />
                  </div>
                  <div className={styles.quickItemText}>
                    <div className={styles.aiLogo}>
                      <AIModelsLogo aiModel={model.name} />
                    </div>
                    <span>{AINamesMap[model.name]}</span>
                    <p>{AIDescriptions[model.name]}</p>
                  </div>
                </Link>
              ))}
            </div>

            <div className={styles.or}>
              <div />
              <span>Or</span>
              <div />
            </div>

            <div className={styles.starterPackContainer}>
              <h3>Starter pack</h3>

              <div className={styles.spacesList}>
                {starterPack.map((model) => (
                  <Link
                    onClick={
                      () => firePlausibleEvent(model.event)
                      // eslint-disable-next-line
                    }
                    to={model.link}
                    key={model.id.toString()}
                    className={styles.quickItem}
                  >
                    <div className={styles.quickItemIcon}>
                      <img loading="lazy" src={model.img} alt={model.title} />
                    </div>
                    <div className={styles.quickItemText}>
                      <div className={styles.aiLogo}>
                        <AIModelsLogo aiModel={model.img} />
                      </div>
                      <span>{model.title}</span>
                      <p>{model.description}</p>
                    </div>
                  </Link>
                ))}
              </div>
            </div>

            <div className={styles.or}>
              <div />
              <span>Or</span>
              <div />
            </div>

            <div className={styles.wizardDescriptionShort}>
              <h3>Discover ZenSpaces</h3>

              <p>A collection of prompts designed for specific types of tasks.</p>

              <div className={styles.spacesList}>
                {config?.suggestedSpaces?.map((space) => (
                  <Link
                    key={space.id.toString()}
                    onClick={
                      () => firePlausibleEvent(PLAUSIBLE_EVENTS.QUICK_SPACE)
                      // eslint-disable-next-line
                    }
                    to={`/spaces/${space.id.toString()}`}
                    className={styles.aiModel}
                  >
                    <div className={styles.aiName}>{space.title}</div>
                    <div className={styles.aiDescription}>{space?.description}</div>
                  </Link>
                ))}
              </div>
            </div>

            <div className={styles.or}>
              <div />
              <span>Or</span>
              <div />
            </div>

            <div className={styles.wizardDescriptionShort}>
              Pick a topic from our awesome choices and start your own special adventure made just for you!
            </div>

            <div className={styles.tagsList}>
              {tagsList?.map((tag) => (
                <Link
                  onClick={() => firePlausibleEvent(PLAUSIBLE_EVENTS.QUICK_TAG)}
                  className={styles.tagItem}
                  to={`/marketplace/${tag.id}/`}
                  key={tag.id.toString()}
                >
                  <span>{tag.emoji}</span>
                  <p>{tag.name}</p>
                </Link>
              ))}
            </div>

            {currentUser && (
              <div className={styles.or}>
                <div />
                <span>Or</span>
                <div />
              </div>
            )}

            {currentUser && (
              <div className={styles.market}>
                <Link
                  onClick={() => firePlausibleEvent(PLAUSIBLE_EVENTS.QUICK_CREATE)}
                  className={styles.createSolution}
                  to="/marketplace/create"
                >
                  <div className={styles.aiName}>Create your own prompt</div>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
