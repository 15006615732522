// eslint-disable-next-line
// @ts-nocheck
import ReactMarkdown from 'markdown-to-jsx'
// import rangeParser from 'parse-numeric-range'
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter'
// eslint-disable-next-line
import bash from 'react-syntax-highlighter/dist/cjs/languages/prism/bash'
// eslint-disable-next-line
import css from 'react-syntax-highlighter/dist/cjs/languages/prism/css'
// eslint-disable-next-line
import docker from 'react-syntax-highlighter/dist/cjs/languages/prism/docker'
// eslint-disable-next-line
import javascript from 'react-syntax-highlighter/dist/cjs/languages/prism/javascript'
// eslint-disable-next-line
import json from 'react-syntax-highlighter/dist/cjs/languages/prism/json'
// eslint-disable-next-line
import markdown from 'react-syntax-highlighter/dist/cjs/languages/prism/markdown'
// eslint-disable-next-line
import php from 'react-syntax-highlighter/dist/cjs/languages/prism/php'
// eslint-disable-next-line
import python from 'react-syntax-highlighter/dist/cjs/languages/prism/python'
// eslint-disable-next-line
import rust from 'react-syntax-highlighter/dist/cjs/languages/prism/rust'
// eslint-disable-next-line
import scss from 'react-syntax-highlighter/dist/cjs/languages/prism/scss'
// eslint-disable-next-line
import tsx from 'react-syntax-highlighter/dist/cjs/languages/prism/tsx'
// eslint-disable-next-line
import typescript from 'react-syntax-highlighter/dist/cjs/languages/prism/typescript'
// eslint-disable-next-line
import vim from 'react-syntax-highlighter/dist/cjs/languages/prism/vim'
// eslint-disable-next-line
import yaml from 'react-syntax-highlighter/dist/cjs/languages/prism/yaml'
// eslint-disable-next-line
import { oneDark } from 'react-syntax-highlighter/dist/cjs/styles/prism'
import { PricesSmall } from 'src/components/Prices/Prices'
// import remarkGfm from 'remark-gfm'
import { Button } from 'src/core/ds/button'
import { ButtonVariants } from 'src/core/ds/button/types'
import { Coupone } from 'src/modules/Coupone/Coupone'
import { SmallImageGenerator } from 'src/pages/Text2Ai/SmallImageGenerator'
import { pushNotification } from 'src/services/notifications/notificationService'
import styles from './index.module.scss'

SyntaxHighlighter.registerLanguage('python', python)
SyntaxHighlighter.registerLanguage('tsx', tsx)
SyntaxHighlighter.registerLanguage('yaml', yaml)
SyntaxHighlighter.registerLanguage('php', php)
SyntaxHighlighter.registerLanguage('vim', vim)
SyntaxHighlighter.registerLanguage('typescript', typescript)
SyntaxHighlighter.registerLanguage('javascript', javascript)
SyntaxHighlighter.registerLanguage('scss', scss)
SyntaxHighlighter.registerLanguage('css', css)
SyntaxHighlighter.registerLanguage('bash', bash)
SyntaxHighlighter.registerLanguage('rust', rust)
SyntaxHighlighter.registerLanguage('docker', docker)
SyntaxHighlighter.registerLanguage('markdown', markdown)
SyntaxHighlighter.registerLanguage('json', json)

// function Pre({ children }) {
//   const handleClick = (e) => {
//     e.preventDefault()

//     navigator.clipboard.writeText(children.props.children)
//     pushNotification({
//       type: 'success',
//       title: 'Code copied to clipboard',
//     })
//   }

//   return (
//     <pre className={styles.wrapper}>
//       <div className={styles.copy}>
//         <Button onClick={handleClick} label="copy code" variant={ButtonVariants.secondary} type="button" />
//       </div>
//       {children}
//     </pre>
//   )
// }

const handleClickCopy = (text) => {
  navigator.clipboard.writeText(text)
  pushNotification({
    type: 'success',
    title: 'Code copied to clipboard',
  })
}

function CodeBlock({ className, children }) {
  let lang = 'text' // default monospaced text

  if (className && className.startsWith('lang-')) {
    lang = className.replace('lang-', '')
  }

  return (
    <pre className={styles.wrapper}>
      <div className={styles.copy}>
        {lang && <span className={styles.lang}>{lang}</span>}
        <Button
          onClick={() => handleClickCopy(children)}
          label="copy code"
          variant={ButtonVariants.secondary}
          type="button"
        />
      </div>
      <SyntaxHighlighter language={lang} style={oneDark} className="codeStyle" showLineNumbers useInlineStyles>
        {children}
      </SyntaxHighlighter>
    </pre>
  )
}

// markdown-to-jsx uses <pre><code/></pre> for code blocks.
function PreBlock({ children, ...rest }) {
  if ('type' in children && children?.type === 'code') {
    return CodeBlock(children?.props)
  }

  // eslint-disable-next-line
  return <pre {...rest}>{children}</pre>
}

// function Code({ children, language }) {
//   const syntaxTheme = oneDark

//   // const language = className?.replace('lang-', '') || null

//   // if (!language) {
//   //   return (
//   //     <span className="pureCode">
//   //       {/* eslint-disable-next-line */}
//   //       <code className={className} {...props}>
//   //         {props.children}
//   //       </code>
//   //     </span>
//   //   )
//   // }

//   return (
//     <SyntaxHighlighter
//       // style={oneDark}
//       // PreTag="div"
//       // className="codeStyle"
//       // showLineNumbers
//       // wrapLines={hasMeta}
//       // useInlineStyles
//       // lineProps={applyHighlights}
//       language={language}
//     >
//       <code>{children}</code>
//     </SyntaxHighlighter>
//   )
// }

const tagsToExclude = ['<Prices />', '<PricesSmall />', '<Coupone />', '<SmallImageGenerator />']
// eslint-disable-next-line
export function Markdown({ markdown }: any) {
  // const syntaxTheme = oneDark
  const isDisabledHTMLParse = tagsToExclude.some((str) => markdown.includes(str))
  // // eslint-disable-next-line
  // const MarkdownComponents: any = {
  //   pre: Pre,
  //   // eslint-disable-next-line
  //   code({ node, inline, className, ...props }) {
  //     const hasLang = /language-(\w+)/.exec(className || '')
  //     const hasMeta = node?.data?.meta

  //     const applyHighlights: object = (applyHighlights: number) => {
  //       if (hasMeta) {
  //         const RE = /{([\d,-]+)}/
  //         const metadata = node.data.meta?.replace(/\s/g, '')
  //         const strlineNumbers = RE?.test(metadata) ? RE?.exec(metadata)[1] : '0'
  //         const highlightLines = rangeParser(strlineNumbers)
  //         const highlight = highlightLines
  //         const data: any = highlight.includes(applyHighlights) ? 'highlight' : null

  //         return { data }
  //       }

  //       return {}
  //     }

  //     return hasLang ? (
  //       <SyntaxHighlighter
  //         style={syntaxTheme}
  //         language={hasLang[1]}
  //         PreTag="div"
  //         className="codeStyle"
  //         showLineNumbers
  //         wrapLines={hasMeta}
  //         useInlineStyles
  //         lineProps={applyHighlights}
  //       >
  //         {props.children}
  //       </SyntaxHighlighter>
  //     ) : (
  //       // <SyntaxHighlighter useInlineStyles language="javascript" style={syntaxTheme}>
  //       //   {props.children}
  //       // </SyntaxHighlighter>
  //       <span className="pureCode">
  //         {/* eslint-disable-next-line */}
  //         <code className={className} {...props}>
  //           {props.children}
  //         </code>
  //       </span>
  //     )
  //   },
  // }

  return (
    <ReactMarkdown
      options={{
        disableParsingRawHTML: !isDisabledHTMLParse,
        forceBlock: true,
        overrides: {
          // code: {
          //   component: Code,
          // },
          a: {
            component: 'a',
            props: {
              rel: 'noopener', // Security, avoids external site opened through your site to have control over your site
              target: '_blank',
            },
          },
          pre: {
            component: PreBlock,
          },
          Prices: PricesSmall,
          PricesSmall,
          SmallImageGenerator,
          Coupone: (
            <div className={styles.coupone}>
              <Coupone />
            </div>
          ),
        },
      }}
    >
      {markdown}
    </ReactMarkdown>
  )
}
