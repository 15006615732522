import { Link, useMatch, useMatches, useNavigate } from '@tanstack/react-location'
import React, { useState } from 'react'
import { Avatar } from 'src/core/ds/avatar'
import { Button } from 'src/core/ds/button'
import { ButtonVariants } from 'src/core/ds/button/types'
import { Icon, IconNames, IconSize } from 'src/core/ds/icon'
import { Modal } from 'src/core/ds/modal'
import { AsideHistory } from 'src/modules/asideHistory/asideHistory'
import { useGetCurrentUser, useLogoutUser } from 'src/modules/currentUser/query'
import { MagicLinkForm } from 'src/modules/magicLink/components/Form'
import { MySpendingsModal } from 'src/modules/mySpendings'
import { Spotlight } from 'src/modules/Spotlight/Spotlight'
import { UpdateProfileModal } from 'src/modules/updateProfileForm'
import { HistoryLine } from 'src/pages/CurrentSpaceLayout/HistoryLine'
import { pushNotification } from 'src/services/notifications/notificationService'
import { PLAUSIBLE_EVENTS } from 'src/services/plausible/consts'
import { firePlausibleEvent } from 'src/services/plausible/plausibleService'
import { useUIStore } from 'src/services/store/UIStore'
import { DateFormats, formatDate } from 'src/utils/dates'
import { AsideCategoryList } from '../AsideCategoryList'
import { AsideSpaceList } from '../AsideSpaceList/AsideSpaceList'
import { AsideZenSpaces } from '../AsideZenSpaces/AsideZenSpaces'
import { api } from '../DashboardUserArea/api'
import { ReversedAccordion } from '../ReversedAccordion/ReversedAccordion'
import { TaskDetails } from '../TaskDetails'
import { ThemeSwitcher } from '../ThemeSwitcher'
import styles from './index.module.scss'

export function MobileIsland() {
  const navigate = useNavigate()
  const [selectedId, setSelectedId] = useState<any>(null)

  const [isUpdateProfileModalOpen, setIsUpdateProfileModalOpen] = useState(false)
  const [isSpendingsModalOpen, setIsSpendingsModalOpen] = useState(false)

  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false)

  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false)

  const updateUIStore = useUIStore((state) => state.updateUIStore)
  const isPROAccount = useUIStore((state) => state.isPROAccount)

  const onLoginSuccess = () => {
    pushNotification({
      type: 'success',
      title: 'Success Login',
      subtitle: 'You will be redirected.',
    })
    setIsAuthModalOpen(false)
    navigate({ to: window.location.pathname })
  }

  const openAuthModal = () => {
    setIsAuthModalOpen(true)
  }

  const matches = useMatches()
  const {
    params: { taskId, historyId, categoryId, spaceId },
  } = useMatch()
  const { data: currentUser } = useGetCurrentUser()

  const onClose = () => {
    setSelectedId(null)
  }

  const { isLoading: isLoadingLogout, mutate } = useLogoutUser()

  const currRoute = matches[matches.length - 1].id
  // console.log('currRoute', currRoute)
  const isHistoryPage =
    (!currRoute.includes('spaces') && currRoute.includes('marketplace/task/')) ||
    (currRoute.includes('/history/') && !currRoute.includes('spaces'))
  const isHistoryRoute = !currRoute.includes('spaces') && currRoute.includes('history/')
  const isOpenSpacePage = currRoute.includes('spaces') && spaceId && taskId

  const isFormPage = currRoute.includes('create') || currRoute.includes('edit')

  const isCategoriesAvailable =
    currRoute.includes('creator') ||
    currRoute.includes('dashboard') ||
    (currRoute.includes('marketplace/') && !isFormPage)

  const onLogoutHandler = async () => {
    // eslint-disable-next-line
    await mutate()
  }

  const onManagePlan = async () => {
    try {
      // console.log('onManagePlan')
      const response = await api.getSubscriptionPlanLink()
      const [error, link] = response as any
      // console.log('link', link)

      if (error === 'e_customer_not_found') {
        pushNotification({
          type: 'error',
          title: 'Error',
          subtitle: 'No user found',
        })

        return
      }

      setTimeout(() => {
        // eslint-disable-next-line
        // @ts-ignore
        window?.open(link, '_blank').focus()
      })

      // console.log('response', response)
    } catch (err) {
      console.log(err)
      pushNotification({
        type: 'error',
        title: 'Error',
        subtitle: "Can't get subscription plan link",
      })
    }
  }

  return (
    <div className={styles.wrapper}>
      <Modal
        withCloseButton
        type="error"
        onDeny={() => setIsLogoutModalOpen(false)}
        onConfirm={onLogoutHandler}
        onClose={() => setIsLogoutModalOpen(false)}
        isOpen={isLogoutModalOpen}
        body={
          // eslint-disable-next-line
          <div className={styles.logoutModal}>
            <h1>Are you sure you want to logout?</h1>
          </div>
        }
      />

      <Modal
        withCloseButton
        onClose={() => setIsAuthModalOpen(false)}
        isOpen={isAuthModalOpen}
        body={<MagicLinkForm onLoginSuccess={onLoginSuccess} />}
      />

      <Modal
        withCloseButton
        onClose={() => setIsUpdateProfileModalOpen(false)}
        isOpen={isUpdateProfileModalOpen}
        body={<UpdateProfileModal currentUser={currentUser} />}
      />
      <Modal
        withCloseButton
        onClose={() => setIsSpendingsModalOpen(false)}
        isOpen={isSpendingsModalOpen}
        body={<MySpendingsModal />}
      />

      <div className={`${styles.inner} ${selectedId !== null ? styles.animated : ''}`}>
        <ReversedAccordion onClose={onClose} isOpen={selectedId}>
          <div className={styles.content}>
            {selectedId === 'categories' && <AsideCategoryList taskId={taskId} categoryId={categoryId} />}
            {selectedId === 'solutions' && <AsideSpaceList currentUser={currentUser} spaceId={spaceId} />}
            {selectedId === 'details' && <TaskDetails taskId={taskId} />}
            {selectedId === 'history' && <AsideHistory taskId={taskId} historyId={historyId} />}
            {selectedId === 'solutionsHistory' && (
              <HistoryLine
                setIsHistoryShown={() => console.log('open')}
                isHistoryShown
                spaceId={spaceId}
                historyId={historyId}
                taskId={taskId}
              />
            )}
            {selectedId === 'spaces' && <AsideZenSpaces />}
            {selectedId === 'userArea' && (
              <div className={styles.userMenu}>
                <div className={styles.userSummary}>
                  <Avatar url={currentUser?.avatar_url} />
                  <div className={styles.userContent}>
                    <div className={styles.userName}>{currentUser?.full_name || currentUser?.username}</div>
                    <div className={styles.email}>{currentUser?.email}</div>
                  </div>
                </div>
                <div className={styles.userMenuActions}>
                  <Button
                    variant={ButtonVariants.unstyled}
                    onClick={() => {
                      updateUIStore('isPricesPopupOpen', true)
                      firePlausibleEvent(PLAUSIBLE_EVENTS.CLICK_BUY_CREDITS)
                    }}
                    icon={IconNames.pro}
                    iconColor="var(--color-secondary)"
                    isIconOnLeft
                    type="button"
                    label="Prices"
                  />
                  <Button
                    variant={ButtonVariants.unstyled}
                    onClick={() => {
                      updateUIStore('isCouponModalOpen', true)
                    }}
                    icon={IconNames.star}
                    iconColor="var(--color-secondary)"
                    isIconOnLeft
                    type="button"
                    label="Activate coupon"
                  />
                  {isPROAccount && (
                    <Button
                      variant={ButtonVariants.unstyled}
                      onClick={onManagePlan}
                      type="button"
                      icon={IconNames.creditCard}
                      iconColor="var(--color-secondary)"
                      isIconOnLeft
                      label={`Manage plan (till ${formatDate(
                        // eslint-disable-next-line
                        // @ts-ignore
                        Number((currentUser?.sub_till || 1) * 1000) as Date,
                        DateFormats.monthWithDay,
                      )})`}
                    />
                  )}
                  <div className={styles.delimeter} />
                  <Button
                    variant={ButtonVariants.unstyled}
                    onClick={() => setIsSpendingsModalOpen(true)}
                    type="button"
                    label="My Rewards"
                    icon={IconNames.bankOut}
                    iconColor="var(--color-secondary)"
                    isIconOnLeft
                  />
                  <Button
                    variant={ButtonVariants.unstyled}
                    onClick={() => {
                      firePlausibleEvent(PLAUSIBLE_EVENTS.CLICK_AFFILIATE_LINK)
                      updateUIStore('isRefferalModalOpen', true)
                    }}
                    type="button"
                    label="Affiliate program"
                    icon={IconNames.userAdd}
                    iconColor="var(--color-secondary)"
                    isIconOnLeft
                  />
                  <Button
                    variant={ButtonVariants.unstyled}
                    onClick={() => setIsUpdateProfileModalOpen(true)}
                    type="button"
                    label="Update profile"
                    icon={IconNames.user}
                    iconColor="var(--color-secondary)"
                    isIconOnLeft
                  />
                </div>
                <div className={styles.actionsFooter}>
                  <div className={styles.userMenuActions}>
                    <Button
                      variant={ButtonVariants.unstyled}
                      onClick={() => setIsLogoutModalOpen(true)}
                      type="button"
                      disabled={isLoadingLogout}
                      isLoading={isLoadingLogout}
                      label="Logout"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </ReversedAccordion>

        <div style={{ display: 'none' }}>
          <ThemeSwitcher />
        </div>

        <div className={styles.dynamicContent}>
          {isOpenSpacePage && (
            <button onClick={() => setSelectedId('solutions')} type="button">
              prompts
            </button>
          )}
          {isOpenSpacePage && (
            <button onClick={() => setSelectedId('solutionsHistory')} type="button">
              history
            </button>
          )}
          {isHistoryPage && (
            <button onClick={() => setSelectedId('history')} type="button">
              history
            </button>
          )}

          {isCategoriesAvailable && currentUser && (
            <button onClick={() => setSelectedId('spaces')} type="button">
              fast actions
            </button>
          )}
          {isHistoryRoute && (
            <Link to={`/marketplace/task/${taskId}`}>
              <button type="button">new input</button>
            </Link>
          )}
        </div>

        <div className={styles.menu}>
          {currentUser && (
            <Link activeOptions={{ exact: true }} to="/zen">
              <div className={styles.item}>
                {/* <Icon name={IconNames.dashboard} size={IconSize.xs} /> */}
                <span className={styles.emoji}>⚡️</span>
                <span>Zen</span>
              </div>
            </Link>
          )}
          {/* eslint-disable-next-line */}
          <div className={styles.item}>
            <Spotlight isDesktop={false} />
          </div>
          {currentUser && (
            <Link activeOptions={{ exact: true }} to="/history">
              <div className={styles.item}>
                <Icon name={IconNames.bills} size={IconSize.xs} />
                <span>History</span>
              </div>
            </Link>
          )}
          {currentUser && (
            <Link to="/spaces">
              <div className={styles.item}>
                <Icon name={IconNames.space} size={IconSize.xs} />
                <span>Spaces</span>
              </div>
            </Link>
          )}
          <Link to="/marketplace">
            <div className={styles.item}>
              <Icon name={IconNames.billingSettings} size={IconSize.xs} />
              <span>Marketplace</span>
            </div>
          </Link>
          {!currentUser && (
            // eslint-disable-next-line
            <div onClick={openAuthModal} className={styles.item}>
              <Icon name={IconNames.user} />
              <span>Sign in</span>
            </div>
          )}
          {currentUser && (
            // eslint-disable-next-line
            <div onClick={() => setSelectedId('userArea')} className={styles.user}>
              <Avatar url={currentUser?.avatar_url} />
              <div className={styles.creds}>
                {isPROAccount && (
                  <span>
                    <Icon name={IconNames.pro} />
                  </span>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
