import { Link, useSearch } from '@tanstack/react-location'
import Pusher from 'pusher-js'
import { useEffect } from 'react'
import ProIcon from 'src/assets/images/icon-pro.svg'
import { DashboardUserArea } from 'src/components/DashboardUserArea'
import { Logo } from 'src/components/Logo/Logo'
import { MobileIsland } from 'src/components/MobileIsland/MobileIsland'
import { config } from 'src/config/config'
import { breaks } from 'src/constants/breaks'
import { Icon, IconNames, IconSize } from 'src/core/ds/icon'
import { Loader } from 'src/core/ds/loader'
import { useMedia } from 'src/hooks/useMedia'
import { useGetAIModels } from 'src/modules/ai/query'
import { useGetCurrentUser } from 'src/modules/currentUser/query'
import { ModalConnector } from 'src/modules/modalsConnector/ModalsConnector'
import { Spotlight } from 'src/modules/Spotlight/Spotlight'
import { useGetTags } from 'src/modules/tags/query'
import { PLAUSIBLE_EVENTS } from 'src/services/plausible/consts'
import { firePlausibleEvent } from 'src/services/plausible/plausibleService'
import { useUIStore } from 'src/services/store/UIStore'
import styles from './index.module.scss'

export function DashboardLayout({ children }) {
  // eslint-disable-next-line
  const { ref_id: refId, utm_content, utm_medium, utm_source, utm_campaign, utm_term } = useSearch()
  const screenWidth = useMedia()
  const isVisibleOnMobile = screenWidth < breaks.tablet
  const selectedAIModelId = useUIStore((state) => state.selectedAIModelId)
  const updateUIStore = useUIStore((state) => state.updateUIStore)
  const isPROAccount = useUIStore((state) => state.isPROAccount)
  useGetTags()
  const { data: AIModels } = useGetAIModels()
  const { data: currentUser, isLoading } = useGetCurrentUser()

  useEffect(() => {
    if (!AIModels) return

    if (selectedAIModelId) return

    updateUIStore('selectedAIModelId', (AIModels[1] && AIModels[1]?.id?.toString()) || '')
  }, [selectedAIModelId, AIModels])

  useEffect(() => {
    if (!refId) return

    localStorage.setItem('refId', refId as string)
  }, [refId])

  useEffect(() => {
    try {
      const adsSource = localStorage.getItem('adsSource') || null

      if (adsSource) return

      localStorage.setItem(
        'adsSource',
        JSON.stringify({
          // eslint-disable-next-line
          utm_medium,
          // eslint-disable-next-line
          utm_source,
          // eslint-disable-next-line
          utm_campaign,
          // eslint-disable-next-line
          utm_content,
          // eslint-disable-next-line
          utm_term,
          // eslint-disable-next-line
          //utm_url: window.location.href
        }),
      )
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    const isProAccount = currentUser?.is_pro // currentUser?.sub_till * 1000 > Date.now()
    updateUIStore('isPROAccount', isProAccount)

    if (currentUser) {
      if (!window?.pusherInstance) {
        const pusher: any = new Pusher(config.pusherKey, {
          cluster: 'eu',
        })

        window.pusherInstance = pusher
      } else {
        // pusherInstance?.disconnect()
      }
    } else {
      // setPusherInstance(null)
    }
  }, [currentUser])

  if (isLoading) {
    return <Loader isBig />
  }

  return (
    <section className={styles.dashboard}>
      <div className={styles.header}>
        <div className={styles.headerWrapper}>
          <div className={styles.leftSide}>
            <Link title="Zentask" className={styles.logo} to="/marketplace">
              <Logo />
            </Link>
            <ul>
              <li>
                <Link
                  onClick={() => firePlausibleEvent(PLAUSIBLE_EVENTS.CLICK_ZENSPACES_LINK)}
                  activeOptions={{ exact: true }}
                  to="/zen"
                >
                  {/* <Icon name={IconNames.} color="transparent" size={IconSize.xs} /> */}
                  <span>⚡️ ZenMode</span>
                </Link>
              </li>

              <li>
                <Link
                  onClick={() => firePlausibleEvent(PLAUSIBLE_EVENTS.CLICK_ZENSPACES_LINK)}
                  activeOptions={{ exact: true }}
                  to="/text-to-image"
                >
                  <Icon name={IconNames.imageAdd} size={IconSize.xs} />
                  <span>Text2Img</span>
                </Link>
              </li>

              <li>
                <Link onClick={() => firePlausibleEvent(PLAUSIBLE_EVENTS.CLICK_MARKETPLACE_LINK)} to="/marketplace">
                  <Icon name={IconNames.billingSettings} size={IconSize.xs} />
                  <span>Marketplace</span>
                </Link>
              </li>

              {currentUser && (
                <li>
                  <Link
                    onClick={() => firePlausibleEvent(PLAUSIBLE_EVENTS.CLICK_ZENSPACES_LINK)}
                    activeOptions={{ exact: true }}
                    to="/spaces/public"
                  >
                    <Icon name={IconNames.space} color="transparent" size={IconSize.xs} />
                    <span>Spaces</span>
                  </Link>
                </li>
              )}

              {currentUser && (
                <li>
                  <Link
                    onClick={() => firePlausibleEvent(PLAUSIBLE_EVENTS.CLICK_CREATOR_AREA_LINK)}
                    activeOptions={{ exact: true }}
                    to="/creator"
                  >
                    <Icon name={IconNames.dashboard} size={IconSize.xs} />
                    <span>Creator</span>
                  </Link>
                </li>
              )}

              {currentUser && (
                <li>
                  <Link
                    onClick={() => firePlausibleEvent(PLAUSIBLE_EVENTS.CLICK_CREATOR_AREA_LINK)}
                    activeOptions={{ exact: true }}
                    to="/history"
                  >
                    <Icon name={IconNames.bills} size={IconSize.xs} />
                    <span>History</span>
                  </Link>
                </li>
              )}
              {currentUser && (
                <li>
                  <Link
                    onClick={() => firePlausibleEvent(PLAUSIBLE_EVENTS.CLICK_ZENSPACES_LINK)}
                    activeOptions={{ exact: true }}
                    to="/teams"
                  >
                    <Icon name={IconNames.team} size={IconSize.xs} />
                    <span>Teams</span>
                  </Link>
                </li>
              )}
            </ul>
          </div>
          <div className={styles.centerSide}>
            <Spotlight isDesktop />
          </div>
          <div className={styles.rightSide}>
            {currentUser && !isPROAccount && (
              <button
                type="button"
                onClick={() => {
                  updateUIStore('isPricesPopupOpen', true)
                  firePlausibleEvent(PLAUSIBLE_EVENTS.CLICK_BUY_CREDITS)
                }}
                className={styles.pro}
              >
                <span>
                  Upgrade.
                  <br />
                  Save 20%
                  <img src={ProIcon} alt="" />
                </span>
              </button>
            )}
            <DashboardUserArea />
          </div>
        </div>
      </div>
      <ModalConnector />
      <section className={styles.dashboardContainer}>
        {children}
        {isVisibleOnMobile && <MobileIsland />}
      </section>
    </section>
  )
}
