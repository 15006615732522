const QUERY_KEYS = {
  CURRENT_USER: 'currentUser',
  ACCOUNT: 'account',
  AUTH: 'auth',
  PAYMENT_SOURCES: 'paymentSources',
  PAYMENT: 'payments',
  DELIVERY_METHODS: 'deliveryMethods',
  ACCOUNTS: 'accounts',
  DEPOSIT_ADDRESS: 'depositAddress',
  DEPOSIT_TRANSACTIONS: 'transactions',
  WALLET_DETAILS: 'walletDetails',
  TRADING_PAIRS: 'tradingPairs',
  SELECTED_TASK: 'selectedTask',
  TASK_HISTORY: 'taskHistory',
  HISTORY_ITEM: 'historyItem',
  CATEGORIES_LIST: 'categoriesList',
  BLOG_LIST: 'blogList',
  SPENDINGS_LIST: 'transactionsList',
  TASKS_LIST: 'tasksList',
  PERSONAL_TASKS_LIST: 'personalTasksList',
  PERSONAL_TASKS_LIST_DASHBOARD: 'personalTasksListDashboard',
  TRENDING_TASKS_LIST: 'trendingTasks',
  FAVOURITES_LIST: 'favouritesList',
  TRANSACTIONS_LIST: 'transactionsList',
  REVENUE_LIST: 'revenueList',
  REWARDS_LIST: 'rewardsList',
  TASK_STATS: 'taskStats',
  ACCOUNT_STATS: 'accountStats',
  ACCOUNT_STATS_SUMMARY: 'accountStatsSummary',
  TAGS_LIST: 'tagsList',
  AI_MODELS_LIST: 'aiModelsList',

  PERSONAL_SPACES_LIST: 'personalSpacesList',
  PUBLIC_SPACES_LIST: 'publicSpacesList',
  CURRENT_CONFIG: 'currentConfig',
  SPACE_DETAILS: 'spaceDetails',
  SPACE_DETAILS_LIST: 'spaceDetailsList',

  TEAMS_LIST: 'teamsList',
  TEAM_SPACES_DETAILS: 'teamSpacesList',
  TEAM_DETAILS: 'teamDetails',
  TEAM_MEMBERS_DETAILS: 'teamMembersDetails',
}

export const getTeamsList = () => [QUERY_KEYS.TEAMS_LIST, 'list']
export const getTeamDetails = (id) => [QUERY_KEYS.TEAM_DETAILS, 'details', id]
export const getTeamMembersList = (id) => [QUERY_KEYS.TEAM_MEMBERS_DETAILS, 'details', id]
export const getTeamSpacesList = (id) => [QUERY_KEYS.TEAM_SPACES_DETAILS, 'list', id]

export const getPublicSpacesList = () => [QUERY_KEYS.PUBLIC_SPACES_LIST, 'list']
export const getPersonalSpacesList = () => [QUERY_KEYS.PERSONAL_SPACES_LIST, 'list']
export const getPersonalSpacesListType = (type) => [QUERY_KEYS.PERSONAL_SPACES_LIST, 'list', type]
export const getSpaceDetails = (id) => [QUERY_KEYS.SPACE_DETAILS, 'details', id]
export const getSpaceDetailsList = (id) => [QUERY_KEYS.SPACE_DETAILS_LIST, 'list', id]

export const getAIModelsList = () => [QUERY_KEYS.AI_MODELS_LIST, 'list']
export const getAccountQueryKey = (accountId: string) => [QUERY_KEYS.ACCOUNTS, 'details', accountId]
export const getCurrentUserKey = () => [QUERY_KEYS.CURRENT_USER]
export const getCurrentConfig = () => [QUERY_KEYS.CURRENT_CONFIG]
export const getSelectedTaskKey = (id) => [QUERY_KEYS.SELECTED_TASK, id]
export const getWalletDetailsKey = () => [QUERY_KEYS.WALLET_DETAILS]
export const getDepositAddressKey = () => [QUERY_KEYS.DEPOSIT_ADDRESS]
// export const getTransactionsList = (type) => [QUERY_KEYS.DEPOSIT_TRANSACTIONS, 'list', type]
export const getAccountList = () => [QUERY_KEYS.ACCOUNTS, 'list']
export const getTradingPairsList = () => [QUERY_KEYS.TRADING_PAIRS, 'list']
export const getCategoriesList = () => [QUERY_KEYS.CATEGORIES_LIST, 'list']
export const getBlogList = () => [QUERY_KEYS.BLOG_LIST, 'list']
export const getTaskHistoryList = (id) => [QUERY_KEYS.TASK_HISTORY, 'list', id]
export const getHistoryItemById = (id) => [QUERY_KEYS.HISTORY_ITEM, id]
export const getSpendingsList = () => [QUERY_KEYS.SPENDINGS_LIST, 'list']
export const getTrendingTasksList = () => [QUERY_KEYS.TRENDING_TASKS_LIST, 'list']
export const getFavouritesList = () => [QUERY_KEYS.FAVOURITES_LIST, 'list']
export const getTagsList = () => [QUERY_KEYS.TAGS_LIST, 'list']

export const getCategoryInfoById = (id) => [QUERY_KEYS.CATEGORIES_LIST, 'details', id]

export const getTaskStatsById = (id) => [QUERY_KEYS.TASK_STATS, 'details', id]
export const getAccountStatsById = (id) => [QUERY_KEYS.ACCOUNT_STATS, 'details', id]
export const getAccountStatsSummaryById = (id) => [QUERY_KEYS.ACCOUNT_STATS_SUMMARY, 'details', id]

export const getTransactionsList = (type) => [QUERY_KEYS.TRANSACTIONS_LIST, 'list', type]
export const getRevenueList = () => [QUERY_KEYS.REVENUE_LIST, 'list']
export const getRewardsList = () => [QUERY_KEYS.REWARDS_LIST, 'list']

export const getTasksList = (aiId, categoryId = 'all', sorting = 'popular') => [
  QUERY_KEYS.TASKS_LIST,
  'list',
  sorting,
  categoryId,
  aiId,
]

export const getMyPersonalTasksList = (accountId, sorting = 'popular') => [
  QUERY_KEYS.PERSONAL_TASKS_LIST,
  'list',
  sorting,
  accountId,
]

export const getMyPersonalTasksListDashboard = (accountId, sorting = 'popular') => [
  QUERY_KEYS.PERSONAL_TASKS_LIST_DASHBOARD,
  'list',
  sorting,
  accountId,
]
