import { useGetBlogList } from 'src/components/AsideCategoryList/query'
import { Markdown } from 'src/components/MarkDownWrapper'
import { DateFormats, formatDate } from 'src/utils/dates'
import styles from './index.module.scss'

export function ProjectUpdatesPage() {
  const { data: blogsList } = useGetBlogList()

  return (
    <div className={styles.dashboard}>
      <div className={styles.feed}>
        <div className={styles.content}>
          <div className={styles.header}>
            <h1>Project Updates</h1>
          </div>
          <div className={styles.list}>
            {blogsList?.map((blog) => (
              <div key={blog.id.toString()} className={styles.blogItem}>
                <h3>{blog?.title}</h3>
                <p className={styles.description}>{blog?.description}</p>
                <div className={styles.blogMD}>
                  <Markdown markdown={blog?.text} />
                </div>
                <div className={styles.blogTime}>
                  {/* eslint-disable-next-line */}
                  {/* @ts-ignore */}
                  {formatDate(Number(blog.published_at * 1000) as Date, DateFormats.datePickerFullDate)}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
