import { useEffect, useState } from 'react'
import { Button } from 'src/core/ds/button'
import { ButtonVariants } from 'src/core/ds/button/types'
import { IconNames } from 'src/core/ds/icon'
import { Modal } from 'src/core/ds/modal'
import { useGetCurrentUser } from 'src/modules/currentUser/query'
import { useGetTaskInfo } from 'src/modules/tasks/query'
import styles from './index.module.scss'
import { PickAIModel } from './PickAIModel'
import { PickTags } from './PickTags'
import { ProgressBar } from './ProgressBar'
import { Prompt } from './Prompt'
import { PublicDetails } from './PublicDetails'
import { ReviewAndEdit } from './ReviewAndEdit'
import { Tabber } from './Tabber'
// import { TestOutput } from './TestResponse'

export function EditorSolutionWizard({ taskId }) {
  const { data: currentUser } = useGetCurrentUser()
  const [currentFormState, setCurrentFormState] = useState({
    title: '',
    description: '',
    cost: 0,
    category: '',
    isPublic: '',
    info: '',
    hint: '',
    context: '',
    model: '',
    input: {},
    tags: [],
    prompt: '',
    currentStep: 0,
    lastMaxStep: 0,
  })
  // const currentFormState = useCreateTemplateStore((state) => state.currentFormState)

  const [renderIndex, setRenderIndex] = useState(0)
  const [currentIndex, setCurrentIndex] = useState(() => currentFormState.currentStep ?? 0)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const resetCurrentForm = () => {
    setCurrentFormState({
      title: '',
      description: '',
      cost: 0,
      category: '',
      isPublic: '',
      info: '',
      hint: '',
      context: '',
      model: '',
      input: {},
      tags: [],
      prompt: '',
      currentStep: 0,
      lastMaxStep: 0,
    })
  }

  const { data: solutionDetails } = useGetTaskInfo({
    id: taskId?.toString(),
    onError: () => false,
  })
  console.log(renderIndex)

  const isSolutionOwner = currentUser?.id.toString() === solutionDetails?.account?.id.toString()

  const updateFormState = (name: string, value: any) => {
    setCurrentFormState((state) => ({
      ...state,
      [name]: value,
    }))
  }

  useEffect(() => {
    if (!solutionDetails) return undefined

    setCurrentFormState({
      title: solutionDetails.title,
      description: solutionDetails.description,
      cost: 0,
      category: '',
      isPublic: solutionDetails.status === 'published' ? 'public' : 'private',
      info: '',
      hint: solutionDetails.hint,
      context: '',
      model: solutionDetails.ai_id,
      input: {},
      tags: solutionDetails.tag_ids,
      currentStep: 0,
      lastMaxStep: 0,
      // eslint-disable-next-line
      // ts-ignore
      prompt: solutionDetails.prompt,
    })

    return undefined
  }, [solutionDetails])

  const onPrev = () => {
    setCurrentIndex((prevIndex) => prevIndex - 1)
    updateFormState('currentStep', currentIndex - 1)
  }

  const onStartAgain = () => {
    updateFormState('currentStep', 0)
    updateFormState('lastMaxStep', 0)
    setCurrentIndex(0)
  }

  const onNext = () => {
    setCurrentIndex((prevIndex) => prevIndex + 1)
    updateFormState('currentStep', currentIndex + 1)

    if (currentIndex + 1 <= currentFormState.lastMaxStep) {
      return false
    }

    return updateFormState('lastMaxStep', currentIndex + 1)
  }

  const onClearForm = () => {
    setRenderIndex((prev) => prev + 1)
    localStorage.removeItem('createFormStore')
    setIsModalOpen(false)
    setCurrentIndex(0)
    resetCurrentForm()
  }

  if (!isSolutionOwner) {
    return (
      <div className={styles.wizardWrapper}>
        <h1>You are not allowed to edit this prompt.</h1>
      </div>
    )
  }

  // updateFormState(name, value)
  return (
    <div className={styles.wizardWrapper}>
      <Modal
        onClose={() => setIsModalOpen(false)}
        withCloseButton
        onDeny={() => setIsModalOpen(false)}
        onConfirm={() => onClearForm()}
        title="Are you sure?"
        type="error"
        isOpen={isModalOpen}
      />
      <ProgressBar
        currentFormState={currentFormState}
        type="ChatGPT"
        updateStep={setCurrentIndex}
        step={currentIndex}
        isEditMode
      />
      <div className={styles.wizardContent}>
        <Tabber currentIndex={currentIndex}>
          <PickAIModel
            updateFormState={updateFormState}
            currentFormState={currentFormState}
            onNext={onNext}
            onPrev={onPrev}
          />
          <PickTags
            updateFormState={updateFormState}
            currentFormState={currentFormState}
            onNext={onNext}
            onPrev={onPrev}
          />
          <PublicDetails
            updateFormState={updateFormState}
            currentFormState={currentFormState}
            onNext={onNext}
            onPrev={onPrev}
          />
          <Prompt
            updateFormState={updateFormState}
            currentFormState={currentFormState}
            onNext={onNext}
            onPrev={onPrev}
          />
          {/* <TestOutput
            updateFormState={updateFormState}
            currentFormState={currentFormState}
            onNext={onNext}
            onPrev={onPrev}
          /> */}
          <ReviewAndEdit
            onStartAgain={onStartAgain}
            updateFormState={updateFormState}
            currentFormState={currentFormState}
            onPrev={onPrev}
            taskId={taskId}
          />
        </Tabber>

        <div className={styles.clearForm}>
          <Button
            variant={ButtonVariants.unstyled}
            isIconOnLeft
            label="clear form"
            onClick={() => setIsModalOpen(true)}
            iconColor="var(--color-secondary)"
            type="button"
            icon={IconNames.close}
          />
        </div>
      </div>
    </div>
  )
}
