import { useMemo } from 'react'
// import Chart from 'react-apexcharts'
import { AxisOptions, Chart as Chart1 } from 'react-charts'
// import { DateFormats, formatDate } from 'src/utils/dates'
import { DateFormats, formatDate } from 'src/utils/dates'

type DailyStars = {
  time: Date | number
  revenue: number
  people_usage_count: number
  creator_usage_count?: number
}

type Series = {
  label: string

  data: DailyStars[]
}

// const fillMissingHours = (data) => {
//   if (data.length === 0 || !data) return []

//   const sortedData = data.sort((a, b) => a.time - b.time)
//   const filledData: any = []

//   let currentTime = sortedData[0].time - (sortedData[0].time % 3600)

//   sortedData.forEach((item: any) => {
//     while (currentTime < item.time - (item.time % 3600)) {
//       filledData.push({
//         time: currentTime,
//         creator_usage_count: 0,
//         people_usage_count: 0,
//         revenue: 0,
//       })
//       currentTime += 3600
//     }

//     filledData.push(item)
//     currentTime += 3600
//   })
//   console.log('filledData', filledData)

//   return filledData
// }

// const fillMissingHours = (data) => {
//   if (data.length === 0 || !data) return []

//   const sortedData = data.sort((a, b) => a.time - b.time)
//   const aggregatedData: any = []

//   let currentDate = new Date(sortedData[0].time * 1000)
//   let currentDayData = {
//     time: Math.floor(currentDate.getTime() / 86400000) * 86400,
//     creator_usage_count: 0,
//     people_usage_count: 0,
//     revenue: 0,
//   }

//   sortedData.forEach((item) => {
//     const itemDate = new Date(item.time * 1000)
//     const itemDay = Math.floor(itemDate.getTime() / 86400000) * 86400

//     if (itemDay !== currentDayData.time) {
//       aggregatedData.push(currentDayData)
//       currentDayData = {
//         time: itemDay,
//         creator_usage_count: 0,
//         people_usage_count: 0,
//         revenue: 0,
//       }
//       currentDate = itemDate
//     }

//     currentDayData.creator_usage_count += item.creator_usage_count
//     currentDayData.people_usage_count += item.people_usage_count
//     currentDayData.revenue += item.revenue
//   })

//   aggregatedData.push(currentDayData)
//   console.log('aggregatedData', aggregatedData)

//   return aggregatedData
// }

const fillMissingHours = (data) => {
  if (data.length === 0 || !data) return []

  const sortedData = data.sort((a, b) => a.time - b.time)
  const aggregatedData: any = []

  let currentDate = new Date(sortedData[0].time * 1000)
  let currentDayData = {
    time: Math.floor(currentDate.getTime() / 86400000) * 86400,
    creator_usage_count: 0,
    people_usage_count: 0,
    revenue: 0,
  }

  sortedData.forEach((item) => {
    const itemDate = new Date(item.time * 1000)
    const itemDay = Math.floor(itemDate.getTime() / 86400000) * 86400

    if (itemDay !== currentDayData.time) {
      while (currentDayData.time < itemDay) {
        aggregatedData.push(currentDayData)
        currentDate = new Date((currentDayData.time + 86400) * 1000)
        currentDayData = {
          time: Math.floor(currentDate.getTime() / 86400000) * 86400,
          creator_usage_count: 0,
          people_usage_count: 0,
          revenue: 0,
        }
      }
    }

    currentDayData.creator_usage_count += item.creator_usage_count
    currentDayData.people_usage_count += item.people_usage_count
    currentDayData.revenue += item.revenue
  })

  while (currentDayData.time <= Math.floor(Date.now() / 86400000) * 86400) {
    aggregatedData.push(currentDayData)
    currentDate = new Date((currentDayData.time + 86400) * 1000)
    currentDayData = {
      time: Math.floor(currentDate.getTime() / 86400000) * 86400,
      creator_usage_count: 0,
      people_usage_count: 0,
      revenue: 0,
    }
  }

  return aggregatedData
}

export function RevenueChartGlobal({ series = [] }: { series: DailyStars[] }) {
  const filledDataSeries = fillMissingHours(series) ?? []

  const data: Series[] = [
    // {
    //   label: 'Revenue',
    //   // secondaryAxisId: '2',
    //   data: filledDataSeries,
    // },
    {
      label: 'Usage',
      // eslint-disable-next-line
      // @ts-ignore
      secondaryAxisId: '2',
      data: filledDataSeries,
    },
  ]

  const primaryAxis = useMemo(
    (): AxisOptions<DailyStars> => ({
      getValue: (datum) => datum.time, // formatDate(datum.date as any, DateFormats.dayMonthYearDateSlash),
      formatters: {
        scale: (value: any) => formatDate((value * 1000) as any, DateFormats.dayMonthYearDateSlash),
      },

      // elementType: 'area',
    }),

    [],
  )

  const secondaryAxes = useMemo(
    (): AxisOptions<DailyStars>[] => [
      // {
      //   getValue: (datum) => datum.revenue,
      //   elementType: 'area',
      //   formatters: {
      //     scale: (value: any) => (value / 10000).toFixed(2).toString(),
      //   },
      //   position: 'right',
      //   stacked: false,
      //   minBandSize: 10,
      //   shouldNice: true,
      // },
      {
        id: '2',
        getValue: (datum) => datum.people_usage_count,
        elementType: 'bar',
        formatters: {
          scale: (value: any) => Number(value).toString(),
        },
        // maxBandSize: 15,
        shouldNice: true,
        outerSeriesBandPadding: 10,
        minBandSize: 10,
        position: 'left',
        stacked: false,
      },
    ],

    [],
  )

  if (!series) return null

  if (series.length === 0) {
    return <div>No data</div>
  }

  return (
    <div id="basic-bar" style={{ position: 'relative' }}>
      {/* disable-next-line @ts-ignore */}
      {/* <Chart theme="dark" options={options as any} height="220" series={options.series} type="area" width="100%" /> */}
      <div style={{ height: '14rem', position: 'relative' }}>
        <Chart1
          options={
            {
              data,
              primaryAxis,
              secondaryAxes,
              // getSeriesStyle: () => ({
              //   area: {
              //     stroke: '#fff',
              //     strokeWidth: 2,
              //     fill: '#fff',
              //     color: '#fff',
              //     background: '#fff',
              //   },
              // }),
              // dark: true,
              // showDebugAxes: false,
              interactionMode: 'primary',
              tooltip: {
                align: 'center',
                groupingMode: 'primary',

                alignPriority: ['right'],
              },
            } as any
          }
        />
        {/* <Chart1
          options={{
            data: data22,

            primaryAxis,

            secondaryAxes,
          }}
        /> */}
      </div>
    </div>
  )
}
