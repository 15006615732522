export enum PLAUSIBLE_EVENTS {
  LOGIN = 'Login',
  LOGIN_FAILED = 'LoginFailed',
  SUBSCRIBE = 'Subscribe',
  CREATE_TASK = 'CreateTask',
  EDIT_TASK = 'EditTask',
  CREATE_TASK_FAILED = 'CreateTaskFailed',
  EDIT_TASK_FAILED = 'EditTaskFailed',
  SUBMIT_SOLUTION = 'SubmitSolution',
  SUBMIT_SOLUTION_ZEN = 'SubmitSolutionZen',
  COPY_RESPONSE = 'CopyResponse',
  CLICK_BUY_CREDITS = 'ClickBuyCredits',
  CLICK_BUY_CREDITS_ZEN = 'ClickBuyCreditsZen',
  CLICK_BUY_BUTTON = 'ClickBuyButton',
  CLICK_BUY_BUTTON_CRYPTO = 'ClickBuyButtonCrypto',
  NOT_VALID_EMAIL = 'NotValidEmail',
  TOO_MANY_LOGIN_ATTEMPTS = 'TooManyLoginAttempts',
  APP_CRASHED = 'AppCrashed',

  SOLUTION_STREAM_DONE = 'SolutionStreamDone',
  SOLUTION_STREAM_STOPPED = 'SolutionStreamStopped',
  SOLUTION_STREAM_LENGTH = 'SolutionStreamLength',
  SOLUTION_STREAM_CONTENT_FILTER = 'SolutionStreamContentFilter',

  SPACE_CREATED = 'SpaceCreated',
  SPACE_CREATION_FAILED = 'SpaceCreationFailed',

  CLICK_MARKETPLACE_LINK = 'ClickMarketPlaceLink',
  CLICK_ZENSPACES_LINK = 'ClickZenspacesLink',
  CLICK_CREATOR_AREA_LINK = 'ClickCreatorAreaLink',
  CLICK_TAG_LINK = 'ClickTagLink',
  CLICK_AFFILIATE_LINK = 'ClickAffiliateLink',

  CLICK_OPEN_PROFILE = 'ClickOpenProfile',
  BEYOND_THE_LIMITS = 'BeyondTheLimits',

  LIMITS_PRO = 'LimitsPRO',
  LIMITS_FREE = 'LimitsFREE',

  ERROR_CREATING_PAYMENT_LINK = 'ErrorCreatingPaymentLink',

  QUICK_TAG = 'QuickTag',
  QUICK_IMAGE = 'QuickImage',
  QUICK_TEXT = 'QuickText',
  QUICK_CREATE = 'QuickCreate',
  QUICK_SPACE = 'QuickSpace',

  OPEN_SETTINGS = 'OpenSettings',

  OPEN_SPOTLIGHT = 'Spotlight',
  START_SEARCH = 'StartSearch',

  CLICK_FAST_EMAIL = 'ClickFastEmail',
  CLICK_FAST_ESSEY = 'ClickFastESSEy',
  CLICK_FAST_CODE = 'ClickFastCode',
  CLICK_FAST_TWITTER = 'ClickFastTwitter',
  CLICK_FAST_ARTICLE = 'ClickFastArticle',
  CLICK_FAST_SEO = 'ClickFastSeo',

  CLICK_DOWNLOAD_WINDOWS = 'CLICK_DOWNLOAD_WIN',
  CLICK_DOWNLOAD_X64 = 'CLICK_DOWNLOAD_X64',
  CLICK_DOWNLOAD_ARM = 'CLICK_DOWNLOAD_ARM',

  TEAM_CREATE = 'TEAM_CREATE',

  ANNOYING_PRICE_POPUP = 'AnnoyingPricePopup',

  COUPON_GET = 'CouponGet',
  COUPON_SUMBIT = 'CouponSubmit',

  LOAD_HISTORY_PAGE = 'LoadHistoryPage',
}
