import { Link } from '@tanstack/react-location'
import { Button } from 'src/core/ds/button'
import { Icon, IconNames } from 'src/core/ds/icon'
import styles from './index.module.scss'

export function FinishPage() {
  return (
    <div>
      <div className={styles.finishicon}>
        <Icon color="var(--color-green)" name={IconNames.checkCircle} />
      </div>
      <h2 className={styles.wizardTitle}>🎉 Hooray! We are ready to start</h2>

      <div className={styles.actionFooter}>
        <Link to="/teams">
          <Button icon={IconNames.arrowRight} type="button" label="Teams dashboard" />
        </Link>
      </div>
    </div>
  )
}
