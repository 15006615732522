// import ReactMarkdown from 'react-markdown'
import { Link, useMatch, useNavigate } from '@tanstack/react-location'
import { createRef, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { config } from 'src/config/config'
import { Avatar } from 'src/core/ds/avatar'
import { AVATAR_SIZES } from 'src/core/ds/avatar/consts'
import { Button } from 'src/core/ds/button'
import { ButtonSizes, ButtonVariants } from 'src/core/ds/button/types'
import { Icon, IconNames } from 'src/core/ds/icon'
import { Input } from 'src/core/ds/input'
import { Loader } from 'src/core/ds/loader'
import { Modal } from 'src/core/ds/modal'
import { useCounter } from 'src/hooks/useCounter'
import { extractInput } from 'src/modules/asideHistory/components/ChatItem'
import { invalidateTaskHistory } from 'src/modules/asideHistory/query'
import { useGetCurrentUser } from 'src/modules/currentUser/query'
import { api } from 'src/modules/StreamForm/api'
import { useGetTaskInfo } from 'src/modules/tasks/query'
import { queryClient } from 'src/queries/reactQueryClient'
import { getCurrentUserKey } from 'src/queries/reactQueryKeys'
import { pushNotification } from 'src/services/notifications/notificationService'
import { PLAUSIBLE_EVENTS } from 'src/services/plausible/consts'
import { firePlausibleEvent } from 'src/services/plausible/plausibleService'
import { useStreamStore } from 'src/services/store/StreamStore'
import { useUIStore } from 'src/services/store/UIStore'
import { ResponseSkeleton } from 'src/skeletons/ResponseSkeleton'
import { copyToClipboard } from 'src/utils/strings'
import LogoImage from '../../assets/images/zentask-new-logo.png'
import { InputFormNew } from '../InputFormNew/InputFormNew'
import { Markdown } from '../MarkDownWrapper'
import { StreamStatus } from '../StreamStatus/StreamStatus'
import styles from './index.module.scss'
import { useAttachHistoryItem, useGetHistoryItemById, useShareHistoryItem } from './query'

// let eventSource
let channel
let currentResponseId = null

export function StreamHistory({ historyId }: any) {
  // const images = useStreamStore((state) => state.images)
  const {
    params: { spaceId, teamId, taskId },
  } = useMatch()
  const navigate = useNavigate()
  // const [historyIdRaw, setHistoryId] = useState(null)
  const isPROAccount = useUIStore((state) => state.isPROAccount)
  const updatePromptInput = useStreamStore((state) => state.updatePrompt)
  const updateStreamData = useStreamStore((state) => state.updateData)
  const updateStreamStatus = useStreamStore((state) => state.updateStatus)
  const resetData = useStreamStore((state) => state.resetData)
  const updateFormData = useStreamStore((state) => state.updateFormData)
  const streamStatus = useStreamStore((state) => state.status)
  const streamData = useStreamStore((state) => state.data)
  const { data: historyItemData, isLoading } = useGetHistoryItemById(historyId)
  const { data: currentUser } = useGetCurrentUser()
  const { data: taskDetails } = useGetTaskInfo({ id: historyItemData?.task_id?.toString(), onError: false })
  const [isHareHistoryModalOpen, setIsHareHistoryModalOpen] = useState(false)
  const formDataStore = useStreamStore((state) => state.formData)
  const images = useStreamStore((state) => state.images)
  const promptInput = useStreamStore((state) => state.prompt)
  const [contextState, setContextState] = useState<'new' | 'current' | 'none'>('current')
  const setImages = useStreamStore((state) => state.updateImages)
  const [contextItemsList, setContextItemsList] = useState<any>([])
  const [limitError, setLimitError] = useState<any>(null)

  const { increment } = useCounter()

  const updateContextState = (newState) => {
    setContextState(newState)
    navigate({ to: `/marketplace/task/${taskId}` })
  }

  // const modelVariant = taskDetails?.category.ai.variations[historyItemData?.variation].model ?? 'not found'
  const isDalleModel = taskDetails?.ai.name.includes('DALL')
  const isContextFlow = historyItemData?.context?.items?.length > 0
  const isContextEnabled = !isDalleModel && isPROAccount && currentUser?.config?.[config.taskAIId]?.context === 1
  const ref = createRef<any>()

  const addContextItem = (item) => {
    setContextItemsList([...contextItemsList, item])
  }

  useEffect(() => {
    if (contextItemsList.length > 1) {
      // window.scrollTo(0, ref.current.offsetTop)
      ref.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [contextItemsList])

  const isContextRequest = historyItemData?.context_id?.toString() === historyId

  const onSuccessAttach = () => {
    pushNotification({
      type: 'success',
      title: 'History attached successfully',
    })
  }

  const onSuccessShare = () => {
    setIsHareHistoryModalOpen(true)
  }

  const onStop = () => {
    const type = isDalleModel ? 'image' : 'text'
    const channelString = `${type}-${currentUser?.id.toString()}-${taskId.toString()}`
    channel.unbind()
    window?.pusherInstance?.unsubscribe(channelString)
    updateStreamStatus('error')
    currentResponseId = null
  }

  const { isLoading: isLoadingAttach, mutate } = useAttachHistoryItem(onSuccessAttach)
  const { isLoading: isLoadingShare, mutate: mutateShare } = useShareHistoryItem(onSuccessShare)

  const onShareHistory = async () => {
    if (historyItemData.is_public !== 1) {
      await mutateShare({ historyId })
    } else {
      setIsHareHistoryModalOpen(true)
    }
  }

  const onAttachHandleClick = async () => {
    await mutate({
      taskId: taskDetails?.id.toString(),
      historyId: historyId.toString(),
    })
  }

  useEffect(() => {
    if (isContextEnabled && streamStatus === 'completed') {
      // updateStreamStatus('idle')
      addContextItem({
        response: streamData,
        request: formDataStore?.request,
        id: uuidv4(),
      })
      updatePromptInput({
        text: '',
        id: '',
      })

      resetData()

      updateFormData(null)
    }
  }, [streamStatus])

  // const modelVariant = isDalleModel
  //   ? `DALL-E 2, ${taskDetails?.ai.variations[appliedVariant].count} images with ${taskDetails?.ai.variations[appliedVariant].size} size`
  //   : taskDetails?.ai.variations[appliedVariant].model ?? 'not found'

  const shareLink = `https://zentask.ai/shared/${historyId}`
  // const shareLink = `http://localhost:3000/shared/${historyId}`

  const onInputTextCopy = (text) => {
    copyToClipboard(
      text,
      pushNotification({
        type: 'success',
        title: 'Copied to clipboard',
      }),
    )
  }

  const onTextCopy = (text) => {
    copyToClipboard(
      text,
      pushNotification({
        type: 'success',
        title: 'Copied to clipboard',
      }),
    )
  }

  const onItemShareCopyLink = () => {
    copyToClipboard(
      shareLink,
      pushNotification({
        type: 'success',
        title: 'Copied to clipboard',
      }),
    )
  }

  const createRequst = async () => {
    increment()
    channel?.unbind()
    // setHistoryId(null)
    updateStreamStatus('processing')

    try {
      const prompt = `${promptInput.text}`
      // const prompt = 'data[image]=test'
      const type = isDalleModel ? 'image' : 'text'

      if (isDalleModel) {
        setImages([] as any)
      }

      const channelString = `${type}-${currentUser?.id.toString()}-${taskId.toString()}`

      channel = window?.pusherInstance?.subscribe(channelString)
      channel?.bind('response', (data) => {
        if (isDalleModel) {
          setImages(data.response)

          if (data?.response?.length === 0) {
            pushNotification({
              type: 'error',
              title: 'Internal error.',
            })
          }
        } else if (data?.id === currentResponseId) {
          updateStreamData(data.response)
        }
      })

      channel?.bind('done', () => {
        try {
          queryClient.invalidateQueries({ queryKey: getCurrentUserKey() })
          // console.log('data', data)
          // if (data?.history_id) {
          //   setHistoryId(data.history_id.toString())
          // }
        } catch (e) {
          console.log('e')
        }

        // channel.unbind_all()
        // channel.unsubscribe()
        channel.unbind()
        updateStreamStatus('completed')
        invalidateTaskHistory(taskId)
        firePlausibleEvent(PLAUSIBLE_EVENTS.SOLUTION_STREAM_STOPPED)
        updatePromptInput({
          text: '',
          id: '',
        })
      })

      // verify
      const response: any = await api.verifyPromptBeforeRun(
        taskId.toString(),
        prompt,
        isDalleModel || !isContextEnabled ? 'none' : 'current',
        isContextRequest ? historyId : null,
      )
      const [error, data] = response
      // console.log('data', data)

      if (data && data?.id) {
        // console.log('SET!')
        currentResponseId = data?.id
      }

      // console.log(currentResponseId)

      if (error) {
        channel.unbind()
        updateStreamStatus('error')
      }
    } catch (err) {
      if (err?.toString().includes('e_request_data_too_long')) {
        const errorString = isPROAccount
          ? 'Your input is too long. 3000 is a Max.'
          : 'Your input is too long. 200 is a Max.\n To increase the limit to 3000 – upgrade to PRO.'
        pushNotification({
          type: 'error',
          title: 'Error occured.',
          subtitle: errorString,
        })
      } else if (err?.toString().includes('banned_words')) {
        pushNotification({
          type: 'error',
          title: 'Bad words.',
          subtitle: 'The words you are using are inappropriate for the requests made to the selected AI.',
        })
      } else if (err?.toString().includes('violates_guidelines')) {
        pushNotification({
          type: 'error',
          title: 'Your request is against the AI guidelines.',
          subtitle: 'Please, try to reword your request.',
        })
      } else if (err?.toString().includes('e_request_premium_forbidden')) {
        pushNotification({
          type: 'error',
          title: 'Forbidden.',
          subtitle: 'You need PRO account to use this prompt.',
        })
      } else if (err?.toString().includes('e_request_too_many_usages')) {
        // const errorString = isPROAccount
        //   ? 'Your limits are: Text AI – 20 / 3 hours,\n Image AI – 10 / 3 hours'
        //   : 'Your limits are: Text AI – 10 / day,\n Image AI – 5 / day. To increase the limits, please use a PRO account.'
        setLimitError(true)
        firePlausibleEvent(isPROAccount ? PLAUSIBLE_EVENTS.LIMITS_PRO : PLAUSIBLE_EVENTS.LIMITS_FREE)
        // pushNotification({
        //   type: 'error',
        //   title: 'Out of limits.',
        //   subtitle: errorString,
        // })
      } else {
        pushNotification({
          type: 'error',
          title: 'Internal error.',
          subtitle: 'Try again later or refresh the page.',
        })
      }

      updateStreamStatus('error')
    }
  }

  useEffect(() => {
    if (!promptInput.text.length) return undefined

    updateStreamStatus('processing')

    createRequst()

    return undefined
  }, [promptInput])

  const isSolutionOwner = currentUser?.id.toString() === taskDetails?.account.id.toString()
  const isCurrentHistoryAttached = taskDetails?.history_id?.toString() === historyItemData?.id.toString()
  let startOverLink = spaceId
    ? `/spaces/${spaceId}/${taskDetails?.id.toString()}`
    : `/marketplace/task/${taskDetails?.id.toString()}`

  if (teamId) {
    startOverLink = `/teams/${teamId}/${taskDetails?.id.toString()}`
  }

  if (isLoading) {
    return (
      <div className={styles.wrapper}>
        <Loader isBig />
      </div>
    )
  }

  if (!currentUser || historyItemData?.account_id.toString() !== currentUser.id.toString()) {
    return <div>Private info</div>
  }

  return (
    <>
      <div className={styles.wrapper}>
        <Modal
          withCloseButton
          title="Share history"
          onClose={() => setIsHareHistoryModalOpen(false)}
          isOpen={isHareHistoryModalOpen}
          body={
            // eslint-disable-next-line
            <div className={styles.modalWrapper}>
              <Input
                label="Your link"
                action={
                  // eslint-disable-next-line
                  <button className={styles.action} onClick={onItemShareCopyLink} type="button">
                    <Icon name={IconNames.copy} />
                  </button>
                }
                name="shareLink"
                value={shareLink}
              />
            </div>
          }
        />

        {isContextFlow &&
          historyItemData?.context?.items
            ?.slice()
            .reverse()
            .map((historyItemData: any) => (
              <div style={{ flexGrow: 1 }} key={historyItemData?.id?.toString()}>
                {/* */}
                <div className={styles.userLine}>
                  <div className={styles.userLineWrapper}>
                    <div className={styles.avatar}>
                      <span>
                        <Icon color="var(--color-secondary)" name={IconNames.settings} />
                      </span>
                      <Avatar url={currentUser?.avatar_url} size={AVATAR_SIZES.sm} />
                    </div>
                    <div className={styles.historySettings}>
                      <div className={styles.historySettingsDate}>
                        {currentUser?.full_name || currentUser?.username}
                        {/* eslint-disable-next-line */}
                        &nbsp;request [{taskDetails?.title}]{' '}
                        {historyItemData?.config &&
                          historyItemData?.config?.model &&
                          `[${historyItemData?.config?.model.option}]`}
                        <div>
                          <Button
                            icon={IconNames.copy}
                            type="button"
                            iconColor="var(--color-secondary)"
                            size={ButtonSizes.xs}
                            variant={ButtonVariants.secondary}
                            label="copy"
                            onClick={() => onInputTextCopy(historyItemData?.input?.data)}
                          />
                        </div>
                      </div>

                      <div className={styles.historySettingsContent}>
                        <div className={styles.settingsItem}>
                          <div className={styles.settingsContent}>
                            {historyItemData?.input?.data ||
                              historyItemData?.input?.text ||
                              extractInput(historyItemData?.input)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={styles.contentLine}>
                  <div className={styles.contentLineWrapper}>
                    <div className={styles.avatar}>
                      <Avatar url={LogoImage} size={AVATAR_SIZES.sm} />
                      <Avatar url={taskDetails?.account.avatar_url} size={AVATAR_SIZES.sm} />
                    </div>
                    <div className={styles.contentWrapper}>
                      <div className={styles.contentWrapperTitle}>
                        <div>
                          {taskDetails?.account.full_name || taskDetails?.account.username}
                          <span>& Zentask assistant response</span>
                        </div>

                        <div className={styles.historyActions}>
                          <div className={styles.shareHistory}>
                            <Button
                              icon={IconNames.share}
                              type="button"
                              iconColor="var(--color-secondary)"
                              size={ButtonSizes.xs}
                              variant={ButtonVariants.unstyled}
                              label=""
                              isLoading={isLoadingShare}
                              tooltip="Share history"
                              onClick={onShareHistory}
                            />
                          </div>

                          {!isDalleModel && (
                            <Button
                              icon={IconNames.copy}
                              type="button"
                              iconColor="var(--color-secondary)"
                              size={ButtonSizes.xs}
                              variant={ButtonVariants.secondary}
                              label="copy"
                              onClick={() => onTextCopy(historyItemData?.response)}
                            />
                          )}
                        </div>
                      </div>

                      <div className={styles.contentStreamLine}>
                        {!isDalleModel && (
                          <div className={styles.contentMD}>
                            {isLoading ? <Loader /> : <Markdown markdown={historyItemData?.response} />}
                          </div>
                        )}

                        {isDalleModel && (
                          <div className={styles.contentMD}>
                            <div className={styles.imagesBox}>
                              {isLoading ? (
                                <Loader />
                              ) : (
                                (JSON.parse(historyItemData?.response) || []).map((image, index) => (
                                  // eslint-disable-next-line
                                  // @ts-ignore
                                  <div className={styles.imageItem}>
                                    <a
                                      href={image}
                                      download
                                      target="_blank"
                                      className={styles.downloadImage}
                                      rel="noreferrer"
                                    >
                                      <Button
                                        type="button"
                                        iconColor="var(--color-secondary)"
                                        icon={IconNames.download}
                                        size={ButtonSizes.xs}
                                        variant={ButtonVariants.secondary}
                                      />
                                    </a>
                                    <img width="323" height="323" loading="lazy" src={image} alt="" key={index} />
                                  </div>
                                ))
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* */}
              </div>
            ))}

        {!isContextFlow && (
          <>
            {/* */}
            <div className={styles.userLine}>
              <div className={styles.userLineWrapper}>
                <div className={styles.avatar}>
                  <span>
                    <Icon color="var(--color-secondary)" name={IconNames.settings} />
                  </span>
                  <Avatar url={currentUser?.avatar_url} size={AVATAR_SIZES.sm} />
                </div>
                <div className={styles.historySettings}>
                  <div className={styles.historySettingsDate}>
                    {currentUser?.full_name || currentUser?.username}
                    {/* eslint-disable-next-line */}
                    &nbsp;request [{taskDetails?.title}]{' '}
                    {historyItemData?.config &&
                      historyItemData?.config?.model &&
                      `[${historyItemData?.config?.model.option}]`}
                    <div>
                      <Button
                        icon={IconNames.copy}
                        type="button"
                        iconColor="var(--color-secondary)"
                        size={ButtonSizes.xs}
                        variant={ButtonVariants.secondary}
                        label="copy"
                        onClick={() => onInputTextCopy(historyItemData?.input?.data)}
                      />
                    </div>
                  </div>

                  <div className={styles.historySettingsContent}>
                    <div className={styles.settingsItem}>
                      <div className={styles.settingsContent}>
                        {historyItemData?.input?.data ||
                          historyItemData?.input?.text ||
                          extractInput(historyItemData?.input)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.contentLine}>
              <div className={styles.contentLineWrapper}>
                <div className={styles.avatar}>
                  <Avatar url={LogoImage} size={AVATAR_SIZES.sm} />
                  <Avatar url={taskDetails?.account.avatar_url} size={AVATAR_SIZES.sm} />
                </div>
                <div className={styles.contentWrapper}>
                  <div className={styles.contentWrapperTitle}>
                    <div>
                      {taskDetails?.account.full_name || taskDetails?.account.username}
                      <span>& Zentask assistant response</span>
                    </div>

                    <div className={styles.historyActions}>
                      <div className={styles.shareHistory}>
                        <Button
                          icon={IconNames.share}
                          type="button"
                          iconColor="var(--color-secondary)"
                          size={ButtonSizes.xs}
                          variant={ButtonVariants.unstyled}
                          label=""
                          isLoading={isLoadingShare}
                          tooltip="Share history"
                          onClick={onShareHistory}
                        />
                      </div>

                      {!isDalleModel && (
                        <Button
                          icon={IconNames.copy}
                          type="button"
                          iconColor="var(--color-secondary)"
                          size={ButtonSizes.xs}
                          variant={ButtonVariants.secondary}
                          label="copy"
                          onClick={() => onTextCopy(historyItemData?.response)}
                        />
                      )}
                    </div>
                  </div>

                  <div className={styles.contentStreamLine}>
                    {!isDalleModel && (
                      <div className={styles.contentMD}>
                        {isLoading ? <Loader /> : <Markdown markdown={historyItemData?.response} />}
                      </div>
                    )}

                    {isDalleModel && (
                      <div className={styles.contentMD}>
                        <div className={styles.imagesBox}>
                          {isLoading ? (
                            <Loader />
                          ) : (
                            (JSON.parse(historyItemData?.response) || []).map((image, index) => (
                              // eslint-disable-next-line
                              // @ts-ignore
                              <div className={styles.imageItem}>
                                <a
                                  href={image}
                                  download
                                  target="_blank"
                                  className={styles.downloadImage}
                                  rel="noreferrer"
                                >
                                  <Button
                                    type="button"
                                    iconColor="var(--color-secondary)"
                                    icon={IconNames.download}
                                    size={ButtonSizes.xs}
                                    variant={ButtonVariants.secondary}
                                  />
                                </a>
                                <img width="323" height="323" loading="lazy" src={image} alt="" key={index} />
                              </div>
                            ))
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* */}
          </>
        )}

        {contextItemsList?.map((item, index) => (
          <div key={item.id}>
            <div className={styles.userLine}>
              <div className={styles.userLineWrapper}>
                <div className={styles.avatar}>
                  <span>
                    <Icon color="var(--color-secondary)" name={IconNames.settings} />
                  </span>
                  <Avatar url={currentUser?.avatar_url} size={AVATAR_SIZES.sm} />
                </div>
                <div className={styles.historySettings}>
                  <div className={styles.historySettingsDate}>
                    {currentUser?.full_name || currentUser?.username}
                    {/* eslint-disable-next-line */}
                    &nbsp;request [{taskDetails?.title}]
                    <div>
                      <Button
                        icon={IconNames.copy}
                        type="button"
                        iconColor="var(--color-secondary)"
                        size={ButtonSizes.xs}
                        variant={ButtonVariants.secondary}
                        label="copy"
                        onClick={() => onInputTextCopy(item.request)}
                      />
                    </div>
                  </div>

                  <div translate="no" className={styles.historySettingsContent}>
                    <div className={styles.settingsItem}>
                      <p>{item.request}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.contentLine}>
              <div className={styles.contentLineWrapper}>
                <div className={styles.avatar}>
                  <Avatar url={LogoImage} size={AVATAR_SIZES.sm} />
                  <Avatar url={taskDetails?.account?.avatar_url} size={AVATAR_SIZES.sm} />
                </div>
                <div className={styles.contentWrapper}>
                  <div className={styles.contentWrapperTitle}>
                    <div>
                      {taskDetails?.account.full_name || taskDetails?.account.username}
                      <span>& Zentask assistant response</span>
                    </div>

                    <div className={styles.historyActions}>
                      <div className={styles.shareHistory}>
                        {index === 0 && historyId && (
                          <Button
                            icon={IconNames.share}
                            type="button"
                            iconColor="var(--color-secondary)"
                            size={ButtonSizes.xs}
                            variant={ButtonVariants.unstyled}
                            label=""
                            isLoading={isLoadingShare}
                            tooltip="Share history"
                            onClick={onShareHistory}
                          />
                        )}
                      </div>
                      <Button
                        icon={IconNames.copy}
                        type="button"
                        iconColor="var(--color-secondary)"
                        size={ButtonSizes.xs}
                        variant={ButtonVariants.secondary}
                        label="copy"
                        onClick={() => onTextCopy(item.response)}
                      />
                    </div>
                  </div>

                  <div className={styles.contentStreamLine}>
                    <div translate="no" className={styles.contentMD}>
                      <Markdown markdown={item.response} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/**/}
          </div>
        ))}

        {!isContextEnabled && (
          <div className={styles.userLine}>
            <div className={styles.userLineWrapper}>
              <div className={styles.endLine}>
                <p>
                  End of response.
                  <Link className={styles.startOver} activeOptions={{ exact: true }} to={startOverLink}>
                    Start over?
                  </Link>
                </p>
                {isSolutionOwner && !isCurrentHistoryAttached && (
                  <div className={styles.userLineAction}>
                    <Button
                      onClick={onAttachHandleClick}
                      isLoading={isLoadingAttach}
                      variant={ButtonVariants.secondary}
                      label="Use as prompt preview"
                      type="button"
                    />
                  </div>
                )}
                {isCurrentHistoryAttached && <div className={styles.attached}>[attached]</div>}
              </div>
            </div>
          </div>
        )}

        {(formDataStore || images) && (
          <div className={styles.userLine}>
            <div className={styles.userLineWrapper}>
              <div className={styles.avatar}>
                <span>
                  <Icon color="var(--color-secondary)" name={IconNames.settings} />
                </span>
                <Avatar url={currentUser?.avatar_url} size={AVATAR_SIZES.sm} />
              </div>
              <div className={styles.historySettings}>
                <div className={styles.historySettingsDate}>
                  {currentUser?.full_name || currentUser?.username}
                  {/* eslint-disable-next-line */}
                  &nbsp;request [{taskDetails?.title}]
                  <div>
                    <Button
                      icon={IconNames.copy}
                      type="button"
                      iconColor="var(--color-secondary)"
                      size={ButtonSizes.xs}
                      variant={ButtonVariants.secondary}
                      label="copy"
                      onClick={() => onInputTextCopy(formDataStore?.request)}
                    />
                  </div>
                </div>

                <div translate="no" className={styles.historySettingsContent}>
                  {Object.keys(formDataStore || {}).map((inputKey) => (
                    <div key={inputKey} className={styles.settingsItem}>
                      <p>{formDataStore[inputKey].toString()}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}

        {streamData && (
          <div className={styles.contentLine}>
            <div className={styles.contentLineWrapper}>
              <div className={styles.avatar}>
                <Avatar url={LogoImage} size={AVATAR_SIZES.sm} />
                <Avatar url={taskDetails?.account?.avatar_url} size={AVATAR_SIZES.sm} />
              </div>
              <div className={styles.contentWrapper}>
                <div className={styles.contentWrapperTitle}>
                  <div>
                    {taskDetails?.account.full_name || taskDetails?.account.username}
                    <span>& Zentask assistant response</span>
                  </div>
                  {streamStatus === 'completed' && (
                    <div className={styles.historyActions}>
                      <div className={styles.shareHistory}>
                        {historyId && (
                          <Button
                            icon={IconNames.share}
                            type="button"
                            iconColor="var(--color-secondary)"
                            size={ButtonSizes.xs}
                            variant={ButtonVariants.unstyled}
                            label=""
                            isLoading={isLoadingShare}
                            tooltip="Share history"
                            onClick={onShareHistory}
                          />
                        )}
                      </div>
                      <Button
                        icon={IconNames.copy}
                        type="button"
                        iconColor="var(--color-secondary)"
                        size={ButtonSizes.xs}
                        variant={ButtonVariants.secondary}
                        label="copy"
                        onClick={() => onTextCopy(streamData)}
                      />
                    </div>
                  )}
                </div>

                <div className={styles.contentStreamLine}>
                  <div translate="no" className={styles.contentMD}>
                    <Markdown markdown={streamData} />
                    <div className={styles.streamStatusWrapper}>
                      <StreamStatus status={streamStatus} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {!limitError && formDataStore && !isDalleModel && !streamData && <ResponseSkeleton />}

        {isContextEnabled && <div style={{ height: '15rem' }} ref={ref} />}
      </div>
      {isContextEnabled && (
        <InputFormNew
          contextState={contextState}
          setContextState={updateContextState}
          isImageModel={isDalleModel}
          onStop={onStop}
          streamStatus={streamStatus}
          currentUser={currentUser}
          hint={taskDetails?.hint}
        />
      )}
    </>
  )
}
