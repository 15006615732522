import { config } from 'src/config/config'
import { apiCall } from 'src/utils/networking'

export const api = {
  sendEmail: async ({ email, refId, utmDetails }) =>
    apiCall(`${config.baseAPIUrl}/account/code`, {
      method: 'POST',
      body: JSON.stringify({
        email: email.toLowerCase(),
        ref_id: refId,
        utm: {
          ...utmDetails,
          utm_url: window.location.href,
        },
      }),
    }),

  sendCode: async ({ email, code }) =>
    apiCall(`${config.baseAPIUrl}/account/auth`, {
      method: 'POST',
      body: JSON.stringify({
        email: email.toLowerCase(),
        code,
      }),
    }),
}
