const BREAK_VALUES = {
  MOBILE: 0,
  PHABLET: 440,
  TABLET: 768,
  DESKTOP: 960,
}

export const breaks = {
  mobile: BREAK_VALUES.MOBILE,
  phablet: BREAK_VALUES.PHABLET,
  tablet: BREAK_VALUES.TABLET,
  desktop: BREAK_VALUES.DESKTOP,
}
