import { CreateTeamWizard } from './components/CreateTeamWizard'
import styles from './index.module.scss'

export function TeamCreatorPage() {
  return (
    <div className={styles.dashboard}>
      <CreateTeamWizard />
    </div>
  )
}
