import styles from './index.module.scss'

export function TermsPage() {
  return (
    <div className={styles.dashboard}>
      <div className={styles.feed}>
        <div className={styles.content}>
          <div className={styles.header}>
            <h1>Terms of use</h1>
          </div>
          <h2>Introduction</h2>

          <p>
            Welcome to Zentask, a platform provided by Muvon Co. Ltd, a company registered in Phuket, Thailand. By using
            our services, you agree to be bound by these terms of use (&quot;Terms&quot;). If you do not agree with
            these Terms, please do not use our services.
          </p>

          <h2>Description of Services</h2>

          <p>
            Zentask is a platform that allows prompt creators to create and sell usage of their prompts with user inputs
            and for users to use high-quality tasks prepared by other creators. Users can add inputs and get the desired
            results by using &quot;tasks&quot; without seeing the real prompt.
          </p>

          <h2>Task Marketplace and Moderation</h2>

          <p>
            Creators can publish their tasks to the Zentask task marketplace, making them available for public use. All
            tasks submitted for publication on the marketplace are subject to moderation by the Zentask team to ensure
            quality. Creators are responsible for providing high-quality tasks and understand that poorly created tasks
            may not pass the moderation process.
          </p>

          <p>
            Users acknowledge that they may receive results that they are not happy with when using tasks from the task
            marketplace. However, as Zentask only acts as a platform connecting users and creators, any dissatisfaction
            with the results is the user&apos;s responsibility.
          </p>

          <h2>Task Ownership and Usage Rights</h2>

          <p>
            By creating a task on the Zentask platform, creators agree that the ownership of the task and the rights to
            the prompt usage are transferred to Zentask. Creators understand that Zentask may make decisions on how to
            use the prompt to solve tasks and may use the prompts in the future as Zentask wishes.
          </p>

          <h2>Private Task Usage</h2>

          <p>
            Creators also have the option to create tasks for private usage without publishing them to the task
            marketplace. In this case, creators will pay the base price for using each task they create, while users pay
            the price set by the creator.
          </p>

          <h2>Credits and Payments</h2>

          <p>
            Users can purchase and deposit money through Stripe, which will be exchanged for credits. Credits can be
            used to pay for tasks on the Zentask platform. Users can either make a one-time purchase or subscribe to
            receive credits periodically (e.g., monthly or quarterly).
          </p>

          <h2>Refund Policy</h2>

          <p>
            There are no refunds for deposits made on the platform. If you have any issues with your purchase, please
            contact our support team for assistance.
          </p>

          <h2>Revenue and Withdrawals</h2>

          <p>
            Creators earn revenue from each usage of their tasks by other users, with the exception of usage of their
            own tasks. Creators earn a fixed amount based on the type of AI the task uses and can set a higher price.
            Revenue above the minimum base price is split with the platform, with the base split being approximately 80%
            to the creator and 20% to the platform. The exact revenue split may vary depending on the AI used for the
            task, and creators should check the revenue information when creating a solution for a new task.
          </p>

          <p>
            Creators can request a withdrawal of their earnings when they have accumulated a minimum amount of $20.
            Withdrawals can be made using the methods listed on the withdrawal request page.
          </p>

          <h2>Account Termination</h2>

          <p>
            Zentask reserves the right to terminate or suspend your account at any time, for any reason, without notice.
            If your account is terminated, you will not be entitled to any refund of credits or other fees.
          </p>

          <h2>Intellectual Property</h2>

          <p>
            By submitting or creating content on the Zentask platform, you grant Zentask a worldwide, non-exclusive,
            royalty-free license to use, reproduce, modify, and distribute your content in any format or medium. You
            retain all rights to your content, and you are responsible for ensuring that you have the necessary
            permissions to use and share your content on the Zentask platform.
          </p>

          <h2>Limitation of Liability</h2>

          <p>
            To the extent permitted by law, Zentask shall not be liable for any direct, indirect, incidental, special,
            consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or
            indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from your use of the
            platform.
          </p>

          <h2>Governing Law and Jurisdiction</h2>

          <p>
            These Terms shall be governed and construed in accordance with the laws of Thailand, without regard to its
            conflict of law provisions.
          </p>

          <h2>Changes to These Terms</h2>

          <p>
            Zentask reserves the right to update or modify these Terms at any time. Your continued use of the platform
            constitutes acceptance of the revised Terms.
          </p>
        </div>
      </div>
    </div>
  )
}
