import { useQuery } from 'react-query'
import { api } from './api'

export const useGetHistoryList = () =>
  useQuery('globalHistory', async () => {
    const response = await api.getItems()
    const [error, data] = response as any[]

    if (error) {
      throw new Error(error)
    }

    return data
  })

export const useGetHistoryList20 = () =>
  useQuery('globalHistory20', async () => {
    const response = await api.getItems20()
    const [error, data] = response as any[]

    if (error) {
      throw new Error(error)
    }

    return data
  })
