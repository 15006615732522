import { useEffect, useState } from 'react'
import { Card } from 'src/components/Card'
import { Avatar } from 'src/core/ds/avatar'
import { AVATAR_SIZES } from 'src/core/ds/avatar/consts'
import { Button } from 'src/core/ds/button'
import { ButtonSizes, ButtonVariants } from 'src/core/ds/button/types'
import { IconNames } from 'src/core/ds/icon'
import { Input } from 'src/core/ds/input'
import { useDebounce } from 'src/hooks/useDebounce'
import { useGetCurrentUser } from 'src/modules/currentUser/query'
import { api } from 'src/modules/teams/api'
import { useAddOrRemoveTeamMember } from 'src/modules/teams/query'
import { PLAUSIBLE_EVENTS } from 'src/services/plausible/consts'
import { firePlausibleEvent } from 'src/services/plausible/plausibleService'
import styles from './index.module.scss'

export function AddTeamMembers({ onNext }) {
  const [name, setName] = useState('')
  const [team, setTeam] = useState<any>([])
  const ids = team?.map((t) => t.id.toString()) || []
  const debouncedValue = useDebounce(name, 500)
  const [searchResults, setSearchResults] = useState<any>(null)
  const { data: currentUser } = useGetCurrentUser()

  const addToTeam = (member) => {
    const isExistingMember = team.some((memberL) => memberL?.id?.toString() === member?.id?.toString())

    // eslint-disable-next-line
    // @ts-ignore
    if (!isExistingMember) {
      setTeam((prevState) => [...prevState, member])
    }
  }

  const removeFromTeam = (id) => {
    // eslint-disable-next-line
    // @ts-ignore
    const filteredMembers = team.filter((member) => member?.id?.toString() !== id)
    setTeam(filteredMembers)
  }

  useEffect(() => {
    if (debouncedValue.length < 3) {
      setSearchResults(null)

      return
    }

    api
      .searchUsers({ username: debouncedValue })
      .then((res: any) => {
        const [error, response] = res
        firePlausibleEvent(PLAUSIBLE_EVENTS.START_SEARCH)

        if (error) {
          console.log('error', error)
        }

        if (response?.items) {
          setSearchResults(response.items)
        }

        if (response?.length === 0) {
          setSearchResults([])
        }
      })
      .catch((err) => {
        console.log('error', err)
      })
  }, [debouncedValue])

  const { mutate, isLoading } = useAddOrRemoveTeamMember(onNext)

  const handleChange = (event) => {
    const { value } = event.target
    setName(value)
  }

  const onAddTeamMembers = async () => {
    if (team.length === 0) {
      return onNext()
    }

    await mutate({
      // eslint-disable-next-line
      // @ts-ignore
      id: window?.lastCreatedTeam,
      details: {
        type: 'member',
        add: team.map((member) => member.id.toString()),
      },
    })

    return false
  }

  return (
    <div>
      <h2 className={styles.wizardTitle}>Add team members</h2>

      <div className={styles.wizardDescription}>Up to 5 members per team.</div>

      <div className={styles.splitDetails}>
        <div className={styles.detailsForm}>
          <Card>
            <div className={styles.formControl}>
              <Input
                id="title"
                value={name}
                onChange={handleChange}
                type="text"
                label="Search for a team member"
                // error={isNameInvalid ? 'error' : undefined}
                placeholder="Start typing username"
                isRequired
              />
              {searchResults && (
                <div className={styles.foundList}>
                  {searchResults?.map((item) => (
                    <div key={item?.id?.toString()} className={`${styles.listItem}`}>
                      <div className={styles.listItemAuthor}>
                        <Avatar
                          size={AVATAR_SIZES.xs}
                          url={
                            item?.avatar_url ||
                            'https://zentask.ai/static/media/zentask-new-logo.00471ab3f7b393c5d6a7.png'
                          }
                        />
                        <span>{item?.username || item?.full_name}</span>
                      </div>
                      <div className={styles.listItemAction}>
                        <Button
                          onClick={() => addToTeam(item)}
                          icon={IconNames.plus}
                          type="button"
                          label="Add"
                          disabled={ids?.includes(item?.id?.toString())}
                          size={ButtonSizes.xs}
                        />
                      </div>
                    </div>
                  ))}
                  {searchResults?.length === 0 && <div className={styles.notFound}>Nothing found.</div>}
                </div>
              )}
            </div>
          </Card>
        </div>
      </div>

      <div className={styles.detailsForm}>
        <h2 className={styles.teamTitle}>My team</h2>
        <div className={styles.members}>
          <div className={`${styles.listItem}`}>
            <div className={styles.listItemAuthor}>
              <Avatar
                size={AVATAR_SIZES.xs}
                url={
                  currentUser?.avatar_url || 'https://zentask.ai/static/media/zentask-new-logo.00471ab3f7b393c5d6a7.png'
                }
              />
              <span>
                {currentUser?.username || currentUser?.full_name}
                &nbsp;(you)
              </span>
            </div>
          </div>
          {team?.map((item) => (
            <div key={item?.id?.toString()} className={`${styles.listItem}`}>
              <div className={styles.listItemAuthor}>
                <Avatar
                  size={AVATAR_SIZES.xs}
                  url={item?.avatar_url || 'https://zentask.ai/static/media/zentask-new-logo.00471ab3f7b393c5d6a7.png'}
                />
                <span>{item?.username || item?.full_name}</span>
              </div>
              <div className={styles.listItemAction}>
                <Button
                  icon={IconNames.delete}
                  variant={ButtonVariants.unstyled}
                  type="button"
                  onClick={() => removeFromTeam(item?.id?.toString())}
                  label=""
                  iconColor="var(--color-secondary)"
                  size={ButtonSizes.xs}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.actionFooter}>
        <Button isLoading={isLoading} disabled={isLoading} onClick={onAddTeamMembers} type="button" label="Continue" />
      </div>
    </div>
  )
}
