import { Link, useNavigate } from '@tanstack/react-location'
import { useEffect, useState } from 'react'
import { config } from 'src/config/config'
import { Button } from 'src/core/ds/button'
import { ButtonSizes, ButtonVariants } from 'src/core/ds/button/types'
import { Dropdown } from 'src/core/ds/dropdown'
import { IconNames } from 'src/core/ds/icon'
import { Input } from 'src/core/ds/input'
import { Tabs } from 'src/core/ds/tabs'
import { useDebounce } from 'src/hooks/useDebounce'
import { useGetCurrentUser } from 'src/modules/currentUser/query'
import { useGetMyTasks } from 'src/modules/tasksList/query'
import { useGetPersonalSpacesAll } from 'src/pages/MyPersonalSpacePage/query'
import { useGetSpaceDetailsList } from 'src/pages/SelectedSpacePage/query'
import { pushNotification } from 'src/services/notifications/notificationService'
import { useUIStore } from 'src/services/store/UIStore'
import { useAddTaskToSpace } from '../AddToSpaceModal/query'
import { api } from './api'
import styles from './index.module.scss'

const tabs = ['Popular spaces', 'My spaces']

const sections = ['ZenSpaces', 'Prompts']

export function AsideZenSpaces() {
  const updateUIStore = useUIStore((state) => state.updateUIStore)
  const selectedAsideSpaceId = useUIStore((state) => state.selectedAsideSpaceId)
  const selectedAsideSpacesTab = useUIStore((state) => state.selectedAsideSpacesTab)
  const selectedAsideSectionTab = useUIStore((state) => state.selectedAsideSectionTab)
  const [currentIndex] = useState(() => selectedAsideSpacesTab || 0)
  const [currentSectionIndex] = useState(() => selectedAsideSectionTab || 0)
  const { data: currentUser } = useGetCurrentUser()
  const [searchResults, setSearchResults] = useState<any>(null)
  const [searchInput, setSearchInput] = useState('')
  const debouncedValue = useDebounce(searchInput, 500)
  const navigate = useNavigate()
  const { data: personalSpaces } = useGetPersonalSpacesAll(currentUser?.id.toString())
  const {
    data: personalTasks,
    // isLoading,
    fetchNextPage,
    hasNextPage,

    isFetchingNextPage,
  } = useGetMyTasks(null, currentUser?.id?.toString(), 'recent')

  const personalFlatTasks: any = personalTasks?.pages?.flatMap((page) => page?.items ?? page)

  const mySpacesFlat: any = personalSpaces?.pages?.flatMap((page) => page?.items ?? page) ?? []
  // const [selectedSpace, setSelectedSpace] = useState<any>(null)
  const isPROAccount = useUIStore((state) => state.isPROAccount)

  const onSetSelectedSpace = (spaceId) => {
    updateUIStore('selectedAsideSpaceId', spaceId)
  }

  const openCreateNewSpaceModal = () => {
    updateUIStore('isCreateNewSpaceOpen', true)
  }

  const onChangeSpace = (index) => {
    updateUIStore('selectedAsideSpacesTab', index)
  }

  const onChangeSection = (index) => {
    updateUIStore('selectedAsideSectionTab', index)
  }

  const onEdit = (spaceId) => {
    updateUIStore('isEditSpaceModalOpen', true)
    updateUIStore('editSpaceBuffer', spaceId)
  }

  const onRemove = async (spaceId) => {
    updateUIStore('isDeleteSpaceModalOpen', true)
    updateUIStore('deleteSpaceBuffer', spaceId)
  }

  const onReset = () => {
    setSearchInput('')
    setSearchResults(null)
  }

  const onPrivate = () => {
    navigate({ to: `/spaces` })
  }

  const onSuccessRemoveFromSpace = () => {
    pushNotification({
      type: 'success',
      title: 'Task removed from space',
    })
  }

  const { mutate } = useAddTaskToSpace(onSuccessRemoveFromSpace)

  const onRemoveTaskFromSpace = async (event, taskId, spaceId) => {
    event.preventDefault()
    await mutate({
      DTO: {
        remove: [taskId.toString()],
      },
      spaceId: spaceId.toString(),
    })
  }

  // eslint-disable-next-line
  // @ts-ignore
  const { data: tasksRaw } = useGetSpaceDetailsList(selectedAsideSpaceId?.toString(), onPrivate)
  const tasks: any = tasksRaw?.pages?.flatMap((page) => page?.items ?? page) ?? []

  // const onLoadSolutionsFromSpaces = (spaceId) => {}

  useEffect(() => {
    if (debouncedValue.length < 3) {
      setSearchResults(null)

      return
    }

    api
      .searchItems({ searchInput: debouncedValue, accountId: currentUser?.id?.toString() })
      .then((res: any) => {
        const [error, response] = res

        if (error) {
          console.log('error', error)
        }

        if (response?.items) {
          setSearchResults(response.items)
        }

        if (response?.length === 0) {
          setSearchResults([])
        }
      })
      .catch((err) => {
        console.log('error', err)
      })
  }, [debouncedValue])

  const isSearchView = debouncedValue.length > 2

  if (!currentUser) return null

  return (
    <div className={styles.sectionWrapper}>
      <Tabs onChangeIndex={onChangeSection} currentIndex={currentSectionIndex} tabs={sections}>
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <h2>Your spaces</h2>
            <Button
              icon={isPROAccount ? IconNames.spaceAdd : IconNames.pro}
              size={ButtonSizes.xs}
              type="button"
              disabled={!isPROAccount}
              onClick={openCreateNewSpaceModal}
              variant={ButtonVariants.secondary}
              label="Create space"
            />
          </div>
          <Tabs onChangeIndex={onChangeSpace} currentIndex={currentIndex} tabs={tabs}>
            <div className={styles.spacesList}>
              {config.suggestedSpaces?.map((space) => (
                <div
                  key={space.id.toString()}
                  className={`${styles.spaceWrapper} ${
                    selectedAsideSpaceId === space.id.toString() ? styles.openedSpace : undefined
                  }`}
                >
                  <button
                    onClick={() => onSetSelectedSpace(space.id.toString())}
                    type="button"
                    key={space.id.toString()}
                    className={`${styles.space} ${
                      selectedAsideSpaceId === space.id.toString() ? styles.activeSpace : undefined
                    }`}
                  >
                    <div className={styles.spaceContent}>
                      <div className={styles.spaceContentTitle}>
                        <span className={styles.count}>{space.task_count}</span>
                        <span>{space.title}</span>
                      </div>
                      {/* <p>{space.description}</p> */}
                    </div>
                  </button>

                  {selectedAsideSpaceId === space.id.toString() && (
                    <div className={styles.listSelectedSolutions}>
                      {(tasks || []).map((item: any) => (
                        <Link
                          key={item.id.toString()}
                          to={`/marketplace/task/${item.id.toString()}`}
                          className={styles.task}
                        >
                          <p>{item.title}</p>
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className={styles.spacesList}>
              {mySpacesFlat?.map((space) => (
                <div
                  key={space.id.toString()}
                  className={`${styles.spaceWrapper} ${
                    selectedAsideSpaceId === space.id.toString() ? styles.openedSpace : undefined
                  }`}
                >
                  <button
                    onClick={() => onSetSelectedSpace(space.id.toString())}
                    type="button"
                    key={space.id.toString()}
                    className={`${styles.space} ${
                      selectedAsideSpaceId === space.id.toString() ? styles.activeSpace : undefined
                    }`}
                  >
                    <div className={styles.spaceContent}>
                      <div className={styles.spaceContentTitle}>
                        <span className={styles.count}>{space.task_count}</span>

                        <span>{space.title}</span>
                      </div>
                      {/* <p>{space.description}</p> */}
                    </div>
                    <div className={styles.spaceActions}>
                      <Dropdown
                        righted
                        visibleContent={
                          // eslint-disable-next-line
                          <div className={styles.fix}>
                            <Button
                              icon={IconNames.theeDots}
                              variant={ButtonVariants.unstyled}
                              size={ButtonSizes.xs}
                              iconColor="var(--color-secondary)"
                              type="button"
                              label=""
                            />
                          </div>
                        }
                        hiddenContent={
                          // eslint-disable-next-line
                          <div className={styles.hiddenMenu}>
                            <ul className={styles.hidden}>
                              <li>
                                <Button
                                  icon={IconNames.edit}
                                  iconColor="var(--color-secondary)"
                                  isIconOnLeft
                                  type="button"
                                  onClick={() => onEdit(space.id.toString())}
                                  label="Edit"
                                  variant={ButtonVariants.unstyled}
                                />
                              </li>
                              <li>
                                <Button
                                  icon={IconNames.delete}
                                  iconColor="var(--color-red)"
                                  isIconOnLeft
                                  type="button"
                                  onClick={() => onRemove(space.id.toString())}
                                  label="Remove"
                                  variant={ButtonVariants.unstyled}
                                />
                              </li>
                            </ul>
                          </div>
                        }
                      />
                    </div>
                  </button>

                  {selectedAsideSpaceId === space.id.toString() && (
                    <div className={styles.listSelectedSolutions}>
                      {(tasks || []).map((item: any) => (
                        <Link
                          key={item.id.toString()}
                          to={`/marketplace/task/${item.id.toString()}`}
                          className={styles.task}
                        >
                          <p>{item.title}</p>
                          <Button
                            onClick={(event) => onRemoveTaskFromSpace(event, item.id.toString(), space.id.toString())}
                            type="button"
                            tooltip="Remove from space"
                            variant={ButtonVariants.unstyled}
                            icon={IconNames.spaceRemove}
                            iconColor="var(--color-secondary)"
                          />
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              ))}

              {mySpacesFlat?.length === 0 ? (
                <span className={styles.noSpaces}>You haven&apos;t created any spaces yet.</span>
              ) : null}
            </div>
          </Tabs>
        </div>

        {/* aaaa */}
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <h2>My prompts</h2>
            <Link to="/marketplace/create">
              <Button
                icon={IconNames.plus}
                size={ButtonSizes.xs}
                type="button"
                iconColor="var(--color-text)"
                variant={ButtonVariants.secondary}
                label="Create prompt"
              />
            </Link>
          </div>

          <div className={styles.search}>
            <Input
              icon={IconNames.search}
              type="text"
              onChange={(e) => setSearchInput(e.target.value)}
              placeholder="Search prompts"
              value={searchInput}
              action={
                // eslint-disable-next-line
                searchInput.length > 2 ? (
                  <Button
                    onClick={onReset}
                    variant={ButtonVariants.unstyled}
                    iconColor="var(--color-secondary)"
                    type="button"
                    icon={IconNames.close}
                  />
                ) : null
              }
            />
          </div>

          {!isSearchView && (
            <div className={styles.spacesList}>
              {(personalFlatTasks || []).map((item: any) => (
                <Link
                  key={item.id.toString()}
                  className={styles.personalSolution}
                  to={`/marketplace/task/${item.id.toString()}`}
                >
                  <div className={styles.personalTitle}>{item.title}</div>
                  <div className={styles.personalDescription}>{item.description}</div>
                </Link>
              ))}
              {personalFlatTasks?.length === 0 && <div>You did not create anything yet.</div>}
              {hasNextPage && (
                <div className={styles.feedActions}>
                  <Button
                    type="button"
                    label="Load more"
                    isLoading={isFetchingNextPage}
                    disabled={!hasNextPage || isFetchingNextPage}
                    onClick={fetchNextPage}
                  />
                </div>
              )}
            </div>
          )}

          {isSearchView && (
            <div className={styles.spacesList}>
              {(searchResults || []).map((item: any) => (
                <Link
                  key={item.id.toString()}
                  className={styles.personalSolution}
                  to={`/marketplace/task/${item.id.toString()}`}
                >
                  <div className={styles.personalTitle}>{item.title}</div>
                  <div className={styles.personalDescription}>{item.description}</div>
                </Link>
              ))}
              {searchResults?.length === 0 && <div>Nothing found.</div>}
            </div>
          )}
        </div>
      </Tabs>
    </div>
  )
}
