import { config } from 'src/config/config'
import { apiCall } from 'src/utils/networking'

export const api = {
  getCouponStatus: async ({ couponCode }) =>
    apiCall(`${config.baseAPIUrl}/account/coupon/${couponCode}`, {
      method: 'POST',
    }),

  submitCoupon: async ({ couponCode }) =>
    apiCall(`${config.baseAPIUrl}/account/coupon/${couponCode}?activate=1`, {
      method: 'POST',
    }),
}
