import { useMatch } from '@tanstack/react-location'
import Select from 'react-select'
import { Button } from 'src/core/ds/button'
import { ButtonVariants } from 'src/core/ds/button/types'
import { PLAUSIBLE_EVENTS } from 'src/services/plausible/consts'
import { firePlausibleEvent } from 'src/services/plausible/plausibleService'
import { useUIStore } from 'src/services/store/UIStore'
import { useGetCurrentUser, useUpdateProfile } from '../currentUser/query'
// import { createParamsString } from '../currentUser/utils'
import { useGetTaskInfo } from '../tasks/query'
import styles from './index.module.scss'
import { useGetConfig } from './query'

const getOptions = (config, key) =>
  Object.keys(config[key] || {})
    ?.filter((itemKey) => config[key][itemKey]?.status === 'active')
    ?.map((itemKey) => ({
      value: config[key][itemKey].id,
      label: `${config[key][itemKey].emoji} ${config[key][itemKey].option}`,
    }))
    .sort((a, b) => a.label.slice(2).localeCompare(b.label.slice(2)))

const foundOption = (options, value) => options.find((element) => +element.value === +value) || undefined

export function SettingsModal() {
  const {
    params: { taskId },
  } = useMatch()
  const isPROAccount = useUIStore((state) => state.isPROAccount)
  const updateUIStore = useUIStore((state) => state.updateUIStore)
  const { mutate, isLoading: isLoadingUpdate } = useUpdateProfile()
  const { data: config, isLoading } = useGetConfig()
  const { data: currentUser } = useGetCurrentUser()
  const { data: taskDetails } = useGetTaskInfo({
    id: taskId,
    onError: () => undefined,
  })

  const currentTaskAIId = taskDetails?.ai?.id?.toString() || '16686324501184513'
  const currentSettingsList = (config && config[currentTaskAIId]) || {}
  const currentUserConfig = (currentUser && currentUser?.config && currentUser?.config[currentTaskAIId]) || {}

  const onSubmit = async (event) => {
    event.preventDefault()

    const configs = Object.keys(currentSettingsList).reduce((acc, curr) => {
      if (event.target.elements[curr]?.value) {
        acc[curr] = +event.target.elements[curr]?.value
      }

      return acc
    }, {})

    const configObject = {
      update: {
        config: {
          ...currentUser?.config,
          [currentTaskAIId]: { ...currentUserConfig, ...configs },
        },
      },
    }

    // const params = createParamsString({ config: configObject })
    await mutate(configObject)
  }

  return (
    <div className={styles.modal}>
      {!isPROAccount && (
        <div className={styles.pro}>
          Settings are available only for PRO accounts.&nbsp;
          <br />
          <Button
            onClick={() => {
              updateUIStore('isPricesPopupOpen', true)
              firePlausibleEvent(PLAUSIBLE_EVENTS.CLICK_BUY_CREDITS)
            }}
            type="button"
            variant={ButtonVariants.unstyled}
            label="Upgrade to PRO."
          />
        </div>
      )}
      {!isLoading && (
        <form onSubmit={onSubmit}>
          {Object.keys(currentSettingsList || {})?.map((settingsKey) => (
            <div className={styles.formControl} key={settingsKey}>
              {/* eslint-disable-next-line */}
              <label htmlFor={settingsKey}>{settingsKey}</label>
              <Select
                className="basic-multi-select"
                classNamePrefix="select_custom"
                defaultValue={foundOption(getOptions(currentSettingsList, settingsKey), currentUserConfig[settingsKey])}
                isSearchable
                isDisabled={!isPROAccount}
                name={settingsKey}
                options={getOptions(currentSettingsList, settingsKey)}
              />
            </div>
          ))}

          <div className={styles.modalActions}>
            <Button disabled={isLoadingUpdate} isLoading={isLoadingUpdate} type="submit" label="Save" />
          </div>
        </form>
      )}
    </div>
  )
}
