import { Link, useNavigate } from '@tanstack/react-location'
import { Button } from 'src/core/ds/button'
import { ButtonSizes, ButtonVariants } from 'src/core/ds/button/types'
import { Dropdown } from 'src/core/ds/dropdown'
import { IconNames } from 'src/core/ds/icon'
import { useAddOrRemoveTeamSpace, useGetTeamSpaces } from 'src/modules/teams/query'
import { useGetSpaceDetailsList } from 'src/pages/SelectedSpacePage/query'
import { pushNotification } from 'src/services/notifications/notificationService'
import { useUIStore } from 'src/services/store/UIStore'
import { useAddTaskToSpace } from '../AddToSpaceModal/query'
import styles from './index.module.scss'

export function AsideTeamSpaces({ isTeamAdmin, teamId, currentUser }) {
  const updateUIStore = useUIStore((state) => state.updateUIStore)
  const selectedAsideSpaceId = useUIStore((state) => state.selectedAsideSpaceId)

  const navigate = useNavigate()
  const { data: personalSpaces } = useGetTeamSpaces(teamId)

  const mySpacesFlat: any = personalSpaces?.items || []
  // const [selectedSpace, setSelectedSpace] = useState<any>(null)
  const isPROAccount = useUIStore((state) => state.isPROAccount)

  const onSpaceDetached = () => {
    pushNotification({
      type: 'success',
      title: 'Space Detached',
    })
  }

  const { mutate: attachTeamSpaceMutate } = useAddOrRemoveTeamSpace(onSpaceDetached)

  const onSetSelectedSpace = (spaceId) => {
    updateUIStore('selectedAsideSpaceId', spaceId)
  }

  const openCreateNewSpaceModal = () => {
    updateUIStore('isCreateNewSpaceOpen', true)
  }

  const onEdit = (spaceId) => {
    updateUIStore('isEditSpaceModalOpen', true)
    updateUIStore('editSpaceBuffer', spaceId)
  }

  const onRemove = async (spaceId) => {
    await attachTeamSpaceMutate({
      // eslint-disable-next-line
      // @ts-ignore
      id: teamId,
      details: {
        type: 'space',
        // eslint-disable-next-line
        // @ts-ignore
        remove: [spaceId],
      },
    })
  }

  const onPrivate = () => {
    navigate({ to: `/spaces` })
  }

  const onSuccessRemoveFromSpace = () => {
    pushNotification({
      type: 'success',
      title: 'Task removed from space',
    })
  }

  const { mutate } = useAddTaskToSpace(onSuccessRemoveFromSpace)

  const onRemoveTaskFromSpace = async (event, taskId, spaceId) => {
    event.preventDefault()
    await mutate({
      DTO: {
        remove: [taskId.toString()],
      },
      spaceId: spaceId.toString(),
    })
  }

  // eslint-disable-next-line
  // @ts-ignore
  const { data: tasksRaw } = useGetSpaceDetailsList(selectedAsideSpaceId?.toString(), onPrivate)
  const tasks: any = tasksRaw?.pages?.flatMap((page) => page?.items ?? page) ?? []

  // const onLoadSolutionsFromSpaces = (spaceId) => {}

  if (!currentUser) return null

  return (
    <div className={styles.sectionWrapper}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <h2>Team spaces</h2>
          {isTeamAdmin && (
            <Button
              icon={isPROAccount ? IconNames.spaceAdd : IconNames.pro}
              size={ButtonSizes.xs}
              type="button"
              disabled={!isPROAccount}
              onClick={openCreateNewSpaceModal}
              variant={ButtonVariants.secondary}
              label="Create space"
            />
          )}
        </div>
        <div className={styles.spacesList}>
          {mySpacesFlat?.map((space) => (
            <div
              key={space.id.toString()}
              className={`${styles.spaceWrapper} ${
                selectedAsideSpaceId === space.id.toString() ? styles.openedSpace : undefined
              }`}
            >
              <button
                onClick={() => onSetSelectedSpace(space.id.toString())}
                type="button"
                key={space.id.toString()}
                className={`${styles.space} ${
                  selectedAsideSpaceId === space.id.toString() ? styles.activeSpace : undefined
                }`}
              >
                <div className={styles.spaceContent}>
                  <div className={styles.spaceContentTitle}>
                    <span className={styles.count}>{space.task_count}</span>
                    <span>{space.title}</span>
                  </div>
                  {/* <p>{space.description}</p> */}
                </div>
                {isTeamAdmin && (
                  <div className={styles.spaceActions}>
                    <Dropdown
                      righted
                      visibleContent={
                        // eslint-disable-next-line
                        <div className={styles.fix}>
                          <Button
                            icon={IconNames.theeDots}
                            variant={ButtonVariants.unstyled}
                            size={ButtonSizes.xs}
                            iconColor="var(--color-secondary)"
                            type="button"
                            label=""
                          />
                        </div>
                      }
                      hiddenContent={
                        // eslint-disable-next-line
                        <div className={styles.hiddenMenu}>
                          <ul className={styles.hidden}>
                            <li>
                              <Button
                                icon={IconNames.edit}
                                iconColor="var(--color-secondary)"
                                isIconOnLeft
                                type="button"
                                onClick={() => onEdit(space.id.toString())}
                                label="Edit"
                                variant={ButtonVariants.unstyled}
                              />
                            </li>
                            <li>
                              <Button
                                icon={IconNames.delete}
                                iconColor="var(--color-red)"
                                isIconOnLeft
                                type="button"
                                onClick={() => onRemove(space.id.toString())}
                                label="Detach space"
                                variant={ButtonVariants.unstyled}
                              />
                            </li>
                          </ul>
                        </div>
                      }
                    />
                  </div>
                )}
              </button>

              {selectedAsideSpaceId === space.id.toString() && (
                <div className={styles.listSelectedSolutions}>
                  {(tasks || []).map((item: any) => (
                    <Link
                      key={item.id.toString()}
                      to={`/teams/${teamId.toString()}/${item.id.toString()}`}
                      className={styles.task}
                    >
                      <p>{item.title}</p>
                      {isTeamAdmin && (
                        <Button
                          onClick={(event) => onRemoveTaskFromSpace(event, item.id.toString(), space.id.toString())}
                          type="button"
                          tooltip="Remove from space"
                          variant={ButtonVariants.unstyled}
                          icon={IconNames.spaceRemove}
                          iconColor="var(--color-secondary)"
                        />
                      )}
                    </Link>
                  ))}
                </div>
              )}
            </div>
          ))}

          {mySpacesFlat?.length === 0 ? (
            <span className={styles.noSpaces}>You haven&apos;t created any spaces yet.</span>
          ) : null}
        </div>
      </div>
    </div>
  )
}
