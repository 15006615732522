import { useMutation } from 'react-query'
import { queryClient } from 'src/queries/reactQueryClient'
import { getPersonalSpacesList } from 'src/queries/reactQueryKeys'
import { pushNotification } from 'src/services/notifications/notificationService'
import { PLAUSIBLE_EVENTS } from 'src/services/plausible/consts'
import { firePlausibleEvent } from 'src/services/plausible/plausibleService'
import { api } from './api'

export const useCreateNewSpace = (onSuccess) =>
  useMutation(api.createNewSpace, {
    onSuccess: (response: any) => {
      const [error, data] = response
      // eslint-disable-next-line
      // @ts-ignore
      window.lastCreatedSpace = data?.id?.toString()

      if (error) {
        pushNotification({
          type: 'error',
          title: 'Too many creation attempts.',
        })
        firePlausibleEvent(PLAUSIBLE_EVENTS.SPACE_CREATION_FAILED)
        throw Error(error)
      }

      // pushNotification({
      //   type: 'success',
      //   title: 'Space created.',
      // })
      firePlausibleEvent(PLAUSIBLE_EVENTS.SPACE_CREATED)
      queryClient.invalidateQueries({ queryKey: getPersonalSpacesList() })
      onSuccess && onSuccess()

      return data
    },
    onError: (err: any) => {
      pushNotification({
        type: 'error',
        title: 'Space creation failed.',
      })
      firePlausibleEvent(PLAUSIBLE_EVENTS.SPACE_CREATION_FAILED)

      return err
    },
  })
