import { Button } from 'src/core/ds/button'
import { ButtonVariants } from 'src/core/ds/button/types'
import { Dropdown } from 'src/core/ds/dropdown'
import { IconNames } from 'src/core/ds/icon'
import styles from './index.module.scss'

export const stepsDictionary = {
  ChatGPT: ['Pick AI Model', 'Pick Tags', 'Public Details', 'Prompt', 'Review'],
  dalle: ['Pick AI Model', 'Pick Tags', 'Public Details', 'Prompt', 'Review'],
}

export function ProgressBar({ currentFormState, type, step, updateStep, isEditMode = false }: any) {
  if (typeof step === undefined || !type) return null

  const { lastMaxStep } = currentFormState
  const stepsLength = stepsDictionary[type].length
  const currentStep = stepsDictionary[type][step]
  const nextStep = stepsDictionary[type][step + 1] ?? null
  // const prevStep = stepsDictionary[type][step - 1] ?? null
  const percentage = ((step + 1) / stepsLength) * 100

  return (
    <div className={styles.progressBox}>
      <div className={styles.line}>
        <div style={{ width: `${percentage ?? 5}%` }} className={styles.progress} />
      </div>
      <div className={styles.dropDownContainer}>
        <Dropdown
          visibleContent={
            // eslint-disable-next-line
            <div className={styles.steps}>
              {/* {prevStep && (
          <div className={styles.step}>
            <span>Prev step</span>
            <p>{prevStep}</p>
          </div>
        )} */}
              {/* {prevStep && <div className={styles.splitter} />} */}
              <div className={styles.step}>
                {nextStep && <span className={styles.stepActive}>Current step</span>}
                {!nextStep && <span className={styles.stepActiveLast}>Final step</span>}
                <p>{currentStep}</p>
              </div>
              {nextStep && <div className={styles.splitter} />}
              {nextStep && (
                <div className={styles.step}>
                  <span>Next step</span>
                  <p>{nextStep}</p>
                </div>
              )}
            </div>
          }
          hiddenContent={
            // eslint-disable-next-line
            <ul className={styles.hiddenDropdown}>
              {stepsDictionary[type].map((stepName, index) => (
                <li key={stepName}>
                  <Button
                    icon={!isEditMode && index <= lastMaxStep ? IconNames.checkFill : undefined}
                    iconColor="var(--color-primary)"
                    disabled={!isEditMode && index > lastMaxStep}
                    label={stepName}
                    onClick={() => updateStep(index)}
                    variant={ButtonVariants.unstyled}
                    type="button"
                  />
                </li>
              ))}
            </ul>
          }
        />
      </div>
    </div>
  )
}

// pick model - pick category - public card - info card
// price block - context - prompts and variables
