import { useInfiniteQuery } from 'react-query'
import { getPublicSpacesList } from 'src/queries/reactQueryKeys'
import { api } from './api'

export const useGetPublicSpaces = () =>
  useInfiniteQuery(
    getPublicSpacesList(),
    async ({ pageParam = 1 }) => {
      const response = await api.getPublicSpaces({ page: pageParam })
      const [error, data] = response as any[]

      if (error) {
        throw new Error(error)
      }

      return data
    },

    {
      getNextPageParam: (lastPage: any) =>
        // lastPage?.length >= 20 ? pages.length * 20 : false

        lastPage?.pagination?.next_page ?? undefined,
      onError: (error: any) => console.log('WTF', error),
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: 0,
      keepPreviousData: false,
    },
  )
