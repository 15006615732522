import { Link } from '@tanstack/react-location'
import { Container } from 'src/core/ds/container'
import styles from './index.module.scss'

export function NotFoundPage() {
  return (
    <Container>
      <div className={styles.wrapper}>
        <div>
          <h1>404</h1>
          <p>Nothing was found.</p>
          <Link to="/marketplace">Go back home</Link>
        </div>
      </div>
    </Container>
  )
}
