import { useInfiniteQuery, useQuery } from 'react-query'
import {
  getCategoryInfoById,
  getMyPersonalTasksList,
  getMyPersonalTasksListDashboard,
  getTasksList,
} from 'src/queries/reactQueryKeys'
import { api } from './api'

export const useGetTasks = (aiId, categoryId, sorting) =>
  useInfiniteQuery(
    getTasksList(categoryId, sorting, aiId),
    async ({ pageParam = 1 }) => {
      const apiCall = categoryId !== 'all' ? api.getTasksByCategoryId : api.getAllTasks

      const response = await apiCall({ categoryId, sorting, page: pageParam, aiId })
      const [error, data] = response as any[]

      if (error) {
        throw new Error(error)
      }

      return data
    },

    {
      getNextPageParam: (lastPage: any) => lastPage?.pagination?.next_page ?? undefined,
      onError: (error: any) => console.log('WTF', error),
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: !!aiId,
      cacheTime: 0,
      keepPreviousData: false,
    },
  )

export const useGetCategory = (categoryId) =>
  useQuery(
    getCategoryInfoById(categoryId),
    async () => {
      const response = await api.getCategoryInfoById({ categoryId, page: 1 })
      const [error, data] = response as any[]

      if (error) {
        throw new Error(error)
      }

      return data
    },
    {
      enabled: categoryId !== 'all',
    },
  )

export const useGetMyTasks = (test, accountId, sorting) =>
  useInfiniteQuery(
    getMyPersonalTasksList(accountId, sorting),
    async ({ pageParam = 1 }) => {
      if (!accountId) return []

      console.log(test)
      const response = await api.geyMyTasks({ accountId, sorting, page: pageParam })
      const [error, data] = response as any[]

      if (error) {
        throw new Error(error)
      }

      return data
    },

    {
      getNextPageParam: (lastPage: any) =>
        // lastPage?.length >= 20 ? pages.length * 20 : false

        lastPage?.pagination?.next_page ?? undefined,
      onError: (error: any) => console.log('WTF', error),
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: 0,
      keepPreviousData: false,
    },
  )

export const useGetMyTasksInfinite = (accountId, sorting = 'popular') =>
  useInfiniteQuery(
    getMyPersonalTasksListDashboard(accountId, sorting),
    async ({ pageParam = 1 }) => {
      const response = await api.geyMyTasks({ accountId, sorting, page: pageParam })
      const [error, data] = response as any[]

      if (error) {
        throw new Error(error)
      }

      return data
    },

    {
      getNextPageParam: (lastPage: any) =>
        // lastPage?.length >= 20 ? pages.length * 20 : false

        lastPage?.pagination?.next_page ?? undefined,
      onError: (error: any) => console.log('WTF', error),
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: 0,
      enabled: !!accountId,
      keepPreviousData: false,
    },
  )
