import { Link, useMatch } from '@tanstack/react-location'
import { createColumnHelper } from '@tanstack/react-table'
import { useDeferredValue, useMemo, useState } from 'react'
import { Card } from 'src/components/Card'
import { NotAuthorized } from 'src/components/NotAuthorized/NotAuthorized'
import { RevenueChart } from 'src/components/RevenueChart/RevenueChart'
import { RevenueChartGlobal } from 'src/components/RevenueChartGlobal/RevenueChartGlobal'
import { badgeFactory } from 'src/components/SingleTask/SingleTask'
import { Button } from 'src/core/ds/button'
import { ButtonSizes, ButtonVariants } from 'src/core/ds/button/types'
import { IconNames } from 'src/core/ds/icon'
import { Input } from 'src/core/ds/input'
import { Modal } from 'src/core/ds/modal'
import { Selector } from 'src/core/ds/selector'
import { useGetCurrentUser } from 'src/modules/currentUser/query'
import { useGetMyTasksInfinite } from 'src/modules/tasksList/query'
import { useUIStore } from 'src/services/store/UIStore'
import styles from './index.module.scss'
import { useGetAccountStats, useGetAccountStatsSummary, useGetTaskStats } from './query'
import { CreatorTasksTable } from './Table'

const sortOptions = [
  { value: 'popular', label: 'Popular' },
  { value: 'recent', label: 'Recent' },
  { value: 'alphabet', label: 'Alphabet' },
]
type Task = {
  title: string
  id: number
  tags: any[]
  people_usage_count: number
  creator_usage_count: number
  revenue: number
  status: string
  action: any
  description: string
}
export function CreatorDashboardPage() {
  const {
    params: { taskId },
  } = useMatch()

  // const [selectedSort, setSelectedSort] = useState(sortOptions[1].value)
  const [searchInput, setSearchInput] = useState('')
  const [selectedTaskId, setSelectedTaskId] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const deferredSearchInput = useDeferredValue(searchInput)

  const updateUIStore = useUIStore((state) => state.updateUIStore)
  const creatorAreaSorting = useUIStore((state) => state.creatorAreaSorting)

  const onOpenModal = (id) => {
    setSelectedTaskId(id.toString())
    setIsModalOpen(true)
  }

  const onSortChange = (event) => {
    // let target
    console.log(taskId)

    updateUIStore('creatorAreaSorting', event.target.value)
  }

  const { data: taskStats } = useGetTaskStats(selectedTaskId)

  const { data: currentUser } = useGetCurrentUser()
  const {
    data: myPersonalTasks,
    // isLoading,
    fetchNextPage: fetchNextPagePersonal,
    hasNextPage: hasNextPagePersonal,
    isFetchingNextPage: isFetchingNextPagePersonal,
  } = useGetMyTasksInfinite(currentUser?.id.toString(), creatorAreaSorting)
  const { data: accountStats } = useGetAccountStats(currentUser?.id.toString())
  const { data: accountSummary } = useGetAccountStatsSummary(currentUser?.id.toString())
  const personalTasks = myPersonalTasks?.pages?.flatMap((page) => page.items || [])

  const isSearchView = deferredSearchInput.length > 2

  const foundTasks = (personalTasks || [])?.filter((task) =>
    task.title.toLowerCase().includes(deferredSearchInput.toLowerCase()),
  )

  const columnHelper = createColumnHelper<Task>()

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => ({ description: row.description, id: row.id.toString(), title: row.title }), {
        id: 'title',
        // eslint-disable-next-line
        cell: (info) => (
          <div className={styles.tableTitle}>
            <Link to={`/marketplace/task/${info.getValue().id}`}>{info.getValue().title}</Link>
            <p>{info.getValue().description}</p>
          </div>
        ),
        // eslint-disable-next-line
        header: () => <span className={styles.head}>Title</span>,
        footer: (info) => info.column.id,
      }),

      columnHelper.accessor((row) => row.tags, {
        id: 'tags',
        // eslint-disable-next-line
        cell: (info) => (
          <div className={styles.date}>
            {info.getValue().map((tag) => (
              <Link key={tag?.id.toString()} to={`/marketplace/${tag?.id.toString()}/`}>
                {tag?.emoji}
                &nbsp;
                {tag?.name}
              </Link>
            ))}
          </div>
        ),
        // eslint-disable-next-line
        header: () => <span className={styles.head}>Tags</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('people_usage_count', {
        // eslint-disable-next-line
        header: () => <span className={styles.head}>Used by users</span>,
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('creator_usage_count', {
        // eslint-disable-next-line
        header: () => <span className={styles.head}>Used by you</span>,
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      // columnHelper.accessor('revenue', {
      //   // eslint-disable-next-line
      //   header: () => <span className={styles.head}>Revenue</span>,
      //   // eslint-disable-next-line
      //   cell: (info) => <div>{getDollarValue(info.renderValue())}</div>,
      //   footer: (info) => info.column.id,
      // }),
      columnHelper.accessor('status', {
        // eslint-disable-next-line
        header: () => <span className={styles.head}>Status</span>,
        // eslint-disable-next-line
        cell: (info) => <div>{badgeFactory(info.renderValue())}</div>,
        footer: (info) => info.column.id,
      }),

      columnHelper.accessor((row) => ({ id: row.id.toString(), people_usage_count: row.people_usage_count }), {
        id: 'action',
        // eslint-disable-next-line
        cell: (info) => (
          <div className={styles.edit}>
            <Link to={`/marketplace/edit/${info?.renderValue()?.id}`}>
              <Button
                type="button"
                variant={ButtonVariants.secondary}
                size={ButtonSizes.xs}
                label="Edit"
                iconColor="var(--color-text)"
                icon={IconNames.edit}
              />
            </Link>
            <Button
              onClick={() => onOpenModal(info?.renderValue()?.id)}
              size={ButtonSizes.xs}
              type="button"
              label="Show chart"
              disabled={info.renderValue()?.people_usage_count === 0}
            />
          </div>
        ),
        // eslint-disable-next-line
        header: () => <span className={styles.head}>Actions</span>,
        footer: (info) => info.column.id,
      }),
    ],
    // eslint-disable-next-line
    [],
  )

  return (
    <div className={styles.dashboard}>
      <Modal
        withCloseButton
        title="Prompt stats"
        onClose={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
        body={
          // eslint-disable-next-line
          <div className={styles.modalInstance}>{taskStats && <RevenueChart series={taskStats} />}</div>
        }
      />

      {!currentUser && <NotAuthorized />}

      {currentUser && (
        <div className={styles.content}>
          <div className={styles.greet}>
            <h1>
              Hello,&nbsp;
              {currentUser?.full_name || currentUser?.username}
            </h1>
            <p>
              This is your personal Zentask dashboard, where you can manage your prompts and keep an eye on performance.
            </p>
          </div>

          <div className={styles.revenueWrapper}>
            <div>
              <h2>Usages chart</h2>
              <RevenueChartGlobal series={accountStats} />
            </div>
            <div className={styles.short}>
              <h2>Summary</h2>

              <div className={styles.summary}>
                {/* <Card>
                  <p>Total revenue</p>
                  <span>{getDollarValue(accountSummary?.revenue)}</span>
                </Card> */}
                <Card>
                  <p>Total usages by users</p>
                  <span>{accountSummary?.people_usage_count || 0}</span>
                </Card>
                <Card>
                  <p>Total usages by you</p>
                  <span>{accountSummary?.creator_usage_count || 0}</span>
                </Card>
              </div>
            </div>
          </div>

          <div className={styles.listWrapper}>
            <div className={styles.listHeader}>
              <div>
                <h2>
                  Created prompts
                  <span>
                    {(myPersonalTasks?.pages && myPersonalTasks?.pages[0]?.total) || 0}
                    &nbsp;total
                  </span>
                </h2>
              </div>
              {currentUser && (
                <Link to="/marketplace/create">
                  <Button label="Create prompt" icon={IconNames.plus} type="button" />
                </Link>
              )}
            </div>

            <div className={styles.filters}>
              <Selector
                hasDefault={false}
                onChange={onSortChange}
                label="Sort by"
                options={sortOptions}
                selected={creatorAreaSorting}
              />
              <Input
                icon={IconNames.search}
                type="text"
                onChange={(e) => setSearchInput(e.target.value)}
                placeholder="Search"
                value={searchInput}
              />
            </div>

            {personalTasks && (
              <CreatorTasksTable
                columns={columns}
                onOpenModal={onOpenModal}
                data={isSearchView ? foundTasks : personalTasks}
              />
            )}

            <div className={styles.actions}>
              {hasNextPagePersonal && (
                <div className={styles.feedActions}>
                  <Button
                    type="button"
                    label="Load more"
                    isLoading={isFetchingNextPagePersonal}
                    disabled={!hasNextPagePersonal || isFetchingNextPagePersonal}
                    onClick={fetchNextPagePersonal}
                  />
                </div>
              )}
            </div>
          </div>

          <div className={styles.help}>
            <h3>Need help?</h3>
            <p>
              Find us on Twitter:&nbsp;
              <a target="_blank" href="https://twitter.com/donhardman88" rel="noreferrer">
                @Don Hardman
              </a>
              ,&nbsp;
              <a target="_blank" href="https://twitter.com/web3brightside" rel="noreferrer">
                @Vladimir Kosenko
              </a>
            </p>
          </div>
        </div>
      )}
    </div>
  )
}
