import { config } from 'src/config/config'
import { apiCall } from 'src/utils/networking'

export const api = {
  searchItems: async ({ searchInput }) =>
    apiCall(`${config.baseAPIUrl}/history/search?q=${searchInput}&limit=50`, {
      method: 'POST',
    }),

  getItems: async () =>
    apiCall(`${config.baseAPIUrl}/history/list`, {
      method: 'POST',
    }),

  getItems20: async () =>
    apiCall(`${config.baseAPIUrl}/history/list?limit=20`, {
      method: 'POST',
    }),
}
