import { useMatch } from '@tanstack/react-location'
import { EditorSolutionWizard } from './components/EditorSolutionWizard'
import styles from './index.module.scss'

export function SolutionEditorPage() {
  const {
    params: { taskId },
  } = useMatch()

  return (
    <div className={styles.dashboard}>
      <EditorSolutionWizard taskId={taskId} />
    </div>
  )
}
