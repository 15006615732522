import { Badge } from 'src/core/ds/badge'
import { BadgeType } from 'src/core/ds/badge/types'
import styles from './index.module.scss'

export function StreamStatus({ status = 'idle' }: { status: 'idle' | 'processing' | 'completed' | 'error' }) {
  if (status === 'idle') {
    return null
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.label}>status:</div>

      {status === 'processing' && (
        <Badge
          label={
            // eslint-disable-next-line
            <div className={styles.flex}>
              processing
              <div className={styles.spinner} />
            </div>
          }
          type={BadgeType.INFO}
        />
      )}

      {status === 'completed' && <Badge label="completed" type={BadgeType.SUCCESS} />}
      {status === 'error' && <Badge label="error" type={BadgeType.FAIL} />}
    </div>
  )
}
