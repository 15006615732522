import { Link } from '@tanstack/react-location'
import { useState } from 'react'
import { Button } from 'src/core/ds/button'
import { ButtonVariants } from 'src/core/ds/button/types'
import { Icon, IconNames, IconSize } from 'src/core/ds/icon'
import { useCreateNewTask } from 'src/modules/createTaskModal/query'
import { useGetCurrentUser } from 'src/modules/currentUser/query'
import { queryClient } from 'src/queries/reactQueryClient'
import { getMyPersonalTasksList, getSelectedTaskKey } from 'src/queries/reactQueryKeys'
import { useUIStore } from 'src/services/store/UIStore'
import styles from './index.module.scss'
import { maxWidthValidation } from './PublicDetails'

const publicItems = [
  {
    id: 1,
    name: 'Private prompt',
    mode: 'private',
    icon: IconNames.eyeHide,
    description: "This prompt is private and doesn't require moderation. Feel free to use any language.",
  },
  {
    id: 2,
    name: 'Public prompt',
    mode: 'public',
    icon: IconNames.billingSettings,
    description: 'This is a public prompt. It should go through moderation and will be placed on the marketplace.',
  },
]
export function ReviewAndEdit({ onStartAgain, taskId, currentFormState, updateFormState, onPrev }) {
  const { data: currentUser } = useGetCurrentUser()
  const selectedPublicMode = currentFormState?.isPublic
  const [isCreatedSolution, setIsCreatedSolution] = useState(false)
  const [error, setError] = useState(null)
  const isPROAccount = useUIStore((state) => state.isPROAccount)

  const onPickPublic = (mode) => {
    updateFormState('isPublic', mode)
  }

  const onSuccess = () => {
    setIsCreatedSolution(true)
    setError(null)
    // invalidate user tasks
    queryClient.invalidateQueries({ queryKey: getMyPersonalTasksList(currentUser?.id.toString(), 'popular') })
    queryClient.invalidateQueries({ queryKey: getSelectedTaskKey(taskId?.toString()) })
    // setAvailableFormulas([])
    // setCurrentFormula({})
  }

  const onStartOver = () => {
    setIsCreatedSolution(false)
    onStartAgain()
  }

  const { isLoading, mutate } = useCreateNewTask(onSuccess, 'edit')

  const onCreateSolutionHandler = async () => {
    const isValid = validation()

    if (!isValid) {
      return
    }

    const taskDTO = {
      tag_ids: currentFormState.tags,
      ai_id: BigInt(currentFormState.model.toString()),
      title: currentFormState.title,
      description: currentFormState.description,
      prompt: currentFormState.prompt,
      hint: currentFormState.hint,
      id: taskId.toString(),
    }

    await mutate({ taskDTO, isPublic: currentFormState?.isPublic === 'public' })
  }

  const validation = () => {
    let error: any = false

    if (currentFormState?.tags?.length === 0) {
      error = 'No Tags provided.'
    }

    if (currentFormState.title.length < 11 || currentFormState.title.length > maxWidthValidation.title) {
      error = 'Title must be between 10 and 60 characters.'
    }

    if (
      currentFormState.description.length < 31 ||
      currentFormState.description.length > maxWidthValidation.description
    ) {
      error = 'Description must be between 30 and 155 characters.'
    }

    if (currentFormState.hint.length < 5) {
      error = 'Hint must be at least 5 characters.'
    }

    if (currentFormState.prompt.length < 5) {
      error = 'Prompt must be specified.'
    }

    setError(error)

    return !error
  }

  return (
    <div>
      {!isCreatedSolution && (
        <div>
          <h2 className={styles.wizardTitle}>Review and Save</h2>
          <div className={styles.wizardDescription}>
            This is the final step. Make sure that everything is correct. You can go back and make edits. Choose whether
            you want to make your prompt public or keep it for personal use.
          </div>

          <div className={styles.subDescription}>
            If you want to place your prompt on the Marketplace, please make sure that it is written in English.
            Otherwise, it will be declined.
          </div>
        </div>
      )}

      {isCreatedSolution && (
        <div className={styles.success}>
          <Icon name={IconNames.checkCircle} color="var(--color-green)" size={IconSize['2xl']} />
          <h1>Prompt Edited!</h1>
          <div>
            <Button type="button" onClick={onStartOver} variant={ButtonVariants.secondary} label="Start over" />
            <Link to={`/marketplace/task/${taskId.toString()}`}>
              <Button type="button" label="View task" />
            </Link>
          </div>
        </div>
      )}

      {!isCreatedSolution && (
        <div className={styles.aiModels}>
          {publicItems.map((model) => (
            <button
              onClick={() => onPickPublic(model.mode)}
              key={model.id.toString()}
              type="button"
              disabled={!isPROAccount && model.mode === 'public' && selectedPublicMode !== 'public'}
              className={`${styles.aiModel} ${selectedPublicMode === model.mode ? styles.activeModel : undefined}`}
            >
              <div className={styles.aiLogo}>
                <Icon name={model.icon} />
              </div>
              <div className={styles.aiName}>
                {/* eslint-disable-next-line */}
                {model.name} {!isPROAccount && model.mode === 'public' && <Icon name={IconNames.pro} />}
              </div>
              <div className={styles.aiDescription}>{model.description}</div>
            </button>
          ))}
        </div>
      )}

      {error && (
        <div className={styles.error}>
          <Icon name={IconNames.warning} color="var(--color-red)" />
          <p>{error}</p>
        </div>
      )}

      {!isCreatedSolution && (
        <div className={styles.actionFooter}>
          <Button variant={ButtonVariants.secondary} onClick={onPrev} type="button" label="Go back" />
          <Button
            isLoading={isLoading}
            disabled={!selectedPublicMode || isLoading}
            onClick={onCreateSolutionHandler}
            type="button"
            label="Save solution"
          />
        </div>
      )}
    </div>
  )
}
