import * as Sentry from '@sentry/react'
import { Link, useNavigate } from '@tanstack/react-location'
import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'
import { ESCAPE } from 'src/constants/keyCodes'
import { Avatar } from 'src/core/ds/avatar'
import { AVATAR_SIZES } from 'src/core/ds/avatar/consts'
import { Button } from 'src/core/ds/button'
import { ButtonVariants } from 'src/core/ds/button/types'
import { Icon, IconNames } from 'src/core/ds/icon'
import { Input } from 'src/core/ds/input'
import { useDebounce } from 'src/hooks/useDebounce'
import { PLAUSIBLE_EVENTS } from 'src/services/plausible/consts'
import { firePlausibleEvent } from 'src/services/plausible/plausibleService'
import { api } from './api'
import styles from './index.module.scss'

export function Spotlight({ isDesktop = true }) {
  const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0
  const buttonText = isMac ? '⌘ + K' : 'Ctrl + K'
  const [searchInput, setSearchInput] = useState('')
  const debouncedValue = useDebounce(searchInput, 200)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [searchResults, setSearchResults] = useState<any>(null)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const containerRef = useRef(null)
  const navigate = useNavigate()

  useEffect(() => {
    if (debouncedValue.length < 1) {
      setSearchResults(null)

      return
    }

    api
      .searchItems({ searchInput: debouncedValue })
      .then((res: any) => {
        const [error, response] = res
        firePlausibleEvent(PLAUSIBLE_EVENTS.START_SEARCH)

        if (error) {
          console.log('error', error)
        }

        if (response?.items) {
          // Send the event
          Sentry.captureEvent({
            message: 'search',
            extra: {
              query: debouncedValue,
            }, // This is your custom payload
          })
          setSearchResults(response.items)
        }

        if (response?.length === 0) {
          setSearchResults([])
        }
      })
      .catch((err) => {
        console.log('error', err)
      })
  }, [debouncedValue])

  const handleKeyDownContainer = (e) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault()

      if (searchResults) {
        setSelectedIndex((prevIndex) => Math.min(prevIndex + 1, searchResults.length - 1))
      }
    } else if (e.key === 'ArrowUp') {
      e.preventDefault()

      if (searchResults) {
        setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, 0))
      }
    } else if (e.key === 'Enter' && selectedIndex >= 0) {
      const selectedItem = searchResults?.[selectedIndex]

      if (selectedItem) {
        const taskURL = `/marketplace/task/${selectedItem?.id?.toString()}`

        // Navigate to the link
        navigate({ to: taskURL, replace: true })
        setIsModalVisible(false)
        // history?.push(taskURL)
      }
    }
  }

  const handleInputFocus = () => {
    firePlausibleEvent(PLAUSIBLE_EVENTS.OPEN_SPOTLIGHT)
    setIsModalVisible(true)
    setSearchInput('')
    setSearchResults(null)
  }

  const onReset = () => {
    setSearchInput('')
    setSearchResults(null)
  }

  // Event handler for the keydown event

  const handleKeyDown = (event) => {
    if ((event.ctrlKey || event.metaKey) && event.key === 'k') {
      event.preventDefault() //
      setIsModalVisible(true)
      setSearchInput('')
      setSearchResults(null)
      firePlausibleEvent(PLAUSIBLE_EVENTS.OPEN_SPOTLIGHT)
    }

    switch (event.keyCode) {
      // close
      case ESCAPE:
        setIsModalVisible(false)
        break

      default:
        break
    }
  }

  const onPopupCloseInner = (e: any) => {
    e.stopPropagation()
  }

  // eslint-disable-next-line
  const onPopupCloseOuter = (e) => {
    setIsModalVisible(false)
  }

  // Add and remove event listeners for the keydown event

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return (
    <div className={styles.spotlight}>
      {isDesktop && (
        <Input
          icon={IconNames.search}
          type="text"
          onFocus={handleInputFocus}
          action={<span className={styles.hotKey}>{buttonText}</span>}
          onChange={(e) => console.log('test', e)}
          placeholder="Search"
          value=""
        />
      )}

      {!isDesktop && (
        // eslint-disable-next-line
        <div onClick={() => setIsModalVisible(true)} className={styles.item}>
          <Icon name={IconNames.search} />
          <span>Search</span>
        </div>
      )}
      {isModalVisible && (
        <AnimatePresence>
          {/* eslint-disable-next-line */}
          <div onClick={(e: any) => onPopupCloseOuter(e)} className={styles.surface}>
            <motion.section
              key="modal"
              initial={{
                opacity: 0,
                // scale: 0.75,
              }}
              animate={{
                opacity: 1,
                // scale: 1,
                transition: {
                  ease: 'easeOut',
                  duration: 0.15,
                },
              }}
              exit={{
                opacity: 0,
                // scale: 0.75,
                transition: {
                  ease: 'easeIn',
                  duration: 0.15,
                },
              }}
              // transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
            >
              <div className={styles.container}>
                <div className={styles.closeButton}>
                  <Button
                    onClick={onPopupCloseOuter}
                    variant={ButtonVariants.unstyled}
                    iconColor="var(--color-secondary)"
                    type="button"
                    icon={IconNames.close}
                  />
                </div>
                {/* eslint-disable-next-line */}
                <div onClick={(e: any) => onPopupCloseInner(e)} className={styles.modal}>
                  {/* eslint-disable-next-line */}
                  <div className={styles.box} ref={containerRef} tabIndex={0} onKeyDown={handleKeyDownContainer}>
                    <Input
                      icon={IconNames.search}
                      type="text"
                      onChange={(e) => setSearchInput(e.target.value)}
                      placeholder="Search prompts"
                      value={searchInput}
                      autofocus
                      action={
                        // eslint-disable-next-line
                        searchInput.length > 2 ? (
                          <Button
                            onClick={onReset}
                            variant={ButtonVariants.unstyled}
                            iconColor="var(--color-secondary)"
                            type="button"
                            icon={IconNames.close}
                          />
                        ) : null
                      }
                    />

                    {searchResults && (
                      <div className={styles.foundList}>
                        {searchResults?.map((item, index) => (
                          <Link
                            key={item?.id?.toString()}
                            onClick={() => setIsModalVisible(false)}
                            to={`/marketplace/task/${item?.id?.toString()}`}
                            className={`${styles.listItem} ${index === selectedIndex ? styles.selected : ''}`}
                            aria-selected={index === selectedIndex}
                          >
                            <div className={styles.listItemTitle}>{item.title}</div>
                            <div className={styles.listItemFooter}>
                              <p>{item.description}</p>
                              <div className={styles.author}>
                                <Avatar size={AVATAR_SIZES.xs} url={item?.account?.avatar_url} />
                                <span>{item?.account?.full_name || item?.account?.username}</span>
                              </div>
                            </div>
                          </Link>
                        ))}
                        {searchResults?.length === 0 && <div className={styles.notFound}>Nothing found.</div>}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </motion.section>
          </div>
        </AnimatePresence>
      )}
    </div>
  )
}
