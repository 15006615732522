import { Link } from '@tanstack/react-location'
import React, { useState } from 'react'
import { Button } from 'src/core/ds/button'
import { TabsStatic } from 'src/core/ds/tabsStatic'
import { DateFormats, formatDate } from 'src/utils/dates'
import styles from './index.module.scss'
import { useGetRevenueList, useGetRewardsList } from './query'
import { getDollarValue } from './utils'

const tabs = ['Revenue', 'Rewards']

export function MySpendingsModal() {
  const [currentIndex, setCurrentIndex] = useState(0)

  const {
    data: revenues,
    isLoading: isLoadingRevenues,
    fetchNextPage: fetchNextPageRevenues,
    hasNextPage: hasNextPageRevenues,

    isFetchingNextPage: isFetchingNextPageRevenues,
  } = useGetRevenueList()

  const {
    data: rewards,
    isLoading: isLoadingRewards,
    fetchNextPage: fetchNextPageRewards,
    hasNextPage: hasNextPageRewards,

    isFetchingNextPage: isFetchingNextPageRewards,
  } = useGetRewardsList()

  const onTabChange = (index) => {
    setCurrentIndex(index)
  }

  const loadMoreRevenuesTextButton = () => {
    if (isFetchingNextPageRevenues) return 'Loading more...'

    return hasNextPageRevenues ? 'Load more' : 'Nothing more to load'
  }

  const isRevenueTable = !isLoadingRevenues && currentIndex === 0
  const isRewardsTable = !isLoadingRewards && currentIndex === 1

  return (
    <div className={styles.wrapper}>
      <h1>My transactions</h1>

      <div className={styles.navigation}>
        <TabsStatic onChangeTab={onTabChange} currentIndex={currentIndex} tabs={tabs} />
      </div>

      {isRevenueTable && (
        <div className={styles.tableWrapper}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Date</th>
                <th>Value</th>

                <th>Task</th>
              </tr>
            </thead>
            <tbody>
              {revenues &&
                revenues?.pages &&
                revenues?.pages?.map((group, i) => (
                  <React.Fragment key={i}>
                    {group &&
                      group?.items?.map((revenue: any) => (
                        <tr>
                          <td>
                            {/* eslint-disable-next-line */}
                            {/* @ts-ignore */}
                            {formatDate(Number(revenue.created_at * 1000) as Date, DateFormats.datePickerFullDate)}
                          </td>
                          <td>{`+ ${getDollarValue(revenue.value)}`}</td>

                          <td>
                            <Link to={`/marketplace/task/${revenue.task.id.toString()}`}>{revenue.task.title}</Link>
                          </td>
                        </tr>
                      ))}
                  </React.Fragment>
                ))}
            </tbody>
          </table>
          {/* eslint-disable-next-line */}
          {/* @ts-ignore */}

          {hasNextPageRevenues && (
            <div className={styles.tableActions}>
              <Button
                type="button"
                label={loadMoreRevenuesTextButton()}
                disabled={!hasNextPageRevenues || isFetchingNextPageRevenues}
                onClick={fetchNextPageRevenues}
              />
            </div>
          )}
        </div>
      )}

      {isRewardsTable && (
        <div className={styles.tableWrapper}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Date</th>
                <th>Value</th>

                <th>Task</th>
              </tr>
            </thead>
            <tbody>
              {rewards &&
                rewards?.pages &&
                rewards?.pages?.map((group, i) => (
                  <React.Fragment key={i}>
                    {group &&
                      group?.items?.map((revenue: any) => (
                        <tr>
                          <td>
                            {/* eslint-disable-next-line */}
                            {/* @ts-ignore */}
                            {formatDate(Number(revenue.created_at * 1000) as Date, DateFormats.datePickerFullDate)}
                          </td>
                          <td>{`+ $${(revenue.value / 10000).toFixed(3)}`}</td>

                          <td>
                            <Link to={`/marketplace/task/${revenue.task.id.toString()}`}>{revenue.task.title}</Link>
                          </td>
                        </tr>
                      ))}
                  </React.Fragment>
                ))}
            </tbody>
          </table>
          {/* eslint-disable-next-line */}
          {/* @ts-ignore */}

          {hasNextPageRewards && (
            <div className={styles.tableActions}>
              <Button
                type="button"
                label={loadMoreRevenuesTextButton()}
                disabled={!hasNextPageRewards || isFetchingNextPageRewards}
                onClick={fetchNextPageRewards}
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}
