import { useMatch } from '@tanstack/react-location'
import { useGetHistoryItemById } from 'src/components/StreamHistory/query'
import { Loader } from 'src/core/ds/loader'
import styles from './index.module.scss'
import { ShortStreamHistory } from './ShortHistory'

export function SharedHistoryPage() {
  const {
    params: { historyId },
  } = useMatch()

  const { data: historyItemData, isLoading } = useGetHistoryItemById(historyId)

  if (isLoading) {
    return (
      <div className={styles.dashboard}>
        <Loader isBig />
      </div>
    )
  }

  if (historyItemData?.is_public !== 1) {
    return (
      <div className={styles.dashboard}>
        <h1>This history item is private</h1>
      </div>
    )
  }

  return (
    <div className={styles.dashboard}>
      <ShortStreamHistory historyItemData={historyItemData} historyId={historyId} />
    </div>
  )
}
