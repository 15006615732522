import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export const useZenTaskStore = create<any>()(
  persist(
    (set, get) => ({
      tasks: [],

      saveTask: (task) => {
        const existingTask = get().tasks.find((t) => t.id.toString() === task.id.toString())
        const propTask = {
          id: task.id.toString(),
          title: task.title,
          hint: task.hint,
        }

        if (!existingTask) {
          const updatedTasks = [propTask, ...get().tasks]
          set({ tasks: updatedTasks })
        }
      },
      removeTask: (taskId) => {
        const updatedTasks = get().tasks.filter((task) => task.id.toString() !== taskId.toString())

        set({ tasks: updatedTasks })
      },
    }),
    {
      name: 'zenTasksStore',
    },
  ),
)
