import { useInfiniteQuery } from 'react-query'
import { getPersonalSpacesList, getPersonalSpacesListType } from 'src/queries/reactQueryKeys'
import { api } from './api'

export const useGetPersonalSpaces = (id, type) =>
  useInfiniteQuery(
    getPersonalSpacesListType(type),
    async () => {
      const response = await api.getPersonalSpaces({ userId: id, type: type.toLowerCase() })
      const [error, data] = response as any[]

      if (error) {
        throw new Error(error)
      }

      return data
    },

    {
      getNextPageParam: (lastPage: any) =>
        // lastPage?.length >= 20 ? pages.length * 20 : false

        lastPage?.pagination?.next_page ?? undefined,
      onError: (error: any) => console.log('WTF', error),
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: 0,
      enabled: !!id,
      keepPreviousData: false,
    },
  )

export const useGetPersonalSpacesAll = (id) =>
  useInfiniteQuery(
    getPersonalSpacesList(),
    async () => {
      const response = await api.getPersonalSpacesAll({ userId: id })
      const [error, data] = response as any[]

      if (error) {
        throw new Error(error)
      }

      return data
    },

    {
      getNextPageParam: (lastPage: any) =>
        // lastPage?.length >= 20 ? pages.length * 20 : false

        lastPage?.pagination?.next_page ?? undefined,
      onError: (error: any) => console.log('WTF', error),
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: 0,
      enabled: !!id,
      keepPreviousData: false,
    },
  )
