import { AnimatePresence, motion } from 'framer-motion'
import { Button } from 'src/core/ds/button'
import { ChatItem } from 'src/modules/asideHistory/components/ChatItem'
import { useGetTaskHistory } from 'src/modules/asideHistory/query'
import { summarizeHistoryByMonths } from 'src/modules/asideHistory/utils'
import styles from './index.module.scss'

// eslint-disable-next-line
export function HistoryLine({ setIsHistoryShown, isHistoryShown, taskId, spaceId, historyId }) {
  const {
    fetchNextPage,
    hasNextPage,

    isFetchingNextPage,
    data: historyItems,
  } = useGetTaskHistory(taskId)

  const summary = summarizeHistoryByMonths(historyItems)

  if (!isHistoryShown) {
    return null
  }

  return (
    <div className={styles.wrapper}>
      <h3>History</h3>
      <div className={styles.chatList}>
        {(Object.keys(summary) || []).map((month) => (
          <div className={styles.section} key={month}>
            <div className={styles.month}>{month}</div>
            {summary[month].items.map((item) => (
              <AnimatePresence key={item.id.toString()}>
                <motion.div
                  transition={{ duration: 0.3, delay: 0.1 }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <ChatItem spaceId={spaceId} isSpaceItem historyId={historyId} item={item} taskId={taskId} />
                </motion.div>
              </AnimatePresence>
            ))}
          </div>
        ))}

        {(Object.keys(summary) || []).length === 0 && <span className={styles.noItems}>No history items.</span>}

        {hasNextPage && (
          <div className={styles.tableActions}>
            <Button
              type="button"
              label="Load more"
              isLoading={isFetchingNextPage}
              disabled={!hasNextPage || isFetchingNextPage}
              onClick={fetchNextPage}
            />
          </div>
        )}
      </div>
    </div>
  )
}
