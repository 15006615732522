import { useMutation, useQuery } from 'react-query'
import { queryClient } from 'src/queries/reactQueryClient'
import {
  // getSpaceDetailsList,
  getTeamDetails,
  getTeamMembersList,
  getTeamsList,
  getTeamSpacesList,
} from 'src/queries/reactQueryKeys'
import { pushNotification } from 'src/services/notifications/notificationService'
import { PLAUSIBLE_EVENTS } from 'src/services/plausible/consts'
import { firePlausibleEvent } from 'src/services/plausible/plausibleService'
import { api } from './api'

export const useCreateTeam = (onSuccess) =>
  useMutation(api.createTeam, {
    onSuccess: (data: any) => {
      const [error, response] = data

      if (error) {
        pushNotification({
          type: 'error',
          title: 'Error creating team.',
        })
        throw Error(error)
      }

      if (response?.id) {
        // eslint-disable-next-line
        // @ts-ignore
        window.lastCreatedTeam = response.id.toString()
        onSuccess && onSuccess()
      }

      queryClient.invalidateQueries({ queryKey: getTeamsList() })
      // queryClient.invalidateQueries({ queryKey: getSpaceDetailsList() })
      firePlausibleEvent(PLAUSIBLE_EVENTS.TEAM_CREATE)
    },
    onError: (err: any) => err,
  })

export const useAddOrRemoveTeamSpace = (onSuccess) =>
  useMutation(api.updateTeamSpacesList, {
    onSuccess: (response: any, variables) => {
      const [error, data] = response

      if (error) {
        throw Error(error)
      }

      onSuccess && onSuccess()

      queryClient.invalidateQueries({ queryKey: getTeamSpacesList(variables?.id) })
      // queryClient.invalidateQueries({ queryKey: getSpaceDetailsList(variables?.id) })

      return data
    },
    onError: (err: any) => {
      if (err.toString().includes('e_team_permissions_denied')) {
        pushNotification({
          type: 'error',
          title: 'You do not have permissions for that.',
        })
      } else {
        pushNotification({
          type: 'error',
          title: 'Error updating team spaces list.',
        })
      }

      return err
    },
  })

export const useAddOrRemoveTeamMember = (onSuccess) =>
  useMutation(api.updateTeamMember, {
    onSuccess: (response: any, variables) => {
      const [error, data] = response

      if (error) {
        throw Error(error)
      }

      onSuccess && onSuccess()
      queryClient.invalidateQueries({ queryKey: getTeamMembersList(variables?.id?.toString()) })

      return data
    },
    onError: (err: any) => {
      // eslint-disable-next-line
      // @ts-ignore
      if (err.toString().includes('e_team_inactive')) {
        pushNotification({
          type: 'error',
          title: 'Team is inactive. Update your subscription.',
        })
      } else if (err.toString().includes('e_team_permissions_denied')) {
        pushNotification({
          type: 'error',
          title: 'You do not have permissions for that.',
        })
      } else if (err.toString().includes('e_team_members_out_of_limit')) {
        pushNotification({
          type: 'error',
          title: '5 team members is a Max.',
        })
      } else {
        pushNotification({
          type: 'error',
          title: 'Error updating team members list.',
        })
      }

      return err
    },
  })

export const useGetTeams = () =>
  useQuery(getTeamsList(), async () => {
    const response = await api.getTeamsList()
    const [error, data] = response as any[]

    if (error) {
      throw new Error(error)
    }

    return data
  })

export const useGetTeamDetails = (id) =>
  useQuery(
    getTeamDetails(id),
    async () => {
      const response = await api.getTeamDetails({ id })
      const [error, data] = response as any[]

      if (error) {
        throw new Error(error)
      }

      return data
    },
    {
      enabled: !!id,
    },
  )

export const useGetTeamSpaces = (id) =>
  useQuery(
    getTeamSpacesList(id),
    async () => {
      try {
        const response = await api.getTeamsSpacesList({ id })
        const [error, data] = response as any[]

        if (error) {
          console.log('error', error)

          if (error.toString().includes('e_team_inactive')) {
            pushNotification({
              type: 'error',
              title: 'Team is inactive. Update your subscription.',
            })
          }

          throw new Error(error)
        }

        return data
      } catch (error) {
        // eslint-disable-next-line
        // @ts-ignore
        if (error.toString().includes('e_team_inactive')) {
          pushNotification({
            type: 'error',
            title: 'Team is inactive. Update your subscription.',
          })
        }

        return false
      }
    },
    {
      enabled: !!id,
      cacheTime: 5 * 60,
    },
  )

export const useGetTeamMembers = (id) =>
  useQuery(
    getTeamMembersList(id),
    async () => {
      const response = await api.getTeamsMembersList({ id })
      const [error, data] = response as any[]

      if (error) {
        throw new Error(error)
      }

      return data
    },
    {
      enabled: !!id,
    },
  )
