import { useQuery } from 'react-query'
import { getTagsList } from 'src/queries/reactQueryKeys'
import { api } from './api'

export const useGetTags = () =>
  useQuery(getTagsList(), async () => {
    const response = await api.getTagsList()
    const [error, data] = response as any[]

    if (error) {
      throw new Error(error)
    }

    return data
  })
