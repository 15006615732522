import { Link, useNavigate } from '@tanstack/react-location'
import { useState } from 'react'
import { Logo } from 'src/components/Logo/Logo'
import { ThemeSwitcher } from 'src/components/ThemeSwitcher'
import { Avatar } from 'src/core/ds/avatar'
import { AVATAR_SIZES } from 'src/core/ds/avatar/consts'
import { Button } from 'src/core/ds/button'
import { ButtonSizes, ButtonVariants } from 'src/core/ds/button/types'
import { Container } from 'src/core/ds/container'
import { IconNames } from 'src/core/ds/icon'
import { Modal } from 'src/core/ds/modal'
import { useMedia } from 'src/hooks/useMedia'
import { useGetCurrentUser } from 'src/modules/currentUser/query'
import { MagicLinkForm } from 'src/modules/magicLink/components/Form'
import { pushNotification } from 'src/services/notifications/notificationService'
import styles from './index.module.scss'

export function Header() {
  const navigate = useNavigate()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false)
  const { data: currentUser } = useGetCurrentUser()

  const onLoginSuccess = () => {
    pushNotification({
      type: 'success',
      title: 'Success Login',
      subtitle: 'You will be redirected.',
    })
    setIsAuthModalOpen(false)
    navigate({ to: `/zen` })
  }

  const openAuthModal = () => {
    setIsAuthModalOpen(true)
  }

  const width = useMedia()

  if (width <= 820) {
    return (
      <>
        {/* <div data-content="promo" className={styles.promo}>
          <div className={styles.promoWrapper}>
            <Link to="/influencer-program">

              Share information about Zentask on social media and earn up to <span>$2,500</span> per post.
            </Link>
          </div>
        </div> */}

        <header className={styles.header}>
          {isDrawerOpen && (
            <div className={styles.drawer}>
              <Container>
                <div className={styles.drawerAction}>
                  <Button
                    icon={IconNames.close}
                    variant={ButtonVariants.unstyled}
                    size={ButtonSizes.xs}
                    type="button"
                    iconColor="var(--color-text)"
                    label=""
                    onClick={() => setIsDrawerOpen(false)}
                  />
                </div>

                <nav className={styles.drawerNav}>
                  <ul>
                    <li>
                      <a onClick={() => setIsDrawerOpen(false)} href="#prompts">
                        Features
                      </a>
                    </li>
                    <li>
                      <a onClick={() => setIsDrawerOpen(false)} href="#prices">
                        Prices
                      </a>
                    </li>

                    <li>
                      <a onClick={() => setIsDrawerOpen(false)} href="#faq">
                        FAQ
                      </a>
                    </li>
                    <li>
                      <a onClick={() => setIsDrawerOpen(false)} href="#footer">
                        Subscribe
                      </a>
                    </li>
                  </ul>
                </nav>

                <div className={styles.drawerLanguage}>
                  <ThemeSwitcher />
                </div>
              </Container>
            </div>
          )}
          <Container>
            <div className={styles.wrapper}>
              <Modal
                withCloseButton
                onClose={() => setIsAuthModalOpen(false)}
                isOpen={isAuthModalOpen}
                body={<MagicLinkForm onLoginSuccess={onLoginSuccess} />}
              />
              <Link title="Cointrapper" className={styles.logo} to="/">
                <Logo />
              </Link>

              {!currentUser && (
                <div className={styles.mobileAction}>
                  <div className={styles.mobileActionButtons}>
                    <Button
                      onClick={openAuthModal}
                      size={ButtonSizes.xs}
                      variant={ButtonVariants.secondary}
                      type="button"
                      label="Sign in"
                    />
                    <Button onClick={openAuthModal} size={ButtonSizes.xs} type="button" label="Sign up" />
                  </div>
                  <div className={styles.hamburger}>
                    <Button
                      icon={IconNames.hamburger}
                      variant={ButtonVariants.unstyled}
                      size={ButtonSizes.xs}
                      type="button"
                      title="Open menu"
                      onClick={() => setIsDrawerOpen(true)}
                      iconColor="var(--color-text)"
                      label=""
                    />
                  </div>
                </div>
              )}
              {currentUser && (
                <div>
                  <Link className={styles.auth} to="/zen">
                    Dashboard
                    <Avatar size={AVATAR_SIZES.sm} url={currentUser.avatar_url} />
                  </Link>
                </div>
              )}
            </div>
          </Container>
        </header>
      </>
    )
  }

  return (
    <>
      {/* <div data-content="promo" className={styles.promo}>
        <div className={styles.promoWrapper}>
          <Link to="/influencer-program">

            Share information about Zentask on social media and earn up to <span>$2,500</span> per post.
          </Link>
        </div>
      </div> */}

      <header className={styles.header}>
        <Modal
          withCloseButton
          onClose={() => setIsAuthModalOpen(false)}
          isOpen={isAuthModalOpen}
          body={<MagicLinkForm onLoginSuccess={onLoginSuccess} />}
        />
        <Container>
          <div className={styles.wrapper}>
            <nav>
              <Link title="Zentask" className={styles.logo} to=".">
                <Logo />
              </Link>

              <ul className={styles.nav}>
                <li>
                  <a href="#prompts">Features</a>
                </li>

                <li>
                  <a href="#prices">Prices</a>
                </li>
                <li>
                  <a href="#faq">FAQ</a>
                </li>
                <li>
                  <a href="#footer">Subscribe</a>
                </li>
              </ul>
            </nav>

            {!currentUser && (
              <div className={styles.auth}>
                <ThemeSwitcher />
                <Button
                  onClick={openAuthModal}
                  size={ButtonSizes.xs}
                  variant={ButtonVariants.secondary}
                  type="button"
                  label="Sign in"
                />
                <Button onClick={openAuthModal} size={ButtonSizes.xs} type="button" label="Sign up" />
              </div>
            )}
            {currentUser && (
              <div>
                <Link className={styles.auth} to="/zen">
                  Dashboard
                  <Avatar size={AVATAR_SIZES.sm} url={currentUser.avatar_url} />
                </Link>
              </div>
            )}
          </div>
        </Container>
      </header>
    </>
  )
}
