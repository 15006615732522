import styles from './index.module.scss'

export function ResponseSkeletonNew() {
  return (
    <div className={styles.formStreamNew}>
      <div style={{ width: '30%' }} className={styles.label} />
      <div className={styles.textRow} />
      <div style={{ width: '70%', marginTop: '.3rem' }} className={styles.label} />
    </div>
  )
}
