const productionSpaces = [
  {
    id: '48285954380660737',
    description: 'A collections of solutions will help you to became a better writer.',
    title: 'Article and Blogs',
    task_count: 6,
  },
  {
    id: '48289034501357569',
    description: 'These tools will help you to became a better marketer.',
    title: 'Marketing expert',
    task_count: 9,
  },
  {
    id: '48283459281485825',
    description: 'These solutions will spark ideas for blog posts, social campaigns, or research papers.',
    title: 'Social Media',
    task_count: 10,
  },
  {
    id: '58828497589633025',
    description:
      'Level up your coding with our top GPT prompts, including Docstrings, HTML-Markdown, Regex, Git Commands, Code Interpreting, Linux Terminal & CSS-Tailwind.',
    title: 'Software Engineers',
    task_count: 8,
  },
  {
    id: '48290153063514113',
    description: 'Boost your Google positions with these solutions.',
    title: 'Tools for SEO',
    task_count: 7,
  },
  {
    id: '48287395132473345',
    description: 'Solutions and ideas for your business.',
    title: 'Tools for business',
    task_count: 8,
  },
]

export const config = {
  baseUrl: '',
  baseMailUrl: 'https://mailer.muvon.dev',
  siteName: 'Zentask',
  pusherKey: process.env.REACT_APP_APP_ENV === 'production' ? '9fcfdbbc51453a2d2cc4' : 'c3d9bcb8face84c21ea2',
  ImageAIId: process.env.REACT_APP_APP_ENV === 'production' ? '24658038178709505' : '24658038178709505',
  taskAIId: process.env.REACT_APP_APP_ENV === 'production' ? '16686324501184513' : '2925930766925825',
  taskImageAIId: process.env.REACT_APP_APP_ENV === 'production' ? '2' : '30480324030889985',
  baseAPIUrl: process.env.REACT_APP_APP_ENV === 'production' ? 'https://api.zentask.ai' : '//api.zentask.zz',
  suggestedSpaces:
    process.env.REACT_APP_APP_ENV === 'production'
      ? productionSpaces
      : [
          {
            id: '42109231008055297',
            description: 'Solutions and ideas for your business.',
            title: 'Optimize your Ads workflow with this space',
            task_count: 2,
          },
          {
            id: '42108872797716481',
            description: 'Solutions and ideas for your business.',
            title: 'SUPER SEO MARKETER',
            task_count: 2,
          },
          {
            id: '42108749795557377',
            description: 'Boost your Google positions with these solutions.',
            title: 'Super drawer space for DALL-E 2',
            task_count: 1,
          },
          {
            id: '42108994138931201',
            description:
              'Level up your coding with our top GPT prompts, including Docstrings, HTML-Markdown, Regex, Git Commands, Code Interpreting, Linux Terminal & CSS-Tailwind.',
            title: 'Writing skills booster',
            task_count: 4,
          },
          {
            id: '30494829913833473',
            description: 'Solutions and ideas for your business.',
            title: 'first space2',
            task_count: 2,
          },
        ],
}
