import { stringify } from 'lossless-json'
import { config } from 'src/config/config'
import { apiCall } from 'src/utils/networking'

export const api = {
  createNewSpace: async ({ DTO }) =>
    apiCall(`${config.baseAPIUrl}/space/save`, {
      method: 'POST',
      body: stringify(DTO),
    }),
}
