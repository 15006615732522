// import ReactMarkdown from 'react-markdown'
import { createRef, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { config } from 'src/config/config'
// import { breaks } from 'src/constants/breaks'
import { Avatar } from 'src/core/ds/avatar'
import { AVATAR_SIZES } from 'src/core/ds/avatar/consts'
import { Button } from 'src/core/ds/button'
import { ButtonSizes, ButtonVariants } from 'src/core/ds/button/types'
import { Icon, IconNames } from 'src/core/ds/icon'
import { Input } from 'src/core/ds/input'
import { Modal } from 'src/core/ds/modal'
import { useCounter } from 'src/hooks/useCounter'
// import { useMedia } from 'src/hooks/useMedia'
import { invalidateTaskHistory } from 'src/modules/asideHistory/query'
import { useGetCurrentUser } from 'src/modules/currentUser/query'
import { api } from 'src/modules/StreamForm/api'
import { useGetTaskInfo } from 'src/modules/tasks/query'
import { NotFoundPage } from 'src/pages/NotFoundPage/NotFoundPage'
import { queryClient } from 'src/queries/reactQueryClient'
import { getCurrentUserKey } from 'src/queries/reactQueryKeys'
import { pushNotification } from 'src/services/notifications/notificationService'
import { PLAUSIBLE_EVENTS } from 'src/services/plausible/consts'
import { firePlausibleEvent } from 'src/services/plausible/plausibleService'
import { useStreamStore } from 'src/services/store/StreamStore'
import { useUIStore } from 'src/services/store/UIStore'
import { ImageSkeleton } from 'src/skeletons/ImagesSkeleton'
import { ResponseSkeleton } from 'src/skeletons/ResponseSkeleton'
import { copyToClipboard } from 'src/utils/strings'
import LogoImage from '../../assets/images/zentask-new-logo.png'
import { InputFormNew } from '../InputFormNew/InputFormNew'
import { Markdown } from '../MarkDownWrapper'
import { useShareHistoryItem } from '../StreamHistory/query'
import { StreamStatus } from '../StreamStatus/StreamStatus'
// import { isKeyOfTypeBoolean } from '../StreamHistory/utils'
import styles from './index.module.scss'
import { SolutionPreview } from './SolutionPreview'

// let eventSource
let channel
let currentResponseId = null

export function Stream({ taskId, isTestForm = false }) {
  const streamData = useStreamStore((state) => state.data)
  const streamStatus = useStreamStore((state) => state.status)
  const promptInput = useStreamStore((state) => state.prompt)
  const updateStreamStatus = useStreamStore((state) => state.updateStatus)
  const [contextState, setContextState] = useState<'new' | 'current' | 'none'>('new')
  const [contextItemsList, setContextItemsList] = useState<any>([])

  const [historyId, setHistoryId] = useState(null)
  const [isHareHistoryModalOpen, setIsHareHistoryModalOpen] = useState(false)

  const shareLink = `https://zentask.ai/shared/${historyId}`

  const addContextItem = (item) => {
    setContextItemsList([...contextItemsList, item])
  }

  const onSuccessShare = () => {
    setIsHareHistoryModalOpen(true)
  }

  const { isLoading: isLoadingShare, mutate: mutateShare } = useShareHistoryItem(onSuccessShare)

  const onShareHistory = async () => {
    await mutateShare({ historyId })
  }

  const { increment } = useCounter()
  // const taskId = useTasksStore((state) => state.taskId)
  const formDataStore = useStreamStore((state) => state.formData)
  const updatePromptInput = useStreamStore((state) => state.updatePrompt)
  const resetData = useStreamStore((state) => state.resetData)
  const updateStreamData = useStreamStore((state) => state.updateData)
  const updateFormData = useStreamStore((state) => state.updateFormData)
  const images = useStreamStore((state) => state.images)
  const setImages = useStreamStore((state) => state.updateImages)
  const updateUIStore = useUIStore((state) => state.updateUIStore)
  const isPROAccount = useUIStore((state) => state.isPROAccount)
  const { data: currentUser } = useGetCurrentUser()
  const [limitError, setLimitError] = useState<any>(null)

  const { data: taskDetails } = useGetTaskInfo({
    id: taskId,
    onError: () => undefined,
  })

  const isDalleModel = taskDetails?.ai?.name?.includes('DALL')
  const isContextEnabled = !isDalleModel && isPROAccount && currentUser?.config?.[config.taskAIId]?.context === 1
  const ref = createRef<any>()

  const onTextCopy = (text) => {
    firePlausibleEvent(PLAUSIBLE_EVENTS.COPY_RESPONSE)
    copyToClipboard(
      text,
      pushNotification({
        type: 'success',
        title: 'Copied to clipboard',
      }),
    )
  }

  useEffect(() => {
    if (!taskDetails) return

    resetDialog()
  }, [taskDetails])

  const resetDialog = () => {
    updateStreamStatus('idle')
    updatePromptInput({
      text: '',
      id: '',
    })
    resetData()
    setImages(null)
    updateFormData(null)
    currentResponseId = null
  }

  const createRequst = async () => {
    increment()
    channel?.unbind()
    setHistoryId(null)
    updateStreamStatus('processing')

    try {
      const prompt = `${promptInput.text}`
      // const prompt = 'data[image]=test'
      const type = isDalleModel ? 'image' : 'text'

      if (isDalleModel) {
        setImages([] as any)
      }

      const channelString = `${type}-${currentUser?.id.toString()}-${taskId.toString()}`

      channel = window?.pusherInstance?.subscribe(channelString)
      channel?.bind('response', (data) => {
        if (isDalleModel) {
          setImages(data.response)

          if (data?.response?.length === 0) {
            pushNotification({
              type: 'error',
              title: 'Internal error.',
            })
          }
        } else if (data?.id === currentResponseId) {
          updateStreamData(data.response)
        }
      })

      channel?.bind('done', (data) => {
        try {
          if (data?.history_id) {
            setHistoryId(data.history_id.toString())
          }

          queryClient.invalidateQueries({ queryKey: getCurrentUserKey() })
        } catch (e) {
          console.log('e')
        }

        // channel.unbind_all()
        // channel.unsubscribe()
        channel.unbind()
        updateStreamStatus('completed')
        invalidateTaskHistory(taskId)
        firePlausibleEvent(PLAUSIBLE_EVENTS.SOLUTION_STREAM_STOPPED)
        updatePromptInput({
          text: '',
          id: '',
        })
      })

      // verify
      const response: any = await api.verifyPromptBeforeRun(
        taskId.toString(),
        prompt,
        isDalleModel || !isContextEnabled ? 'none' : contextState,
      )
      const [error, data] = response
      // console.log('data', data)

      if (data && data?.id) {
        // console.log('SET!')
        currentResponseId = data?.id
      }

      // console.log(currentResponseId)

      if (error) {
        channel.unbind()
        updateStreamStatus('error')
      }
    } catch (err) {
      if (err?.toString().includes('e_request_data_too_long')) {
        const errorString = isPROAccount
          ? 'Your input is too long. 3000 is a Max.'
          : 'Your input is too long. 200 is a Max.\n To increase the limit to 3000 – upgrade to PRO.'
        pushNotification({
          type: 'error',
          title: 'Error occured.',
          subtitle: errorString,
        })
      } else if (err?.toString().includes('banned_words')) {
        pushNotification({
          type: 'error',
          title: 'Bad words.',
          subtitle: 'The words you are using are inappropriate for the requests made to the selected AI.',
        })
      } else if (err?.toString().includes('violates_guidelines')) {
        pushNotification({
          type: 'error',
          title: 'Your request is against the AI guidelines.',
          subtitle: 'Please, try to reword your request.',
        })
      } else if (err?.toString().includes('e_request_premium_forbidden')) {
        pushNotification({
          type: 'error',
          title: 'Forbidden.',
          subtitle: 'You need PRO account to use this prompt.',
        })
      } else if (err?.toString().includes('e_request_too_many_usages')) {
        // const errorString = isPROAccount
        //   ? 'Your limits are: Text AI – 20 / 3 hours,\n Image AI – 10 / 3 hours'
        //   : 'Your limits are: Text AI – 10 / day,\n Image AI – 5 / day. To increase the limits, please use a PRO account.'
        setLimitError(true)
        firePlausibleEvent(isPROAccount ? PLAUSIBLE_EVENTS.LIMITS_PRO : PLAUSIBLE_EVENTS.LIMITS_FREE)
        // pushNotification({
        //   type: 'error',
        //   title: 'Out of limits.',
        //   subtitle: errorString,
        // })
      } else {
        pushNotification({
          type: 'error',
          title: 'Internal error.',
          subtitle: 'Try again later or refresh the page.',
        })
      }

      updateStreamStatus('error')
    }
  }

  const onItemShareCopyLink = () => {
    copyToClipboard(
      shareLink,
      pushNotification({
        type: 'success',
        title: 'Copied to clipboard',
      }),
    )
  }

  const onInputTextCopy = (text) => {
    copyToClipboard(
      text,
      pushNotification({
        type: 'success',
        title: 'Copied to clipboard',
      }),
    )
  }

  useEffect(() => {
    if (!promptInput.text.length) return undefined

    updateStreamStatus('processing')

    if (isContextEnabled) {
      setContextState((prevState) => {
        if (prevState === 'new') return 'current'

        if (prevState === 'current') return 'current'

        return 'new'
      })
    }

    createRequst()

    return undefined
  }, [promptInput])

  const onStop = () => {
    const type = isDalleModel ? 'image' : 'text'
    const channelString = `${type}-${currentUser?.id.toString()}-${taskId.toString()}`
    channel.unbind()
    window?.pusherInstance?.unsubscribe(channelString)
    updateStreamStatus('error')
    currentResponseId = null
  }

  useEffect(() => {
    const type = isDalleModel ? 'image' : 'text'
    const channelString = `${type}-${currentUser?.id.toString()}-${taskId.toString()}`

    return () => window?.pusherInstance?.unsubscribe(channelString)
  }, [])

  useEffect(() => {
    if (isContextEnabled && streamStatus === 'completed') {
      // updateStreamStatus('idle')
      addContextItem({
        response: streamData,
        request: formDataStore?.request,
        id: uuidv4(),
      })
      updatePromptInput({
        text: '',
        id: '',
      })

      resetData()

      updateFormData(null)
    }
  }, [streamStatus])

  useEffect(() => {
    if (contextState === 'new') {
      setContextItemsList([])
    }
  }, [contextState])

  useEffect(() => {
    if (contextItemsList.length > 1) {
      // window.scrollTo(0, ref.current.offsetTop)
      ref.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [contextItemsList])

  useEffect(
    () => () => {
      // reset all on component leave
      resetDialog()
    },
    [],
  )
  const isAuthor = currentUser?.id.toString() === taskDetails?.account_id?.toString()
  const isFormVisible = streamStatus === 'idle'
  const isHiddenFormForNonPRO = !isPROAccount && taskDetails?.is_premium && !isAuthor

  if (taskDetails?.notFound) {
    return <NotFoundPage />
  }

  return (
    <div className={styles.wrapper}>
      <SolutionPreview
        currentUser={currentUser}
        isTestForm={isTestForm}
        isFormVisible={isFormVisible}
        solutionDetails={taskDetails}
      />

      <Modal
        withCloseButton
        title="Share history"
        onClose={() => setIsHareHistoryModalOpen(false)}
        isOpen={isHareHistoryModalOpen}
        body={
          // eslint-disable-next-line
          <div className={styles.modalWrapper}>
            <Input
              label="Your link"
              action={
                // eslint-disable-next-line
                <button className={styles.action} onClick={onItemShareCopyLink} type="button">
                  <Icon name={IconNames.copy} />
                </button>
              }
              name="shareLink"
              value={shareLink}
            />
          </div>
        }
      />

      {contextItemsList?.map((item, index) => (
        <div key={item.id}>
          <div className={styles.userLine}>
            <div className={styles.userLineWrapper}>
              <div className={styles.avatar}>
                <span>
                  <Icon color="var(--color-secondary)" name={IconNames.settings} />
                </span>
                <Avatar url={currentUser?.avatar_url} size={AVATAR_SIZES.sm} />
              </div>
              <div className={styles.historySettings}>
                <div className={styles.historySettingsDate}>
                  {currentUser?.full_name || currentUser?.username}
                  {/* eslint-disable-next-line */}
                  &nbsp;request [{taskDetails?.title}]
                  <div>
                    <Button
                      icon={IconNames.copy}
                      type="button"
                      iconColor="var(--color-secondary)"
                      size={ButtonSizes.xs}
                      variant={ButtonVariants.secondary}
                      label="copy"
                      onClick={() => onInputTextCopy(item.request)}
                    />
                  </div>
                </div>

                <div translate="no" className={styles.historySettingsContent}>
                  <div className={styles.settingsItem}>
                    <p>{item.request}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.contentLine}>
            <div className={styles.contentLineWrapper}>
              <div className={styles.avatar}>
                <Avatar url={LogoImage} size={AVATAR_SIZES.sm} />
                <Avatar url={taskDetails?.account?.avatar_url} size={AVATAR_SIZES.sm} />
              </div>
              <div className={styles.contentWrapper}>
                <div className={styles.contentWrapperTitle}>
                  <div>
                    {taskDetails?.account.full_name || taskDetails?.account.username}
                    <span>& Zentask assistant response</span>
                  </div>

                  <div className={styles.historyActions}>
                    <div className={styles.shareHistory}>
                      {index === 0 && historyId && (
                        <Button
                          icon={IconNames.share}
                          type="button"
                          iconColor="var(--color-secondary)"
                          size={ButtonSizes.xs}
                          variant={ButtonVariants.unstyled}
                          label=""
                          isLoading={isLoadingShare}
                          tooltip="Share history"
                          onClick={onShareHistory}
                        />
                      )}
                    </div>
                    <Button
                      icon={IconNames.copy}
                      type="button"
                      iconColor="var(--color-secondary)"
                      size={ButtonSizes.xs}
                      variant={ButtonVariants.secondary}
                      label="copy"
                      onClick={() => onTextCopy(item.response)}
                    />
                  </div>
                </div>

                <div className={styles.contentStreamLine}>
                  <div translate="no" className={styles.contentMD}>
                    <Markdown markdown={item.response} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/**/}
        </div>
      ))}

      {(formDataStore || images) && (
        <div className={styles.userLine}>
          <div className={styles.userLineWrapper}>
            <div className={styles.avatar}>
              <span>
                <Icon color="var(--color-secondary)" name={IconNames.settings} />
              </span>
              <Avatar url={currentUser?.avatar_url} size={AVATAR_SIZES.sm} />
            </div>
            <div className={styles.historySettings}>
              <div className={styles.historySettingsDate}>
                {currentUser?.full_name || currentUser?.username}
                {/* eslint-disable-next-line */}
                &nbsp;request [{taskDetails?.title}]
                <div>
                  <Button
                    icon={IconNames.copy}
                    type="button"
                    iconColor="var(--color-secondary)"
                    size={ButtonSizes.xs}
                    variant={ButtonVariants.secondary}
                    label="copy"
                    onClick={() => onInputTextCopy(formDataStore?.request)}
                  />
                </div>
              </div>

              <div translate="no" className={styles.historySettingsContent}>
                {Object.keys(formDataStore || {}).map((inputKey) => (
                  <div key={inputKey} className={styles.settingsItem}>
                    <p>{formDataStore[inputKey].toString()}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {/** NEW ENDS */}

      {!limitError && formDataStore && !isDalleModel && !streamData && <ResponseSkeleton />}
      {limitError && (
        <div className={styles.limitErrorWrapper}>
          <div className={styles.limitError}>
            <Icon color="var(--color-secondary)" name={IconNames.alertCircle} />
            <div>
              <span className={styles.errorTitle}>Beyond the Limits</span>
              {!isPROAccount && <p>Your current limits are: Text AI – 10/day, Image AI – 5/day</p>}
              {isPROAccount && <p>Your current limits are: Text AI – 20/3 hours, Image AI – 10/3 hours</p>}
              {!isPROAccount && (
                <div className={styles.errorAction}>
                  <Button
                    onClick={() => {
                      updateUIStore('isPricesPopupOpen', true)
                      firePlausibleEvent(PLAUSIBLE_EVENTS.CLICK_BUY_CREDITS)
                    }}
                    variant={ButtonVariants.unstyled}
                    type="button"
                    label="Upgrade to PRO"
                  />
                  &nbsp;and increase limits to: Text AI – 20/3 hours, Image AI – 10/3 hours with better AI Model.
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {streamData && !isFormVisible && (
        <div className={styles.contentLine}>
          <div className={styles.contentLineWrapper}>
            <div className={styles.avatar}>
              <Avatar url={LogoImage} size={AVATAR_SIZES.sm} />
              <Avatar url={taskDetails?.account?.avatar_url} size={AVATAR_SIZES.sm} />
            </div>
            <div className={styles.contentWrapper}>
              <div className={styles.contentWrapperTitle}>
                <div>
                  {taskDetails?.account.full_name || taskDetails?.account.username}
                  <span>& Zentask assistant response</span>
                </div>
                {streamStatus === 'completed' && (
                  <div className={styles.historyActions}>
                    <div className={styles.shareHistory}>
                      {historyId && (
                        <Button
                          icon={IconNames.share}
                          type="button"
                          iconColor="var(--color-secondary)"
                          size={ButtonSizes.xs}
                          variant={ButtonVariants.unstyled}
                          label=""
                          isLoading={isLoadingShare}
                          tooltip="Share history"
                          onClick={onShareHistory}
                        />
                      )}
                    </div>
                    <Button
                      icon={IconNames.copy}
                      type="button"
                      iconColor="var(--color-secondary)"
                      size={ButtonSizes.xs}
                      variant={ButtonVariants.secondary}
                      label="copy"
                      onClick={() => onTextCopy(streamData)}
                    />
                  </div>
                )}
              </div>

              <div className={styles.contentStreamLine}>
                {!isPROAccount && (
                  <div className={styles.upgrade}>
                    You are using free plan.&nbsp;
                    <Button
                      onClick={() => {
                        updateUIStore('isPricesPopupOpen', true)
                        firePlausibleEvent(PLAUSIBLE_EVENTS.CLICK_BUY_CREDITS)
                      }}
                      type="button"
                      variant={ButtonVariants.unstyled}
                      label="Upgrade to PRO"
                    />
                    &nbsp;for the ultimate content output quality, utilizing GPT-4 and SD XL. Save 20% today.
                  </div>
                )}
                <div translate="no" className={styles.contentMD}>
                  <Markdown markdown={streamData} />
                  <div className={styles.streamStatusWrapper}>
                    <StreamStatus status={streamStatus} />
                    {streamStatus === 'completed' && (
                      <Button
                        variant={ButtonVariants.unstyled}
                        type="button"
                        label="Start over"
                        onClick={resetDialog}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {images && (
        <div className={styles.contentLine}>
          <div className={styles.contentLineWrapper}>
            <div className={styles.avatar}>
              <Avatar url={LogoImage} size={AVATAR_SIZES.sm} />
              <Avatar url={taskDetails?.account?.avatar_url} size={AVATAR_SIZES.sm} />
            </div>
            <div className={styles.contentWrapper}>
              <div className={styles.contentWrapperTitle}>
                <div>
                  {taskDetails?.account.full_name || taskDetails?.account.username}
                  <span>& Zentask assistant response</span>
                </div>
              </div>

              <div className={styles.contentStreamLine}>
                <div className={styles.contentMD}>
                  <div className={styles.imagesBox}>
                    {images?.length === 0 && (
                      <>
                        <ImageSkeleton />
                        <ImageSkeleton />
                      </>
                    )}
                    {images.length > 0 &&
                      images?.map((image, index) => (
                        // eslint-disable-next-line
                        // @ts-ignore
                        <div key={image} className={styles.imageItem}>
                          <a href={image} download target="_blank" className={styles.downloadImage} rel="noreferrer">
                            <Button
                              type="button"
                              iconColor="var(--color-secondary)"
                              icon={IconNames.download}
                              size={ButtonSizes.xs}
                              variant={ButtonVariants.secondary}
                            />
                          </a>
                          <img width="323" height="323" loading="lazy" src={image} alt="" key={index} />
                        </div>
                      ))}
                  </div>
                  <div className={styles.streamStatusWrapper}>
                    <StreamStatus status={streamStatus} />
                    {streamStatus === 'completed' && isDalleModel && (
                      <Button
                        variant={ButtonVariants.unstyled}
                        type="button"
                        label="Regenerate"
                        onClick={createRequst}
                      />
                    )}
                    {streamStatus === 'completed' ||
                      (streamStatus === 'error' && (
                        <Button
                          variant={ButtonVariants.unstyled}
                          type="button"
                          label="Start over"
                          onClick={resetDialog}
                        />
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/** END OF CONTENT */}

      {/* <div className={styles.userLine}>
        <div className={styles.userLineWrapper}>
          <div className={styles.endLine}>End of response</div>
        </div>
      </div> */}
      {/** END LINE */}
      <div style={{ height: '15rem' }} ref={ref} />

      {!isHiddenFormForNonPRO && (
        <InputFormNew
          contextState={contextState}
          setContextState={setContextState}
          isImageModel={isDalleModel}
          onStop={onStop}
          streamStatus={streamStatus}
          currentUser={currentUser}
          hint={taskDetails?.hint}
        />
      )}
    </div>
  )
}
