import { ReactLocation, Route } from '@tanstack/react-location'
import { MetaTitle } from './components/MetaTitle'
import { DashboardLayout } from './layouts/DashboardLayout'
import { AllTasksPage } from './pages/AllTasks'
import { ConsumerDashboardPage } from './pages/ConsumerDashboardPage/ConsumerDashboardPage'
// import { CreateTaskPage } from './pages/CreateTaskPage'
import { CreatorDashboardPage } from './pages/CreatorDashboardPage/CreatorDashboardPage'
import { CurrentSpaceLayout } from './pages/CurrentSpaceLayout/CurrentSpaceLayout'
import { DashboardPage } from './pages/Dashboard'
import { EarnPage } from './pages/Earn/EarnPage'
import { HistorySearchPage } from './pages/HistorySearchPage/HistorySearchPage'
// import { EditTaskPage } from './pages/EditTask/EditTask'
import { LandingPage } from './pages/Landing'
import { LandingPROPage } from './pages/LandingPro'
import { MagicLinkPage } from './pages/MagicLinkPage/MagicLinkPage'
import { MyPersonalSpacePage } from './pages/MyPersonalSpacePage/MyPersonalSpacePage'
import { MySpaceLayout } from './pages/MySpacePage/MySpacePage'
import { NotFoundPage } from './pages/NotFoundPage/NotFoundPage'
import { PersonalTasksPage } from './pages/PersonalTasksPage'
import { ProjectUpdatesPage } from './pages/ProjectUpdates/ProjectUpdates'
import { PublicSpacesPage } from './pages/PublicSpacesPage/PublicSpacesPage'
import { SelectedSpacePage } from './pages/SelectedSpacePage/SelectedSpacePage'
import { SharedHistoryPage } from './pages/SharedHistory/SharedHistoryPage'
import { SolutionCreatorPage } from './pages/SolutionCreator/SolutionCreator'
import { SolutionEditorPage } from './pages/SolutionCreator/SolutionEditor'
import { TeamPage } from './pages/Team/TeamPage'
import { TeamCreatorPage } from './pages/TeamCreator/TeamCreator'
import { TermsPage } from './pages/Terms/TermsPage'
import { Text2Ai } from './pages/Text2Ai/Text2AI'
import { TrendingTasksPage } from './pages/TrendingTasksPage'
import { ZenPage } from './pages/Zen/ZenPage'

export const location = new ReactLocation()

enum ROUTES {
  landing = '/',
  dashboard = 'dashboard',
  creatorArea = 'creator',
  quick = 'quick',
  marketplace = 'marketplace',
  personal = 'personal',
  trending = 'trending',
  auth = 'auth',
  spaces = 'spaces',
  task = 'task/:taskId',
  tasks = 'tasks',
  sharedHistory = 'shared/:historyId',
  updates = 'project-updates',
  tasksIds = ':categoryId/:taskId',
  history = 'history/:historyId',
  terms = 'terms-of-use',
  earn = 'influencer-program',
  landingPro = 'landing',
  teams = 'teams',
  historyGlobal = 'history',
  text2image = 'text-to-image',
  zen = 'zen',
}

export const routes: Route[] = [
  {
    path: ROUTES.landing,
    element: <LandingPage />,
  },
  {
    path: ROUTES.landingPro,
    element: <LandingPROPage />,
  },
  {
    path: ROUTES.zen,
    element: <ZenPage />,
  },
  {
    path: ROUTES.auth,
    element: <MagicLinkPage />,
  },
  {
    path: ROUTES.earn,
    element: <EarnPage />,
  },

  {
    path: ROUTES.sharedHistory,
    element: <SharedHistoryPage />,
  },
  {
    path: ROUTES.historyGlobal,
    element: (
      <DashboardLayout>
        <MetaTitle title="History" />
        <HistorySearchPage />
      </DashboardLayout>
    ),
  },
  {
    path: ROUTES.text2image,
    children: [
      {
        path: '/',

        element: (
          <DashboardLayout>
            <MetaTitle title="Text to Image" />
            <Text2Ai />
          </DashboardLayout>
        ),
      },
    ],
  },
  {
    path: ROUTES.creatorArea,
    children: [
      {
        path: '/',

        element: (
          <DashboardLayout>
            <MetaTitle title="Creator area" />
            <CreatorDashboardPage />
          </DashboardLayout>
        ),
      },
    ],
  },
  {
    path: ROUTES.teams,
    children: [
      {
        path: '/',

        element: (
          <DashboardLayout>
            <MetaTitle title="Teams" />
            <TeamPage />
          </DashboardLayout>
        ),
      },
      {
        path: '/create',

        element: (
          <DashboardLayout>
            <MetaTitle title="Create new Team" />
            <TeamCreatorPage />
          </DashboardLayout>
        ),
      },
      {
        path: ':teamId',

        children: [
          {
            path: '/',
            element: (
              <DashboardLayout>
                <TeamPage />
              </DashboardLayout>
            ),
          },
          {
            path: ':taskId',
            children: [
              {
                path: '/',
                element: (
                  <DashboardLayout>
                    <TeamPage />
                  </DashboardLayout>
                ),
              },
              {
                path: '/history/:historyId',
                element: (
                  <DashboardLayout>
                    <TeamPage />
                  </DashboardLayout>
                ),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: ROUTES.spaces,
    children: [
      {
        path: '/',

        element: (
          <DashboardLayout>
            <MetaTitle title="Spaces" />
            <MySpaceLayout>
              <MyPersonalSpacePage />
            </MySpaceLayout>
          </DashboardLayout>
        ),
      },
      {
        path: 'public',

        element: (
          <DashboardLayout>
            <MetaTitle title="Public Spaces" />
            <MySpaceLayout>
              <PublicSpacesPage />
            </MySpaceLayout>
          </DashboardLayout>
        ),
      },
      {
        path: ':spaceId',
        children: [
          {
            path: '/',
            element: (
              <DashboardLayout>
                <CurrentSpaceLayout>
                  <SelectedSpacePage />
                </CurrentSpaceLayout>
              </DashboardLayout>
            ),
          },
          {
            path: ':taskId',
            children: [
              {
                path: '/',
                element: (
                  <DashboardLayout>
                    <MetaTitle title="22 Public Spaces" />
                    <CurrentSpaceLayout>
                      <SelectedSpacePage />
                    </CurrentSpaceLayout>
                  </DashboardLayout>
                ),
              },
              {
                path: 'history/:historyId',
                element: (
                  <DashboardLayout>
                    <MetaTitle title="22 Public Spaces" />
                    <CurrentSpaceLayout>
                      <SelectedSpacePage />
                    </CurrentSpaceLayout>
                  </DashboardLayout>
                ),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: 'marketp',
    children: [
      {
        path: '/',

        element: (
          <DashboardLayout>
            <MetaTitle title="Marketplace" />
            <AllTasksPage />
          </DashboardLayout>
        ),
      },
    ],
  },
  {
    path: ROUTES.marketplace,
    children: [
      {
        path: '/',
        element: (
          <DashboardLayout>
            <MetaTitle title="Marketplace" />
            <AllTasksPage />
          </DashboardLayout>
        ),
      },
      {
        path: ROUTES.personal,
        element: (
          <DashboardLayout>
            <MetaTitle title="Personal tasks" />
            <PersonalTasksPage />
          </DashboardLayout>
        ),
      },
      {
        path: ROUTES.trending,
        element: (
          <DashboardLayout>
            <MetaTitle title="Trending tasks" />
            <TrendingTasksPage />
          </DashboardLayout>
        ),
      },
      {
        path: ROUTES.task,

        children: [
          {
            path: '/',
            element: (
              <DashboardLayout>
                <MetaTitle title="Task" />
                <DashboardPage />
              </DashboardLayout>
            ),
          },
          {
            path: ROUTES.history,
            element: (
              <DashboardLayout>
                <MetaTitle title="History" />
                <DashboardPage />
              </DashboardLayout>
            ),
          },
        ],
      },
      {
        path: 'edit/:taskId',
        element: (
          <DashboardLayout>
            <MetaTitle title="Edit prompt" />
            <SolutionEditorPage />
          </DashboardLayout>
        ),
      },

      {
        path: 'createNew',
        element: (
          <DashboardLayout>
            <MetaTitle title="Create new prompt 2.0" />
            <SolutionCreatorPage />
          </DashboardLayout>
        ),
      },

      {
        path: 'editNew/:taskId',
        element: (
          <DashboardLayout>
            <MetaTitle title="Edit prompt 2.0" />
            <SolutionEditorPage />
          </DashboardLayout>
        ),
      },

      {
        path: 'create',
        element: (
          <DashboardLayout>
            <MetaTitle title="Create new prompt" />
            <SolutionCreatorPage />
          </DashboardLayout>
        ),
      },

      {
        path: ROUTES.tasksIds,
        element: (
          <DashboardLayout>
            <MetaTitle title="Marketplace" />
            <AllTasksPage />
          </DashboardLayout>
        ),
      },
    ],
  },
  {
    path: ROUTES.quick,
    children: [
      {
        path: '/',
        element: (
          <DashboardLayout>
            <MetaTitle title="Quick Start" />
            <ConsumerDashboardPage />
          </DashboardLayout>
        ),
      },
    ],
  },
  {
    path: ROUTES.updates,
    element: (
      <DashboardLayout>
        <MetaTitle title="Recent project updates" />
        <ProjectUpdatesPage />
      </DashboardLayout>
    ),
  },
  {
    path: ROUTES.terms,
    element: (
      <DashboardLayout>
        <MetaTitle title="Terms of use" />
        <TermsPage />
      </DashboardLayout>
    ),
  },
  {
    path: '/*',
    element: (
      <div>
        <MetaTitle title="404 - not found" />
        <NotFoundPage />
      </div>
    ),
  },
]
