import { AnimatePresence, motion } from 'framer-motion'
import { Button } from 'src/core/ds/button'
import { ButtonVariants } from 'src/core/ds/button/types'
import { IconNames } from 'src/core/ds/icon'
import styles from './index.module.scss'

export function ReversedAccordion({ isOpen, onClose, children }) {
  // By using `AnimatePresence` to mount and unmount the contents, we can animate
  // them in and out while also only rendering the contents of open accordions
  return (
    <>
      {isOpen && (
        <motion.header initial={false} onClick={onClose}>
          <div className={styles.close}>
            <Button
              variant={ButtonVariants.unstyled}
              iconColor="var(--color-secondary)"
              type="button"
              icon={IconNames.close}
            />
          </div>
        </motion.header>
      )}
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            // transition={{ duration: 0.4, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            {children}
          </motion.section>
        )}
      </AnimatePresence>
    </>
  )
}
