import { Card } from 'src/components/Card'
import { CreateTeamSpace } from 'src/modules/createSpaceModal/createTeamSpaceModal'
import { useAddOrRemoveTeamSpace } from 'src/modules/teams/query'
import styles from './index.module.scss'

export function AddTeamSpace({ onNext }) {
  const { mutate: attachTeamMutate } = useAddOrRemoveTeamSpace(onNext)

  return (
    <div>
      <h2 className={styles.wizardTitle}>Add team space</h2>

      <div className={styles.wizardDescription}>You can create a new space or attach an existing space later.</div>

      <div className={styles.splitDetails}>
        <div className={styles.detailsForm}>
          <Card>
            <div className={styles.formControl}>
              <CreateTeamSpace attachTeamMutate={attachTeamMutate} />
            </div>
          </Card>
        </div>
      </div>
    </div>
  )
}
