import styles from './index.module.scss'

export function CardSkeleton() {
  return (
    <div className={styles.card}>
      <div className={styles.label} />
      <div style={{ width: '100%' }} className={styles.label} />
      <div className={styles.textRow} />
      <div className={styles.row}>
        <div className={styles.label} />
      </div>
    </div>
  )
}
