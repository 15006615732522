export const suggestions = [
  // 'Write a summary of an article',
  // 'Translate this text into French',
  // 'Generate a title for my essay',
  'Write a motivational quote',
  'Compose a short poem',
  'Create a birthday greeting message',
  'Help me write a cover letter for a job application',
  // 'Generate a creative caption for this picture',
  'Suggest a topic for my next blog post',
  'Compose a tweet about climate change',
  'Create a workout plan for weight loss',
  'Write a recipe for a vegan dish',
  'Generate a shopping list for a healthy diet',
  'Compose a love letter',
  'Help me write a business proposal',
  'Create an engaging Instagram post about my bakery',
  'Create a catchy slogan for my coffee shop',
  // 'Write a review for a recent movie',
  // 'Compose an inspirational message for my team',
  'Help me write a complaint letter to my internet service provider',
  'Generate a catchy subject line for my email campaign',
  'Write a bedtime story for my kid',
  'Generate a fitness tip for the day',
  'Compose a thank you note for a gift',
  'Help me write a condolence message',
  'Generate a mission statement for my startup',
  'Write a press release for our new product launch',
  'Compose a Facebook post about our upcoming event',
  'Create a study plan for my final exams',
  'Help me write a personal development goal',
]
