import { config } from 'src/config/config'
import { apiCall } from 'src/utils/networking'

export const api = {
  getAllTasks: async ({
    sorting = 'popular',
    page = 1,
    aiId,
  }: {
    categoryId?: any
    sorting: string
    page: any
    aiId: any
  }) =>
    apiCall(`${config.baseAPIUrl}/task/list/${aiId}?page=${page}&type=ai`, {
      method: 'POST',
      body: JSON.stringify({
        sorting,
      }),
    }),

  getCategoryInfoById: async ({ categoryId, page = 1 }: { categoryId: any; page: any }) =>
    apiCall(`${config.baseAPIUrl}/category/get/${categoryId === 'all' ? '' : categoryId}?page=${page}`, {
      method: 'POST',
    }),

  geyMyTasks: async ({ sorting = 'popular', accountId, page = 1 }: { accountId?: any; sorting: string; page?: any }) =>
    apiCall(`${config.baseAPIUrl}/task/list/${accountId}?type=account&page=${page}&limit=30`, {
      method: 'POST',
      body: JSON.stringify({
        sorting,
      }),
    }),

  getTasksByCategoryId: async ({ categoryId, sorting = 'popular', page = 1, aiId }) =>
    apiCall(`${config.baseAPIUrl}/task/list/${categoryId}?type=tag&page=${page}&ai_id=${aiId}`, {
      method: 'POST',
      body: JSON.stringify({
        sorting,
      }),
    }),
}
