import { useState } from 'react'
import { Button } from 'src/core/ds/button'
import { ButtonVariants } from 'src/core/ds/button/types'
import { useGetCurrentUser } from 'src/modules/currentUser/query'
import { useCreateTemplateStore } from 'src/services/store/CreateTemplateStore'
import { AddTeamMembers } from './AddTeamMembers'
import { AddTeamSpace } from './AddTeamSpace'
import { FinishPage } from './FinishPage'
import styles from './index.module.scss'
import { ProgressBar } from './ProgressBar'
import { PublicDetails } from './PublicDetails'
import { Tabber } from './Tabber'
// import { TestOutput } from './TestResponse'

export function CreateTeamWizard() {
  const { data: currentUser } = useGetCurrentUser()
  const currentFormState = useCreateTemplateStore((state) => state.currentFormState)
  const [renderIndex] = useState(0)
  const [currentIndex, setCurrentIndex] = useState(0)

  console.log(renderIndex)

  const onPrev = () => {
    setCurrentIndex((prevIndex) => prevIndex - 1)
  }

  console.log(onPrev)

  const onNext = () => {
    setCurrentIndex((prevIndex) => prevIndex + 1)

    return false
  }

  const updateProgressStep = (index) => {
    setCurrentIndex(index)
  }

  if (!currentUser) {
    return (
      <div className={styles.wizardWrapper}>
        <h1>You are not authorized</h1>
      </div>
    )
  }

  // updateFormState(name, value)
  return (
    <div className={styles.wizardWrapper}>
      {currentIndex < 3 && (
        <ProgressBar
          currentFormState={currentFormState}
          type="ChatGPT"
          updateStep={updateProgressStep}
          step={currentIndex}
        />
      )}
      <div className={styles.wizardContent}>
        <Tabber currentIndex={currentIndex}>
          <PublicDetails onNext={onNext} />
          <AddTeamMembers onNext={onNext} />
          <AddTeamSpace onNext={onNext} />
          <FinishPage />
        </Tabber>

        {currentIndex > 0 && currentIndex < 3 ? (
          <div className={styles.clearForm}>
            <Button
              variant={ButtonVariants.unstyled}
              isIconOnLeft
              label="Skip step"
              onClick={onNext}
              iconColor="var(--color-secondary)"
              type="button"
            />
          </div>
        ) : (
          <div />
        )}
      </div>
    </div>
  )
}
