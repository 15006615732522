import { useQuery } from 'react-query'
import { getAIModelsList } from 'src/queries/reactQueryKeys'
import { api } from './api'

export const useGetAIModels = () =>
  useQuery(getAIModelsList(), async () => {
    const response = await api.getAIModels()
    const [error, data] = response as any[]

    if (error) {
      throw new Error(error)
    }

    return data
  })
