import { Link } from '@tanstack/react-location'
import styles from './index.module.scss'

export type Props = {
  currentIndex?: number
  tabs: any[]
}

export function TabsLinks({ currentIndex, tabs }: Props) {
  return (
    <div className={styles.container}>
      <ul className={styles.tabContainer}>
        {tabs?.map((tab, index) => (
          <Link key={index} to={tab.link}>
            <button key={index} className={currentIndex === index ? styles.tabActive : undefined} type="button">
              {tab.label}
            </button>
          </Link>
        ))}
      </ul>
    </div>
  )
}
