import { stringify } from 'lossless-json'
import { config } from 'src/config/config'
import { apiCall } from 'src/utils/networking'

export const api = {
  addTaskToSpace: async ({ DTO, spaceId }) =>
    apiCall(`${config.baseAPIUrl}/space/update/${spaceId}`, {
      method: 'POST',
      body: stringify(DTO),
    }),
}
