// import ReactMarkdown from 'react-markdown'
import { Link } from '@tanstack/react-location'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Logo } from 'src/components/Logo/Logo'
import { Markdown } from 'src/components/MarkDownWrapper'
import { Button } from 'src/core/ds/button'
import { ButtonSizes, ButtonVariants } from 'src/core/ds/button/types'
import { IconNames } from 'src/core/ds/icon'
import { extractInput } from 'src/modules/asideHistory/components/ChatItem'
import { pushNotification } from 'src/services/notifications/notificationService'
import { PLAUSIBLE_EVENTS } from 'src/services/plausible/consts'
import { firePlausibleEvent } from 'src/services/plausible/plausibleService'
import { copyToClipboard } from 'src/utils/strings'
import styles from './index.module.scss'

export function ShortStreamHistory({ historyItemData }: any) {
  // const modelVariant = taskDetails?.category.ai.variations[historyItemData?.variation].model ?? 'not found'
  const isDalleModel =
    historyItemData?.response.includes('image.zentask.ai') || historyItemData?.response.includes('api.picogen.io')

  useEffect(() => {
    firePlausibleEvent(PLAUSIBLE_EVENTS.LOAD_HISTORY_PAGE)
  }, [])

  const isContextFlow = historyItemData?.context?.items?.length > 0

  const onInputTextCopy = (text) => {
    copyToClipboard(
      text,
      pushNotification({
        type: 'success',
        title: 'Copied to clipboard',
      }),
    )
  }

  const onTextCopy = (text) => {
    copyToClipboard(
      text,
      pushNotification({
        type: 'success',
        title: 'Copied to clipboard',
      }),
    )
  }

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>
          {`${(
            historyItemData?.input?.data ||
            historyItemData?.input?.text ||
            extractInput(historyItemData?.input)
          ).slice(0, 50)} | History |  Zentask.ai`}
        </title>
        <meta name="description" content={historyItemData?.response.slice(0, 140)} />
        <meta
          property="og:title"
          content={`${(
            historyItemData?.input?.data ||
            historyItemData?.input?.text ||
            extractInput(historyItemData?.input)
          ).slice(0, 50)} | History | Zentask.ai`}
        />
        <meta property="og:description" content={historyItemData?.response.slice(0, 140)} />
        <meta
          property="og:image"
          content="https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/7e15cc5b-b169-4605-96ca-0541110b7000/large"
        />
        <meta
          name="twitter:image"
          content="https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/7e15cc5b-b169-4605-96ca-0541110b7000/large"
        />
        {/* <meta name="robots" content="noindex" /> */}
      </Helmet>

      <div className={styles.content}>
        <div className={styles.header}>
          <Link to="/" className={styles.logo}>
            <Logo />
          </Link>
          <div className={styles.try}>
            <div className={styles.tryItem}>
              <p>{historyItemData?.task.title}</p>
              <Link to={`/marketplace/task/${historyItemData?.task?.id?.toString()}`}>
                <Button size={ButtonSizes.xs} type="button" icon={IconNames.arrowRight} label="Try yourself" />
              </Link>
            </div>
          </div>
        </div>

        {isContextFlow &&
          historyItemData?.context?.items
            ?.slice()
            .reverse()
            .map((historyItemData: any) => (
              <div>
                <div className={styles.historySettingsContent}>
                  <div className={styles.settingsItem}>
                    <p>
                      {historyItemData?.input?.data ||
                        historyItemData?.input?.text ||
                        extractInput(historyItemData?.input)}
                    </p>
                  </div>

                  <Button
                    icon={IconNames.copy}
                    type="button"
                    tooltip="Copy request"
                    iconColor="var(--color-secondary)"
                    size={ButtonSizes.xs}
                    variant={ButtonVariants.unstyled}
                    onClick={() => {
                      onInputTextCopy(
                        historyItemData?.input?.data ||
                          historyItemData?.input?.text ||
                          extractInput(historyItemData?.input),
                      )
                    }}
                  />
                </div>

                <div className={styles.contentWrapper}>
                  {!isDalleModel && (
                    <div className={styles.contentMD}>
                      <Markdown markdown={historyItemData?.response} />
                    </div>
                  )}

                  {!isDalleModel && (
                    <div className={styles.bottomCopy}>
                      <Button
                        icon={IconNames.copy}
                        type="button"
                        tooltip="Copy output"
                        iconColor="var(--color-secondary)"
                        size={ButtonSizes.xs}
                        variant={ButtonVariants.unstyled}
                        onClick={() => onTextCopy(historyItemData?.response)}
                      />
                    </div>
                  )}

                  {isDalleModel && (
                    <div className={styles.contentMD}>
                      <div className={styles.imagesBox}>
                        {(JSON.parse(historyItemData?.response) || []).map((image, index) => (
                          // eslint-disable-next-line
                          // @ts-ignore
                          <div key={image} className={styles.imageItem}>
                            <a href={image} download target="_blank" className={styles.downloadImage} rel="noreferrer">
                              <Button
                                type="button"
                                iconColor="var(--color-secondary)"
                                icon={IconNames.download}
                                size={ButtonSizes.xs}
                                variant={ButtonVariants.secondary}
                              />
                            </a>
                            <img width="323" height="323" loading="lazy" src={image} alt="" key={index} />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}

        {!isContextFlow && (
          <>
            <div className={styles.historySettingsContent}>
              <div className={styles.settingsItem}>
                <p>
                  {historyItemData?.input?.data || historyItemData?.input?.text || extractInput(historyItemData?.input)}
                </p>
              </div>

              <Button
                icon={IconNames.copy}
                type="button"
                tooltip="Copy request"
                iconColor="var(--color-secondary)"
                size={ButtonSizes.xs}
                variant={ButtonVariants.unstyled}
                onClick={() => {
                  onInputTextCopy(
                    historyItemData?.input?.data ||
                      historyItemData?.input?.text ||
                      extractInput(historyItemData?.input),
                  )
                }}
              />
            </div>

            <div className={styles.contentWrapper}>
              {!isDalleModel && (
                <div className={styles.contentMD}>
                  <Markdown markdown={historyItemData?.response} />
                </div>
              )}

              {!isDalleModel && (
                <div className={styles.bottomCopy}>
                  <Button
                    icon={IconNames.copy}
                    type="button"
                    tooltip="Copy output"
                    iconColor="var(--color-secondary)"
                    size={ButtonSizes.xs}
                    variant={ButtonVariants.unstyled}
                    onClick={() => onTextCopy(historyItemData?.response)}
                  />
                </div>
              )}

              {isDalleModel && (
                <div className={styles.contentMD}>
                  <div className={styles.imagesBox}>
                    {(JSON.parse(historyItemData?.response) || []).map((image, index) => (
                      // eslint-disable-next-line
                      // @ts-ignore
                      <div key={image} className={styles.imageItem}>
                        <a href={image} download target="_blank" className={styles.downloadImage} rel="noreferrer">
                          <Button
                            type="button"
                            iconColor="var(--color-secondary)"
                            icon={IconNames.download}
                            size={ButtonSizes.xs}
                            variant={ButtonVariants.secondary}
                          />
                        </a>
                        <img width="323" height="323" loading="lazy" src={image} alt="" key={index} />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  )
}
