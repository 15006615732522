import { create } from 'zustand'

export interface StreamStoreType {
  zenTaskSelected: string
  data: string
  prompt: { id: string; text: string }
  formData: any
  images: any
  updateFormData: (formData: any) => void
  updateImages: (images: any) => void
  updatePrompt: (data: any) => void
  setData: (data: any) => void
  updateData: (data: string) => void
  updateSelectedZenTask: (taskId: string) => void
  status: 'idle' | 'processing' | 'completed' | 'error'
  updateStatus: (status: 'idle' | 'processing' | 'completed' | 'error') => void
  resetData: () => void
  resetSelectedTemplate: () => void
}

export const defaultTaskId = '1'

export const useStreamStore = create<StreamStoreType>()((set) => ({
  zenTaskSelected: defaultTaskId,
  data: '',
  status: 'idle',
  formData: null,
  images: null,
  prompt: {
    id: '',
    text: '',
  },
  resetSelectedTemplate: () => {
    set({ zenTaskSelected: defaultTaskId })
  },
  updateImages: (images) => {
    set({ images })
  },
  updateSelectedZenTask: (taskId) => {
    set({ zenTaskSelected: taskId.toString() })
  },
  updateStatus: (status) => {
    set({ status })
  },
  updateFormData: (formData) => {
    set({ formData })
  },
  updatePrompt: (prompt: any) => {
    set({ prompt })
  },
  resetData: () => {
    set(() => ({ data: '' }))
  },
  setData: (data: string) => {
    set({ data })
  },
  updateData: (data: string) => {
    if (data === undefined) return

    if (data === '') {
      set((state) => ({ data: `${state.data}\n` }))

      return
    }

    set((state) => ({ data: state.data + data }))
  },
}))
