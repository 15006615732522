import { Link } from '@tanstack/react-location'
import styles from './index.module.scss'

export function TaskCategoryBadge({ tags }: any) {
  return (
    <div className={styles.flex}>
      {tags.map((tag) => (
        <div key={tag.id.toString()} className={styles.badge}>
          <Link to={`/marketplace/${tag.id.toString()}/`}>
            <span>
              {tag.emoji}
              &nbsp; &nbsp;
              {tag.name}
            </span>
          </Link>
        </div>
      ))}
    </div>
  )
}
