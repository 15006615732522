import { Link, useSearch } from '@tanstack/react-location'
import Pusher from 'pusher-js'
import { createRef, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { DashboardUserArea } from 'src/components/DashboardUserArea'
import { InputFormNew } from 'src/components/InputFormNew/InputFormNew'
import { Markdown } from 'src/components/MarkDownWrapper'
import { MetaTitle } from 'src/components/MetaTitle'
import { PricesSmall } from 'src/components/Prices/Prices'
import { useShareHistoryItem } from 'src/components/StreamHistory/query'
import { config } from 'src/config/config'
import { Button } from 'src/core/ds/button'
import { ButtonSizes, ButtonVariants } from 'src/core/ds/button/types'
import { Icon, IconNames } from 'src/core/ds/icon'
import { Input } from 'src/core/ds/input'
import { Modal } from 'src/core/ds/modal'
import { useCounter } from 'src/hooks/useCounter'
import { invalidateTaskHistory } from 'src/modules/asideHistory/query'
import { useGetCurrentUser } from 'src/modules/currentUser/query'
import { ModalConnector } from 'src/modules/modalsConnector/ModalsConnector'
import { SpotlightStatic } from 'src/modules/SpotlightStatic/SpotlightStatic'
import { api } from 'src/modules/StreamForm/api'
import { queryClient } from 'src/queries/reactQueryClient'
import { getCurrentUserKey } from 'src/queries/reactQueryKeys'
import { pushNotification } from 'src/services/notifications/notificationService'
import { PLAUSIBLE_EVENTS } from 'src/services/plausible/consts'
import { firePlausibleEvent } from 'src/services/plausible/plausibleService'
import { defaultTaskId, useStreamStore } from 'src/services/store/StreamStore'
import { useUIStore } from 'src/services/store/UIStore'
import { useZenTaskStore } from 'src/services/store/ZenTasksStore'
import { ResponseSkeletonNew } from 'src/skeletons/ResponseSkeletonNew'
import { copyToClipboard } from 'src/utils/strings'
import { useGetHistoryList20 } from '../HistorySearchPage/query'
import { suggestions } from './data'
import styles from './index.module.scss'

function pickRandomStrings(array: any) {
  if (array.length < 3) {
    console.log('Cannot pick 3 items, array size is less than 3')

    return []
  }

  const result = []
  // eslint-disable-next-line
  for (let i = 0; i < 3; i++) {
    const index = Math.floor(Math.random() * array.length)
    // eslint-disable-next-line
    // @ts-ignore
    result.push(array[index])
    array.splice(index, 1)
  }
  // eslint-disable-next-line
  return result
}

// let eventSource
let channel
// const taskId = '5012806596296705'

const randomTasks = pickRandomStrings(suggestions)
const taskMap = {}

export function ZenPage() {
  // eslint-disable-next-line camelcase
  const { ref_id: refId, utm_content, utm_medium, utm_source, utm_campaign, utm_term } = useSearch()
  const [isDefaultState, setIsDefaultState] = useState(true)
  const { data: currentUser } = useGetCurrentUser()
  const [isSpotlightVisible, setIsSpotlightVisible] = useState(false)
  const [historyId, setHistoryId] = useState(null)
  const [currentHistoryId, setCurrentHistoryId] = useState<any>(null)
  const [isHareHistoryModalOpen, setIsHareHistoryModalOpen] = useState(false)
  const [contextState, setContextState] = useState<'new' | 'current' | 'none'>('new')
  const [contextItemsList, setContextItemsList] = useState<any>([])

  const { data: currentHistoryItems, isLoading } = useGetHistoryList20()

  const shareLink = `https://zentask.ai/shared/${historyId}`

  const onCloseSpotlight = (bool) => {
    setIsSpotlightVisible(bool)
  }

  const addContextItem = (item) => {
    setContextItemsList((prevItems) => [...prevItems, item])
  }

  const taskId = useStreamStore((state) => state.zenTaskSelected)
  const updateSelectedZenTask = useStreamStore((state) => state.updateSelectedZenTask)

  const resetSelectedTemplate = useStreamStore((state) => state.resetSelectedTemplate)
  const streamData = useStreamStore((state) => state.data)

  const streamStatus = useStreamStore((state) => state.status)
  const promptInput = useStreamStore((state) => state.prompt)
  const updateStreamStatus = useStreamStore((state) => state.updateStatus)

  const tasks = useZenTaskStore((state) => state.tasks)
  const saveTask = useZenTaskStore((state) => state.saveTask)
  const removeTask = useZenTaskStore((state) => state.removeTask)

  const ref = createRef<any>()

  const onAddTask = (task) => {
    saveTask(task)
  }

  useEffect(() => {
    if (contextState === 'new') {
      setContextItemsList([])
    }
  }, [contextState])

  const { increment } = useCounter()
  // const taskId = useTasksStore((state) => state.taskId)
  const formDataStore = useStreamStore((state) => state.formData)
  const updatePromptInput = useStreamStore((state) => state.updatePrompt)
  const resetData = useStreamStore((state) => state.resetData)
  const updateStreamData = useStreamStore((state) => state.updateData)
  const setData = useStreamStore((state) => state.setData)
  const updateFormData = useStreamStore((state) => state.updateFormData)
  const updateUIStore = useUIStore((state) => state.updateUIStore)

  const setImages = useStreamStore((state) => state.updateImages)
  const isPROAccount = useUIStore((state) => state.isPROAccount)

  const isContextEnabled = isPROAccount && currentUser?.config?.[config.taskAIId]?.context === 1

  const [limitError, setLimitError] = useState<any>(null)

  const updateCurrentHistoryScreenPrev = () => {
    if (currentHistoryId === null) {
      setIsDefaultState(false)
      setCurrentHistoryId(0)
      const currHistoryElement = currentHistoryItems[0]
      updateStreamStatus('completed')
      setHistoryId(currHistoryElement?.id?.toString())
      setData(currHistoryElement.response)
      updateFormData({ request: currHistoryElement?.input?.data })
      setContextItemsList([])
      setContextState('new')

      return
    }

    if (currentHistoryId !== null && currentHistoryId + 1 <= currentHistoryItems?.length) {
      setIsDefaultState(false)
      setCurrentHistoryId(currentHistoryId + 1)
      const currHistoryElement = currentHistoryItems[currentHistoryId + 1]
      updateStreamStatus('completed')
      setHistoryId(currHistoryElement?.id?.toString())
      setData(currHistoryElement.response)
      updateFormData({ request: currHistoryElement?.input?.data })
      setContextItemsList([])
      setContextState('new')
    }
  }

  const updateCurrentHistoryScreenNext = () => {
    if (currentHistoryId !== null && currentHistoryId - 1 >= 0) {
      setCurrentHistoryId(currentHistoryId - 1)
      const currHistoryElement = currentHistoryItems[currentHistoryId - 1]
      setHistoryId(currHistoryElement?.id?.toString())
      setData(currHistoryElement.response)
      updateFormData({ request: currHistoryElement?.input?.data })
      setContextItemsList([])
      setContextState('new')
    }
  }

  useEffect(() => {
    const isProAccount = currentUser?.is_pro // currentUser?.sub_till * 1000 > Date.now()
    updateUIStore('isPROAccount', isProAccount)

    if (currentUser) {
      if (!window?.pusherInstance) {
        const pusher: any = new Pusher(config.pusherKey, {
          cluster: 'eu',
        })

        window.pusherInstance = pusher
      } else {
        // pusherInstance?.disconnect()
      }
    } else {
      // setPusherInstance(null)
    }
  }, [currentUser])

  useEffect(() => {
    if (!refId) return

    localStorage.setItem('refId', refId as string)
  }, [refId])

  useEffect(() => {
    try {
      const adsSource = localStorage.getItem('adsSource') || null

      if (adsSource) return

      localStorage.setItem(
        'adsSource',
        JSON.stringify({
          // eslint-disable-next-line
          utm_medium,
          // eslint-disable-next-line
          utm_source,
          // eslint-disable-next-line
          utm_campaign,
          // eslint-disable-next-line
          utm_term,
          // eslint-disable-next-line
          utm_content,
          // eslint-disable-next-line
          //utm_url: window.location.href
        }),
      )
    } catch (error) {
      console.log(error)
    }
  }, [])

  const isEligibleToShowAds = () => {
    if (!currentUser) return false

    try {
      const createdAt = new Date(currentUser?.created_at * 1000) // replace with your date

      const threeDaysFromNow = new Date()
      threeDaysFromNow.setDate(threeDaysFromNow.getDate() - 3)

      if (createdAt < threeDaysFromNow) {
        return true
      }

      return false
    } catch (e) {
      return false
    }
  }

  const ifShowSuggestions = () => {
    const currentDate = new Date()
    // eslint-disable-next-line
    // @ts-ignore
    const diffTime = Math.abs(currentDate - currentUser.created_at * 1000)
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

    return diffDays < 4
  }

  const onSuccessShare = () => {
    setIsHareHistoryModalOpen(true)
  }

  const { isLoading: isLoadingShare, mutate: mutateShare } = useShareHistoryItem(onSuccessShare)

  const onShareHistory = async () => {
    await mutateShare({ historyId })
  }

  const ifShowSuggestionBlock = currentUser && ifShowSuggestions()

  const onTextCopy = (text) => {
    firePlausibleEvent(PLAUSIBLE_EVENTS.COPY_RESPONSE)
    copyToClipboard(
      text,
      pushNotification({
        type: 'success',
        title: 'Copied to clipboard',
      }),
    )
  }

  const onRefreshRequest = () => {
    const uniqueID = uuidv4()
    resetData()
    updatePromptInput({
      text: formDataStore.request,
      id: uniqueID,
    })
  }

  const resetDialog = () => {
    updateStreamStatus('idle')
    updatePromptInput({
      text: '',
      id: '',
    })
    resetData()
    setImages(null)
    updateFormData(null)
  }

  const createRequst = async () => {
    setIsDefaultState(false)

    if (contextState === 'new') {
      setHistoryId(null)
    }

    increment()
    channel?.unbind()
    updateStreamStatus('processing')

    try {
      const prompt = `${promptInput.text}`

      const channelString = `${'text'}-${currentUser?.id.toString()}-${taskId.toString()}`

      channel = window?.pusherInstance?.subscribe(channelString)
      channel?.bind('response', (data) => {
        updateStreamData(data?.response || '')
      })

      channel?.bind('done', (data) => {
        try {
          if (data?.history_id) {
            if (!historyId) {
              setHistoryId(data.history_id.toString())
            }

            queryClient.invalidateQueries({ queryKey: 'globalHistory20' })
            queryClient.invalidateQueries({ queryKey: getCurrentUserKey() })

            if (currentHistoryId === null) {
              setCurrentHistoryId(0)
            }

            taskMap[taskId.toString()] =
              taskMap[taskId.toString()] === 'current' || taskMap[taskId.toString()] === 'new' ? 'current' : 'new'
          }
        } catch (e) {
          console.log('e')
        }

        channel.unbind()
        updateStreamStatus('completed')
        invalidateTaskHistory(taskId)
        firePlausibleEvent(PLAUSIBLE_EVENTS.SOLUTION_STREAM_STOPPED)
      })

      const response: any = await api.verifyPromptBeforeRun(
        taskId.toString(),
        prompt,
        !isContextEnabled ? 'none' : contextState,
      )
      const [error] = response

      if (error) {
        channel.unbind()
        updateStreamStatus('error')
      }
    } catch (err) {
      if (err?.toString().includes('e_request_data_too_long')) {
        const errorString = isPROAccount
          ? 'Your input is too long. 3000 is a Max.'
          : 'Your input is too long. 200 is a Max.\n To increase the limit to 3000 – upgrade to PRO.'
        pushNotification({
          type: 'error',
          title: 'Error occured.',
          subtitle: errorString,
        })
      } else if (err?.toString().includes('e_request_premium_forbidden')) {
        pushNotification({
          type: 'error',
          title: 'Forbidden.',
          subtitle: 'You need PRO account to use this prompt.',
        })
      } else if (err?.toString().includes('e_request_too_many_usages')) {
        // const errorString = isPROAccount
        //   ? 'Your limits are: Text AI – 20 / 3 hours,\n Image AI – 10 / 3 hours'
        //   : 'Your limits are: Text AI – 10 / day,\n Image AI – 5 / day. To increase the limits, please use a PRO account.'
        setLimitError(true)
        firePlausibleEvent(isPROAccount ? PLAUSIBLE_EVENTS.LIMITS_PRO : PLAUSIBLE_EVENTS.LIMITS_FREE)
        // pushNotification({
        //   type: 'error',
        //   title: 'Out of limits.',
        //   subtitle: errorString,
        // })
      } else {
        pushNotification({
          type: 'error',
          title: 'Internal error.',
          subtitle: 'Try again later or refresh the page.',
        })
      }

      updateStreamStatus('error')
    }
  }

  useEffect(() => {
    if (isContextEnabled && streamStatus === 'completed' && (currentHistoryId === null || currentHistoryId === 0)) {
      // updateStreamStatus('idle')
      updatePromptInput({
        text: '',
        id: '',
      })
      addContextItem({
        response: streamData,
        request: promptInput.text,
        id: uuidv4(),
      })
      resetData()

      updateFormData(null)
    }
  }, [streamStatus])

  const onClickSuggestion = (value) => {
    const uniqueID = uuidv4()
    updateFormData({ request: value })
    updatePromptInput({
      text: value,
      id: uniqueID,
    })
  }

  const onInputTextCopy = (text) => {
    copyToClipboard(
      text,
      pushNotification({
        type: 'success',
        title: 'Copied to clipboard',
      }),
    )
  }

  useEffect(() => {
    if (!promptInput.text.length) return undefined

    // const currTaskContextStatus = taskMap[taskId?.toString()]
    if (isContextEnabled) {
      setContextState((prevState) => {
        if (prevState === 'new') return 'current'

        if (prevState === 'current') return 'current'

        return 'new'
      })
    }

    updateStreamStatus('processing')

    createRequst()

    return undefined
  }, [promptInput])

  useEffect(() => {
    if (contextItemsList?.length > 0) {
      window.scrollTo(0, ref?.current?.offsetTop)
    }
  }, [contextItemsList])

  const onStop = () => {
    const type = 'text'
    const channelString = `${type}-${currentUser?.id.toString()}-${taskId.toString()}`
    channel.unbind()
    window?.pusherInstance?.unsubscribe(channelString)
    updateStreamStatus('error')
  }

  const onItemShareCopyLink = () => {
    copyToClipboard(
      shareLink,
      pushNotification({
        type: 'success',
        title: 'Copied to clipboard',
      }),
    )
  }

  useEffect(() => {
    const type = 'text'
    const channelString = `${type}-${currentUser?.id.toString()}-${taskId.toString()}`

    return () => window?.pusherInstance?.unsubscribe(channelString)
  }, [])

  // useEffect(() => {
  //   if (streamStatus === 'completed') {
  //     // invalidate user balance
  //     queryClient.invalidateQueries({ queryKey: getCurrentUserKey() })
  //   }
  // }, [streamStatus])

  useEffect(
    () => () => {
      // reset all on component leave
      resetDialog()
    },
    [],
  )

  const selectedTask = tasks.find((task) => task.id.toString() === taskId) || null

  useEffect(() => {
    const type = 'text'
    const channelString = `${type}-${currentUser?.id.toString()}-${taskId.toString()}`

    return () => window?.pusherInstance?.unsubscribe(channelString)
  }, [])

  return (
    <div className={styles.dashboard}>
      <SpotlightStatic onAddTask={onAddTask} onClose={onCloseSpotlight} isVisible={isSpotlightVisible} />
      <MetaTitle title="Zen Mode" />
      <Modal
        withCloseButton
        title="Share history"
        onClose={() => setIsHareHistoryModalOpen(false)}
        isOpen={isHareHistoryModalOpen}
        body={
          // eslint-disable-next-line
          <div className={styles.modalWrapper}>
            <Input
              label="Your link"
              action={
                // eslint-disable-next-line
                <button className={styles.action} onClick={onItemShareCopyLink} type="button">
                  <Icon name={IconNames.copy} />
                </button>
              }
              name="shareLink"
              value={shareLink}
            />
          </div>
        }
      />

      <div className={styles.content}>
        {contextItemsList?.map((item, index) => (
          <div key={item.id}>
            <div translate="no" className={styles.historySettingsContent}>
              <div className={styles.settingsItem}>
                <p>{item.request}</p>
              </div>

              <div className={styles.topCopy}>
                <Button
                  icon={IconNames.copy}
                  type="button"
                  tooltip="Copy request"
                  iconColor="var(--color-secondary)"
                  size={ButtonSizes.xs}
                  variant={ButtonVariants.unstyled}
                  onClick={() => onInputTextCopy(item.request)}
                />
              </div>
            </div>

            <div className={styles.contentWrapper}>
              <div translate="no" className={styles.contentMD}>
                <Markdown markdown={item.response} />
              </div>
              <div className={styles.bottomCopy}>
                <Button
                  icon={IconNames.copy}
                  type="button"
                  tooltip="Copy output"
                  iconColor="var(--color-secondary)"
                  size={ButtonSizes.xs}
                  variant={ButtonVariants.unstyled}
                  onClick={() => onTextCopy(item.response)}
                />

                {index === 0 && historyId && (
                  <Button
                    icon={IconNames.share}
                    type="button"
                    isLoading={isLoadingShare}
                    tooltip="Share history"
                    label=""
                    iconColor="var(--color-secondary)"
                    size={ButtonSizes.xs}
                    variant={ButtonVariants.unstyled}
                    onClick={onShareHistory}
                  />
                )}
              </div>
            </div>
          </div>
        ))}

        {formDataStore && (
          <div translate="no" className={styles.historySettingsContent}>
            {Object.keys(formDataStore || {}).map((inputKey) => (
              <div key={inputKey} className={styles.settingsItem}>
                <p>{formDataStore[inputKey].toString()}</p>
              </div>
            ))}

            <div className={styles.topCopy}>
              <Button
                icon={IconNames.refresh}
                type="button"
                tooltip="Refresh request"
                iconColor="var(--color-secondary)"
                size={ButtonSizes.xs}
                variant={ButtonVariants.unstyled}
                onClick={onRefreshRequest}
              />
              <Button
                icon={IconNames.copy}
                type="button"
                tooltip="Copy request"
                iconColor="var(--color-secondary)"
                size={ButtonSizes.xs}
                variant={ButtonVariants.unstyled}
                onClick={() => onInputTextCopy(formDataStore?.request)}
              />
            </div>
          </div>
        )}

        {limitError && !isPROAccount && (
          <div>
            <div className={styles.contentMD}>
              <h1>Seems like you are out of you limits. It&apos;s time to upgrade!</h1>
              <PricesSmall />
            </div>
          </div>
        )}

        {limitError && isPROAccount && (
          <div>
            <div className={styles.contentMD}>
              <h1>Seems like you are out of you limits. Wait 3 hours and try again!</h1>
            </div>
          </div>
        )}

        {!limitError && formDataStore && !streamData && <ResponseSkeletonNew />}

        {streamData && (
          <div className={styles.contentWrapper}>
            <div translate="no" className={styles.contentMD}>
              {!isPROAccount && isEligibleToShowAds() && (
                <div className={styles.upgrade}>
                  You are using free plan.&nbsp;
                  <Button
                    onClick={() => {
                      updateUIStore('isPricesPopupOpen', true)
                      firePlausibleEvent(PLAUSIBLE_EVENTS.CLICK_BUY_CREDITS)
                    }}
                    type="button"
                    variant={ButtonVariants.unstyled}
                    label="Upgrade to PRO"
                  />
                  &nbsp;for the ultimate content output quality, utilizing GPT-4, Bard, SD XL. Save 20% today.
                </div>
              )}

              <Markdown markdown={streamData} />
            </div>

            {streamStatus === 'completed' && (
              <div className={styles.bottomCopy}>
                <Button
                  icon={IconNames.copy}
                  type="button"
                  tooltip="Copy output"
                  iconColor="var(--color-secondary)"
                  size={ButtonSizes.xs}
                  variant={ButtonVariants.unstyled}
                  onClick={() => onTextCopy(streamData)}
                />

                {historyId && (
                  <Button
                    icon={IconNames.share}
                    type="button"
                    isLoading={isLoadingShare}
                    tooltip="Share history"
                    label=""
                    iconColor="var(--color-secondary)"
                    size={ButtonSizes.xs}
                    variant={ButtonVariants.unstyled}
                    onClick={onShareHistory}
                  />
                )}
              </div>
            )}
          </div>
        )}

        <div style={{ height: '15rem' }} ref={ref} />
      </div>
      <div className={styles.userArea}>
        {currentUser && !isPROAccount && (
          <button
            onClick={() => {
              updateUIStore('isPricesPopupOpen', true)
              firePlausibleEvent(PLAUSIBLE_EVENTS.CLICK_BUY_CREDITS_ZEN)
            }}
            className={styles.buyButton}
            type="button"
          >
            Upgrade to PRO.
            <br />
            GPT4 + SD XL
          </button>
        )}
        <DashboardUserArea isZen />
      </div>
      <div className={styles.nav}>
        <Link to="/marketplace">
          <Button
            icon={IconNames.arrowLeft}
            type="button"
            tooltipOnLeft
            tooltip="Leave Zen Mode"
            iconColor="var(--color-secondary)"
            size={ButtonSizes.xs}
            variant={ButtonVariants.unstyled}
          />
        </Link>
      </div>
      <div className={styles.actions}>
        <Link to="/marketplace">
          <Button
            icon={IconNames.billingSettings}
            type="button"
            tooltipOnLeft
            tooltip="Prompts"
            iconColor="var(--color-secondary)"
            size={ButtonSizes.xs}
            variant={ButtonVariants.unstyled}
          />
          <span>Prompts</span>
        </Link>
        <Link to="/text-to-image">
          <Button
            icon={IconNames.imageAdd}
            type="button"
            tooltipOnLeft
            tooltip="Create Images with AI"
            iconColor="var(--color-secondary)"
            size={ButtonSizes.xs}
            variant={ButtonVariants.unstyled}
          />
          <span>Text2Img</span>
        </Link>
        <Link to="/history">
          <Button
            icon={IconNames.bills}
            type="button"
            tooltipOnLeft
            tooltip="History page"
            iconColor="var(--color-secondary)"
            size={ButtonSizes.xs}
            variant={ButtonVariants.unstyled}
          />
          <span>History</span>
        </Link>
      </div>

      {isDefaultState && (
        <div className={styles.starter}>
          <h1>👋 How can I help you today?</h1>
        </div>
      )}

      {streamStatus !== 'processing' && currentHistoryId <= currentHistoryItems?.length - 1 && (
        <button disabled={isLoading} onClick={updateCurrentHistoryScreenPrev} className={styles.leftNav} type="button">
          <span>
            <Icon name={IconNames.arrowLeft} color="var(--color-secondary)" />
            Prev
          </span>
        </button>
      )}

      {streamStatus !== 'processing' && currentHistoryId > 0 && (
        <button disabled={isLoading} onClick={updateCurrentHistoryScreenNext} className={styles.rightNav} type="button">
          <span>
            Next
            <Icon name={IconNames.arrowRight} color="var(--color-secondary)" />
          </span>
        </button>
      )}

      <div className={`${styles.form} ${isDefaultState ? styles.topForm : undefined}`}>
        <div className={styles.list}>
          <div className={styles.listWrapper}>
            <div className={styles.smallActions}>
              <div style={{ zIndex: 3 }} className={styles.listAction}>
                <Button
                  icon={IconNames.plus}
                  type="button"
                  tooltipOnLeft
                  tooltip="Add prompt"
                  iconColor="var(--color-secondary)"
                  size={ButtonSizes.xs}
                  variant={ButtonVariants.unstyled}
                  onClick={() => setIsSpotlightVisible(true)}
                />
              </div>
              {taskId !== defaultTaskId && (
                <div className={styles.listAction}>
                  <Button
                    icon={IconNames.closeMini}
                    type="button"
                    tooltipOnLeft
                    tooltip="Reset selected"
                    iconColor="var(--color-secondary)"
                    size={ButtonSizes.xs}
                    variant={ButtonVariants.unstyled}
                    onClick={() => {
                      resetSelectedTemplate()
                      setContextState('new')
                    }}
                  />
                </div>
              )}
            </div>
            {tasks.map((task) => (
              // eslint-disable-next-line
              // @ts-ignore
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
              <div
                // eslint-disable-next-line
                // @ts-ignore
                className={`${styles.selectItem} ${task.id === taskId ? styles.activeItem : undefined}`}
                // eslint-disable-next-line
                // @ts-ignore
                key={task.id}
                // eslint-disable-next-line
                // @ts-ignore
                onClick={() => {
                  updateSelectedZenTask(task.id)
                  // taskMap[task.id.toString()] = 'new'
                  setContextState('new')
                }}
              >
                {task.title}
                <div className={styles.removeFromList}>
                  <Button
                    icon={IconNames.delete}
                    type="button"
                    tooltip="Remove from list"
                    iconColor="var(--color-secondary)"
                    size={ButtonSizes.xs}
                    variant={ButtonVariants.unstyled}
                    onClick={(event) => {
                      event.stopPropagation()
                      removeTask(task.id)
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <InputFormNew
          contextState={contextState}
          setContextState={setContextState}
          currentUser={currentUser}
          onStop={onStop}
          isImageModel={false}
          streamStatus={streamStatus}
          hint={selectedTask?.hint ? selectedTask.hint : 'ask me anything'}
        />

        {isDefaultState && ifShowSuggestionBlock && (
          <div className={styles.suggestWrapper}>
            <h2>Try these examples:</h2>
            <div className={styles.suggestList}>
              {randomTasks.map((task) => (
                <button onClick={() => onClickSuggestion(task)} className={styles.suggestItem} type="button" key={task}>
                  {task}
                  &nbsp;→
                </button>
              ))}
            </div>
          </div>
        )}
      </div>

      <ModalConnector />
    </div>
  )
}
