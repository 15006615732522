import { useMutation } from 'react-query'
import { queryClient } from 'src/queries/reactQueryClient'
import { getCurrentUserKey } from 'src/queries/reactQueryKeys'
import { pushNotification } from 'src/services/notifications/notificationService'
import { PLAUSIBLE_EVENTS } from 'src/services/plausible/consts'
import { firePlausibleEvent } from 'src/services/plausible/plausibleService'
import { api } from './api'

export const useSendAuthEmailRequest = () =>
  useMutation(api.sendEmail, {
    onSuccess: (data: any) => {
      const [error] = data

      if (error) {
        throw Error(error)
      }
    },
    onError: (err: any) => {
      if (err.toString().includes('e_account_email_not_valid')) {
        firePlausibleEvent(PLAUSIBLE_EVENTS.NOT_VALID_EMAIL)
        pushNotification({
          title: 'Email not valid',
          subtitle: 'Please try again.',
          type: 'error',
        })
      }

      if (err.toString().includes('e_account_too_many_login_attempts')) {
        firePlausibleEvent(PLAUSIBLE_EVENTS.TOO_MANY_LOGIN_ATTEMPTS)
        pushNotification({
          title: 'Too many login attempts',
          subtitle: 'Please try again after 5 mins.',
          type: 'error',
        })
      }

      return err
    },
  })

export const useSendCode = () =>
  useMutation(api.sendCode, {
    onSuccess: (data: any, variables: any) => {
      const [error, response] = data

      if (error) {
        throw Error(error)
      }

      const { token } = response

      if (token) {
        localStorage.setItem('token', token)
        // redirect to dashboard

        firePlausibleEvent(PLAUSIBLE_EVENTS.LOGIN)
        window?.twq('event', 'tw-od9qx-oemnj', {})
        variables.onRedirect()
        queryClient.invalidateQueries({ queryKey: getCurrentUserKey() })

        return token
      }

      return null
    },
    onError: (err: any) => {
      firePlausibleEvent(PLAUSIBLE_EVENTS.LOGIN_FAILED)

      if (err.toString().includes('e_account_unauthorized')) {
        localStorage.removeItem('token')
        // redirect to main
        window.location.replace('/')
      }

      if (err.toString().includes('e_account_auth_missing')) {
        pushNotification({
          type: 'error',
          title: 'Auth failed.',
          subtitle: 'Wrong or expired code.',
        })
      }

      return err
    },
  })
