import { useInfiniteQuery } from 'react-query'
import { getRevenueList, getRewardsList, getTransactionsList } from 'src/queries/reactQueryKeys'
import { api } from './api'

export const useGetUsagesList = (type) =>
  useInfiniteQuery(
    getTransactionsList(type),
    async ({ pageParam = 1 }) => {
      const response = await api.getTransactionsList({ type, page: pageParam })
      const [error, data] = response as any[]

      if (error) {
        throw new Error(error)
      }

      return data
    },

    {
      getNextPageParam: (lastPage: any) =>
        // lastPage?.length >= 20 ? pages.length * 20 : false

        lastPage?.pagination?.next_page ?? undefined,
      onError: (error: any) => console.log('WTF', error),
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: 0,
      keepPreviousData: false,
    },
  )

// getRevenueList

export const useGetRevenueList = () =>
  useInfiniteQuery(
    getRevenueList(),
    async ({ pageParam = 1 }) => {
      const response = await api.getRevenueList({ page: pageParam })
      const [error, data] = response as any[]

      if (error) {
        throw new Error(error)
      }

      return data
    },

    {
      getNextPageParam: (lastPage: any) => lastPage?.pagination?.next_page ?? undefined,
      onError: (error: any) => console.log('WTF', error),
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: 0,
      keepPreviousData: false,
    },
  )

export const useGetRewardsList = () =>
  useInfiniteQuery(
    getRewardsList(),
    async ({ pageParam = 1 }) => {
      const response = await api.getRewardsList({ page: pageParam })
      const [error, data] = response as any[]

      if (error) {
        throw new Error(error)
      }

      return data
    },

    {
      getNextPageParam: (lastPage: any) => lastPage?.pagination?.next_page ?? undefined,
      onError: (error: any) => console.log('WTF', error),
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: 0,
      keepPreviousData: false,
    },
  )
