import { useMatch } from '@tanstack/react-location'
import { AsideZenSpaces } from 'src/components/AsideZenSpaces/AsideZenSpaces'
import { breaks } from 'src/constants/breaks'
import { useMedia } from 'src/hooks/useMedia'
import { useGetCurrentUser } from 'src/modules/currentUser/query'
import { TasksList } from 'src/modules/tasksList'
import { useGetMyTasks } from 'src/modules/tasksList/query'
import { useUIStore } from 'src/services/store/UIStore'
import styles from './index.module.scss'

export function PersonalTasksPage() {
  const {
    params: { taskId, categoryId },
  } = useMatch()
  const updateUIStore = useUIStore((state) => state.updateUIStore)

  const { data: currentUser } = useGetCurrentUser()

  const personalAreaSorting = useUIStore((state) => state.personalAreaSorting)

  const onSortChange = (event) => {
    // let target
    console.log(taskId)
    updateUIStore('personalAreaSorting', event.target.value)
  }

  const screenWidth = useMedia()
  const isVisibleOnDesktop = screenWidth > breaks.tablet

  return (
    <div className={styles.dashboard}>
      <TasksList
        isPersonalView
        fetchIdentifier={currentUser?.id.toString()}
        sortingState={personalAreaSorting}
        onSortChange={onSortChange}
        tasksQuery={useGetMyTasks}
        currentTabIndex={2}
        taskId={taskId}
        categoryId={categoryId}
      />
      {isVisibleOnDesktop && <AsideZenSpaces key="zen" />}
    </div>
  )
}
