import { useMutation } from 'react-query'
import { pushNotification } from 'src/services/notifications/notificationService'
import { PLAUSIBLE_EVENTS } from 'src/services/plausible/consts'
import { firePlausibleEvent } from 'src/services/plausible/plausibleService'
import { api } from './api'

export const useCreateNewTask = (onSuccess, type: 'create' | 'edit') =>
  useMutation(api.createNewTask, {
    // onMutate: () => {

    // },
    onSuccess: (data: any) => {
      const [error, errorsArr] = data

      if (error) {
        if (errorsArr.includes('e_task_account_id_too_many_pending')) {
          pushNotification({
            type: 'error',
            title: 'Too many creation attempts.',
          })
        }

        throw Error(error)
      }

      onSuccess && onSuccess()
      // pushNotification({
      //   type: 'success',
      //   title: 'Task created and move to moderation.',
      // })
      type === 'create'
        ? firePlausibleEvent(PLAUSIBLE_EVENTS.CREATE_TASK)
        : firePlausibleEvent(PLAUSIBLE_EVENTS.EDIT_TASK)
      // firePlausibleEvent(PLAUSIBLE_EVENTS.LOGIN)
    },
    onError: (err: any) => {
      pushNotification({
        type: 'error',
        title: 'Task creation failed.',
      })
      type === 'create'
        ? firePlausibleEvent(PLAUSIBLE_EVENTS.CREATE_TASK_FAILED)
        : firePlausibleEvent(PLAUSIBLE_EVENTS.EDIT_TASK_FAILED)

      return err
    },
  })
