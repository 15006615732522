import { useMatch } from '@tanstack/react-location'
import { useState } from 'react'
import { Button } from 'src/core/ds/button'
import { ButtonVariants } from 'src/core/ds/button/types'
import { Icon, IconNames, IconSize } from 'src/core/ds/icon'
import { Loader } from 'src/core/ds/loader'
import { TabsStatic } from 'src/core/ds/tabsStatic'
import { useGetCurrentUser } from 'src/modules/currentUser/query'
import { useGetPersonalSpaces } from 'src/pages/MyPersonalSpacePage/query'
import { pushNotification } from 'src/services/notifications/notificationService'
import { useUIStore } from 'src/services/store/UIStore'
import styles from './index.module.scss'
import { useAddTaskToSpace } from './query'

const tabs = ['Public', 'Private', 'Team']

export function AddToSpaceModal() {
  const {
    params: { teamId },
  } = useMatch()
  const [currentIndex, setCurrentIndex] = useState(0)
  const taskInBuffer = useUIStore((state) => state.addToSpaceTaskBuffer)
  const { data: currentUser } = useGetCurrentUser()
  const { data: personalSpaces, isLoading } = useGetPersonalSpaces(currentUser?.id.toString(), tabs[currentIndex])
  const updateUIStore = useUIStore((state) => state.updateUIStore)
  const isPROAccount = useUIStore((state) => state.isPROAccount)
  const data: any = personalSpaces?.pages?.flatMap((page) => page?.items ?? page) ?? []
  const [selectedSpace, setSelectedSpace] = useState(null)

  const onSuccess = () => {
    updateUIStore('isAddToSpaceModalOpen', false)
    pushNotification({
      type: 'success',
      title: 'Task added to space',
    })
  }

  const openPricesModal = () => {
    updateUIStore('isPricesPopupOpen', true)
  }

  const { mutate, isLoading: isLoadingMutate } = useAddTaskToSpace(onSuccess)

  const onSubmit = async () => {
    if (selectedSpace === null) return

    await mutate({
      DTO: {
        add: [taskInBuffer.id.toString()],
      },
      spaceId: data[selectedSpace]?.id.toString(),
    })
  }

  const onTabChange = (index) => {
    setCurrentIndex(index)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.preview}>
        <p>Prompt to add:</p>
        <div>{taskInBuffer.title}</div>
      </div>

      {isLoading && <Loader isBig />}
      {!isLoading && (
        <>
          <div className={styles.preTitle}>Select the space to save the prompt:</div>
          <TabsStatic onChangeTab={onTabChange} currentIndex={currentIndex} tabs={tabs} />
          <div className={styles.list}>
            {data?.map((space, index) => (
              // eslint-disable-next-line
              <div
                onClick={() => setSelectedSpace(index)}
                key={index}
                className={`${styles.card} ${index === selectedSpace ? styles.selected : ''}`}
              >
                <Icon color="var(--color-secondary)" size={IconSize.m} name={IconNames.space} />
                <span>{space.title}</span>
                {index === selectedSpace && <Icon name={IconNames.check} color="var(--color-primary)" />}
              </div>
            ))}
            {data?.length === 0 && <p>No spaces created.</p>}
          </div>
        </>
      )}

      <div className={styles.action}>
        {isPROAccount && (
          <Button
            isLoading={isLoadingMutate}
            disabled={selectedSpace === null || isLoadingMutate}
            type="button"
            onClick={onSubmit}
            label="Add to space"
          />
        )}
        {!isPROAccount && (
          <Button isLoading={isLoadingMutate} type="button" onClick={openPricesModal} label="You need PRO account" />
        )}
        {!teamId && (
          <Button
            onClick={() => updateUIStore('isCreateNewSpaceOpen', true)}
            variant={ButtonVariants.secondary}
            type="button"
            icon={!isPROAccount ? IconNames.pro : undefined}
            disabled={!isPROAccount}
            label="Create new space"
          />
        )}
      </div>
    </div>
  )
}
