import { AIModelsLogo } from 'src/components/AIModelsLogo/AIModelsLogo'
import { Card } from 'src/components/Card'
import { TaskCategoryBadge } from 'src/components/TaskCategoryBadge/TaskCategoryBadge'
import { Avatar } from 'src/core/ds/avatar'
import { AVATAR_SIZES } from 'src/core/ds/avatar/consts'
import { Button } from 'src/core/ds/button'
import { ButtonVariants } from 'src/core/ds/button/types'
import { Input } from 'src/core/ds/input'
import { useGetAIModels } from 'src/modules/ai/query'
import { useGetCurrentUser } from 'src/modules/currentUser/query'
import { useGetTags } from 'src/modules/tags/query'
import styles from './index.module.scss'

export const maxWidthValidation = {
  title: 60,
  description: 155,
  prompt: 1500,
  context: 2500,
  info: 4096,
}

export function PublicDetails({ currentFormState, updateFormState, onNext, onPrev }) {
  const { data: tagsList } = useGetTags()
  const { data: currentUser } = useGetCurrentUser()
  const { data: AIModels } = useGetAIModels()
  const selectedModelId = currentFormState?.model
  const selectedAIModel = AIModels?.find((model) => model?.id?.toString() === selectedModelId?.toString())

  const tags = currentFormState?.tags?.map(String)
  const selectedTags = tagsList?.filter((tag) => tags?.includes(tag?.id?.toString()))

  const handleChange = (event) => {
    const { name, value } = event.target

    updateFormState(name, value)
  }

  const isTitleInvalid = currentFormState?.title.length < 11 || currentFormState?.title.length > maxWidthValidation.title

  const isDescriptionInvalid =
    currentFormState?.description.length < 31 || currentFormState?.description.length > maxWidthValidation.description

  if (!AIModels) return null

  return (
    <div>
      <h2 className={styles.wizardTitle}>Add public Details</h2>
      <div className={styles.wizardDescription}>
        Keep in mind that these fields are public and should be catchy for your clients.
      </div>
      <div className={styles.subDescription}>
        Only prompts in English are allowed on marketplace.
        <br />
        For personal usage all languages are supported.
      </div>

      <div className={styles.splitDetails}>
        <div className={styles.detailsForm}>
          <Card>
            <div className={styles.formControl}>
              <Input
                id="title"
                value={currentFormState.title}
                onChange={handleChange}
                type="text"
                label="Title"
                // error={isTitleInvalid ? 'error' : undefined}
                description="It's important to make it catchy and attention-grabbing"
                isRequired
              />
              <div className={styles.details}>
                <div style={{ marginRight: 'auto' }}>(min: 12)&nbsp;</div>
                {`${currentFormState.title.length} / ${maxWidthValidation.title}`}
              </div>
            </div>

            <div className={styles.formControl}>
              <Input
                id="description"
                description="It should expand on the title and provide potential buyers with a clear idea of what they can expect."
                value={currentFormState.description}
                onChange={handleChange}
                label="Description"
                isRequired
              // error={isDescriptionInvalid ? 'error' : undefined}
              />
              <div className={styles.details}>
                <div style={{ marginRight: 'auto' }}>(min: 32)&nbsp;</div>
                {`${currentFormState.description.length} / ${maxWidthValidation.description}`}
              </div>
            </div>
          </Card>
        </div>
        <div className={styles.detailsCard}>
          <div className={styles.header}>
            <AIModelsLogo aiModel={selectedAIModel?.name} />
            <TaskCategoryBadge tags={selectedTags || []} />
          </div>
          <div className={styles.itemTitle}>
            <h2>{currentFormState.title}</h2>
          </div>

          <p className={styles.taskItemInfo}>{currentFormState.description}</p>

          <div className={styles.footer}>
            <div className={styles.author}>
              <Avatar size={AVATAR_SIZES.xs} url={currentUser?.avatar_url} />
              <span>{currentUser?.full_name || currentUser?.username}</span>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.actionFooter}>
        <Button variant={ButtonVariants.secondary} onClick={onPrev} type="button" label="Go back" />
        <Button disabled={isTitleInvalid || isDescriptionInvalid} onClick={onNext} type="button" label="Continue" />
      </div>
    </div>
  )
}
