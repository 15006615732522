import { useDeferredValue, useState } from 'react'
import { SpaceCard } from 'src/components/SpaceCard/SpaceCard'
import { Button } from 'src/core/ds/button'
import { IconNames } from 'src/core/ds/icon'
import { Input } from 'src/core/ds/input'
import { Loader } from 'src/core/ds/loader'
import { TabsLinks } from 'src/core/ds/tabsLinks'
import { useGetCurrentUser } from 'src/modules/currentUser/query'
import { useUIStore } from 'src/services/store/UIStore'
import styles from './index.module.scss'
import { useGetPublicSpaces } from './query'

const tabs = [
  { link: '/spaces', label: 'My spaces' },
  { link: '/spaces/public', label: 'Public spaces' },
]

// const sortOptions = [
//   { value: 'popular', label: 'Popular' },
//   { value: 'recent', label: 'Recent' },
//   { value: 'alphabet', label: 'Alphabet' },
// ]

export function PublicSpacesPage() {
  const updateUIStore = useUIStore((state) => state.updateUIStore)
  // const publicSpaceSorting = useUIStore((state) => state.publicSpaceSorting)
  const [searchInput, setSearchInput] = useState('')
  const deferredSearchInput = useDeferredValue(searchInput)
  const { data: currentUser, isLoading: isLoadingUser } = useGetCurrentUser()
  // const { data: currentUser } = useGetCurrentUser()
  const isPROAccount = useUIStore((state) => state.isPROAccount)

  const {
    data: personalSpaces,
    isLoading,
    fetchNextPage,
    hasNextPage,

    isFetchingNextPage,
  } = useGetPublicSpaces()
  const data: any = personalSpaces?.pages?.flatMap((page) => page?.items ?? page) ?? []

  // const onSortChange = (e) => {
  //   updateUIStore('publicSpaceSorting', e.target.value)
  // }

  const openCreateNewSpaceModal = () => {
    updateUIStore('isCreateNewSpaceOpen', true)
  }

  const onEdit = (spaceId) => {
    updateUIStore('isEditSpaceModalOpen', true)
    updateUIStore('editSpaceBuffer', spaceId)
  }

  const isSearchView = deferredSearchInput.length > 2

  const foundTasks = data?.filter((task) => task.title.toLowerCase().includes(deferredSearchInput.toLowerCase()))

  if (isLoadingUser) {
    return (
      <div className={styles.wrapper}>
        <Loader isBig />
      </div>
    )
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1>Public Spaces</h1>
        <p>Discover the world of Zen Spaces.</p>
      </div>

      <div className={styles.controls}>
        <div className={styles.filters}>
          {/* <Selector hasDefault={false} onChange={onSortChange} options={sortOptions} selected={publicSpaceSorting} /> */}
          <Input
            icon={IconNames.search}
            type="text"
            onChange={(e) => setSearchInput(e.target.value)}
            placeholder="Search"
            value={searchInput}
          />
        </div>
        <div className={styles.listTabs}>
          <TabsLinks currentIndex={1} tabs={tabs} />
        </div>
      </div>

      {currentUser && (
        <div className={styles.newSpace}>
          <Button
            onClick={openCreateNewSpaceModal}
            disabled={!isPROAccount}
            icon={isPROAccount ? IconNames.plus : IconNames.pro}
            type="button"
            label="Create space"
          />
        </div>
      )}

      {isLoading && (
        <div className={styles.list}>
          <Loader isBig />
        </div>
      )}

      {!isLoading && !isSearchView && (
        <div className={styles.list}>
          {data.length === 0 && <p>Nothing to show</p>}

          {data.map((space) => (
            <SpaceCard onEdit={onEdit} key={space.id.toString()} space={space} />
          ))}
        </div>
      )}

      {isSearchView && (
        <div className={styles.list}>
          {foundTasks.length === 0 && <p>Nothing to show</p>}

          {foundTasks.map((space) => (
            <SpaceCard onEdit={onEdit} key={space.id.toString()} space={space} />
          ))}
        </div>
      )}

      {hasNextPage && (
        <div className={styles.tableActions}>
          <Button
            type="button"
            label="Load more"
            isLoading={isFetchingNextPage}
            disabled={!hasNextPage || isFetchingNextPage}
            onClick={fetchNextPage}
          />
        </div>
      )}
    </div>
  )
}
