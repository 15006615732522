import { useEffect, useState } from 'react'
import { useGetCurrentUser } from 'src/modules/currentUser/query'
import { PLAUSIBLE_EVENTS } from 'src/services/plausible/consts'
import { firePlausibleEvent } from 'src/services/plausible/plausibleService'
import { useUIStore } from 'src/services/store/UIStore'

export function useCounter() {
  const { data: currentUser, isSuccess } = useGetCurrentUser()
  // Get the initial value from localStorage or start with 0
  const updateUIStore = useUIStore((state) => state.updateUIStore)
  const isPROAccount = useUIStore((state) => state.isPROAccount)
  const initialCount = Number(localStorage.getItem('count')) || 0

  // Initialize state
  const [count, setCount] = useState(initialCount)

  const openPopup = () => {
    updateUIStore('isNoisyPricePopupOpen', true)
    increment()
  }

  // Function to increment and store the count
  const increment = () => {
    const newCount = 0
    setCount(newCount)
    // eslint-disable-next-line
    // @ts-ignore
    localStorage.setItem('count', newCount)
  }

  // Check if count is divisible by 5
  useEffect(() => {
    if (currentUser?.id && isPROAccount === false && isSuccess) {
      if (count && count % 6 === 0) {
        openPopup()
        firePlausibleEvent(PLAUSIBLE_EVENTS.ANNOYING_PRICE_POPUP)
      }
    }
  }, [count, isPROAccount])

  return { count, increment }
}
