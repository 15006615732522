import { Link } from '@tanstack/react-location'
import { useState } from 'react'
import { Button } from 'src/core/ds/button'
import { ButtonVariants } from 'src/core/ds/button/types'
import { Icon, IconNames, IconSize } from 'src/core/ds/icon'
import { useCreateNewTask } from 'src/modules/createTaskModal/query'
import { useUIStore } from 'src/services/store/UIStore'
import styles from './index.module.scss'

const publicItems = [
  {
    id: 1,
    name: 'Private prompt',
    mode: 'private',
    icon: IconNames.eyeHide,
    description: "This prompt is private and doesn't require moderation. Feel free to use any language.",
  },
  {
    id: 2,
    name: 'Public prompt',
    mode: 'public',
    icon: IconNames.billingSettings,
    description: 'This is a public prompt. It should go through moderation and will be placed on the marketplace.',
  },
]

export function Review({ onStartAgain, resetCurrentForm, currentFormState, updateFormState, onPrev }) {
  const selectedPublicMode = currentFormState?.isPublic
  const [isCreatedSolution, setIsCreatedSolution] = useState(false)
  const isPROAccount = useUIStore((state) => state.isPROAccount)

  const onPickPublic = (mode) => {
    updateFormState('isPublic', mode)
  }

  const onSuccess = () => {
    setIsCreatedSolution(true)

    resetCurrentForm()
    // setAvailableFormulas([])
    // setCurrentFormula({})
  }

  const { isLoading, mutate } = useCreateNewTask(onSuccess, 'create')

  const onCreateSolutionHandler = async () => {
    const taskDTO = {
      tag_ids: currentFormState?.tags,
      ai_id: BigInt(currentFormState?.model?.toString() || ''),
      title: currentFormState?.title,
      description: currentFormState?.description,
      prompt: currentFormState?.prompt,
      hint: currentFormState?.hint,
    }

    await mutate({ taskDTO, isPublic: currentFormState?.isPublic === 'public' })
  }

  return (
    <div>
      {!isCreatedSolution && (
        <div>
          <h2 className={styles.wizardTitle}>Review and Create</h2>
          <div className={styles.wizardDescription}>
            This is the final step. Make sure that everything is correct. You can go back and make edits. Choose whether
            you want to make your prompt public or keep it for personal use.
          </div>

          <div className={styles.subDescription}>
            If you want to place your prompt on the Marketplace, please make sure that it is written in English.
            Otherwise, it will be declined.
          </div>
        </div>
      )}

      {isCreatedSolution && (
        <div className={styles.success}>
          <Icon name={IconNames.checkCircle} color="var(--color-green)" size={IconSize['2xl']} />
          <h1>Prompt created!</h1>
          <div>
            <Button type="button" onClick={onStartAgain} variant={ButtonVariants.secondary} label="Create one more" />
            <Link to="/creator">
              <Button type="button" label="Creator dashboard" />
            </Link>
          </div>
        </div>
      )}

      {!isCreatedSolution && (
        <div className={styles.aiModels}>
          {publicItems.map((model) => (
            <button
              onClick={() => onPickPublic(model.mode)}
              key={model.id.toString()}
              type="button"
              disabled={!isPROAccount && model.mode === 'public'}
              className={`${styles.aiModel} ${selectedPublicMode === model.mode ? styles.activeModel : undefined}`}
            >
              <div className={styles.aiLogo}>
                <Icon name={model.icon} />
              </div>
              <div className={styles.aiName}>
                {/* eslint-disable-next-line */}
                {model.name} {!isPROAccount && model.mode === 'public' && <Icon name={IconNames.pro} />}
              </div>
              <div className={styles.aiDescription}>{model.description}</div>
            </button>
          ))}
        </div>
      )}

      {!isCreatedSolution && (
        <div className={styles.actionFooter}>
          <Button variant={ButtonVariants.secondary} onClick={onPrev} type="button" label="Go back" />
          <Button
            isLoading={isLoading}
            disabled={!selectedPublicMode || isLoading}
            onClick={onCreateSolutionHandler}
            type="button"
            label="Create prompt"
          />
        </div>
      )}
    </div>
  )
}
