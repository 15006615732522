import { useMutation, useQuery } from 'react-query'
import { queryClient } from 'src/queries/reactQueryClient'
import { getHistoryItemById, getSelectedTaskKey } from 'src/queries/reactQueryKeys'
import { pushNotification } from 'src/services/notifications/notificationService'
import { api } from './api'

export const useGetHistoryItemById = (id) =>
  useQuery(getHistoryItemById(id), async () => {
    const response = await api.getHistoryItemById({ id })
    const [error, data] = response as any[]

    if (error) {
      throw new Error(error)
    }

    return data
  })

export const useAttachHistoryItem = (onSuccess) =>
  useMutation(api.attachHistoryItemById, {
    onSuccess: (response: any, variables: any) => {
      const [error, data] = response

      if (error) {
        throw Error(error)
      }

      queryClient.invalidateQueries({ queryKey: getSelectedTaskKey(variables?.taskId) })
      onSuccess && onSuccess()

      return data
    },
    onError: (err: any) => {
      pushNotification({
        type: 'error',
        title: 'Error attaching this history item.',
      })

      return err
    },
  })

export const useShareHistoryItem = (onSuccess) =>
  useMutation(api.shareHistoryItemById, {
    onSuccess: (response: any, variables: any) => {
      const [error, data] = response

      if (error) {
        throw Error(error)
      }

      queryClient.invalidateQueries({ queryKey: getHistoryItemById(variables?.historyId) })
      onSuccess && onSuccess()

      return data
    },
    onError: (err: any) => {
      pushNotification({
        type: 'error',
        title: 'Error sharing this history item.',
      })

      return err
    },
  })
