import { config } from 'src/config/config'
import { apiCall } from 'src/utils/networking'

export const api = {
  getSpaceDetails: async ({ spaceId }) =>
    apiCall(`${config.baseAPIUrl}/space/get/${spaceId}`, {
      method: 'POST',
    }),

  getSpaceDetailsList: async ({ spaceId }) =>
    apiCall(`${config.baseAPIUrl}/space/tasks/${spaceId}?limit=50`, {
      method: 'POST',
    }),
}
