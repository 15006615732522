import { useMatch } from '@tanstack/react-location'
import { AsideZenSpaces } from 'src/components/AsideZenSpaces/AsideZenSpaces'
import { breaks } from 'src/constants/breaks'
import { useMedia } from 'src/hooks/useMedia'
import { useGetCurrentUser } from 'src/modules/currentUser/query'
import { TasksList } from 'src/modules/tasksList'
import { useGetTrendingTasksInfinite } from 'src/modules/trendingItems/query'
import { useUIStore } from 'src/services/store/UIStore'
import styles from './index.module.scss'

export function TrendingTasksPage() {
  const {
    params: { taskId, categoryId },
  } = useMatch()
  const updateUIStore = useUIStore((state) => state.updateUIStore)

  const { data: currentUser } = useGetCurrentUser()

  const trendingAreaSorting = useUIStore((state) => state.trendingAreaSorting)

  const onSortChange = (event) => {
    // let target
    console.log(taskId)
    updateUIStore('trendingAreaSorting', event.target.value)
  }

  const screenWidth = useMedia()
  const isVisibleOnDesktop = screenWidth > breaks.tablet

  return (
    <div className={styles.dashboard}>
      <TasksList
        isPersonalView={false}
        fetchIdentifier={currentUser?.id.toString()}
        sortingState={trendingAreaSorting}
        onSortChange={onSortChange}
        tasksQuery={useGetTrendingTasksInfinite}
        currentTabIndex={0}
        taskId={taskId}
        categoryId={categoryId}
      />
      {isVisibleOnDesktop && <AsideZenSpaces key="zen" />}
    </div>
  )
}
