import { useState } from 'react'
import { Button } from 'src/core/ds/button'
import { Input } from 'src/core/ds/input'
import { Textarea } from 'src/core/ds/textarea'
import { pushNotification } from 'src/services/notifications/notificationService'
import styles from './index.module.scss'
import { useCreateNewSpace } from './query'

export function CreateTeamSpace({ attachTeamMutate }) {
  const [spaceName, setSpaceName] = useState('')
  const [spaceDescription, setSpaceDescription] = useState('')

  const [errors, setErrors] = useState<any>({})

  const onSuccess = () => {
    pushNotification({
      type: 'success',
      title: 'Space created.',
    })
  }

  const { mutate, isLoading } = useCreateNewSpace(onSuccess)

  const validateForm = () => {
    setErrors({})
    const errors: any = {}

    if (spaceName.length < 11 || spaceName.length > 60) {
      errors.title = 'Required'
    }

    if (spaceDescription.length < 31 || spaceDescription.length > 155) {
      errors.description = 'Required'
    }

    setErrors(errors)

    return Object.keys(errors).length === 0
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    const isValid = validateForm()

    if (!isValid) {
      return undefined
    }

    await mutate({
      DTO: {
        title: spaceName,
        description: spaceDescription,
        status: 'private',
      },
    })

    setTimeout(async () => {
      await attachTeamMutate({
        // eslint-disable-next-line
        // @ts-ignore
        id: window.lastCreatedTeam,
        details: {
          type: 'space',
          // eslint-disable-next-line
          // @ts-ignore
          add: [window.lastCreatedSpace],
        },
      })
    }, 300)

    return false
  }

  return (
    <div className={styles.wrapper}>
      <form onSubmit={onSubmit}>
        <div className={styles.formControl}>
          <Input
            value={spaceName}
            error={errors?.title}
            onChange={(e) => setSpaceName(e.target.value)}
            isRequired
            label="Space name"
          />
          <div className={styles.details}>
            <div style={{ marginRight: 'auto' }}>(min: 12)&nbsp;</div>
            {`${spaceName.length} / ${60}`}
          </div>
        </div>

        <div className={styles.formControl}>
          <Textarea
            value={spaceDescription}
            onChange={(e) => setSpaceDescription(e.target.value)}
            isRequired
            error={errors?.description}
            label="Description"
            rows={3}
          />
          <div className={styles.details}>
            <div style={{ marginRight: 'auto' }}>(min: 32)&nbsp;</div>
            {`${spaceDescription.length} / ${155}`}
          </div>
        </div>

        <div className={styles.action} style={{ justifyContent: 'flex-end' }}>
          <Button isLoading={isLoading} disabled={isLoading} type="submit" label="Create" />
        </div>
      </form>
    </div>
  )
}
