import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export const useRecentTaskStore = create<any>()(
  persist(
    (set, get) => ({
      tasks: [],

      saveTask: (task) => {
        const existingTask = get().tasks.find((t) => t.id === task.id)

        if (!existingTask) {
          const updatedTasks = [task, ...get().tasks.slice(-4)]
          set({ tasks: updatedTasks })
        }
      },
    }),
    {
      name: 'recentTasksStore',
    },
  ),
)
