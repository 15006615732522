import { Link } from '@tanstack/react-location'
import { useState } from 'react'
import Turnstile from 'react-turnstile'
// import { FormattedText } from 'src/components/FormattedText'
import { Logo } from 'src/components/Logo/Logo'
import { Button } from 'src/core/ds/button'
import { Container } from 'src/core/ds/container'
import { Input } from 'src/core/ds/input'
import { useEmailService } from 'src/services/email/query'
import styles from './index.module.scss'

export function Footer() {
  const [email, setEmail] = useState('')

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const emailService = useEmailService()

  const { isLoading, isSuccess, mutate } = emailService.useSubscribeEmail()

  const isFormDisabled = isLoading || isSuccess

  const onSubmit = async (e) => {
    e.preventDefault()

    if (!email.length) {
      return
    }

    await mutate({ email, token: window.cfToken })
  }

  return (
    <footer id="footer" className={styles.footer}>
      <Container>
        <div className={styles.footerTop}>
          <div className={styles.nav}>
            <ul>
              <li>
                <a href="/#prompts">Features</a>
              </li>

              <li>
                <a href="/#prices">Prices</a>
              </li>
              <Link to="/influencer-program">Influencer program</Link>
              <li>
                <a href="/#faq">FAQ</a>
              </li>
              <li>
                <a href="/#footer">Subscribe</a>
              </li>
              <li>
                Contact:
                <br />
                <a href="mailto:hello@zentask.ai">hello@zentask.ai</a>
              </li>
            </ul>

            <ul>
              <li>AI prompts:</li>
              <li>
                <a href="/marketplace/16686324752842761/">AI Prompts for Students</a>
              </li>
              <li>
                <a href="/marketplace/16686324576681985/">AI Prompts for Business</a>
              </li>
              <li>
                <a href="/marketplace/16686324803174411/">AI Prompts for Influencers</a>
              </li>
              <li>
                <a href="/spaces/48290153063514113/">AI Prompts for SEO</a>
              </li>
              <li>
                <a href="/marketplace/16686324660568069/">AI Prompts for Finances</a>
              </li>
              <li>
                <a href="/marketplace/16686324601847810/">AI Prompts for Health</a>
              </li>
              <li>
                <a href="/marketplace/16686324677345286/">AI Prompts for Marketers</a>
              </li>
              <li>
                <a href="/marketplace/16686324778008586/">AI Prompts for Personal Growth</a>
              </li>
              <li>
                <a href="/marketplace/16686324643790852/">AI Prompts for Travelers</a>
              </li>
              <li>
                <a href="/marketplace/16686324694122503/">AI Prompts for Writers</a>
              </li>
            </ul>
          </div>

          <div className={styles.form}>
            <p>Don&#39;t miss updates:</p>
            <form onSubmit={onSubmit}>
              <Input
                isDisabled={isFormDisabled}
                onChange={handleEmailChange}
                value={email}
                type="email"
                placeholder="youremail@email.com"
              />
              <Button isLoading={isLoading} disabled={isFormDisabled} type="submit" label="Subscribe" />
              <div className="turnstile">
                <Turnstile
                  theme="light"
                  sitekey="0x4AAAAAAADaAKssW_ZClFec"
                  onVerify={(token) => {
                    window.cfToken = token
                  }}
                />
              </div>
            </form>
          </div>
        </div>

        <div className={styles.footerBottom}>
          <div>
            <a title="Zentask" href="/">
              <Logo />
            </a>
            <p>All rights reserved. 2023.</p>
          </div>
        </div>
      </Container>
    </footer>
  )
}
