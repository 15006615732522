import { useMatch } from '@tanstack/react-location'
import { useState } from 'react'
import { AsideSpaceList } from 'src/components/AsideSpaceList/AsideSpaceList'
import { breaks } from 'src/constants/breaks'
import { Button } from 'src/core/ds/button'
import { ButtonSizes, ButtonVariants } from 'src/core/ds/button/types'
import { IconNames } from 'src/core/ds/icon'
import { useMedia } from 'src/hooks/useMedia'
import { useGetCurrentUser } from 'src/modules/currentUser/query'
import { HistoryLine } from './HistoryLine'
import styles from './index.module.scss'

export function CurrentSpaceLayout({ children }) {
  const {
    params: { spaceId, taskId, historyId },
  } = useMatch()
  const screenWidth = useMedia()
  const isVisibleOnDesktop = screenWidth > breaks.tablet
  const { data: currentUser } = useGetCurrentUser()
  const [isHistoryShown, setIsHistoryShown] = useState(false)

  const toggleHistory = () => {
    setIsHistoryShown((prevState) => !prevState)
  }

  return (
    <div className={styles.dashboard}>
      {taskId && isVisibleOnDesktop && <AsideSpaceList currentUser={currentUser} spaceId={spaceId} />}
      {isVisibleOnDesktop && taskId && (
        <HistoryLine
          setIsHistoryShown={setIsHistoryShown}
          isHistoryShown={isHistoryShown}
          spaceId={spaceId}
          historyId={historyId}
          taskId={taskId}
        />
      )}
      <div className={`${styles.content} ${taskId && styles.mobileContent}`}>
        {!historyId && taskId && (
          <div className={styles.showHistory}>
            <Button
              iconColor="var(--color-secondary)"
              icon={IconNames.bills}
              variant={ButtonVariants.secondary}
              label={isHistoryShown ? 'Hide History' : 'Show History'}
              type="button"
              onClick={toggleHistory}
              size={ButtonSizes.xs}
            />
          </div>
        )}
        {children}
      </div>
    </div>
  )
}
