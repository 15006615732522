import { Link, Navigate, useNavigate, useSearch } from '@tanstack/react-location'
import { useEffect, useState } from 'react'
import { Prices } from 'src/components/Prices/Prices'
import { SingleTask } from 'src/components/SingleTask/SingleTask'
import { Avatar } from 'src/core/ds/avatar'
import { AVATAR_SIZES } from 'src/core/ds/avatar/consts'
import { Button } from 'src/core/ds/button'
import { ButtonSizes } from 'src/core/ds/button/types'
import { Container } from 'src/core/ds/container'
import { IconNames } from 'src/core/ds/icon'
import { Modal } from 'src/core/ds/modal'
import { AppLayout } from 'src/layouts/AppLayout'
import { Footer } from 'src/layouts/Footer'
import { useGetCurrentUser } from 'src/modules/currentUser/query'
import { MagicLinkForm } from 'src/modules/magicLink/components/Form'
import { useGetTrendingTasks } from 'src/modules/trendingItems/query'
import { pushNotification } from 'src/services/notifications/notificationService'
import { PLAUSIBLE_EVENTS } from 'src/services/plausible/consts'
import { firePlausibleEvent } from 'src/services/plausible/plausibleService'
import VideoSrc from '../../assets/images/hero-preview.webm'
import styles from './index.module.scss'

declare global {
  interface Window {
    cfToken: string
    plausible: any
  }
}

export function LandingPage() {
  const navigate = useNavigate()
  // eslint-disable-next-line
  const { app, ref_id: refId, utm_content, utm_medium, utm_source, utm_campaign, utm_term } = useSearch()
  const { data: currentUser } = useGetCurrentUser()
  const { data: trendingTasks, isLoading } = useGetTrendingTasks()

  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false)

  const onLoginSuccess = () => {
    pushNotification({
      type: 'success',
      title: 'Success Login',
      subtitle: 'You will be redirected.',
    })
    setIsAuthModalOpen(false)
    navigate({ to: `/zen` })
  }

  useEffect(() => {
    if (!refId) return

    localStorage.setItem('refId', refId as string)
  }, [refId])

  useEffect(() => {
    try {
      const adsSource = localStorage.getItem('adsSource') || null

      if (adsSource) return

      localStorage.setItem(
        'adsSource',
        JSON.stringify({
          // eslint-disable-next-line
          utm_medium,
          // eslint-disable-next-line
          utm_source,
          // eslint-disable-next-line
          utm_campaign,
          // eslint-disable-next-line
          utm_content,
          // eslint-disable-next-line
          utm_term,
          // eslint-disable-next-line
          //utm_url: window.location.href
        }),
      )
    } catch (error) {
      console.log(error)
    }
  }, [])

  if (app === 'electron') {
    return <Navigate to="/zen" />
  }

  return (
    <AppLayout>
      <Modal
        withCloseButton
        onClose={() => setIsAuthModalOpen(false)}
        isOpen={isAuthModalOpen}
        body={<MagicLinkForm onLoginSuccess={onLoginSuccess} />}
      />
      <Container>
        <div className={styles.hero}>
          <a href="https://muvon.io" target="_blank" className={styles.muvon} rel="noreferrer">
            <span>
              built by
              <svg width="126" height="40" viewBox="0 0 126 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="16.7757" cy="6.04188" r="6.04188" fill="#76B744" />
                <circle cx="37.5282" cy="31.2601" r="6.04188" fill="#76B744" />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M40.4889 14.7002C40.6847 18.0313 38.1431 20.8904 34.812 21.0862C33.2728 21.1767 31.6218 21.4473 30.5785 22.5826L25.4075 28.2087C24.2377 29.4814 24.1311 31.3703 24.1311 33.099C24.1311 36.4359 21.4261 39.1409 18.0892 39.1409C14.7524 39.1409 12.0473 36.4359 12.0473 33.099C12.0473 33.0689 12.0475 33.0387 12.048 33.0087C12.0696 31.5357 11.9005 29.9507 10.8815 28.8867C9.74425 27.6992 7.93379 27.5495 6.29084 27.615C6.01445 27.6261 5.73401 27.6182 5.45092 27.5904C2.13011 27.2638 -0.297169 24.3069 0.0294542 20.9861C0.356077 17.6653 3.31291 15.238 6.63373 15.5646C9.95454 15.8913 12.3818 18.8481 12.0552 22.1689C11.9519 23.2191 12.1129 24.3374 12.8427 25.0995L13.3778 25.6582C14.5465 26.8785 16.3995 27.0571 18.0892 27.0571C19.5836 27.0571 21.1971 26.8255 22.2084 25.7252L27.3209 20.1627C28.456 18.9277 28.5244 17.0838 28.426 15.4092C28.2302 12.0781 30.7718 9.21903 34.1029 9.02323C37.434 8.82742 40.2931 11.3691 40.4889 14.7002Z"
                  fill="#76B744"
                />
                <path
                  d="M65.6534 17.4566C65.3946 16.9132 64.8877 16.6414 64.1329 16.6414C63.2559 16.6414 62.6664 16.9132 62.3644 17.4566C62.1991 17.7661 62.1164 18.227 62.1164 18.8392V25.6079H59V14.3818H61.987V16.0224C62.368 15.4377 62.7275 15.0215 63.0654 14.7739C63.662 14.3336 64.4348 14.1135 65.3838 14.1135C66.2824 14.1135 67.0085 14.3027 67.562 14.681C68.0078 15.0318 68.3456 15.4824 68.5757 16.0327C68.9783 15.3723 69.4779 14.8874 70.0746 14.5778C70.7072 14.2683 71.4117 14.1135 72.1881 14.1135C72.7057 14.1135 73.2161 14.2098 73.7194 14.4024C74.2226 14.595 74.6791 14.9321 75.0889 15.4136C75.4196 15.8057 75.6424 16.2872 75.7574 16.8581C75.8293 17.2365 75.8653 17.7902 75.8653 18.5193L75.8437 25.6079H72.6949V18.4471C72.6949 18.0206 72.6231 17.6698 72.4793 17.3947C72.2061 16.8719 71.7029 16.6105 70.9696 16.6105C70.1213 16.6105 69.5354 16.9475 69.2119 17.6217C69.0466 17.9794 68.9639 18.4093 68.9639 18.9114V25.6079H65.869V18.9114C65.869 18.2442 65.7972 17.7592 65.6534 17.4566Z"
                  fill="var(--color-text)"
                />
                <path
                  d="M85.5764 24.0189C85.5476 24.0533 85.4757 24.1565 85.3607 24.3285C85.2457 24.5004 85.1091 24.6518 84.951 24.7825C84.4693 25.1952 84.002 25.4772 83.5491 25.6285C83.1034 25.7799 82.5786 25.8555 81.9747 25.8555C80.235 25.8555 79.0632 25.2571 78.4593 24.0602C78.1215 23.3998 77.9525 22.4265 77.9525 21.1402V14.3611H81.1013V21.1402C81.1013 21.7799 81.1804 22.2614 81.3385 22.5847C81.6189 23.1556 82.1688 23.4411 82.9884 23.4411C84.038 23.4411 84.7569 23.0353 85.1451 22.2236C85.3463 21.7833 85.447 21.2021 85.447 20.4798V14.3611H88.5634V25.6079H85.5764V24.0189Z"
                  fill="var(--color-text)"
                />
                <path
                  d="M89.7233 14.3611H93.174L95.6218 22.6569L98.1236 14.3611H101.423L97.1854 25.6079H93.9396L89.7233 14.3611Z"
                  fill="var(--color-text)"
                />
                <path
                  d="M112.31 15.785C113.302 16.9751 113.798 18.3818 113.798 20.0052C113.798 21.6561 113.302 23.0696 112.31 24.2459C111.318 25.4153 109.812 26 107.792 26C105.771 26 104.265 25.4153 103.273 24.2459C102.281 23.0696 101.785 21.6561 101.785 20.0052C101.785 18.3818 102.281 16.9751 103.273 15.785C104.265 14.595 105.771 14 107.792 14C109.812 14 111.318 14.595 112.31 15.785ZM107.781 16.4867C106.882 16.4867 106.188 16.7928 105.7 17.405C105.218 18.0103 104.977 18.877 104.977 20.0052C104.977 21.1333 105.218 22.0034 105.7 22.6156C106.188 23.2279 106.882 23.534 107.781 23.534C108.679 23.534 109.37 23.2279 109.851 22.6156C110.333 22.0034 110.574 21.1333 110.574 20.0052C110.574 18.877 110.333 18.0103 109.851 17.405C109.37 16.7928 108.679 16.4867 107.781 16.4867Z"
                  fill="var(--color-text)"
                />
                <path
                  d="M120.878 16.5383C119.835 16.5383 119.12 16.9613 118.732 17.8074C118.531 18.2545 118.43 18.8255 118.43 19.5202V25.6079H115.368V14.3818H118.333V16.0224C118.728 15.4445 119.102 15.0284 119.454 14.7739C120.087 14.3199 120.889 14.0929 121.859 14.0929C123.074 14.0929 124.066 14.399 124.835 15.0112C125.612 15.6165 126 16.6242 126 18.0344V25.6079H122.851V18.767C122.851 18.1754 122.769 17.7214 122.603 17.405C122.301 16.8272 121.726 16.5383 120.878 16.5383Z"
                  fill="var(--color-text)"
                />
              </svg>
            </span>
          </a>
          <div className={styles.launch}>
            <span>Released: Claude 3, Gemini Pro. Next: Mobile App</span>
          </div>

          <div className={styles.launch}>
            <a
              href="https://www.producthunt.com/posts/ai-marketplace-by-zentask-ai?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-ai&#0045;marketplace&#0045;by&#0045;zentask&#0045;ai"
              target="_blank"
              rel="noreferrer"
              style={{ display: 'flex' }}
            >
              <img
                src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=389893&theme=dark&period=daily"
                alt="AI&#0032;Marketplace&#0032;by&#0032;Zentask&#0046;ai - The&#0032;Spotify&#0032;of&#0032;AI&#0032;solutions&#0032;to&#0032;simplify&#0032;your&#0032;daily&#0032;tasks | Product Hunt"
                style={{ width: 200, height: 43 }}
                width="200"
                height="43"
              />
            </a>

            <a
              href="https://www.producthunt.com/posts/ai-marketplace-by-zentask-ai?utm_source=badge-top-post-topic-badge&utm_medium=badge&utm_souce=badge-ai&#0045;marketplace&#0045;by&#0045;zentask&#0045;ai"
              target="_blank"
              rel="noreferrer"
              style={{ display: 'flex' }}
            >
              <img
                src="https://api.producthunt.com/widgets/embed-image/v1/top-post-topic-badge.svg?post_id=389893&theme=dark&period=weekly&topic_id=237"
                alt="AI&#0032;Marketplace&#0032;by&#0032;Zentask&#0046;ai - The&#0032;Spotify&#0032;of&#0032;AI&#0032;solutions&#0032;to&#0032;simplify&#0032;your&#0032;daily&#0032;tasks | Product Hunt"
                style={{ width: 200, height: 43 }}
                width="200"
                height="43"
              />
            </a>
          </div>
          {/* <h1>
            Simplify your daily Tasks
            <br />
            with
            <span> AI Assistance</span>
          </h1> */}
          {/* <h1>
            Save Time, Money
            <br />
            & 10X Productivity with
            <br />
            <span>AI-Powered Solutions</span>
          </h1> */}
          <h1>
            Create Articles & Images
            <br />
            for Blogs and Businesses
            <br />
            <span>in 1-Click with AI</span>
          </h1>
          <p>
            Create unique textual content and visuals, saving time and energy every day.
            <br />
            &nbsp;Designed for professionals in various fields. Get started today!
          </p>

          {currentUser && (
            <div className={styles.form}>
              <Link to="/marketplace">
                <Button type="button" size={ButtonSizes.xl} label="Discover prompts" />
              </Link>
            </div>
          )}

          {!currentUser && (
            <div className={styles.form}>
              <Button
                type="button"
                onClick={() => setIsAuthModalOpen(true)}
                size={ButtonSizes.xl}
                label="Get started for free"
              />
            </div>
          )}
          {!currentUser && <div className={styles.bonus}>No credit card required</div>}

          <div className={styles.others}>
            <div className={styles.stackedAvatars}>
              <img
                src="https://ph-avatars.imgix.net/3259959/469bf616-bc06-424f-86d6-810c0a18e635?auto=compress&codec=mozjpeg&cs=strip&auto=format&w=36&h=36&fit=crop&dpr=2"
                loading="lazy"
                width="36"
                height="36"
                alt="Social proof users"
              />
              <img
                src="https://ph-avatars.imgix.net/581391/23b58d4d-ab80-4d01-94b4-62c2b97859a8?auto=compress&codec=mozjpeg&cs=strip&auto=format&w=36&h=36&fit=crop&dpr=2"
                loading="lazy"
                width="36"
                height="36"
                alt="Social proof users"
              />
              <img
                src="https://ph-avatars.imgix.net/5136189/ff275044-b287-4562-a447-ceffc0333186?auto=compress&codec=mozjpeg&cs=strip&auto=format&w=36&h=36&fit=crop&dpr=2"
                loading="lazy"
                width="36"
                height="36"
                alt="Social proof users"
              />
              <img
                src="https://ph-avatars.imgix.net/5415947/original?auto=compress&codec=mozjpeg&cs=strip&auto=format&w=36&h=36&fit=crop&dpr=2"
                loading="lazy"
                width="36"
                height="36"
                alt="Social proof users"
              />
              <img
                src="https://ph-avatars.imgix.net/4955004/4d8950c7-10b4-4a14-ba54-efd9be352b38?auto=compress&codec=mozjpeg&cs=strip&auto=format&w=36&h=36&fit=crop&dpr=2"
                loading="lazy"
                width="36"
                height="36"
                alt="Social proof users"
              />
            </div>

            <p>Join 80k+ others</p>
          </div>

          <div className={styles.download}>
            <span>Desktop Apps On-The-Go</span>
            <div className={styles.downloadList}>
              <a
                href="https://www.dropbox.com/scl/fi/xsanrp91207922pl1h5th/Zentask-Setup-1.0.1.exe?rlkey=qk9kjtqc504i6nf4618n15buu&dl=1"
                target="_blank"
                className={styles.downloadItem}
                onClick={() => firePlausibleEvent(PLAUSIBLE_EVENTS.CLICK_DOWNLOAD_WINDOWS)}
                rel="noreferrer"
              >
                <img
                  src="https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/7ef33e53-7184-41c7-261c-27c5fc922d00/small"
                  alt="Download Zentask.ai desktop app for Windows"
                />
              </a>
              <a
                href="https://www.dropbox.com/scl/fi/34225cil8o5smjgzqupyi/Zentask-1.0.1-amd64.dmg?rlkey=b7f1zxfyuhxyvrpp25rhgh2wk&dl=1"
                target="_blank"
                onClick={() => firePlausibleEvent(PLAUSIBLE_EVENTS.CLICK_DOWNLOAD_X64)}
                className={styles.downloadItem}
                rel="noreferrer"
              >
                <img
                  src="https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/b8f5739a-73fc-4a69-1304-2c5b0ef7e100/small"
                  alt="Download Zentask.ai desktop app for Mac OS x64"
                />
              </a>
              <a
                href="https://www.dropbox.com/scl/fi/sdcs6fnoldfkjrs2oigft/Zentask-1.0.1-arm64.dmg?rlkey=cuwd1dc02rocuisfvio6exjen&dl=1"
                target="_blank"
                onClick={() => firePlausibleEvent(PLAUSIBLE_EVENTS.CLICK_DOWNLOAD_ARM)}
                className={styles.downloadItem}
                rel="noreferrer"
              >
                <img
                  src="https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/90d2e21a-2517-4a0c-b941-c4eed415be00/small"
                  alt="Download Zentask.ai desktop app for Mac OS ARM"
                />
              </a>
            </div>
          </div>
        </div>

        <div className={styles.video}>
          {/* eslint-disable-next-line */}
          <video controls loop>
            <source src={VideoSrc} type="video/webm" />
            Your browser does not support the video tag.
          </video>
        </div>

        <section className={styles.students}>
          <p>Join companies and teams that are using Zentask:</p>
          <div className={styles.list}>
            <img
              src="https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/b74b232a-ec39-45d7-4a2c-fa0716df6e00/large"
              alt="companies using zentask"
              loading="lazy"
              width="1160"
              height="140"
            />
          </div>
        </section>

        <section id="prompts" className={styles.advantages}>
          <h2>
            The All-In-One
            <br />
            AI Solution Platform
          </h2>
          <p>
            Develop unique content by employing diverse AI resources for composing, investigating, evaluating, producing
            visuals, and beyond.
          </p>

          <div className={styles.newFeaturesList}>
            <div className={styles.newFeatureItem}>
              <div className={styles.newFeatureItemContent}>
                <h3>Write articles and marketing copies 10X faster</h3>
                <p>
                  Develop unique and SEO-friendly content for your blogs, advertisements, emails, and website to ensure
                  it is free from plagiarism.
                </p>
                <div className={styles.newFeatureItemContentAction}>
                  <Link to="/marketplace/task/63588371699597313">
                    <Button iconColor="#fff" icon={IconNames.arrowRight} label="Try now" type="button" />
                  </Link>
                </div>
              </div>
              <div className={styles.newFeatureItemImage}>
                <img
                  src="https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/ee1606c9-d3f6-4d01-4521-984013cf8400/medium"
                  alt="Write articles and marketing copies 10X faster"
                  loading="lazy"
                />
              </div>
            </div>

            <div className={styles.newFeatureItem}>
              <div className={styles.newFeatureItemContent}>
                <h3>Best Alternative to ChatGPT</h3>
                <p>
                  Zentask boasts a better UI, a prebuilt collection of prompts, supports various image AI models, and
                  offers customizable settings, such as language and voice tone. It provides the ability to create and
                  organize your prompt templates.
                </p>
                <div className={styles.newFeatureItemContentAction}>
                  <Link to="/zen">
                    <Button iconColor="#fff" icon={IconNames.arrowRight} label="Try now" type="button" />
                  </Link>
                </div>
              </div>
              <div className={styles.newFeatureItemImage}>
                <img
                  src="https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/cd14546a-1ba0-437a-62d5-3f41e33b7400/medium"
                  alt="Best Alternative to ChatGPT"
                  loading="lazy"
                />
              </div>
            </div>

            <div className={styles.newFeatureItem}>
              <div className={styles.newFeatureItemContent}>
                <h3>Boost Your Site&apos;s Success with SEO Magic</h3>
                <p>
                  Unleash the full potential of your website with our easy-to-use tool. Improve SEO, craft engaging
                  articles, generate metatags, and refine your keywords for maximum online visibility.
                </p>
                <div className={styles.newFeatureItemContentAction}>
                  <Link to="/marketplace/task/18259766736322561">
                    <Button iconColor="#fff" icon={IconNames.arrowRight} label="Try now" type="button" />
                  </Link>
                </div>
              </div>
              <div className={styles.newFeatureItemImage}>
                <img
                  src="https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/7a8920cb-4425-4cfd-1494-bacb14ab9200/medium"
                  alt="Boost Your Site's Success with SEO Magic"
                  loading="lazy"
                />
              </div>
            </div>

            <div className={styles.newFeatureItem}>
              <div className={styles.newFeatureItemContent}>
                <h3>Generate Images from Text</h3>
                <p>
                  Empower your promo, articles, and marketing campaigns using the power of Stable Diffusion, Dall-E 2.
                  Transform ideas into stunning visuals!
                </p>
                <div className={styles.newFeatureItemContentAction}>
                  <Link to="/text-to-image">
                    <Button iconColor="#ff" icon={IconNames.arrowRight} label="Try now" type="button" />
                  </Link>
                </div>
              </div>
              <div className={styles.newFeatureItemImage}>
                <img
                  src="https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/08796626-a355-4381-23e4-b12bf9380800/medium"
                  alt="Generate Images from Text"
                  loading="lazy"
                />
              </div>
            </div>

            <div className={styles.newFeatureItem}>
              <div className={styles.newFeatureItemContent}>
                <h3>Various settings output</h3>
                <p>
                  We offer support for various settings, such as language selection, writing style, voice tone, and AI
                  model.
                </p>
                <div className={styles.newFeatureItemContentAction}>
                  <Link to="/marketplace/task/1">
                    <Button iconColor="#fff" icon={IconNames.arrowRight} label="Try now" type="button" />
                  </Link>
                </div>
              </div>
              <div className={styles.newFeatureItemImage}>
                <img
                  src="https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/6c5d8ae3-fa9d-4918-ce8e-9a2e22bdf400/medium"
                  alt="Various settings output"
                  loading="lazy"
                />
              </div>
            </div>
          </div>

          <div className={styles.featuresList}>
            <div className={styles.flexRow}>
              <div className={styles.featureItem}>
                <div className={styles.featureItemImage}>
                  <img
                    src="https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/acc78da4-9513-4f39-aab8-938fdb04f900/medium"
                    alt="Zenspaces"
                    width="568"
                    height="368"
                    loading="lazy"
                  />
                </div>
                <div className={styles.featureItemText}>
                  <h3>Simplify your workload with ZenSpaces</h3>
                  <p>
                    Streamline your daily routine with ZenSpaces! Our exceptional tool helps you create, categorize, and
                    organize tasks effortlessly, saving you valuable time.
                  </p>
                </div>
              </div>

              <div className={styles.featureItem}>
                <div className={styles.featureItemImage}>
                  <img
                    src="https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/ae95a7a5-3cb8-4309-6a17-dc6abd022a00/medium"
                    alt="Create your own templates"
                    width="568"
                    height="368"
                    loading="lazy"
                  />
                </div>
                <div className={styles.featureItemText}>
                  <h3>Create your own custom instructions</h3>
                  <p>
                    Do you have a large collection of prompts? Easily create and organize them on Zentask. Make them
                    private or available for public use.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="trending">
          <div className={styles.trend}>
            <h3>
              Trending on&nbsp;
              <Link to="/marketplace">marketplace</Link>
              🚀
            </h3>
            <p>
              Discover the latest and most popular prompts used for boosting Creativity, Productivity, and Innovation.
            </p>

            <div className={styles.trendList}>
              {isLoading && <div className={styles.loading}>Loading...</div>}

              {(trendingTasks || []).slice(0, 12).map((item) => (
                <SingleTask isAuth={!!currentUser} key={item.id.toString()} task={item} />
              ))}
            </div>
          </div>
        </section>

        <section className={styles.cost}>
          <Prices />
        </section>

        <section className={styles.reviews}>
          <h2>Join our empowered community</h2>
          <p>Zentask has helped thousands of people boost their productivity and improve their quality of life.</p>

          <div className={styles.reviewsList}>
            <div className={styles.reviewItem}>
              <p>
                I&apos;m absolutely thrilled to see this AI marketplace! The concept of creating prompts and earning
                commission on each use is truly innovative. It&apos;s exciting to see a platform that brings the power
                of AI to engineers in such a seamless and user-friendly way.
              </p>
              <div className={styles.reviewItemAuthor}>
                <span>
                  <Avatar
                    size={AVATAR_SIZES.sm}
                    alt="Odin Enes Ozlen"
                    url="https://ph-avatars.imgix.net/3921679/6c4eddf2-2585-4357-b8a7-5e5b078c443f?auto=compress&codec=mozjpeg&cs=strip&auto=format&w=40&h=40&fit=crop&dpr=2"
                  />
                  Odin Enes Ozlen
                </span>
                <span>at Product Hunt</span>
              </div>
            </div>

            <div className={styles.reviewItem}>
              <p>
                Hi, Nice product guys! Loved how it saves time by providing ready to use use-cases. Also loved the way
                website was designed, keeping in mind easability as the prominent factor.
              </p>
              <div className={styles.reviewItemAuthor}>
                <span>
                  <Avatar
                    alt="Jatin Keni"
                    size={AVATAR_SIZES.sm}
                    url="https://ph-avatars.imgix.net/5412406/original?auto=compress&codec=mozjpeg&cs=strip&auto=format&w=40&h=40&fit=crop&dpr=2"
                  />
                  Jatin Keni
                </span>
                <span>at Product Hunt</span>
              </div>
            </div>

            <div className={styles.reviewItem}>
              <p>
                Zentask AI has become my go-to task management tool. It&apos;s powerful features and intuitive interface
                make managing tasks a breeze. Give it a try and see the difference it can make in your productivity!
              </p>
              <div className={styles.reviewItemAuthor}>
                <span>
                  <Avatar
                    alt="GS"
                    size={AVATAR_SIZES.sm}
                    url="https://pbs.twimg.com/profile_images/1664876161724297216/6Gqj0uaN_x96.jpg"
                  />
                  GS
                </span>
                <span>at Twitter</span>
              </div>
            </div>

            <div className={styles.reviewItem}>
              <p>
                Amazing Love it how this my saves time by providing ready to use use-cases. Also loved the way website
                was designed, keeping in mind easability as the prominent factor.
              </p>
              <div className={styles.reviewItemAuthor}>
                <span>
                  <Avatar
                    alt="William Miranda"
                    size={AVATAR_SIZES.sm}
                    url="https://ph-avatars.imgix.net/5068604/61bd412a-1fda-4a8d-b701-e54dc3448c47?auto=compress&codec=mozjpeg&cs=strip&auto=format&w=40&h=40&fit=crop&dpr=2"
                  />
                  William Miranda
                </span>
                <span>at Product Hunt</span>
              </div>
            </div>

            <div className={styles.reviewItem}>
              <p>
                Great product! It brings together so many AI-related services on one site. Why didn&apos;t someone think
                of this before? Can&apos;t wait to see what you&apos;ll do next
              </p>
              <div className={styles.reviewItemAuthor}>
                <span>
                  <Avatar
                    alt="John Toomey"
                    size={AVATAR_SIZES.sm}
                    url="https://ph-avatars.imgix.net/5140792/original?auto=compress&codec=mozjpeg&cs=strip&auto=format&w=40&h=40&fit=crop&dpr=2"
                  />
                  John Toomey
                </span>
                <span>at Product Hunt</span>
              </div>
            </div>

            <div className={styles.reviewItem}>
              <p>Amazing project I am really excited for this</p>
              <div className={styles.reviewItemAuthor}>
                <span>
                  <Avatar
                    alt="Melissa McCarthy"
                    size={AVATAR_SIZES.sm}
                    url="https://ph-avatars.imgix.net/5079278/dc90fb9e-c987-4396-9a65-b8737518e62b?auto=compress&codec=mozjpeg&cs=strip&auto=format&w=40&h=40&fit=crop&dpr=2"
                  />
                  Melissa McCarthy
                </span>
                <span>at Product Hunt</span>
              </div>
            </div>
          </div>
        </section>

        <section id="faq" className={styles.faq}>
          <h2>Frequently Asked Questions (FAQs)</h2>
          <p>
            Find answers to your questions about using Zentask, from prompt creation to marketplace publishing and
            earnings.
          </p>

          <div className={styles.faqList}>
            <div className={styles.faqItem}>
              <span>What is Zentask, and how can it help me with my daily tasks?</span>
              <p>
                Zentask is a platform that offers easy-to-use AI solutions to simplify your work and daily routines. By
                using prepared prompts created by professionals, you can generate content, optimize processes, and
                accomplish a wide range of tasks with the help of AI.
              </p>
            </div>

            <div className={styles.faqItem}>
              <span>How can I become a prompt engineer and create prompts for Zentask?</span>
              <p>
                To become a prompt engineer, simply register on the Zentask platform. Once registered, you can create
                prompts designed to solve popular tasks or challenges using AI. You can then publish your prompts to the
                marketplace catalog for others to use.
              </p>
            </div>

            <div className={styles.faqItem}>
              <span>How do prompt engineers earn money on Zentask?</span>
              <p>
                Prompt engineers earn money each time a visitor uses one of their prompts to solve a task. For each
                usage, prompt engineers receive 1 cent per credit spent by the visitor.
              </p>
            </div>

            <div className={styles.faqItem}>
              <span>How can I use Zentask prompts without revealing the underlying code?</span>
              <p>
                Zentask ensures the security and uniqueness of each prompt. Visitors can use the prompts without seeing
                the actual code, as they only need to fill in the required parameters and enjoy the results.
              </p>
            </div>

            <div className={styles.faqItem}>
              <span>What is the cost of using Zentask, and what features are available?</span>
              <p>
                Zentask is now free to use, allowing users to access any available prompt with some usage limitations
                per time. By default, users can access The Average quality AI model for their tasks. However, if they
                want to take advantage of the ZenSpaces feature or use the top-notch quality AI model, they need to
                subscribe to a PRO account.
              </p>
            </div>

            <div className={styles.faqItem}>
              <span>Can Zentask help me create SEO-optimized articles, recipes, training plans, and more?</span>
              <p>
                Yes, Zentask&#39;s AI-powered prompts can help you with various tasks, such as creating SEO-optimized
                articles, generating recipes, designing personalized training plans, and much more. Simply choose the
                desired task, provide the required inputs, and enjoy the results.
              </p>
            </div>

            <div className={styles.faqItem}>
              <span>Can I cancel my subscription plan?</span>
              <p>Of course! Cancel at any time by clicking the manage plan button in settings.</p>
            </div>

            <div className={styles.faqItem}>
              <span>What&apos;s the difference between GPT-4 and GPT-3.5</span>
              <p>
                GPT-4 is much better at coding. GPT-4 will output significantly more correct explanations, answers, and
                code than GPT-3.5.
              </p>
            </div>
          </div>
        </section>

        {!currentUser && (
          <section className={styles.try}>
            <h3>Try Zentask today</h3>
            <p>Say goodbye to chaos and hello to a harmonious work-life balance 🚀</p>

            <Button size={ButtonSizes.xl} onClick={() => setIsAuthModalOpen(true)} type="button" label="Try it now" />
          </section>
        )}
      </Container>
      <Footer />
    </AppLayout>
  )
}
