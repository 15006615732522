import { useMatch } from '@tanstack/react-location'
import { Helmet } from 'react-helmet'
import { Stream } from 'src/components/Stream'
import { StreamHistory } from 'src/components/StreamHistory'
import { useGetCurrentUser } from 'src/modules/currentUser/query'
import styles from './index.module.scss'
import { useGetSpaceDetails } from './query'
import { SpacePreview } from './SpacePreview'

export function SelectedSpacePage() {
  const {
    params: { spaceId, taskId, historyId },
  } = useMatch()

  const { data: currentUser } = useGetCurrentUser()

  const { data: spaceDetails } = useGetSpaceDetails(spaceId.toString())

  const streamComponent = historyId ? <StreamHistory historyId={historyId} /> : <Stream taskId={taskId} />

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>{`${spaceDetails?.title} Space | Zentask.ai`}</title>
        <meta name="description" content={spaceDetails?.description} />
        <meta property="og:title" content={`${spaceDetails?.description} Space | Zentask.ai`} />
      </Helmet>
      {taskId ? streamComponent : <SpacePreview currentUser={currentUser} spaceDetails={spaceDetails} />}
    </div>
  )
}
