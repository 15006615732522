import { config } from 'src/config/config'
import { apiCall } from 'src/utils/networking'

export const api = {
  getTransactionsList: async ({ type, page = 1 }) =>
    apiCall(`${config.baseAPIUrl}/transaction/list?page=${page}&type=${type.toLowerCase()}`, {
      method: 'POST',
      // body: JSON.stringify({
      //   type: [type.toLowerCase()],
      //   page,
      // }),
    }),

  getRevenueList: async ({ page = 1 }) =>
    apiCall(`${config.baseAPIUrl}/revenue/list?page=${page}`, {
      method: 'POST',
      // body: JSON.stringify({
      //   offset,
      // }),
    }),

  getRewardsList: async ({ page = 1 }) =>
    apiCall(`${config.baseAPIUrl}/reward/list?page=${page}`, {
      method: 'POST',
      // body: JSON.stringify({
      //   offset,
      // }),
    }),
}
