import { useMatch } from '@tanstack/react-location'
import { useEffect, useState } from 'react'
import { Button } from 'src/core/ds/button'
import { Checkbox } from 'src/core/ds/checkbox'
import { Input } from 'src/core/ds/input'
import { Textarea } from 'src/core/ds/textarea'
import { useGetSpaceDetails } from 'src/pages/SelectedSpacePage/query'
import { pushNotification } from 'src/services/notifications/notificationService'
import { useUIStore } from 'src/services/store/UIStore'
import { useCreateNewSpace } from '../createSpaceModal/query'
import styles from './index.module.scss'

export function EditSpaceModal() {
  const {
    params: { teamId },
  } = useMatch()
  const [spaceName, setSpaceName] = useState('')
  const [spaceDescription, setSpaceDescription] = useState('')
  const updateUIStore = useUIStore((state) => state.updateUIStore)
  const editSpaceBuffer = useUIStore((state) => state.editSpaceBuffer)
  const [isPrivate, setIsPrivate] = useState(false)
  const [errors, setErrors] = useState<any>({})
  const onSuccess = () => {
    updateUIStore('isEditSpaceModalOpen', false)
    updateUIStore('editSpaceBuffer', null)
    pushNotification({
      type: 'success',
      title: 'Space edited.',
    })
  }

  const { mutate, isLoading } = useCreateNewSpace(onSuccess)
  const { data: spaceDetails } = useGetSpaceDetails(editSpaceBuffer?.toString())

  // useEffect(() => {
  //   console.log('test')

  //   return () => updateUIStore('editSpaceBuffer', null)
  // })

  useEffect(() => {
    if (!spaceDetails) return undefined

    setSpaceName(spaceDetails?.title)
    setSpaceDescription(spaceDetails?.description)
    setIsPrivate(spaceDetails?.status === 'public')

    return undefined
  }, [spaceDetails])

  const validateForm = () => {
    setErrors({})
    const errors: any = {}

    if (spaceName.length < 11 || spaceName.length > 60) {
      errors.title = 'Required'
    }

    if (spaceDescription.length < 31 || spaceDescription.length > 155) {
      errors.description = 'Required'
    }

    setErrors(errors)

    return Object.keys(errors).length === 0
  }

  const onSubmit = (e) => {
    e.preventDefault()
    const isValid = validateForm()

    if (!isValid) {
      return undefined
    }

    return mutate({
      DTO: {
        title: spaceName,
        description: spaceDescription,
        status: !teamId && isPrivate ? 'public' : 'private',
        id: spaceDetails.id.toString(),
      },
    })
  }

  return (
    <div className={styles.wrapper}>
      <form onSubmit={onSubmit}>
        <div className={styles.formControl}>
          <Input
            value={spaceName}
            error={errors?.title}
            onChange={(e) => setSpaceName(e.target.value)}
            isRequired
            label="Space name"
          />
          <div className={styles.details}>
            <div style={{ marginRight: 'auto' }}>(min: 12)&nbsp;</div>
            {`${spaceName.length} / ${60}`}
          </div>
        </div>

        <div className={styles.formControl}>
          <Textarea
            value={spaceDescription}
            onChange={(e) => setSpaceDescription(e.target.value)}
            isRequired
            error={errors?.description}
            label="Description"
            rows={3}
          />
          <div className={styles.details}>
            <div style={{ marginRight: 'auto' }}>(min: 32)&nbsp;</div>
            {`${spaceDescription.length} / ${155}`}
          </div>
        </div>

        <div className={styles.action}>
          {!teamId && (
            <Checkbox
              label="Make public"
              id="isPublic"
              checked={isPrivate}
              onChange={(e) => setIsPrivate(e.target.checked)}
            />
          )}
          <Button isLoading={isLoading} disabled={isLoading} type="submit" label="Edit" />
        </div>
      </form>
    </div>
  )
}
