import { Link } from '@tanstack/react-location'
import { Avatar } from 'src/core/ds/avatar'
import { AVATAR_SIZES } from 'src/core/ds/avatar/consts'
import { Button } from 'src/core/ds/button'
import { ButtonVariants } from 'src/core/ds/button/types'
import { Dropdown } from 'src/core/ds/dropdown'
import { IconNames } from 'src/core/ds/icon'
import styles from './index.module.scss'

export function SpaceCard({ onRemove, showControls = false, space, onEdit }: any) {
  const onEditHandle = () => {
    onEdit(space?.id.toString())
  }

  const onRemoveHandle = () => {
    onRemove(space?.id.toString())
  }

  return (
    <Link to={`/spaces/${space.id.toString()}`} className={styles.card}>
      <div className={styles.header}>
        <h2>{space.title}</h2>
        {showControls && (
          // eslint-disable-next-line
          <div className={styles.headerActions}>
            <Dropdown
              righted
              visibleContent={
                // eslint-disable-next-line
                <Button
                  type="button"
                  tooltip="Menu"
                  icon={IconNames.theeDots}
                  iconColor="var(--color-text)"
                  variant={ButtonVariants.unstyled}
                />
              }
              hiddenContent={
                // eslint-disable-next-line
                <div className={styles.hiddenMenu}>
                  <p>Actions:</p>
                  <ul className={styles.hidden}>
                    <li>
                      <Button
                        icon={IconNames.edit}
                        iconColor="var(--color-secondary)"
                        isIconOnLeft
                        type="button"
                        onClick={onEditHandle}
                        label="Edit"
                        variant={ButtonVariants.unstyled}
                      />
                    </li>
                    <li>
                      <Button
                        icon={IconNames.delete}
                        iconColor="var(--color-red)"
                        isIconOnLeft
                        type="button"
                        onClick={onRemoveHandle}
                        label="Remove"
                        variant={ButtonVariants.unstyled}
                      />
                    </li>
                  </ul>
                </div>
              }
            />
          </div>
        )}
      </div>
      <div className={styles.details}>
        {!space.account && (
          <div className={styles.author}>
            Your&nbsp;
            <span className={space.status === 'public' ? styles.public : styles.private}>{space.status}</span>
            &nbsp;space
          </div>
        )}
        {space.account && (
          <div className={styles.author}>
            <Avatar size={AVATAR_SIZES.xs} url={space?.account?.avatar_url} />
            <div className={styles.avatarUsername}>{space?.account?.full_name || space?.account?.username}</div>
          </div>
        )}
        <div className={styles.tasksCount}>
          {space?.task_count}
          &nbsp;tasks
        </div>
      </div>
      <p>{space.description}</p>
    </Link>
  )
}
