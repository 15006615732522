import { Icon, IconNames, IconSize } from '../icon'
import styles from './index.module.scss'

export type Props = {
  options: any[]
  selected: any
  noBorder?: boolean
  label?: string
  onChange?: any
  name?: string
  isRequired?: boolean
  description?: string
  error?: string
  disabled?: boolean
  hasDefault?: boolean
}

export function Selector({
  label,
  description,
  options,
  selected,
  noBorder,
  onChange,
  name,
  isRequired,
  hasDefault = true,
  disabled = false,
  error,
}: Props) {
  return (
    <div className={styles.container}>
      {label && (
        <label htmlFor="selector-id" className={styles.label}>
          {label}
          {isRequired && '*'}
          {error && <Icon size={IconSize.xs} name={IconNames.alertFill} color="var(--color-red)" />}
        </label>
      )}
      <span className={styles.icon}>
        <Icon color="var(--color-grey)" size={IconSize.xs} name={IconNames.shevronDown} />
      </span>
      {description && <div className={styles.description}>{description}</div>}
      <select
        value={selected}
        onChange={onChange}
        name={name}
        disabled={disabled}
        id="selector-id"
        className={`${styles.select} ${error ? styles.error : ''} ${noBorder ? styles.noBorder : ''}`}
      >
        {hasDefault && <option value="">Choose:</option>}
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  )
}
