import { Icon, IconNames } from 'src/core/ds/icon'
import { Input } from 'src/core/ds/input'
import { pushNotification } from 'src/services/notifications/notificationService'
import { PLAUSIBLE_EVENTS } from 'src/services/plausible/consts'
import { firePlausibleEvent } from 'src/services/plausible/plausibleService'
import { useGetCurrentUser } from '../currentUser/query'
import { copyToClipboard } from '../StreamForm/utils'
import styles from './index.module.scss'

export function ReferalModal() {
  const { data: currentUser } = useGetCurrentUser()
  const link = `https://zentask.ai?ref_id=${currentUser?.id.toString()}`

  const onReferalCopy = () => {
    firePlausibleEvent(PLAUSIBLE_EVENTS.COPY_RESPONSE)
    copyToClipboard(
      link,
      pushNotification({
        type: 'success',
        title: 'Copied to clipboard',
      }),
    )
  }

  return (
    <div className={styles.wrapper}>
      <Input
        label="Your referral link"
        action={
          // eslint-disable-next-line
          <button className={styles.action} onClick={onReferalCopy} type="button">
            <Icon name={IconNames.copy} />
          </button>
        }
        value={link}
      />

      <div className={styles.description}>
        You can find your referral earnings by checking &#34;Rewards&#34; tab at &#34;My Rewards&#34; popup.
      </div>

      <div className={styles.description}>How much affiliate will get for user per 1 run: ~$0.002</div>
    </div>
  )
}
