/* eslint-disable react/jsx-one-expression-per-line */
import { Link, useNavigate } from '@tanstack/react-location'
import { useState } from 'react'
import ProIcon from 'src/assets/images/icon-pro.svg'
import { Avatar } from 'src/core/ds/avatar'
import { Button } from 'src/core/ds/button'
import { ButtonSizes, ButtonVariants } from 'src/core/ds/button/types'
import { Dropdown } from 'src/core/ds/dropdown'
import { Icon, IconNames, IconSize } from 'src/core/ds/icon'
import { Modal } from 'src/core/ds/modal'
import { useGetCurrentUser, useLogoutUser } from 'src/modules/currentUser/query'
import { MagicLinkForm } from 'src/modules/magicLink/components/Form'
import { MySpendingsModal } from 'src/modules/mySpendings'
import { UpdateProfileModal } from 'src/modules/updateProfileForm'
import { pushNotification } from 'src/services/notifications/notificationService'
import { PLAUSIBLE_EVENTS } from 'src/services/plausible/consts'
import { firePlausibleEvent } from 'src/services/plausible/plausibleService'
import { useUIStore } from 'src/services/store/UIStore'
import { DateFormats, formatDate } from 'src/utils/dates'
import { ThemeSwitcher } from '../ThemeSwitcher'
import { api } from './api'
import styles from './index.module.scss'

export function DashboardUserArea({ isZen = false }) {
  const navigate = useNavigate()
  const { data: currentUser } = useGetCurrentUser()
  const { isLoading: isLoadingLogout, mutate } = useLogoutUser()
  const updateUIStore = useUIStore((state) => state.updateUIStore)
  const isPROAccount = useUIStore((state) => state.isPROAccount)
  const [isUpdateProfileModalOpen, setIsUpdateProfileModalOpen] = useState(false)
  const [isSpendingsModalOpen, setIsSpendingsModalOpen] = useState(false)

  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false)

  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false)

  const onLoginSuccess = () => {
    pushNotification({
      type: 'success',
      title: 'Success Login',
      subtitle: 'You will be redirected.',
    })
    setIsAuthModalOpen(false)
    // eslint-disable-next-line
    // @ts-ignore
    navigate({ to: window.location.pathname })
  }

  const openAuthModal = () => {
    setIsAuthModalOpen(true)
  }

  const calcLeftRequests = (max, curr) => {
    const requests = max - curr

    if (requests < 0) return 0

    return requests
  }

  const onLogoutHandler = async () => {
    // eslint-disable-next-line
    await mutate()
  }

  // if (!isLoading && !currentUser) {
  //   return <Navigate to="/" />
  // }

  const onManagePlan = async () => {
    try {
      // console.log('onManagePlan')
      const response = await api.getSubscriptionPlanLink()
      const [error, link] = response as any
      // console.log('link', link)

      if (error === 'e_customer_not_found') {
        pushNotification({
          type: 'error',
          title: 'Error',
          subtitle: 'No user found',
        })

        return
      }

      setTimeout(() => {
        // eslint-disable-next-line
        // @ts-ignore
        window?.open(link, '_blank').focus()
      })

      // console.log('response', response)
    } catch (err) {
      console.log(err)
      pushNotification({
        type: 'error',
        title: 'Error',
        subtitle: "Can't get subscription plan link",
      })
    }
  }

  return (
    <div className={styles.navigation}>
      <div className={styles.themeSwitch}>
        <div className={styles.left}>
          <ThemeSwitcher />
        </div>
        {/* <div className={styles.left}>
          <a target="_blank" title="hello@zentask.ai" href="mailto:hello@zentask.ai" rel="noreferrer">
            <Icon size={IconSize.s} name={IconNames.email} color="var(--color-secondary)" />
          </a>
          <a target="_blank" title="Zentask.ai twitter" href="https://twitter.com/zentaskai" rel="noreferrer">
            <Icon size={IconSize.s} name={IconNames.twitter} color="var(--color-secondary)" />
          </a>
        </div> */}
      </div>

      <div className={styles.userArea}>
        <Modal
          withCloseButton
          type="error"
          onDeny={() => setIsLogoutModalOpen(false)}
          onConfirm={onLogoutHandler}
          onClose={() => setIsLogoutModalOpen(false)}
          isOpen={isLogoutModalOpen}
          body={
            // eslint-disable-next-line
            <div className={styles.logoutModal}>
              <h1>Are you sure you want to logout?</h1>
            </div>
          }
        />

        <Modal
          withCloseButton
          onClose={() => setIsUpdateProfileModalOpen(false)}
          isOpen={isUpdateProfileModalOpen}
          body={<UpdateProfileModal currentUser={currentUser} />}
        />
        <Modal
          withCloseButton
          onClose={() => setIsSpendingsModalOpen(false)}
          isOpen={isSpendingsModalOpen}
          body={<MySpendingsModal />}
        />

        {!currentUser && (
          <div className={styles.mobileAction}>
            <Modal
              withCloseButton
              onClose={() => setIsAuthModalOpen(false)}
              isOpen={isAuthModalOpen}
              body={<MagicLinkForm onLoginSuccess={onLoginSuccess} />}
            />
            <div className={styles.mobileActionButtons}>
              <Button
                onClick={openAuthModal}
                size={ButtonSizes.xs}
                variant={ButtonVariants.secondary}
                type="button"
                label="Sign in"
              />
              <Button onClick={openAuthModal} size={ButtonSizes.xs} type="button" label="Sign up" />
            </div>
          </div>
        )}
        {currentUser && (
          <Dropdown
            visibleContent={
              // eslint-disable-next-line
              <div className={styles.userMenus} onClick={() => firePlausibleEvent(PLAUSIBLE_EVENTS.CLICK_OPEN_PROFILE)}>
                {!isZen && (
                  <div className={styles.icon}>
                    <Icon color="var(--color-secondary)" name={IconNames.theeDots} size={IconSize.xs} />
                  </div>
                )}
                <Avatar url={currentUser?.avatar_url} />
                {isZen && isPROAccount && (
                  <div className={styles.floatingCreds}>
                    <img src={ProIcon} alt="" />
                  </div>
                )}
                {!isZen && (
                  <div className={styles.userContent}>
                    <div className={styles.userName}>{currentUser?.full_name || currentUser?.username}</div>
                    {isPROAccount && (
                      <div className={styles.creds}>
                        <img src={ProIcon} alt="" />
                      </div>
                    )}
                  </div>
                )}
              </div>
            }
            hiddenContent={
              // eslint-disable-next-line
              <div className={styles.userMenu}>
                <div className={styles.userSummary}>
                  <div className={styles.userContent}>
                    <div className={styles.email}>{currentUser?.email}</div>
                    <div className={styles.limits}>
                      <span>Your limits:</span>
                      <div className={styles.limit}>
                        Text requests:&nbsp;
                        <b>
                          {calcLeftRequests(currentUser?.limits.max_text_usages, currentUser?.limits.text_usages)} /{' '}
                          {currentUser?.limits.max_text_usages}
                          {isPROAccount ? ' /3h' : null}
                        </b>
                      </div>
                      <div className={styles.limit}>
                        Image requests:&nbsp;
                        <b>
                          {calcLeftRequests(currentUser?.limits.max_image_usages, currentUser?.limits.image_usages)} /{' '}
                          {currentUser?.limits.max_image_usages}
                          {isPROAccount ? ' /3h' : null}
                        </b>
                      </div>
                      <div className={styles.limit}>
                        Input limit:&nbsp;
                        <b>{currentUser?.limits?.max_input_chars}</b>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.userMenuActions}>
                  <Link to="/project-updates">
                    <Button
                      variant={ButtonVariants.unstyled}
                      icon={IconNames.company}
                      iconColor="var(--color-secondary)"
                      isIconOnLeft
                      type="button"
                      label="Project Updates"
                    />
                  </Link>
                  <div className={styles.delimeter} />
                  <Button
                    variant={ButtonVariants.unstyled}
                    onClick={() => {
                      updateUIStore('isPricesPopupOpen', true)
                      firePlausibleEvent(PLAUSIBLE_EVENTS.CLICK_BUY_CREDITS)
                    }}
                    icon={IconNames.pro}
                    iconColor="var(--color-secondary)"
                    isIconOnLeft
                    type="button"
                    label="Prices"
                  />
                  <Button
                    variant={ButtonVariants.unstyled}
                    onClick={() => {
                      updateUIStore('isCouponModalOpen', true)
                    }}
                    icon={IconNames.star}
                    iconColor="var(--color-secondary)"
                    isIconOnLeft
                    type="button"
                    label="Activate coupon"
                  />
                  {isPROAccount && (
                    <Button
                      variant={ButtonVariants.unstyled}
                      onClick={onManagePlan}
                      type="button"
                      icon={IconNames.creditCard}
                      iconColor="var(--color-secondary)"
                      isIconOnLeft
                      label={`Manage plan (till ${formatDate(
                        // eslint-disable-next-line
                        // @ts-ignore
                        Number((currentUser?.sub_till || 1) * 1000) as Date,
                        DateFormats.monthWithDay,
                      )})`}
                    />
                  )}
                  <div className={styles.delimeter} />
                  <Button
                    variant={ButtonVariants.unstyled}
                    onClick={() => setIsSpendingsModalOpen(true)}
                    type="button"
                    label="My Rewards"
                    icon={IconNames.bankOut}
                    iconColor="var(--color-secondary)"
                    isIconOnLeft
                  />
                  <Button
                    variant={ButtonVariants.unstyled}
                    onClick={() => {
                      firePlausibleEvent(PLAUSIBLE_EVENTS.CLICK_AFFILIATE_LINK)
                      updateUIStore('isRefferalModalOpen', true)
                    }}
                    type="button"
                    label="Affiliate program"
                    icon={IconNames.userAdd}
                    iconColor="var(--color-secondary)"
                    isIconOnLeft
                  />
                  <Button
                    variant={ButtonVariants.unstyled}
                    onClick={() => setIsUpdateProfileModalOpen(true)}
                    type="button"
                    label="Update profile"
                    icon={IconNames.user}
                    iconColor="var(--color-secondary)"
                    isIconOnLeft
                  />
                  <div className={styles.delimeter} />
                  <div className={styles.socials}>
                    <a target="_blank" title="hello@zentask.ai" href="mailto:hello@zentask.ai" rel="noreferrer">
                      <Icon size={IconSize.s} name={IconNames.email} color="var(--color-secondary)" />
                    </a>
                    <a target="_blank" title="Zentask.ai twitter" href="https://twitter.com/zentaskai" rel="noreferrer">
                      <Icon size={IconSize.s} name={IconNames.twitter} color="var(--color-secondary)" />
                    </a>
                    <a target="_blank" title="Zentask.ai Discord" href="http://discord.gg/Z5wjwyR78d" rel="noreferrer">
                      <Icon size={IconSize.s} name={IconNames.discord} color="var(--color-secondary)" />
                    </a>
                  </div>
                </div>
                <div className={styles.actionsFooter}>
                  <div className={styles.userMenuActions}>
                    <Button
                      variant={ButtonVariants.unstyled}
                      onClick={() => setIsLogoutModalOpen(true)}
                      type="button"
                      disabled={isLoadingLogout}
                      isLoading={isLoadingLogout}
                      label="Logout"
                    />
                  </div>
                </div>
              </div>
            }
          />
        )}
      </div>
    </div>
  )
}
