import { Icon, IconNames, IconSize } from '../icon'
import styles from './index.module.scss'

export function Checkbox({ label, disabled = false, checked, onChange, id }) {
  return (
    <label htmlFor={`${id}`} className={`${styles.checkbox} ${checked ? styles.checked : ''}`}>
      <Icon
        name={checked ? IconNames.check : IconNames.closeMini}
        size={IconSize.m}
        color={checked ? 'var(--color-white)' : 'var(--color-secondary)'}
      />
      <input disabled={disabled} name={id} id={id} type="checkbox" checked={checked} onChange={onChange} />
      <span className={styles.checkmark}>{label}</span>
    </label>
  )
}
