import { useMutation } from 'react-query'
import { queryClient } from 'src/queries/reactQueryClient'
import { getCurrentUserKey } from 'src/queries/reactQueryKeys'
import { pushNotification } from 'src/services/notifications/notificationService'
import { PLAUSIBLE_EVENTS } from 'src/services/plausible/consts'
import { firePlausibleEvent } from 'src/services/plausible/plausibleService'
import { api } from './api'

export const useGetCouponeInfo = () =>
  useMutation(api.getCouponStatus, {
    onSuccess: (data: any) => {
      const [error, response] = data

      if (error) {
        pushNotification({
          type: 'error',
          title: 'Error getting coupong info.',
        })
        throw Error(error)
      }

      firePlausibleEvent(PLAUSIBLE_EVENTS.COUPON_GET)

      return response
    },
    onError: (err: any) => {
      pushNotification({
        type: 'error',
        title: 'Error getting coupong info.',
      })

      return err
    },
  })

export const useGetCouponeSubmit = (onSuccess) =>
  // eslint-disable-next-line
  // @ts-ignore
  useMutation(api.submitCoupon, {
    onSuccess: (data: any) => {
      const [error, response] = data

      if (error) {
        throw Error(error)
      }

      onSuccess && onSuccess()
      firePlausibleEvent(PLAUSIBLE_EVENTS.COUPON_SUMBIT)
      queryClient.invalidateQueries({ queryKey: getCurrentUserKey() })

      return response
    },
    onError: (error: any) => {
      if (error.toString().includes('e_coupon_is_missing')) {
        return pushNotification({
          type: 'error',
          title: 'Coupon is missing.',
        })
      }

      if (error.toString().includes('e_coupon_has_been_used')) {
        return pushNotification({
          type: 'error',
          title: 'Coupon was used or expired.',
        })
      }

      if (error.toString().includes('e_account_personal')) {
        return pushNotification({
          type: 'error',
          title: 'This coupon is private.',
        })
      }

      return pushNotification({
        type: 'error',
        title: 'Error occured.',
      })
    },
  })
