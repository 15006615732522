const monthsMapper = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
}

export const getMonth = (date: number) => {
  const d = new Date(date * 1000)
  const month = d.getMonth()

  return monthsMapper[month]
}

export const summarizeHistoryByMonths = (data: any) => {
  if (!data) return {}

  const history = data?.pages?.flatMap((page) => page?.items ?? [])

  const groupedHistory = history?.reduce((acc, historyItem) => {
    const date = new Date(historyItem?.created_at * 1000)
    const year = date.getFullYear()
    const month = date.getMonth() + 1 // Add 1 since getMonth() returns 0-based month
    const key = `${year}-${monthsMapper[month]}`

    if (!acc[key]) {
      acc[key] = { year, month, items: [historyItem] }
    } else {
      acc[key].items.push(historyItem)
    }

    return acc
  }, {})

  return groupedHistory
}
