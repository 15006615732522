import { DateFormats, formatDate } from 'src/utils/dates'
import { DashboardUserArea } from '../DashboardUserArea'
import { Markdown } from '../MarkDownWrapper'
import styles from './index.module.scss'
import { useGetBlogList } from './query'

// eslint-disable-next-line
export function AsideCategoryList({ taskId, categoryId }) {
  const { data: blogsList } = useGetBlogList()

  return (
    <div className={styles.wrapper}>
      <div className={styles.catsList}>
        <h1>Recent updates</h1>
        <div className={styles.list}>
          {blogsList?.map((blog) => (
            <div key={blog.id.toString()} className={styles.blogItem}>
              <h3>{blog?.title}</h3>
              <p>{blog?.description}</p>
              <div className={styles.blogMD}>
                <Markdown markdown={blog?.text} />
              </div>
              <div className={styles.blogTime}>
                {/* eslint-disable-next-line */}
                {/* @ts-ignore */}
                {formatDate(Number(blog.published_at * 1000) as Date, DateFormats.datePickerFullDate)}
              </div>
            </div>
          ))}
        </div>
      </div>
      <DashboardUserArea />
    </div>
  )
}
