import { Link } from '@tanstack/react-location'
import { useState } from 'react'
import { config } from 'src/config/config'
import { Button } from 'src/core/ds/button'
import { ButtonVariants } from 'src/core/ds/button/types'
import { Icon, IconNames } from 'src/core/ds/icon'
import { Tabs } from 'src/core/ds/tabs'
import { useGetCurrentUser } from 'src/modules/currentUser/query'
import { PLAUSIBLE_EVENTS } from 'src/services/plausible/consts'
import { firePlausibleEvent } from 'src/services/plausible/plausibleService'
import { useUIStore } from 'src/services/store/UIStore'
import styles from './index.module.scss'

const tabs = ['Monthly', 'Semi-annual', 'Annual']

const prices = {
  Monthly: [
    {
      title: 'Start',
      price: '$7.99',
      type: 'start',
      oldprice: '$29',
      credits: 750,
      textLimit: 20,
      imageLimit: 7,
      label:
        'The initial input is 2000. The maximum input is 4000. Any characters beyond the initial input will be counted as additional requests.',
      period: 1,
      charsInputLimit: '4,000',
      url: 'https://pay.zentask.ai/b/eVaaI33kzcgGbn2bIV',
    },
    {
      title: 'PRO',
      price: '$23.20',
      type: 'pro',
      oldprice: '$29',
      credits: 750,
      textLimit: 60,
      imageLimit: 20,
      label:
        'The initial input is 6000. The maximum input is 48,000. Any characters beyond the initial input will be counted as additional requests.',
      charsInputLimit: '48,000',
      period: 1,
      url: 'https://pay.zentask.ai/b/eVaaI33kzcgGbn2bIV',
    },
    {
      title: 'Team',
      price: '$79.2',
      type: 'team',
      oldprice: '$29',
      credits: 750,
      textLimit: 400,
      imageLimit: 125,
      charsInputLimit: '48,000',
      label:
        'The initial input is 6000. The maximum input is 48,000. Any characters beyond the initial input will be counted as additional requests.',
      period: 1,
      url: 'https://pay.zentask.ai/b/eVaaI33kzcgGbn2bIV',
    },
  ],
  'Semi-annual': [
    {
      title: 'Start',
      price: '$6.65',
      oldprice: '$29',
      type: 'start',
      credits: 2475,
      textLimit: 20,
      imageLimit: 7,
      label:
        'The initial input is 2000. The maximum input is 4000. Any characters beyond the initial input will be counted as additional requests.',
      charsInputLimit: '4,000',
      period: 6,
      url: 'https://pay.zentask.ai/b/6oE2bx3kzdkKeze14i',
    },

    {
      title: 'PRO',
      price: '$19.32',
      oldprice: '$29',
      type: 'pro',
      credits: 2475,
      textLimit: 60,
      imageLimit: 20,
      charsInputLimit: '48,000',
      label:
        'The initial input is 6000. The maximum input is 48,000. Any characters beyond the initial input will be counted as additional requests.',
      period: 6,
      url: 'https://pay.zentask.ai/b/6oE2bx3kzdkKeze14i',
    },
    {
      title: 'Team',
      price: '$66',
      type: 'team',
      oldprice: '$29',
      credits: 750,
      textLimit: 400,
      imageLimit: 125,
      charsInputLimit: '48,000',
      label:
        'The initial input is 6000. The maximum input is 48,000. Any characters beyond the initial input will be counted as additional requests.',
      period: 6,
      url: 'https://pay.zentask.ai/b/eVaaI33kzcgGbn2bIV',
    },
  ],
  Annual: [
    {
      title: 'Start',
      price: '$5.99',
      oldprice: '$29',
      type: 'start',
      credits: 400,
      textLimit: 20,
      imageLimit: 7,
      label:
        'The initial input is 2000. The maximum input is 4,000. Any characters beyond the initial input will be counted as additional requests.',
      charsInputLimit: '4,000',
      period: 12,
      url: 'https://pay.zentask.ai/b/fZe17t9IX5Si2QwcN1',
    },
    {
      title: 'PRO',
      price: '$17.40',
      oldprice: '$29',
      type: 'pro',
      credits: 400,
      label:
        'The initial input is 6000. The maximum input is 48,000. Any characters beyond the initial input will be counted as additional requests.',
      textLimit: 60,
      imageLimit: 20,
      charsInputLimit: '48,000',
      period: 12,
      url: 'https://pay.zentask.ai/b/fZe17t9IX5Si2QwcN1',
    },
    {
      title: 'Team',
      price: '$59.40',
      type: 'team',
      oldprice: '$29',
      credits: 750,
      textLimit: 400,
      imageLimit: 125,
      label:
        'The initial input is 6000. The maximum input is 48,000. Any characters beyond the initial input will be counted as additional requests.',
      charsInputLimit: '48,000',
      period: 12,
      url: 'https://pay.zentask.ai/b/eVaaI33kzcgGbn2bIV',
    },
  ],
}

export function Prices({ showFree = true, shouldShowUrl = false, hideHeader = false }) {
  console.log(showFree)
  const [currentIndex] = useState(2)
  const token = (localStorage && localStorage.getItem('token')) || ''
  const updateUIStore = useUIStore((state) => state.updateUIStore)
  const isPROAccount = useUIStore((state) => state.isPROAccount)

  const { data: currentUser } = useGetCurrentUser()

  const currentSubPlan = (isPROAccount && currentUser?.sub_plan) || null
  const currentSubPeriod = currentUser?.sub_period?.toString() || null

  return (
    <section id="prices" className={styles.prices}>
      {!hideHeader && (
        <div className={styles.hero}>
          <h2>Prices</h2>
          <p className={styles.description}>Unleash the power of AI with a PRO account.</p>
        </div>
      )}

      <div className={styles.pricesWrapper}>
        <div className={styles.pricesBox}>
          <Tabs
            // context={
            //   // eslint-disable-next-line
            //   <div>
            //     <span className={styles.discount}>1m free</span>
            //     <span className={styles.bonus}>2m free</span>
            //   </div>
            // }
            currentIndex={currentIndex}
            tabs={tabs}
          >
            {tabs.map((tab, index) => (
              <div key={index} className={styles.tab}>
                <div className={styles.list}>
                  {prices[tab].map((priceItem) => (
                    <div
                      key={priceItem.title}
                      className={`${styles.priceItem} ${
                        currentSubPeriod === priceItem.period?.toString() && currentSubPlan === priceItem.type
                          ? styles.activeSub
                          : undefined
                      }`}
                    >
                      <div className={styles.dealWrapper}>
                        <div className={styles.deal}>First Purchase Offer. Save 20%.</div>
                        {/* <div className={styles.oldPrice}>{priceItem.oldprice}</div> */}
                      </div>
                      <h4>
                        {priceItem.price}
                        <span> / month</span>
                      </h4>

                      <h3>
                        {priceItem.title}
                        {priceItem.title === 'Advanced' ? <span>popular</span> : null}
                      </h3>

                      <div className={styles.advantages}>
                        <p title={priceItem?.label}>
                          <Icon name={IconNames.checkFill} color="var(--color-primary)" />
                          Input: up to&nbsp;
                          {priceItem.charsInputLimit}
                          &nbsp;chars*
                        </p>

                        {priceItem.title === 'Team' || priceItem.title === 'PRO' ? (
                          <p>
                            <Icon name={IconNames.checkFill} color="var(--color-primary)" />
                            {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                            GPT3.5 requests: <b>Unlimited</b>
                            <sup>1</sup>
                          </p>
                        ) : null}

                        <p>
                          <Icon name={IconNames.checkFill} color="var(--color-primary)" />
                          {/* eslint-disable-next-line */}
                          {priceItem.title === 'Team' ? (
                            // eslint-disable-next-line
                            <span>Total Text Requests{': '}</span>
                          ) : (
                            <span>Text requests: </span>
                          )}

                          <b>
                            {priceItem.textLimit}
                            &nbsp;/ 3hrs
                          </b>
                        </p>

                        <p>
                          <Icon name={IconNames.checkFill} color="var(--color-primary)" />
                          {/* eslint-disable-next-line */}
                          {priceItem.title === 'Team' ? (
                            <span>
                              Total Image generations
                              {': '}
                            </span>
                          ) : (
                            <span>Image generations: </span>
                          )}

                          <b>
                            {priceItem.imageLimit}
                            &nbsp;/ 3hrs
                          </b>
                        </p>

                        {priceItem.title === 'Team' ? (
                          <p>
                            <Icon name={IconNames.checkFill} color="var(--color-primary)" />
                            {/* eslint-disable-next-line */}
                            Up to
                            <b>&nbsp;5&nbsp;</b>
                            team members
                          </p>
                        ) : null}
                      </div>

                      {shouldShowUrl && (
                        <div className={styles.actionWrapper}>
                          <form
                            action={`${config.baseAPIUrl}/account/pay/${priceItem.type}?period=${priceItem.period}`}
                            method="post"
                          >
                            <input hidden name="TKN" value={token} />

                            {currentSubPeriod === priceItem.period?.toString() && currentSubPlan === priceItem.type ? (
                              <Button
                                onClick={() => {
                                  firePlausibleEvent(PLAUSIBLE_EVENTS.CLICK_BUY_BUTTON)
                                }}
                                disabled
                                type="submit"
                                label="Your current plan"
                                icon={IconNames.arrowRight}
                              />
                            ) : (
                              <Button
                                onClick={() => {
                                  firePlausibleEvent(PLAUSIBLE_EVENTS.CLICK_BUY_BUTTON)
                                }}
                                type="submit"
                                label="Get started"
                                icon={IconNames.arrowRight}
                              />
                            )}
                            <a
                              href="https://docs.google.com/forms/d/e/1FAIpQLSdPWVPRp63QmwKm-N1RrmTjextBRp1L2QDeSugEQGUdJZcqOA/viewform"
                              target="_blank"
                              className={styles.crypto}
                              rel="noreferrer"
                            >
                              <Button
                                onClick={() => {
                                  firePlausibleEvent(PLAUSIBLE_EVENTS.CLICK_BUY_BUTTON_CRYPTO)
                                }}
                                type="button"
                                variant={ButtonVariants.secondary}
                                label="Buy with crypto"
                              />
                            </a>
                          </form>
                          <p className={styles.cancel}>Cancel anytime</p>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </Tabs>

          <div className={styles.common}>
            <h4>Included in all plans</h4>

            <div className={styles.showcase}>
              <div className={styles.showcaseItem}>
                <span>
                  <Icon name={IconNames.checkFill} color="var(--color-primary)" />
                  Unlimited text usage
                  <sup>1</sup>
                </span>
                <p>With GPT3.5</p>
              </div>

              <div className={styles.showcaseItem}>
                <span>
                  <Icon name={IconNames.checkFill} color="var(--color-primary)" />
                  Dedicated Execution Queue
                </span>
                <p>No need to wait</p>
              </div>

              <div className={styles.showcaseItem}>
                <span>
                  <Icon name={IconNames.checkFill} color="var(--color-primary)" />
                  Fast Support
                </span>
                <p>Same Day Reply</p>
              </div>

              <div className={styles.showcaseItem}>
                <span>
                  <Icon name={IconNames.checkFill} color="var(--color-primary)" />
                  Auto Moderation
                </span>
                <p>For Public Prompts</p>
              </div>

              <div className={styles.showcaseItem}>
                <span>
                  <Icon name={IconNames.checkFill} color="var(--color-primary)" />
                  GPT 3.5, GPT 4, Claude 3, Gemini Pro
                </span>
                <p>Text models</p>
              </div>

              <div className={styles.showcaseItem}>
                <span>
                  <Icon name={IconNames.checkFill} color="var(--color-primary)" />
                  Stable Diffusion 2.1, Dall-E 2/3, SD XL
                </span>
                <p>Image Models</p>
              </div>

              <div className={styles.showcaseItem}>
                <span>
                  <Icon name={IconNames.checkFill} color="var(--color-primary)" />
                  Zen Spaces
                </span>
                <p>View, Create, Manage</p>
              </div>

              <div className={styles.showcaseItem}>
                <span>
                  <Icon name={IconNames.checkFill} color="var(--color-primary)" />
                  Configuring
                </span>
                <p>Language, style, tone and more</p>
              </div>

              {/* <div className={styles.showcaseItem}>
                        <span>Exclusive Beta Features and More</span>
                      </div> */}
            </div>
          </div>
        </div>
      </div>

      {shouldShowUrl && (
        <div style={{ marginTop: '1.5rem' }}>
          <Link
            onClick={() => {
              updateUIStore('isPricesPopupOpen', false)
            }}
            activeOptions={{ exact: true }}
            to="/terms-of-use"
          >
            Terms of use
          </Link>
        </div>
      )}
    </section>
  )
}

export function PricesSmall({ shouldShowUrl = true }) {
  const [currentIndex] = useState(2)
  const token = (localStorage && localStorage.getItem('token')) || ''

  return (
    <section id="prices" className={styles.prices}>
      <div className={styles.pricesWrapper}>
        <div className={styles.pricesBox}>
          <Tabs currentIndex={currentIndex} tabs={tabs}>
            {tabs.map((tab, index) => (
              <div key={index} className={styles.tab}>
                <div className={styles.list}>
                  {prices[tab].map((priceItem) => (
                    <div key={priceItem.title} className={styles.priceItem}>
                      <div className={styles.dealWrapper}>
                        <div className={styles.deal}>First Purchase Offer. Save 20%.</div>
                        {/* <div className={styles.oldPrice}>{priceItem.oldprice}</div> */}
                      </div>
                      <h4>
                        {priceItem.price}
                        <span> / month</span>
                      </h4>

                      <h3>
                        {priceItem.title}
                        {priceItem.title === 'Advanced' ? <span>popular</span> : null}
                      </h3>

                      <div className={styles.advantages}>
                        <p>
                          <Icon name={IconNames.checkFill} color="var(--color-primary)" />
                          Input: up to&nbsp;
                          {priceItem.charsInputLimit}
                          &nbsp;chars
                        </p>

                        <p>
                          <Icon name={IconNames.checkFill} color="var(--color-primary)" />
                          {/* eslint-disable-next-line */}
                          {priceItem.title === 'Team' ? (
                            // eslint-disable-next-line
                            <span>Total Text Limits{': '}</span>
                          ) : (
                            <span>Text Limits: </span>
                          )}

                          <b>
                            {priceItem.textLimit}
                            &nbsp;requests / 3hrs
                          </b>
                        </p>

                        <p>
                          <Icon name={IconNames.checkFill} color="var(--color-primary)" />
                          {/* eslint-disable-next-line */}
                          {priceItem.title === 'Team' ? (
                            <span>
                              Total Image Limits
                              {': '}
                            </span>
                          ) : (
                            <span>Image Limits: </span>
                          )}

                          <b>
                            {priceItem.imageLimit}
                            &nbsp;generations / 3hrs
                          </b>
                        </p>

                        {priceItem.title === 'Team' ? (
                          <p>
                            <Icon name={IconNames.checkFill} color="var(--color-primary)" />
                            {/* eslint-disable-next-line */}
                            Up to
                            <b>&nbsp;5&nbsp;</b>
                            team members
                          </p>
                        ) : null}
                        {/* <p>
                          <Icon name={IconNames.checkFill} color="var(--color-primary)" />

                          <b>Possibility to earn</b> from public solutions
                        </p> */}
                        {/* <p>
                          <Icon name={IconNames.checkFill} color="var(--color-primary)" />

                          Zen Spaces: <b>View, Create, Manage</b>
                        </p> */}
                        {/* <p>
                          <Icon name={IconNames.checkFill} color="var(--color-primary)" />

                          Configuring <b>language, style, tone</b> and more.
                        </p> */}
                        {/* <p>
                          <Icon name={IconNames.checkFill} color="var(--color-primary)" />

                          Access to <b>BETA</b> Features
                        </p> */}
                      </div>

                      {shouldShowUrl && (
                        <div className={styles.actionWrapper}>
                          <form
                            action={`${config.baseAPIUrl}/account/pay/${priceItem.type}?period=${priceItem.period}`}
                            method="post"
                          >
                            <input hidden name="TKN" value={token} />

                            <Button
                              onClick={() => {
                                firePlausibleEvent(PLAUSIBLE_EVENTS.CLICK_BUY_BUTTON)
                              }}
                              type="submit"
                              label="Get started"
                              icon={IconNames.arrowRight}
                            />
                          </form>
                          <p className={styles.cancel}>Cancel anytime</p>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </Tabs>
        </div>
      </div>
    </section>
  )
}
