import { useMemo } from 'react'
// import Chart from 'react-apexcharts'
import { AxisOptions, Chart as Chart1 } from 'react-charts'
// import { DateFormats, formatDate } from 'src/utils/dates'
import { DateFormats, formatDate } from 'src/utils/dates'

type DailyStars = {
  time: Date | number
  revenue: number
  people_usage_count: number
  creator_usage_count?: number
}

type Series = {
  label: string

  data: DailyStars[]
}

export const fillMissingHours = (data) => {
  if (data.length === 0 || !data) return []

  const sortedData = data.sort((a, b) => a.time - b.time)
  const aggregatedData: any = []

  let currentDate = new Date(sortedData[0].time * 1000)
  let currentDayData = {
    time: Math.floor(currentDate.getTime() / 86400000) * 86400,
    creator_usage_count: 0,
    people_usage_count: 0,
    revenue: 0,
  }

  sortedData.forEach((item) => {
    const itemDate = new Date(item.time * 1000)
    const itemDay = Math.floor(itemDate.getTime() / 86400000) * 86400

    if (itemDay !== currentDayData.time) {
      while (currentDayData.time < itemDay) {
        aggregatedData.push(currentDayData)
        currentDate = new Date((currentDayData.time + 86400) * 1000)
        currentDayData = {
          time: Math.floor(currentDate.getTime() / 86400000) * 86400,
          creator_usage_count: 0,
          people_usage_count: 0,
          revenue: 0,
        }
      }
    }

    currentDayData.creator_usage_count += item.creator_usage_count
    currentDayData.people_usage_count += item.people_usage_count
    currentDayData.revenue += item.revenue
  })

  while (currentDayData.time <= Math.floor(Date.now() / 86400000) * 86400) {
    aggregatedData.push(currentDayData)
    currentDate = new Date((currentDayData.time + 86400) * 1000)
    currentDayData = {
      time: Math.floor(currentDate.getTime() / 86400000) * 86400,
      creator_usage_count: 0,
      people_usage_count: 0,
      revenue: 0,
    }
  }

  return aggregatedData
}

export function RevenueChart({ series }: { series: DailyStars[] }) {
  const filledDataSeries = fillMissingHours(series)

  const data: Series[] = [
    {
      label: 'Revenue',
      // secondaryAxisId: '2',
      data: filledDataSeries,
    },
    {
      label: 'Usage',
      // eslint-disable-next-line
      // @ts-ignore
      secondaryAxisId: '2',
      data: filledDataSeries,
    },
  ]

  const primaryAxis = useMemo(
    (): AxisOptions<DailyStars> => ({
      getValue: (datum) => datum.time, // formatDate(datum.date as any, DateFormats.dayMonthYearDateSlash),
      formatters: {
        scale: (value: any) => formatDate((value * 1000) as any, DateFormats.dayMonthYearDateSlash),
      },

      // elementType: 'area',
    }),

    [],
  )

  const secondaryAxes = useMemo(
    (): AxisOptions<DailyStars>[] => [
      {
        getValue: (datum) => datum.revenue,
        elementType: 'area',
        formatters: {
          scale: (value: any) => (value / 10000).toFixed(2).toString(),
        },
        position: 'right',
        stacked: false,
      },
      {
        id: '2',
        getValue: (datum) => datum.people_usage_count,
        elementType: 'bar',
        formatters: {
          scale: (value: any) => Number(value).toString(),
        },
        // maxBandSize: 15,
        position: 'left',
        stacked: false,
      },
    ],

    [],
  )

  if (!series) return null

  if (series.length === 0) {
    return <div>No data</div>
  }

  return (
    <div id="basic-bar" style={{ position: 'relative' }}>
      {/* disable-next-line @ts-ignore */}
      {/* <Chart theme="dark" options={options as any} height="220" series={options.series} type="area" width="100%" /> */}
      <div style={{ height: '10rem', position: 'relative' }}>
        <Chart1
          options={
            {
              data,
              primaryAxis,
              secondaryAxes,
              // dark: true,

              tooltip: {
                align: 'center',
                groupingMode: 'single',
                alignPriority: ['right'],
              },
            } as any
          }
        />
        {/* <Chart1
          options={{
            data: data22,

            primaryAxis,

            secondaryAxes,
          }}
        /> */}
      </div>
    </div>
  )
}
