import { Link, useNavigate, useSearch } from '@tanstack/react-location'
import { useEffect, useState } from 'react'
import { MetaTitle } from 'src/components/MetaTitle'
import { Prices } from 'src/components/Prices/Prices'
// import { SingleTask } from 'src/components/SingleTask/SingleTask'
import { Avatar } from 'src/core/ds/avatar'
import { AVATAR_SIZES } from 'src/core/ds/avatar/consts'
import { Button } from 'src/core/ds/button'
import { ButtonSizes } from 'src/core/ds/button/types'
import { Container } from 'src/core/ds/container'
import { IconNames } from 'src/core/ds/icon'
import { Modal } from 'src/core/ds/modal'
import { AppLayout } from 'src/layouts/AppLayout'
import { Footer } from 'src/layouts/Footer'
import { useGetCurrentUser } from 'src/modules/currentUser/query'
import { MagicLinkForm } from 'src/modules/magicLink/components/Form'
// import { useGetTrendingTasks } from 'src/modules/trendingItems/query'
import { pushNotification } from 'src/services/notifications/notificationService'
import { PLAUSIBLE_EVENTS } from 'src/services/plausible/consts'
import { firePlausibleEvent } from 'src/services/plausible/plausibleService'
import VideoSrc from '../../assets/images/hero-preview.webm'
import styles from './index.module.scss'

declare global {
  interface Window {
    cfToken: string
    plausible: any
  }
}

export function LandingPROPage() {
  const navigate = useNavigate()
  // eslint-disable-next-line
  const { ref_id: refId, utm_medium, utm_source, utm_campaign, utm_term } = useSearch()
  const { data: currentUser } = useGetCurrentUser()
  // const { data: trendingTasks, isLoading } = useGetTrendingTasks()

  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false)

  const onLoginSuccess = () => {
    pushNotification({
      type: 'success',
      title: 'Success Login',
      subtitle: 'You will be redirected.',
    })
    setIsAuthModalOpen(false)
    navigate({ to: `/zen` })
  }

  useEffect(() => {
    if (!refId) return

    localStorage.setItem('refId', refId as string)
  }, [refId])

  // useEffect(() => {
  //   try {
  //     localStorage.setItem('lockedEntry', 'true')
  //     const adsSource = localStorage.getItem('adsSource') || null

  //     if (adsSource) return

  //     localStorage.setItem(
  //       'adsSource',
  //       JSON.stringify({
  //         // eslint-disable-next-line
  //         utm_medium,
  //         // eslint-disable-next-line
  //         utm_source,
  //         // eslint-disable-next-line
  //         utm_campaign,
  //         // eslint-disable-next-line
  //         utm_term,
  //         // eslint-disable-next-line
  //         //utm_url: window.location.href
  //       }),
  //     )
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }, [])

  return (
    <AppLayout>
      <Modal
        withCloseButton
        onClose={() => setIsAuthModalOpen(false)}
        isOpen={isAuthModalOpen}
        body={<MagicLinkForm onLoginSuccess={onLoginSuccess} />}
      />
      <MetaTitle title="All-in-One AI tool: GPT4 + Midjourney starting at $5.99" />
      <Container>
        <div className={styles.hero}>
          <div className={styles.launch}>
            <span>Released Teams. Next: Real time context</span>
          </div>
          <div className={styles.launch}>
            <a
              href="https://www.producthunt.com/posts/ai-marketplace-by-zentask-ai?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-ai&#0045;marketplace&#0045;by&#0045;zentask&#0045;ai"
              target="_blank"
              rel="noreferrer"
              style={{ display: 'flex' }}
            >
              <img
                src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=389893&theme=dark&period=daily"
                alt="AI&#0032;Marketplace&#0032;by&#0032;Zentask&#0046;ai - The&#0032;Spotify&#0032;of&#0032;AI&#0032;solutions&#0032;to&#0032;simplify&#0032;your&#0032;daily&#0032;tasks | Product Hunt"
                style={{ width: 200, height: 43 }}
                width="200"
                height="43"
              />
            </a>

            <a
              href="https://www.producthunt.com/posts/ai-marketplace-by-zentask-ai?utm_source=badge-top-post-topic-badge&utm_medium=badge&utm_souce=badge-ai&#0045;marketplace&#0045;by&#0045;zentask&#0045;ai"
              target="_blank"
              rel="noreferrer"
              style={{ display: 'flex' }}
            >
              <img
                src="https://api.producthunt.com/widgets/embed-image/v1/top-post-topic-badge.svg?post_id=389893&theme=dark&period=weekly&topic_id=237"
                alt="AI&#0032;Marketplace&#0032;by&#0032;Zentask&#0046;ai - The&#0032;Spotify&#0032;of&#0032;AI&#0032;solutions&#0032;to&#0032;simplify&#0032;your&#0032;daily&#0032;tasks | Product Hunt"
                style={{ width: 200, height: 43 }}
                width="200"
                height="43"
              />
            </a>
          </div>
          <h1>
            All-in-One AI tool
            <br />
            <span>GPT4 + Midjourney</span>
            <br />
            starting at&nbsp;
            <span>$5.99 🤯</span>
          </h1>
          <p>
            Create unique textual content and visuals, saving time and energy every day.
            <br />
            &nbsp;Designed for professionals in various fields. Get started today!
          </p>

          {currentUser && (
            <div className={styles.form}>
              <Link to="/marketplace">
                <Button type="button" size={ButtonSizes.xl} label="Go to marketplace" />
              </Link>
            </div>
          )}

          {!currentUser && (
            <div className={styles.form}>
              <Button
                type="button"
                onClick={() => setIsAuthModalOpen(true)}
                size={ButtonSizes.xl}
                label="Get started"
              />
            </div>
          )}
          {/* {!currentUser && <div className={styles.bonus}>No credit card required</div>} */}

          <div className={styles.others}>
            <div className={styles.stackedAvatars}>
              <img
                src="https://ph-avatars.imgix.net/3259959/469bf616-bc06-424f-86d6-810c0a18e635?auto=compress&codec=mozjpeg&cs=strip&auto=format&w=36&h=36&fit=crop&dpr=2"
                loading="lazy"
                width="36"
                height="36"
                alt="Social proof users"
              />
              <img
                src="https://ph-avatars.imgix.net/581391/23b58d4d-ab80-4d01-94b4-62c2b97859a8?auto=compress&codec=mozjpeg&cs=strip&auto=format&w=36&h=36&fit=crop&dpr=2"
                loading="lazy"
                width="36"
                height="36"
                alt="Social proof users"
              />
              <img
                src="https://ph-avatars.imgix.net/5136189/ff275044-b287-4562-a447-ceffc0333186?auto=compress&codec=mozjpeg&cs=strip&auto=format&w=36&h=36&fit=crop&dpr=2"
                loading="lazy"
                width="36"
                height="36"
                alt="Social proof users"
              />
              <img
                src="https://ph-avatars.imgix.net/5415947/original?auto=compress&codec=mozjpeg&cs=strip&auto=format&w=36&h=36&fit=crop&dpr=2"
                loading="lazy"
                width="36"
                height="36"
                alt="Social proof users"
              />
              <img
                src="https://ph-avatars.imgix.net/4955004/4d8950c7-10b4-4a14-ba54-efd9be352b38?auto=compress&codec=mozjpeg&cs=strip&auto=format&w=36&h=36&fit=crop&dpr=2"
                loading="lazy"
                width="36"
                height="36"
                alt="Social proof users"
              />
            </div>

            <p>Join 12,500+ others</p>
          </div>

          <div className={styles.download}>
            <span>Desktop Apps On-The-Go</span>
            <div className={styles.downloadList}>
              <a
                href="https://www.dropbox.com/scl/fi/xsanrp91207922pl1h5th/Zentask-Setup-1.0.1.exe?rlkey=qk9kjtqc504i6nf4618n15buu&dl=1"
                target="_blank"
                className={styles.downloadItem}
                onClick={() => firePlausibleEvent(PLAUSIBLE_EVENTS.CLICK_DOWNLOAD_WINDOWS)}
                rel="noreferrer"
              >
                <img
                  src="https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/7ef33e53-7184-41c7-261c-27c5fc922d00/small"
                  alt="Download Zentask.ai desktop app for Windows"
                />
              </a>
              <a
                href="https://www.dropbox.com/scl/fi/34225cil8o5smjgzqupyi/Zentask-1.0.1-amd64.dmg?rlkey=b7f1zxfyuhxyvrpp25rhgh2wk&dl=1"
                target="_blank"
                onClick={() => firePlausibleEvent(PLAUSIBLE_EVENTS.CLICK_DOWNLOAD_X64)}
                className={styles.downloadItem}
                rel="noreferrer"
              >
                <img
                  src="https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/b8f5739a-73fc-4a69-1304-2c5b0ef7e100/small"
                  alt="Download Zentask.ai desktop app for Mac OS x64"
                />
              </a>
              <a
                href="https://www.dropbox.com/scl/fi/sdcs6fnoldfkjrs2oigft/Zentask-1.0.1-arm64.dmg?rlkey=cuwd1dc02rocuisfvio6exjen&dl=1"
                target="_blank"
                onClick={() => firePlausibleEvent(PLAUSIBLE_EVENTS.CLICK_DOWNLOAD_ARM)}
                className={styles.downloadItem}
                rel="noreferrer"
              >
                <img
                  src="https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/90d2e21a-2517-4a0c-b941-c4eed415be00/small"
                  alt="Download Zentask.ai desktop app for Mac OS ARM"
                />
              </a>
            </div>
          </div>
        </div>

        <div className={styles.video}>
          {/* eslint-disable-next-line */}
          <video controls loop>
            <source src={VideoSrc} type="video/webm" />
            Your browser does not support the video tag.
          </video>
        </div>

        <section className={styles.students}>
          <p>Join companies and teams that are using Zentask:</p>
          <div className={styles.list}>
            <img
              src="https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/b74b232a-ec39-45d7-4a2c-fa0716df6e00/large"
              alt="companies using zentask"
              loading="lazy"
              width="1160"
              height="140"
            />
          </div>
        </section>

        <section id="prompts" className={styles.advantages}>
          <h2>
            The All-In-One
            <br />
            AI Solution Platform
          </h2>
          <p>
            Develop unique content by employing diverse AI resources for composing, investigating, evaluating, producing
            visuals, and beyond.
          </p>

          <div className={styles.newFeaturesList}>
            <div className={styles.newFeatureItem}>
              <div className={styles.newFeatureItemContent}>
                <h3>Write articles and marketing copies 10X faster</h3>
                <p>
                  Develop unique and SEO-friendly content for your blogs, advertisements, emails, and website to ensure
                  it is free from plagiarism.
                </p>
                <div className={styles.newFeatureItemContentAction}>
                  <Link to="/marketplace/task/63588371699597313">
                    <Button iconColor="#fff" icon={IconNames.arrowRight} label="Try now" type="button" />
                  </Link>
                </div>
              </div>
              <div className={styles.newFeatureItemImage}>
                <img
                  src="https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/ee1606c9-d3f6-4d01-4521-984013cf8400/medium"
                  alt=""
                  loading="lazy"
                />
              </div>
            </div>

            <div className={styles.newFeatureItem}>
              <div className={styles.newFeatureItemContent}>
                <h3>Best Alternative to ChatGPT</h3>
                <p>
                  Zentask boasts a better UI, a prebuilt collection of prompts, supports various image AI models, and
                  offers customizable settings, such as language and voice tone. It provides the ability to create and
                  organize your prompt templates.
                </p>
                <div className={styles.newFeatureItemContentAction}>
                  <Link to="/zen">
                    <Button iconColor="#fff" icon={IconNames.arrowRight} label="Try now" type="button" />
                  </Link>
                </div>
              </div>
              <div className={styles.newFeatureItemImage}>
                <img
                  src="https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/cd14546a-1ba0-437a-62d5-3f41e33b7400/medium"
                  alt=""
                  loading="lazy"
                />
              </div>
            </div>

            <div className={styles.newFeatureItem}>
              <div className={styles.newFeatureItemContent}>
                <h3>Boost Your Site&apos;s Success with SEO Magic</h3>
                <p>
                  Unleash the full potential of your website with our easy-to-use tool. Improve SEO, craft engaging
                  articles, generate metatags, and refine your keywords for maximum online visibility.
                </p>
                <div className={styles.newFeatureItemContentAction}>
                  <Link to="/marketplace/task/18259766736322561">
                    <Button iconColor="#fff" icon={IconNames.arrowRight} label="Try now" type="button" />
                  </Link>
                </div>
              </div>
              <div className={styles.newFeatureItemImage}>
                <img
                  src="https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/7a8920cb-4425-4cfd-1494-bacb14ab9200/medium"
                  alt=""
                  loading="lazy"
                />
              </div>
            </div>

            <div className={styles.newFeatureItem}>
              <div className={styles.newFeatureItemContent}>
                <h3>Generate Images from Text</h3>
                <p>
                  Empower your promo, articles, and marketing campaigns using the power of Stable Diffusion, Dall-E 2,
                  and Midjourney. Transform ideas into stunning visuals!
                </p>
                <div className={styles.newFeatureItemContentAction}>
                  <Link to="/text-to-image">
                    <Button iconColor="#fff" icon={IconNames.arrowRight} label="Try now" type="button" />
                  </Link>
                </div>
              </div>
              <div className={styles.newFeatureItemImage}>
                <img
                  src="https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/08796626-a355-4381-23e4-b12bf9380800/medium"
                  alt=""
                  loading="lazy"
                />
              </div>
            </div>

            <div className={styles.newFeatureItem}>
              <div className={styles.newFeatureItemContent}>
                <h3>Various settings output</h3>
                <p>
                  We offer support for various settings, such as language selection, writing style, voice tone, and AI
                  model.
                </p>
                <div className={styles.newFeatureItemContentAction}>
                  <Link to="/marketplace/task/1">
                    <Button iconColor="#fff" icon={IconNames.arrowRight} label="Try now" type="button" />
                  </Link>
                </div>
              </div>
              <div className={styles.newFeatureItemImage}>
                <img
                  src="https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/6c5d8ae3-fa9d-4918-ce8e-9a2e22bdf400/medium"
                  alt=""
                  loading="lazy"
                />
              </div>
            </div>
          </div>

          <div className={styles.featuresList}>
            <div className={styles.flexRow}>
              <div className={styles.featureItem}>
                <div className={styles.featureItemImage}>
                  <img
                    src="https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/acc78da4-9513-4f39-aab8-938fdb04f900/medium"
                    alt="Zenspaces"
                    width="568"
                    height="368"
                    loading="lazy"
                  />
                </div>
                <div className={styles.featureItemText}>
                  <h3>Simplify your workload with ZenSpaces</h3>
                  <p>
                    Streamline your daily routine with ZenSpaces! Our exceptional tool helps you create, categorize, and
                    organize tasks effortlessly, saving you valuable time.
                  </p>
                </div>
              </div>

              <div className={styles.featureItem}>
                <div className={styles.featureItemImage}>
                  <img
                    src="https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/ae95a7a5-3cb8-4309-6a17-dc6abd022a00/medium"
                    alt="Create your own templates"
                    width="568"
                    height="368"
                    loading="lazy"
                  />
                </div>
                <div className={styles.featureItemText}>
                  <h3>Create your own custom instructions</h3>
                  <p>
                    Do you have a large collection of prompts? Easily create and organize them on Zentask. Make them
                    private or available for public use.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section id="trending">
          <div className={styles.trend}>
            <h3>
              Trending on&nbsp;
              <Link to="/marketplace">marketplace</Link>
              🚀
            </h3>
            <p>
              Discover the latest and most popular solutions used for boosting Creativity, Productivity, and Innovation.
            </p>

            <div className={styles.trendList}>
              {isLoading && <div className={styles.loading}>Loading...</div>}

              {(trendingTasks || []).slice(0, 12).map((item) => (
                <SingleTask isAuth={!!currentUser} key={item.id.toString()} task={item} />
              ))}
            </div>
          </div>
        </section> */}

        <section className={styles.cost}>
          <Prices showFree={false} />
        </section>

        <section className={styles.reviews}>
          <h2>Join our empowered community</h2>
          <p>Zentask has helped thousands of people boost their productivity and improve their quality of life.</p>

          <div className={styles.reviewsList}>
            <div className={styles.reviewItem}>
              <p>
                I&apos;m absolutely thrilled to see this AI marketplace! The concept of creating prompts and earning
                commission on each use is truly innovative. It&apos;s exciting to see a platform that brings the power
                of AI to engineers in such a seamless and user-friendly way.
              </p>
              <div className={styles.reviewItemAuthor}>
                <span>
                  <Avatar
                    size={AVATAR_SIZES.sm}
                    url="https://ph-avatars.imgix.net/3921679/6c4eddf2-2585-4357-b8a7-5e5b078c443f?auto=compress&codec=mozjpeg&cs=strip&auto=format&w=40&h=40&fit=crop&dpr=2"
                  />
                  Odin Enes Ozlen
                </span>
                <span>at Product Hunt</span>
              </div>
            </div>

            <div className={styles.reviewItem}>
              <p>
                Hi, Nice product guys! Loved how it saves time by providing ready to use use-cases. Also loved the way
                website was designed, keeping in mind easability as the prominent factor.
              </p>
              <div className={styles.reviewItemAuthor}>
                <span>
                  <Avatar
                    size={AVATAR_SIZES.sm}
                    url="https://ph-avatars.imgix.net/5412406/original?auto=compress&codec=mozjpeg&cs=strip&auto=format&w=40&h=40&fit=crop&dpr=2"
                  />
                  Jatin Keni
                </span>
                <span>at Product Hunt</span>
              </div>
            </div>

            <div className={styles.reviewItem}>
              <p>
                Zentask AI has become my go-to task management tool. It&apos;s powerful features and intuitive interface
                make managing tasks a breeze. Give it a try and see the difference it can make in your productivity!
              </p>
              <div className={styles.reviewItemAuthor}>
                <span>
                  <Avatar
                    size={AVATAR_SIZES.sm}
                    url="https://pbs.twimg.com/profile_images/1664876161724297216/6Gqj0uaN_x96.jpg"
                  />
                  GS
                </span>
                <span>at Twitter</span>
              </div>
            </div>

            <div className={styles.reviewItem}>
              <p>
                Amazing Love it how this my saves time by providing ready to use use-cases. Also loved the way website
                was designed, keeping in mind easability as the prominent factor.
              </p>
              <div className={styles.reviewItemAuthor}>
                <span>
                  <Avatar
                    size={AVATAR_SIZES.sm}
                    url="https://ph-avatars.imgix.net/5068604/61bd412a-1fda-4a8d-b701-e54dc3448c47?auto=compress&codec=mozjpeg&cs=strip&auto=format&w=40&h=40&fit=crop&dpr=2"
                  />
                  William Miranda
                </span>
                <span>at Product Hunt</span>
              </div>
            </div>

            <div className={styles.reviewItem}>
              <p>
                Great product! It brings together so many AI-related services on one site. Why didn&apos;t someone think
                of this before? Can&apos;t wait to see what you&apos;ll do next
              </p>
              <div className={styles.reviewItemAuthor}>
                <span>
                  <Avatar
                    size={AVATAR_SIZES.sm}
                    url="https://ph-avatars.imgix.net/5140792/original?auto=compress&codec=mozjpeg&cs=strip&auto=format&w=40&h=40&fit=crop&dpr=2"
                  />
                  John Toomey
                </span>
                <span>at Product Hunt</span>
              </div>
            </div>

            <div className={styles.reviewItem}>
              <p>Amazing project I am really excited for this</p>
              <div className={styles.reviewItemAuthor}>
                <span>
                  <Avatar
                    size={AVATAR_SIZES.sm}
                    url="https://ph-avatars.imgix.net/5079278/dc90fb9e-c987-4396-9a65-b8737518e62b?auto=compress&codec=mozjpeg&cs=strip&auto=format&w=40&h=40&fit=crop&dpr=2"
                  />
                  Melissa McCarthy
                </span>
                <span>at Product Hunt</span>
              </div>
            </div>
          </div>
        </section>

        <section id="faq" className={styles.faq}>
          <h2>Frequently Asked Questions (FAQs)</h2>
          <p>
            Find answers to your questions about using Zentask, from prompt creation to marketplace publishing and
            earnings.
          </p>

          <div className={styles.faqList}>
            <div className={styles.faqItem}>
              <span>What is Zentask, and how can it help me with my daily tasks?</span>
              <p>
                Zentask is a platform that offers easy-to-use AI solutions to simplify your work and daily routines. By
                using prepared prompts created by professionals, you can generate content, optimize processes, and
                accomplish a wide range of tasks with the help of AI.
              </p>
            </div>

            <div className={styles.faqItem}>
              <span>How can I become a prompt engineer and create prompts for Zentask?</span>
              <p>
                To become a prompt engineer, simply register on the Zentask platform. Once registered, you can create
                prompts designed to solve popular tasks or challenges using AI. You can then publish your prompts to the
                marketplace catalog for others to use.
              </p>
            </div>

            <div className={styles.faqItem}>
              <span>How do prompt engineers earn money on Zentask?</span>
              <p>
                Prompt engineers earn money each time a visitor uses one of their prompts to solve a task. For each
                usage, prompt engineers receive 1 cent per credit spent by the visitor.
              </p>
            </div>

            <div className={styles.faqItem}>
              <span>How can I use Zentask prompts without revealing the underlying code?</span>
              <p>
                Zentask ensures the security and uniqueness of each prompt. Visitors can use the prompts without seeing
                the actual code, as they only need to fill in the required parameters and enjoy the results.
              </p>
            </div>

            <div className={styles.faqItem}>
              <span>What is the cost of using Zentask, and what features are available?</span>
              <p>
                Zentask is now free to use, allowing users to access any available prompt with some usage limitations
                per time. By default, users can access The Average quality AI model for their tasks. However, if they
                want to take advantage of the ZenSpaces feature or use the top-notch quality AI model, they need to
                subscribe to a PRO account.
              </p>
            </div>

            <div className={styles.faqItem}>
              <span>Can Zentask help me create SEO-optimized articles, recipes, training plans, and more?</span>
              <p>
                Yes, Zentask&#39;s AI-powered prompts can help you with various tasks, such as creating SEO-optimized
                articles, generating recipes, designing personalized training plans, and much more. Simply choose the
                desired task, provide the required inputs, and enjoy the results.
              </p>
            </div>

            <div className={styles.faqItem}>
              <span>Can I cancel my subscription plan?</span>
              <p>Of course! Cancel at any time by clicking the manage plan button in settings.</p>
            </div>

            <div className={styles.faqItem}>
              <span>What&apos;s the difference between GPT-4 and GPT-3.5</span>
              <p>
                GPT-4 is much better at coding. GPT-4 will output significantly more correct explanations, answers, and
                code than GPT-3.5.
              </p>
            </div>
          </div>
        </section>

        {!currentUser && (
          <section className={styles.try}>
            <h3>Try Zentask today</h3>
            <p>Say goodbye to chaos and hello to a harmonious work-life balance 🚀</p>

            <Button size={ButtonSizes.xl} onClick={() => setIsAuthModalOpen(true)} type="button" label="Try it now" />
          </section>
        )}
      </Container>
      <Footer />
    </AppLayout>
  )
}
