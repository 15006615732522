import { Link, useNavigate } from '@tanstack/react-location'
import { useAddTaskToSpace } from 'src/components/AddToSpaceModal/query'
import { SingleTask } from 'src/components/SingleTask/SingleTask'
import { Avatar } from 'src/core/ds/avatar'
import { AVATAR_SIZES } from 'src/core/ds/avatar/consts'
import { Button } from 'src/core/ds/button'
import { ButtonSizes, ButtonVariants } from 'src/core/ds/button/types'
import { IconNames } from 'src/core/ds/icon'
import { Loader } from 'src/core/ds/loader'
import { queryClient } from 'src/queries/reactQueryClient'
import { getSpaceDetailsList } from 'src/queries/reactQueryKeys'
import { pushNotification } from 'src/services/notifications/notificationService'
import { useUIStore } from 'src/services/store/UIStore'
import { DateFormats, formatDate } from 'src/utils/dates'
import { NotFoundPage } from '../NotFoundPage/NotFoundPage'
import styles from './index.module.scss'
import { useGetSpaceDetailsList } from './query'

export function SpacePreview({ spaceDetails, currentUser }) {
  const navigate = useNavigate()
  const isPROAccount = useUIStore((state) => state.isPROAccount)
  const updateUIStore = useUIStore((state) => state.updateUIStore)
  const openAddToSpaceModal = useUIStore((state) => state.openAddToSpaceModal)
  const onPrivate = () => {
    navigate({ to: `/spaces` })
  }

  const onSuccessRemoveFromSpace = () => {
    pushNotification({
      type: 'success',
      title: 'Task removed from space',
    })

    queryClient.invalidateQueries({ queryKey: getSpaceDetailsList(spaceDetails?.id.toString()) })
  }

  const { mutate } = useAddTaskToSpace(onSuccessRemoveFromSpace)

  const onRemoveTaskFromSpace = async (taskId) => {
    await mutate({
      DTO: {
        remove: [taskId.toString()],
      },
      spaceId: spaceDetails?.id.toString(),
    })
  }

  const onEdit = (spaceId) => {
    updateUIStore('isEditSpaceModalOpen', true)
    updateUIStore('editSpaceBuffer', spaceId)
  }

  const onAddToTeam = (spaceId) => {
    updateUIStore('isAddToTeamModalOpen', true)
    updateUIStore('editSpaceBuffer', spaceId)
  }

  const isEditable = currentUser?.id.toString() === spaceDetails?.account?.id?.toString()
  const { data: tasksRaw } = useGetSpaceDetailsList(spaceDetails?.id?.toString(), onPrivate)
  const tasks: any = tasksRaw?.pages?.flatMap((page) => page?.items ?? page) ?? []
  const isRemovableFromSpace = spaceDetails?.account?.id.toString() === currentUser?.id.toString()

  if (spaceDetails?.notFound) {
    return (
      <div className={styles.solutionWrapper}>
        <NotFoundPage />
      </div>
    )
  }

  if (!currentUser || !spaceDetails?.account) {
    return (
      <div className={styles.solutionWrapper}>
        <Loader isBig />
      </div>
    )
  }

  return (
    <div className={styles.solutionWrapper}>
      <div className={styles.solutionBreadWrapper}>
        <div className={styles.headerTopWrapper}>
          <div className={styles.solutionBreadcrumbs}>
            <Link activeOptions={{ exact: true }} to="/spaces/public">
              Discover spaces
            </Link>
            <span>|</span>
            {currentUser && (
              <Link activeOptions={{ exact: true }} to="/spaces">
                My spaces
              </Link>
            )}
          </div>
        </div>

        {currentUser && (
          <div className={styles.previewActions}>
            {isPROAccount && (
              <Button
                type="button"
                variant={ButtonVariants.secondary}
                size={ButtonSizes.xs}
                label="Add to team"
                onClick={() => onAddToTeam(spaceDetails?.id.toString())}
                iconColor="var(--color-text)"
                icon={IconNames.userAdd}
              />
            )}
            {isEditable && (
              <Button
                type="button"
                variant={ButtonVariants.secondary}
                size={ButtonSizes.xs}
                label="Edit"
                onClick={() => onEdit(spaceDetails?.id.toString())}
                iconColor="var(--color-text)"
                icon={IconNames.edit}
              />
            )}
          </div>
        )}
      </div>
      <div className={styles.solutionContainer}>
        <div className={styles.solutionHero}>
          <div className={styles.author}>
            <Avatar size={AVATAR_SIZES.sm} url={spaceDetails?.account?.avatar_url} />
            <span>{spaceDetails?.account?.full_name || spaceDetails?.account?.username}</span>
            &nbsp;created this space on&nbsp;
            {formatDate(
              // eslint-disable-next-line
              // @ts-ignore
              Number((spaceDetails?.created_at || 1) * 1000) as Date,
              DateFormats.monthWithDay,
            )}
          </div>
          <h1>{spaceDetails?.title}</h1>

          <p>{spaceDetails?.description}</p>
        </div>

        <div className={styles.starter}>Select a prompt to start.</div>
        <div className={styles.list}>
          {(tasks || []).map((item: any) => (
            <SingleTask
              isRemovableFromSpace={isRemovableFromSpace}
              key={item.id.toString()}
              spaceLink={`/spaces/${spaceDetails?.id.toString()}/${item.id.toString()}`}
              isAuth={currentUser}
              withStatus={false}
              onRemoveFavorite={() => {
                console.log('1')
              }}
              onAddFavorite={() => {
                console.log('1')
              }}
              onAddToSpace={openAddToSpaceModal}
              removeFromSpace={onRemoveTaskFromSpace}
              task={item}
            />
          ))}
          {tasks?.length === 0 && <div>Nothing to show.</div>}
        </div>
        <div />
      </div>
    </div>
  )
}
