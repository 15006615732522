import { Link, useNavigate } from '@tanstack/react-location'
import { Helmet } from 'react-helmet'
import { Avatar } from 'src/core/ds/avatar'
import { AVATAR_SIZES } from 'src/core/ds/avatar/consts'
import { Button } from 'src/core/ds/button'
import { ButtonSizes, ButtonVariants } from 'src/core/ds/button/types'
import { Icon, IconNames } from 'src/core/ds/icon'
import { Loader } from 'src/core/ds/loader'
import { PLAUSIBLE_EVENTS } from 'src/services/plausible/consts'
import { firePlausibleEvent } from 'src/services/plausible/plausibleService'
import { useStreamStore } from 'src/services/store/StreamStore'
import { useUIStore } from 'src/services/store/UIStore'
import { useZenTaskStore } from 'src/services/store/ZenTasksStore'
import { Markdown } from '../MarkDownWrapper'
import { TaskCategoryBadge } from '../TaskCategoryBadge/TaskCategoryBadge'
import styles from './index.module.scss'

export function SolutionPreview({ currentUser, isTestForm, isFormVisible, solutionDetails }) {
  const navigate = useNavigate()
  const updateSelectedZenTask = useStreamStore((state) => state.updateSelectedZenTask)
  const openAddToSpaceModal = useUIStore((state) => state.openAddToSpaceModal)
  const saveTask = useZenTaskStore((state) => state.saveTask)
  const updateUIStore = useUIStore((state) => state.updateUIStore)
  const isPROAccount = useUIStore((state) => state.isPROAccount)

  const isDalleModel = solutionDetails?.ai?.name?.includes('DALL')

  if (!solutionDetails) return null

  const addToSpace = (event) => {
    event.preventDefault()
    openAddToSpaceModal({
      title: solutionDetails.title,
      id: solutionDetails.id.toString(),
    })
  }

  const onOpenInZen = (event, task) => {
    event.preventDefault()
    updateSelectedZenTask(task.id.toString())
    saveTask(task)
    navigate({ to: `/zen` })
  }

  const isEditable = currentUser?.id.toString() === solutionDetails?.account_id?.toString()

  const isSpace = window.location.pathname.includes('spaces')

  if (!solutionDetails.account) {
    return <Loader isBig />
  }

  return (
    <div className={styles.solutionWrapper}>
      {!isTestForm && (
        <Helmet>
          <title>{`${solutionDetails?.title} | AI Prompt |  Zentask.ai`}</title>
          <meta name="description" content={solutionDetails?.description} />
          <meta property="og:title" content={`${solutionDetails?.title} | AI Prompt | Zentask.ai`} />
          <meta property="og:description" content={solutionDetails?.description} />
          <link rel="canonical" href={`https://zentask.ai/marketplace/task/${solutionDetails?.id}`} />
          {isSpace && <meta name="robots" content="noindex" />}
        </Helmet>
      )}
      {!isTestForm && (
        <div className={styles.solutionBreadWrapper}>
          <div className={styles.headerTopWrapper}>
            <Button
              iconColor="var(--color-secondary)"
              variant={ButtonVariants.unstyled}
              type="button"
              // label="Back"
              // tooltip="Go back in history"
              isIconOnLeft
              onClick={() => window?.history.back()}
              icon={IconNames.arrowLeft}
            />
            <div className={styles.solutionBreadcrumbs}>
              <span>|</span>
              <Link activeOptions={{ exact: true }} to="/marketplace">
                Marketplace
              </Link>
            </div>
          </div>

          {currentUser && (
            <div className={styles.previewActions}>
              {isEditable && (
                <Link to={`/marketplace/edit/${solutionDetails?.id.toString()}`}>
                  <Button
                    type="button"
                    variant={ButtonVariants.secondary}
                    size={ButtonSizes.xs}
                    label="Edit"
                    iconColor="var(--color-text)"
                    icon={IconNames.edit}
                  />
                </Link>
              )}
              <Button
                onClick={addToSpace}
                size={ButtonSizes.xs}
                icon={IconNames.plus}
                iconColor="var(--color-text)"
                label="Add to space"
                type="button"
                variant={ButtonVariants.secondary}
              />
              <Button
                onClick={(event) => onOpenInZen(event, solutionDetails)}
                size={ButtonSizes.xs}
                tooltip="Open in ZenMode"
                // icon={IconNames.plus}
                // iconColor="var(--color-text)"
                label="⚡️"
                type="button"
                variant={ButtonVariants.secondary}
              />
            </div>
          )}
        </div>
      )}
      {/* <div className={styles.solutionImageWrapper}>
        <img
          src="https://images.unsplash.com/photo-1579546928937-641f7ac9bced?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1239&q=80"
          alt="background"
          loading="lazy"
        />
      </div> */}
      {isFormVisible && !isTestForm && (
        <div className={styles.solutionContainer}>
          <div className={styles.solutionHero}>
            <div className={styles.solutionHeroTags}>
              <TaskCategoryBadge tags={solutionDetails.tags} />
            </div>
            <h1>{solutionDetails.title}</h1>
            <div className={styles.author}>
              <Avatar size={AVATAR_SIZES.xs} url={solutionDetails?.account?.avatar_url} />
              <span>{solutionDetails?.account?.full_name || solutionDetails.account.username}</span>
            </div>
            <p>{solutionDetails.description}</p>
          </div>

          {!isPROAccount && !!solutionDetails?.is_premium && !isEditable && (
            <div className={styles.taskInfo}>
              This is the premium prompt and available for PRO only.&nbsp;
              <Button
                onClick={() => {
                  updateUIStore('isPricesPopupOpen', true)
                  firePlausibleEvent(PLAUSIBLE_EVENTS.CLICK_BUY_CREDITS)
                }}
                type="button"
                variant={ButtonVariants.unstyled}
                label="Upgrade to PRO"
              />
              .
            </div>
          )}

          {solutionDetails?.history?.response && (
            <noindex>
              <div className={styles.example}>
                <div className={styles.inputExample}>
                  <div className={styles.exampleTitle}>Input Example:</div>
                  <p>
                    <Icon color="var(--color-primary)" name={IconNames.chat} />
                    {Object.keys(solutionDetails?.history?.input || {}).map((key, index) => (
                      <span key={index}>{solutionDetails?.history?.input[key].trim()}</span>
                    ))}
                  </p>
                </div>
                <div className={styles.exampleTitle}>
                  Output Example
                  {/* eslint-disable-next-line */}
                  {solutionDetails?.history?.config && ` [${solutionDetails?.history?.config?.model.option}]`}:
                </div>

                <div className={styles.exampleContainer}>
                  {!isDalleModel && (
                    <div className={styles.contentMD}>
                      <Markdown markdown={solutionDetails?.history?.response} />
                    </div>
                  )}
                  {isDalleModel && (
                    <div className={styles.contentMD}>
                      <div className={styles.imagesBox}>
                        {(JSON.parse(solutionDetails?.history?.response) || []).map((image, index) => (
                          // eslint-disable-next-line
                          // @ts-ignore
                          <div key={index} className={styles.imageItem}>
                            <a href={image} download target="_blank" className={styles.downloadImage} rel="noreferrer">
                              <Button
                                type="button"
                                iconColor="var(--color-secondary)"
                                icon={IconNames.download}
                                size={ButtonSizes.xs}
                                variant={ButtonVariants.secondary}
                              />
                            </a>
                            <img width="323" height="323" loading="lazy" src={image} alt="" key={index} />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </noindex>
          )}
        </div>
      )}
    </div>
  )
}
