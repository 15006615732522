import { Link } from '@tanstack/react-location'
import { useState } from 'react'
import { Button } from 'src/core/ds/button'
import { ButtonVariants } from 'src/core/ds/button/types'
import { Icon, IconNames, IconSize } from 'src/core/ds/icon'
import { Loader } from 'src/core/ds/loader'
import { useAddOrRemoveTeamSpace, useGetTeams } from 'src/modules/teams/query'
import { pushNotification } from 'src/services/notifications/notificationService'
import { useUIStore } from 'src/services/store/UIStore'
import styles from './index.module.scss'

export function AddToTeamModal() {
  const editSpaceBuffer = useUIStore((state) => state.editSpaceBuffer)

  const { data: teamsList, isLoading } = useGetTeams()
  const updateUIStore = useUIStore((state) => state.updateUIStore)
  const isPROAccount = useUIStore((state) => state.isPROAccount)

  const [selectedSpace, setSelectedSpace] = useState(null)

  const onSpaceDetached = () => {
    pushNotification({
      type: 'success',
      title: 'Space Attached',
    })
    updateUIStore('isAddToTeamModalOpen', false)
  }

  const { mutate: attachTeamSpaceMutate, isLoading: isLoadingMutate } = useAddOrRemoveTeamSpace(onSpaceDetached)

  const onSubmit = async () => {
    if (selectedSpace === null) return

    await attachTeamSpaceMutate({
      id: selectedSpace,
      details: {
        type: 'space',
        // eslint-disable-next-line
        // @ts-ignore
        add: [editSpaceBuffer],
      },
    })
  }

  return (
    <div className={styles.wrapper}>
      {isLoading && <Loader isBig />}
      {!isLoading && (
        <>
          <div className={styles.preTitle}>Select the team to add the space:</div>
          <div className={styles.list}>
            {teamsList?.items?.map((space, index) => (
              // eslint-disable-next-line
              <div
                onClick={() => setSelectedSpace(space?.id?.toString())}
                key={index}
                className={`${styles.card} ${space?.id?.toString() === selectedSpace ? styles.selected : ''}`}
              >
                <Icon color="var(--color-secondary)" size={IconSize.m} name={IconNames.space} />
                <span>{space.name}</span>
                {space?.id?.toString() === selectedSpace && (
                  <Icon name={IconNames.check} color="var(--color-primary)" />
                )}
              </div>
            ))}
          </div>
        </>
      )}

      <div className={styles.action}>
        {isPROAccount && (
          <Button
            isLoading={isLoadingMutate}
            disabled={selectedSpace === null || isLoadingMutate}
            type="button"
            onClick={onSubmit}
            label="Add to team"
          />
        )}
        <Link to="/teams/create">
          <Button
            onClick={() => updateUIStore('isCreateNewSpaceOpen', true)}
            variant={ButtonVariants.secondary}
            type="button"
            label="Create new team"
          />
        </Link>
      </div>
    </div>
  )
}
