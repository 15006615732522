import { Link } from '@tanstack/react-location'
import { Avatar } from 'src/core/ds/avatar'
import { AVATAR_SIZES } from 'src/core/ds/avatar/consts'
import { Button } from 'src/core/ds/button'
import { ButtonVariants } from 'src/core/ds/button/types'
import { Icon, IconNames } from 'src/core/ds/icon'
import { useAddOrRemoveTeamMember, useGetTeamMembers } from 'src/modules/teams/query'
import { queryClient } from 'src/queries/reactQueryClient'
import { getTeamMembersList } from 'src/queries/reactQueryKeys'
import { pushNotification } from 'src/services/notifications/notificationService'
import { useUIStore } from 'src/services/store/UIStore'
import styles from './index.module.scss'

export function TeamList({ isTeamAdmin, teamDetails, teamId, teamsList }) {
  const updateUIStore = useUIStore((state) => state.updateUIStore)
  const { data: teamMembers } = useGetTeamMembers(teamId)

  const onSuccessRemoveMember = () => {
    pushNotification({
      type: 'success',
      title: 'Team member removed',
    })
    // getTeamMembersList(id)
    queryClient.invalidateQueries({ queryKey: getTeamMembersList(teamId) })
  }
  const { mutate, isLoading } = useAddOrRemoveTeamMember(onSuccessRemoveMember)

  const teamCreatorId = teamDetails?.account?.id?.toString() || ''

  const removeMember = async (memberId) => {
    await mutate({
      id: teamId,
      details: {
        type: 'member',
        remove: [memberId.toString()],
      },
    })
  }

  const onOpenMembersModal = () => {
    updateUIStore('isAddTeamMemberModalOpen', true)
  }

  return (
    <div className={styles.teamBox}>
      <div className={styles.teamTitle}>
        <span>Your teams</span>
        {isTeamAdmin && (
          <Link to="/teams/create">
            <Button
              label="add team"
              iconColor="var(--color-secondary)"
              type="button"
              icon={IconNames.plus}
              variant={ButtonVariants.unstyled}
            />
          </Link>
        )}
      </div>

      <div className={styles.teamsList}>
        {(teamsList || []).map((team) => (
          <div key={team.id.toString()}>
            <Link to={`/teams/${team.id.toString()}`} className={styles.teamItem} key={team.id.toString()}>
              {team.name}
            </Link>
            {teamId === team.id.toString() && (
              <div className={styles.teamMembers}>
                <div className={styles.teamTitle}>
                  <span>team members</span>
                  {isTeamAdmin && (
                    <Button
                      label="add member"
                      iconColor="var(--color-secondary)"
                      type="button"
                      icon={IconNames.plus}
                      variant={ButtonVariants.unstyled}
                      onClick={onOpenMembersModal}
                    />
                  )}
                </div>
                <div className={styles.membersList}>
                  {teamMembers?.items?.map((member) => (
                    <div key={member.id.toString()} className={styles.memberItem}>
                      <div className={styles.memberItemDetails}>
                        <Avatar
                          size={AVATAR_SIZES.xs}
                          url={
                            member?.avatar_url ||
                            'https://zentask.ai/static/media/zentask-new-logo.00471ab3f7b393c5d6a7.png'
                          }
                        />
                        <span>{member?.username || member?.full_name}</span>
                        {member?.id.toString() === teamCreatorId && (
                          <span className={styles.admin}>
                            <Icon color="var(--color-primary)" name={IconNames.crown} />
                          </span>
                        )}
                      </div>
                      {isTeamAdmin && member?.id.toString() !== teamCreatorId && (
                        <div className={styles.memberAction}>
                          <Button
                            iconColor="var(--color-secondary)"
                            variant={ButtonVariants.unstyled}
                            label=""
                            disabled={isLoading}
                            type="button"
                            tooltip="Remove from team"
                            onClick={() => removeMember(member?.id.toString())}
                            icon={IconNames.userRemove}
                          />
                        </div>
                      )}
                    </div>
                  ))}

                  {teamMembers?.items?.length === 0 && <div>No members in this team.</div>}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
