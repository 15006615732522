import { useState } from 'react'
import { Button } from 'src/core/ds/button'
import { ButtonVariants } from 'src/core/ds/button/types'
import { IconNames } from 'src/core/ds/icon'
import { Modal } from 'src/core/ds/modal'
import { useGetCurrentUser } from 'src/modules/currentUser/query'
import { useCreateTemplateStore } from 'src/services/store/CreateTemplateStore'
import styles from './index.module.scss'
import { PickAIModel } from './PickAIModel'
import { PickTags } from './PickTags'
import { ProgressBar } from './ProgressBar'
import { Prompt } from './Prompt'
import { PublicDetails } from './PublicDetails'
import { Review } from './Review'
import { Tabber } from './Tabber'
// import { TestOutput } from './TestResponse'

export function CreateSolutionWizard() {
  const { data: currentUser } = useGetCurrentUser()
  const currentFormState = useCreateTemplateStore((state) => state.currentFormState)
  const [renderIndex, setRenderIndex] = useState(0)
  const [currentIndex, setCurrentIndex] = useState(() => currentFormState.currentStep ?? 0)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const updateFormState = useCreateTemplateStore((state) => state.updateFormState)
  const resetCurrentForm = useCreateTemplateStore((state) => state.resetCurrentForm)
  console.log(renderIndex)

  const onPrev = () => {
    setCurrentIndex((prevIndex) => prevIndex - 1)
    updateFormState('currentStep', currentIndex - 1)
  }

  const onStartAgain = () => {
    updateFormState('currentStep', 0)
    updateFormState('lastMaxStep', 0)
    setCurrentIndex(0)
  }

  const onNext = () => {
    setCurrentIndex((prevIndex) => prevIndex + 1)
    updateFormState('currentStep', currentIndex + 1)

    if (currentIndex + 1 <= currentFormState.lastMaxStep) {
      return false
    }

    return updateFormState('lastMaxStep', currentIndex + 1)
  }

  const updateProgressStep = (index) => {
    setCurrentIndex(index)
    updateFormState('currentStep', index)
  }

  const onClearForm = () => {
    setRenderIndex((prev) => prev + 1)
    localStorage.removeItem('createFormStore')
    setIsModalOpen(false)
    setCurrentIndex(0)
    resetCurrentForm()
  }

  if (!currentUser) {
    return (
      <div className={styles.wizardWrapper}>
        <h1>You are not authorized</h1>
      </div>
    )
  }

  // updateFormState(name, value)
  return (
    <div className={styles.wizardWrapper}>
      <Modal
        onClose={() => setIsModalOpen(false)}
        withCloseButton
        onDeny={() => setIsModalOpen(false)}
        onConfirm={() => onClearForm()}
        title="Are you sure?"
        type="error"
        isOpen={isModalOpen}
      />
      <ProgressBar
        currentFormState={currentFormState}
        type="ChatGPT"
        updateStep={updateProgressStep}
        step={currentIndex}
      />
      <div className={styles.wizardContent}>
        <Tabber currentIndex={currentIndex}>
          <PickAIModel
            updateFormState={updateFormState}
            currentFormState={currentFormState}
            onNext={onNext}
            onPrev={onPrev}
          />
          <PickTags
            updateFormState={updateFormState}
            currentFormState={currentFormState}
            onNext={onNext}
            onPrev={onPrev}
          />
          <PublicDetails
            updateFormState={updateFormState}
            currentFormState={currentFormState}
            onNext={onNext}
            onPrev={onPrev}
          />
          <Prompt
            updateFormState={updateFormState}
            currentFormState={currentFormState}
            onNext={onNext}
            onPrev={onPrev}
          />
          {/* <TestOutput
            updateFormState={updateFormState}
            currentFormState={currentFormState}
            onNext={onNext}
            onPrev={onPrev}
          /> */}
          <Review
            onStartAgain={onStartAgain}
            updateFormState={updateFormState}
            currentFormState={currentFormState}
            onPrev={onPrev}
            resetCurrentForm={resetCurrentForm}
          />
        </Tabber>

        <div className={styles.clearForm}>
          <Button
            variant={ButtonVariants.unstyled}
            isIconOnLeft
            label="clear form"
            onClick={() => setIsModalOpen(true)}
            iconColor="var(--color-secondary)"
            type="button"
            icon={IconNames.close}
          />
        </div>
      </div>
    </div>
  )
}
