import { config } from 'src/config/config'
import { apiCall } from 'src/utils/networking'

export const api = {
  getTeamsList: async () =>
    apiCall(`${config.baseAPIUrl}/team/list`, {
      method: 'POST',
    }),

  getTeamsSpacesList: async ({ id }) =>
    apiCall(`${config.baseAPIUrl}/team/spaces/${id}`, {
      method: 'GET',
    }),

  getTeamsMembersList: async ({ id }) =>
    apiCall(`${config.baseAPIUrl}/team/members/${id}`, {
      method: 'GET',
    }),

  getTeamDetails: async ({ id }) =>
    apiCall(`${config.baseAPIUrl}/team/get/${id}`, {
      method: 'GET',
    }),

  createTeam: async ({ name }) =>
    apiCall(`${config.baseAPIUrl}/team/save`, {
      method: 'POST',
      body: JSON.stringify({
        name,
      }),
    }),

  updateTeamSpacesList: async ({ id, details }) =>
    apiCall(`${config.baseAPIUrl}/team/update/${id}`, {
      method: 'POST',
      body: JSON.stringify({
        ...details,
      }),
    }),

  updateTeamMember: async ({ id, details }) =>
    apiCall(`${config.baseAPIUrl}/team/update/${id}`, {
      method: 'POST',
      body: JSON.stringify({
        ...details,
      }),
    }),

  searchUsers: async ({ username }) =>
    apiCall(`${config.baseAPIUrl}/account/search?query=${username}&limit=5`, {
      method: 'POST',
    }),
}
