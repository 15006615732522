import { Link, useNavigate } from '@tanstack/react-location'
import { Avatar } from 'src/core/ds/avatar'
import { AVATAR_SIZES } from 'src/core/ds/avatar/consts'
import { Badge } from 'src/core/ds/badge'
import { BadgeType } from 'src/core/ds/badge/types'
import { Button } from 'src/core/ds/button'
import { ButtonSizes, ButtonVariants } from 'src/core/ds/button/types'
import { IconNames } from 'src/core/ds/icon'
import { useStreamStore } from 'src/services/store/StreamStore'
import { useZenTaskStore } from 'src/services/store/ZenTasksStore'
import { AIModelsLogo } from '../AIModelsLogo/AIModelsLogo'
// import { Card } from '../Card'
import { TaskCategoryBadge } from '../TaskCategoryBadge/TaskCategoryBadge'
import styles from './index.module.scss'

export const badgeFactory = (type) => {
  if (type === 'created') {
    return <Badge type={BadgeType.INFO} label={type} />
  }

  if (type === 'created') {
    return <Badge type={BadgeType.INFO} label={type} />
  }

  if (type === 'pending') {
    return <Badge type={BadgeType.WARNING} label={type} />
  }

  if (type === 'failed') {
    return <Badge type={BadgeType.FAIL} label={type} />
  }

  if (type === 'rejected') {
    return <Badge type={BadgeType.FAIL} label={type} />
  }

  return <Badge type={BadgeType.SUCCESS} label={type} />
}

const noop = () => 0

export function SingleTask({
  onAddToSpace = noop,
  removeFromSpace = noop,
  task,

  withStatus = false,
  isAuth = false,
  isRemovableFromSpace = false,
  spaceLink = false,
}: any) {
  const navigate = useNavigate()
  const updateSelectedZenTask = useStreamStore((state) => state.updateSelectedZenTask)
  const saveTask = useZenTaskStore((state) => state.saveTask)
  const addToSpace = (event, task) => {
    event.preventDefault()
    onAddToSpace({
      title: task.title,
      id: task.id.toString(),
    })
  }

  const onOpenInZen = (event, task) => {
    event.preventDefault()
    updateSelectedZenTask(task.id.toString())
    saveTask(task)
    navigate({ to: `/zen` })
  }

  const removeFromSpaceHandler = (event, task) => {
    event.preventDefault()
    removeFromSpace(task.id.toString())
  }

  const link = spaceLink || `/marketplace/task/${task.id.toString()}`

  return (
    <Link className={styles.taskItem} to={link}>
      <div className={styles.header}>
        <AIModelsLogo aiModel={task?.ai.name} />
        {task?.is_premium ? (
          <div className={styles.toolTipWrapper}>
            <div className={styles.tooltip}>Premium quality prompt</div>
            <div className={styles.angleWrapper}>
              <div className={styles.proAngle}>
                <Button
                  variant={ButtonVariants.unstyled}
                  // tooltip="Premium quality solution"
                  type="button"
                  label=""
                  icon={IconNames.proAngle}
                />
              </div>
            </div>
          </div>
        ) : null}
        <TaskCategoryBadge tags={task.tags} />
        <div className={styles.headerActions}>
          {withStatus && (
            <Link to={`/marketplace/edit/${task.id.toString()}`}>
              <Button
                type="button"
                variant={ButtonVariants.secondary}
                size={ButtonSizes.xs}
                label="Edit"
                iconColor="var(--color-text)"
                icon={IconNames.edit}
              />
            </Link>
          )}
          {true && (
            <Button
              tooltip="Open in ZenMode"
              onClick={(event) => onOpenInZen(event, task)}
              type="button"
              label="⚡️"
              variant={ButtonVariants.unstyled}
              // icon={IconNames.star}
            />
          )}

          {isAuth && isRemovableFromSpace && (
            <Button
              onClick={(event) => removeFromSpaceHandler(event, task)}
              type="button"
              tooltip="Remove from space"
              variant={ButtonVariants.unstyled}
              icon={IconNames.spaceRemove}
              iconColor="var(--color-secondary)"
            />
          )}

          {isAuth && !isRemovableFromSpace && (
            <Button
              onClick={(event) => addToSpace(event, task)}
              type="button"
              tooltip="Add to space"
              variant={ButtonVariants.unstyled}
              icon={IconNames.spaceAdd}
              iconColor="var(--color-secondary)"
            />
          )}
        </div>
      </div>
      <div className={styles.itemTitle}>
        <h2>{task.title}</h2>
      </div>

      <p className={styles.taskItemInfo}>{task.description}</p>

      <div className={styles.footer}>
        <div className={styles.author}>
          <Avatar size={AVATAR_SIZES.xs} url={task?.account?.avatar_url} />
          <span>{task.account.full_name || task.account.username}</span>
        </div>
        {/* <div className={styles.likes}>
          {task.people_usage_count}
          &nbsp;runs
        </div> */}
        {withStatus && <div>{badgeFactory(task.status)}</div>}
      </div>
    </Link>
  )
}

// status` enum('created','pending','published','rejected','deleted')
