import * as Sentry from '@sentry/react'
import { Outlet, Router } from '@tanstack/react-location'
import { useEffect } from 'react'
import { Toaster } from 'react-hot-toast'
import { QueryClientProvider } from 'react-query'
import { ErrorBoundary } from './components/ErrorBoundary'
import { IconsSpriteContainer } from './core/ds/iconsSprite'
// import { locale } from './locale/en-US'
// import { useGetCurrentUser } from './modules/currentUser/query'
import { queryClient } from './queries/reactQueryClient'
import { location, routes } from './Routes'
// import { useLanguageStore } from './services/store/LanguageStore'
// import { LocalisationProvider } from './services/store/TranslationStore'
// import { createTranslationService, TranslationProvider } from './services/translation/translation-service'
// import { flattenMessages } from './utils/translation'

Sentry.init({
  dsn: 'https://79a6b27d8d0245dd88d7a7be16b78a4f@o4505131295703040.ingest.sentry.io/4505131296817152',
  // integrations: [new Sentry.BrowserTracing()],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
})

export function App() {
  // const currentLocale = useLanguageStore((state) => state.currentLocale)
  // const updateLanguage = useLanguageStore((state) => state.updateLanguage)

  // document.documentElement.setAttribute('lang', currentLocale.split('-')[0])

  // const intl = createTranslationService({
  //   locale: currentLocale,
  //   defaultLocale: currentLocale,
  //   formats: {},
  //   defaultFormats: {},
  //   messages: flattenMessages(locale[currentLocale]),
  // })

  useEffect(() => {
    document.body.dataset.appRendered = 'true'
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      {/* eslint-disable-next-line */}
      {/* <LocalisationProvider currentLocale={currentLocale} updateCurrentLocal={updateLanguage}>
        <TranslationProvider value={intl}> */}
      <IconsSpriteContainer />

      <ErrorBoundary>
        <Router routes={routes} location={location}>
          <Outlet />
        </Router>
      </ErrorBoundary>

      <Toaster position="top-right" />
      {/* </TranslationProvider>
      </LocalisationProvider> */}
    </QueryClientProvider>
  )
}
