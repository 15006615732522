import { config } from 'src/config/config'
import { apiCall } from 'src/utils/networking'

export const api = {
  getHistoryItemById: async ({ id }) =>
    apiCall(`${config.baseAPIUrl}/history/get/${id}`, {
      method: 'POST',
    }),

  attachHistoryItemById: async ({ taskId, historyId }) =>
    apiCall(`${config.baseAPIUrl}/history/attach/${historyId}/${taskId}`, {
      method: 'GET',
    }),

  shareHistoryItemById: async ({ historyId }) =>
    apiCall(`${config.baseAPIUrl}/history/share/${historyId}`, {
      method: 'GET',
    }),
}
