import ProIcon from 'src/assets/images/icon-pro.svg'
import { AddToSpaceModal } from 'src/components/AddToSpaceModal/AddToSpaceModal'
import { AddToTeamModal } from 'src/components/AddToTeamModal/AddToTeamModal'
import { Prices } from 'src/components/Prices/Prices'
import { Modal } from 'src/core/ds/modal'
import { useUIStore } from 'src/services/store/UIStore'
import { AddTeamMembersModal } from '../addTeamMembersModal/AddTeamMembersModal'
import { Coupone } from '../Coupone/Coupone'
import { CreateSpaceModal } from '../createSpaceModal/CreateSpaceModal'
import { EditSpaceModal } from '../EditSpaceModal/EditSpaceModal'
import { NoisyPricePopup } from '../NoisyPricePopup/NoisyPricePopup'
import { ReferalModal } from '../referalModal/referalModal'
import { RemoveSpaceModal } from '../RemoveSpaceModal/RemoveSpaceModal'
import { SettingsModal } from '../settingsModal/SettingsModal'
import styles from './index.module.scss'

export function ModalConnector() {
  const isCreditsInfoPopupOpen = useUIStore((state) => state.isCreditsInfoPopupOpen)
  const updateUIStore = useUIStore((state) => state.updateUIStore)
  const isPricesPopupOpen = useUIStore((state) => state.isPricesPopupOpen)
  const isCreateNewSpaceOpen = useUIStore((state) => state.isCreateNewSpaceOpen)
  const isRefferalModalOpen = useUIStore((state) => state.isRefferalModalOpen)
  const isAddToSpaceModalOpen = useUIStore((state) => state.isAddToSpaceModalOpen)
  const isAddToTeamModalOpen = useUIStore((state) => state.isAddToTeamModalOpen)
  const isEditSpaceModalOpen = useUIStore((state) => state.isEditSpaceModalOpen)
  const isDeleteSpaceModalOpen = useUIStore((state) => state.isDeleteSpaceModalOpen)
  const isSettingsModalOpen = useUIStore((state) => state.isSettingsModalOpen)
  const isAddTeamMemberModalOpen = useUIStore((state) => state.isAddTeamMemberModalOpen)
  const isNoisyPricePopupOpen = useUIStore((state) => state.isNoisyPricePopupOpen)
  const isCouponModalOpen = useUIStore((state) => state.isCouponModalOpen)

  const isLockedEntry = !!localStorage.getItem('lockedEntry')

  return (
    <>
      <Modal
        withCloseButton
        onClose={() => updateUIStore('isCouponModalOpen', false)}
        isOpen={isCouponModalOpen}
        body={<Coupone />}
        title="Activate coupon"
      />
      <Modal
        withCloseButton
        onClose={() => updateUIStore('isNoisyPricePopupOpen', false)}
        isOpen={isNoisyPricePopupOpen}
        body={<NoisyPricePopup />}
      />
      <Modal
        withCloseButton
        onClose={() => updateUIStore('isAddToTeamModalOpen', false)}
        isOpen={isAddToTeamModalOpen}
        body={<AddToTeamModal />}
      />
      <Modal
        withCloseButton
        title="Output settings"
        onClose={() => updateUIStore('isSettingsModalOpen', false)}
        isOpen={isSettingsModalOpen}
        body={<SettingsModal />}
      />
      <Modal
        withCloseButton
        title="Add team members"
        onClose={() => updateUIStore('isAddTeamMemberModalOpen', false)}
        isOpen={isAddTeamMemberModalOpen}
        body={<AddTeamMembersModal />}
      />
      <Modal
        withCloseButton
        type="error"
        title="Confirm removing space"
        onClose={() => updateUIStore('isDeleteSpaceModalOpen', false)}
        isOpen={isDeleteSpaceModalOpen}
        body={<RemoveSpaceModal />}
      />
      <Modal
        withCloseButton
        title="Edit space"
        onClose={() => updateUIStore('isEditSpaceModalOpen', false)}
        isOpen={isEditSpaceModalOpen}
        body={<EditSpaceModal />}
      />
      <Modal
        withCloseButton
        title="Add to space"
        onClose={() => updateUIStore('isAddToSpaceModalOpen', false)}
        isOpen={isAddToSpaceModalOpen}
        body={<AddToSpaceModal />}
      />
      <Modal
        withCloseButton
        title="Create new space"
        onClose={() => updateUIStore('isCreateNewSpaceOpen', false)}
        isOpen={isCreateNewSpaceOpen}
        body={<CreateSpaceModal />}
      />
      <Modal
        withCloseButton
        onClose={() => updateUIStore('isPricesPopupOpen', false)}
        isOpen={isPricesPopupOpen}
        body={<Prices shouldShowUrl showFree={!isLockedEntry} />}
      />
      <Modal
        withCloseButton
        title="Affiliate program"
        onClose={() => updateUIStore('isRefferalModalOpen', false)}
        isOpen={isRefferalModalOpen}
        body={<ReferalModal />}
      />
      <Modal
        withCloseButton
        type="help"
        title="What is AI Model Quality?"
        onClose={() => updateUIStore('isCreditsInfoPopupOpen', false)}
        isOpen={isCreditsInfoPopupOpen}
        body={
          // eslint-disable-next-line
          <div className={styles.creditsWrapper}>
            <p>
              By default, all registered users have access to the AI Model with average quality generation, which is
              completely free. If you desire to utilize the top-notch AI model quality, you will need to purchase a PRO
              account
            </p>

            <h2>What distinguishes the AI Models?</h2>

            <p>
              <b>Average</b>
              The average model is optimized for maximum speed and efficiency. It is an excellent choice for tasks that
              involve generating a substantial amount of output.
            </p>

            <p>
              <b className={styles.pro}>
                Premium
                <img src={ProIcon} alt="" />
              </b>
              The premium model is capable of advanced input/output handling, providing highly intelligent responses. It
              excels in tasks that require advanced reasoning, comprehension of instructions, and imaginative solutions.
            </p>
          </div>
        }
      />
    </>
  )
}
