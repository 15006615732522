import { Link, Navigate } from '@tanstack/react-location'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Avatar } from 'src/core/ds/avatar'
import { AVATAR_SIZES } from 'src/core/ds/avatar/consts'
import { Button } from 'src/core/ds/button'
import { ButtonVariants } from 'src/core/ds/button/types'
import { Icon, IconNames } from 'src/core/ds/icon'
import { useGetCurrentUser } from 'src/modules/currentUser/query'
import { useGetTaskInfo } from 'src/modules/tasks/query'
import { useRecentTaskStore } from 'src/services/store/RecentTasksStore'
import { useUIStore } from 'src/services/store/UIStore'
import { TaskDetailsSkeleton } from 'src/skeletons/TaskDetailsSkeleton'
import { DateFormats, formatDate } from 'src/utils/dates'
import { AIModelsLogo } from '../AIModelsLogo/AIModelsLogo'
import { Markdown } from '../MarkDownWrapper'
import styles from './index.module.scss'

export function TaskDetails({ taskId }: any) {
  const saveTask = useRecentTaskStore((state) => state.saveTask)
  const openAddToSpaceModal = useUIStore((state) => state.openAddToSpaceModal)

  const { data, isError, isLoading } = useGetTaskInfo({ id: taskId?.toString(), onError: false })
  const { data: currentUser } = useGetCurrentUser()

  useEffect(() => {
    if (!data) return

    saveTask({
      id: data.id.toString(),
      title: data.title,
      description: data.description,
    })
  }, [data])

  const isTaskOwner = currentUser && data?.account.id.toString() === currentUser?.id.toString()
  const taskCost = data?.cost + data?.ai.variations[0].cost ?? '~'

  const onAddToSpaceHandle = () => {
    openAddToSpaceModal({
      title: data?.title,
      id: data?.id.toString(),
    })
  }

  const isHistoryPage = window.location.pathname.includes('history')

  if (isError) {
    return <Navigate to="/notFound" />
  }

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>{`${data?.title} | prompt | Zentask.ai`}</title>
        <meta name="description" content={data?.description} />
        <meta property="og:title" content={`${data?.title} | prompt | Zentask.ai`} />
        <meta property="og:description" content={data?.description} />
      </Helmet>

      {!isLoading ? (
        <div>
          {!isHistoryPage && (
            <div className={styles.backBlock}>
              <Button
                iconColor="var(--color-secondary)"
                variant={ButtonVariants.unstyled}
                type="button"
                isIconOnLeft
                label="Back"
                onClick={() => window?.history.back()}
                icon={IconNames.arrowLeft}
              />
            </div>
          )}
          <div className={styles.bread}>
            <AIModelsLogo aiModel={data?.ai.name} />
            {/* <Link to="/marketplace">
              <span className={styles.category}>Marketplace</span>
            </Link>
            <span>/</span> */}
            {data?.tags?.map((tag) => (
              <Link key={tag.id.toString()} to={`/marketplace/${tag.id.toString()}/`}>
                <span className={styles.category}>{tag?.name}</span>
              </Link>
            ))}
          </div>
          <h1>{data?.title}</h1>

          <div className={styles.info}>
            <div className={styles.author}>
              <div className={styles.username}>
                <Avatar size={AVATAR_SIZES.sm} url={data?.account?.avatar_url} />
                <b>{data?.account?.full_name || data?.account?.username}</b>
              </div>
            </div>
          </div>

          <p>{data?.description}</p>

          <div className={styles.itemStats}>
            <div className={styles.credit}>
              <span>
                <Icon name={IconNames.coins} />
                {taskCost}
              </span>
            </div>

            <div className={styles.likes}>
              {data?.people_usage_count}
              &nbsp;usages
            </div>
            {data?.updated_at && (
              <div className={styles.updated}>
                Updated:&nbsp;
                {formatDate((Number(data?.updated_at * 1000) as unknown as Date) || 1, DateFormats.monthWithDay)}
              </div>
            )}

            {currentUser && (
              <div className={styles.addToSpace}>
                <Button
                  onClick={onAddToSpaceHandle}
                  type="button"
                  tooltip="Add to space"
                  variant={ButtonVariants.unstyled}
                  icon={IconNames.spaceAdd}
                  iconColor="var(--color-secondary)"
                />
              </div>
            )}
          </div>

          <div className={styles.examples}>
            <h2>Info:</h2>

            <div className={styles.markdown}>
              <Markdown markdown={data?.info} />
            </div>
          </div>
        </div>
      ) : (
        <TaskDetailsSkeleton />
      )}

      {isTaskOwner && (
        <div className={styles.newTask}>
          <Link to={`/marketplace/edit/${data?.id?.toString()}`}>
            <Button type="button" label="Edit prompt" icon={IconNames.edit} />
          </Link>
        </div>
      )}
    </div>
  )
}
