import { Prices } from 'src/components/Prices/Prices'
import styles from './index.module.scss'

export function NoisyPricePopup() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.hero}>
        <h2>Say Goodbye to Interruptions!</h2>
        <p>
          Upgrade to our premium account! Experience seamless AI functionality and supercharge your productivity without
          any interruptions.
        </p>
      </div>

      <Prices showFree={false} hideHeader shouldShowUrl />
    </div>
  )
}
