import { config } from 'src/config/config'
import { apiCall } from 'src/utils/networking'

export const api = {
  getFavouriteTasks: async () =>
    apiCall(`${config.baseAPIUrl}/favorite/list`, {
      method: 'POST',
    }),

  addTaskToFavourite: async (id) =>
    apiCall(`${config.baseAPIUrl}/favorite/add/${id}`, {
      method: 'POST',
    }),

  removeTaskToFavourite: async (id) =>
    apiCall(`${config.baseAPIUrl}/favorite/remove/${id}`, {
      method: 'POST',
    }),
}
