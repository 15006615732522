import { useNavigate } from '@tanstack/react-location'
import { useEffect, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Button } from 'src/core/ds/button'
import { ButtonVariants } from 'src/core/ds/button/types'
import { Icon, IconNames } from 'src/core/ds/icon'
import { Modal } from 'src/core/ds/modal'
import { Textarea } from 'src/core/ds/textarea'
import { MagicLinkForm } from 'src/modules/magicLink/components/Form'
import { pushNotification } from 'src/services/notifications/notificationService'
import { PLAUSIBLE_EVENTS } from 'src/services/plausible/consts'
import { firePlausibleEvent } from 'src/services/plausible/plausibleService'
import { useStreamStore } from 'src/services/store/StreamStore'
import { useUIStore } from 'src/services/store/UIStore'
import styles from './index.module.scss'

const modelMap = {
  1: 'GPT 3.5',
  2: 'GPT 4',
  3: 'PaLM 2 (beta)',
  4: 'Claude 3 Haiku',
  5: 'Claude 3 Sonnet',
  6: 'Claude 3 Opus',
  7: 'Gemini 1.0 Pro',
  8: 'Gemini 1.5 Pro',
  9: 'GPT 4o',
  10: 'Claude 3.5 Sonnet',
}

const modelMapImage = {
  1: 'Stable Diffusion 2.1',
  2: 'Dall-E 2',
  3: 'Midjourney v5.2 (beta)',
  4: 'Midjourney Niji 5 (beta)',
  5: 'Stable Diffusion XL v1.0',
}
// const NON_PRO_LIMIT = 200

const getModelLabel = (modelId, isImageModel = false) => {
  const mapAI = isImageModel ? modelMapImage : modelMap
  const modelLabel = (modelId?.model && mapAI[modelId?.model]) || null

  if (modelLabel) return modelLabel

  if (isImageModel) {
    return 'Stable Diffusion 2.1'
  }

  // return 'GPT 3.5'
  return 'Claude 3 Haiku'
}

export function InputFormNew({
  hint,
  currentUser,
  streamStatus,
  onStop,
  contextState,
  setContextState,
  isImageModel = false,
}) {
  const navigate = useNavigate()
  const resetStreamData = useStreamStore((state) => state.resetData)
  const updateUIStore = useUIStore((state) => state.updateUIStore)
  const updatePromptInput = useStreamStore((state) => state.updatePrompt)
  const updateStreamStatus = useStreamStore((state) => state.updateStatus)
  const resetData = useStreamStore((state) => state.resetData)
  const updateFormData = useStreamStore((state) => state.updateFormData)
  const [input, setInput] = useState('')

  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false)
  const isPROAccount = useUIStore((state) => state.isPROAccount)

  const containerRef = useRef(null)

  const isZenMode = window.location.pathname === '/zen'
  const INPUT_LIMIT = currentUser?.limits?.max_input_chars + currentUser?.limits?.max_extra_chars

  const resetContextState = () => {
    setContextState('new')
    updateStreamStatus('idle')
  }

  const onLoginSuccess = () => {
    pushNotification({
      type: 'success',
      title: 'Success Login',
      subtitle: 'You will be redirected.',
    })
    setIsAuthModalOpen(false)
    navigate({ to: window.location.pathname })
  }

  const openAuthModal = () => {
    setIsAuthModalOpen(true)
  }

  const openSettingsModal = () => {
    firePlausibleEvent(PLAUSIBLE_EVENTS.OPEN_SETTINGS)
    updateUIStore('isSettingsModalOpen', true)
  }

  const onSubmit = async (e) => {
    e.preventDefault()

    const isValid = input.length > 1 && input.length <= INPUT_LIMIT
    const newPrompt = input

    if (!currentUser) return

    if (!isValid) return

    resetStreamData()
    const uniqueID = uuidv4()
    window.responseId = uniqueID
    updateFormData({ request: input })
    updatePromptInput({
      text: newPrompt,
      id: uniqueID,
    })
    firePlausibleEvent(isZenMode ? PLAUSIBLE_EVENTS.SUBMIT_SOLUTION_ZEN : PLAUSIBLE_EVENTS.SUBMIT_SOLUTION)
    setInput('')
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      onSubmit(e)
    }
  }
  const onChangeInput = (e) => {
    setInput(e.target.value)
  }

  const getLimitError = () => {
    const usages =
      1 + Math.ceil((input.length - currentUser?.limits?.max_input_chars) / currentUser?.limits?.extra_chars_per_usage)

    if (!isPROAccount) {
      if (usages > 1) {
        return (
          <div>
            Your input exceeds&nbsp;
            {currentUser?.limits?.max_input_chars}
            &nbsp;characters. As a result, it will be counted as
            {usages}
            &nbsp;usages.
            {/* eslint-disable-next-line  */}
            Free account requests are limited to {INPUT_LIMIT} characters.
            <Button
              onClick={() => {
                updateUIStore('isPricesPopupOpen', true)
                firePlausibleEvent(PLAUSIBLE_EVENTS.CLICK_BUY_CREDITS)
              }}
              type="button"
              variant={ButtonVariants.unstyled}
              label="Upgrade to PRO"
            />
            and save 20%.
          </div>
        )
      }
      // eslint-disable-next-line
      return (
        <div>
          {/* eslint-disable-next-line  */}
          Free account requests are limited to {INPUT_LIMIT} characters.
          <Button
            onClick={() => {
              updateUIStore('isPricesPopupOpen', true)
              firePlausibleEvent(PLAUSIBLE_EVENTS.CLICK_BUY_CREDITS)
            }}
            type="button"
            variant={ButtonVariants.unstyled}
            label="Upgrade to PRO"
          />
          and save 20%.
        </div>
      )
    }

    return `Your input exceeds ${currentUser?.limits?.max_input_chars} characters. As a result, it will be counted as ${usages} usages.`
  }

  const resetDialog = () => {
    updateStreamStatus('idle')
    updatePromptInput({
      text: '',
      id: '',
    })
    resetData()
    updateFormData(null)
  }

  useEffect(
    () => () => {
      resetDialog()
    },
    [],
  )

  return (
    <div className={styles.wrapper}>
      <Modal
        withCloseButton
        onClose={() => setIsAuthModalOpen(false)}
        isOpen={isAuthModalOpen}
        body={<MagicLinkForm onLoginSuccess={onLoginSuccess} />}
      />
      {/* eslint-disable-next-line */}
      <div className={styles.container} ref={containerRef} tabIndex={0}>
        <div className={styles.form}>
          <form onSubmit={onSubmit}>
            {input.length >= currentUser?.limits?.max_input_chars && (
              <div className={styles.infoField}>
                <Icon color="var(--color-red)" name={IconNames.infoCircle} />
                {getLimitError()}
              </div>
            )}
            {input.length > 0 && (
              <span className={styles.maxInput}>
                {/* eslint-disable-next-line */}
                {input?.length} / {currentUser?.limits?.max_input_chars}
              </span>
            )}
            {currentUser && (
              <div className={styles.settings}>
                <Button
                  iconColor="var(--color-white)"
                  icon={IconNames.settings}
                  variant={ButtonVariants.unstyled}
                  type="button"
                  onClick={openSettingsModal}
                  label=""
                />
              </div>
            )}
            {currentUser && contextState === 'current' && (
              <div className={styles.settings}>
                <Button
                  iconColor="var(--color-white)"
                  icon={IconNames.mop}
                  variant={ButtonVariants.unstyled}
                  type="button"
                  tooltipOnLeft
                  tooltip="Start new chat"
                  onClick={resetContextState}
                  label=""
                />
              </div>
            )}
            <Textarea
              onKeyPress={handleKeyPress}
              onChange={onChangeInput}
              value={input}
              placeholder={hint}
              isDisabled={!currentUser}
              autoFocus
            />
            {currentUser && streamStatus !== 'processing' && (
              // <Button variant={ButtonVariants.unstyled} type="submit" label="Generate" />
              <Button icon={IconNames.send} variant={ButtonVariants.unstyled} type="submit" label="" />
            )}
            {currentUser && streamStatus === 'processing' && (
              <Button onClick={onStop} withLoader variant={ButtonVariants.unstyled} type="button" label="Stop" />
            )}
            {!currentUser && (
              <Button onClick={openAuthModal} variant={ButtonVariants.unstyled} type="button" label="Try for free" />
            )}
            {streamStatus !== 'processing' && <span className={styles.tip}>Submit on Enter</span>}
            {currentUser && (
              <span className={styles.selectedModel}>
                {getModelLabel(
                  currentUser?.config && currentUser?.config[isImageModel ? '24658038178709505' : '16686324501184513'],
                  isImageModel,
                )}
                {contextState === 'current' && <> | You are in chat mode.</>}
              </span>
            )}
          </form>
        </div>
      </div>
    </div>
  )
}
