import { useMutation, useQuery } from 'react-query'
import { queryClient } from 'src/queries/reactQueryClient'
import { getCurrentUserKey } from 'src/queries/reactQueryKeys'
import { pushNotification } from 'src/services/notifications/notificationService'
import { api } from './api'

export const useGetCurrentUser = () =>
  useQuery(
    getCurrentUserKey(),
    async () => {
      const token = localStorage.getItem('token')

      if (!token) return null

      const response = await api.getCurrentUser()
      const [error, data] = response as any[]

      if (error) {
        throw new Error(error)
      }

      return data
    },
    {
      retry: 1,
      retryOnMount: false,
      refetchOnMount: false,
    },
  )

export const useUpdateProfile = () =>
  useMutation(api.updateUserProfile, {
    onSuccess: (data: any) => {
      const [error] = data

      if (error) {
        throw Error(error)
      }

      pushNotification({
        type: 'success',
        title: 'Profile updated',
      })
      queryClient.invalidateQueries({ queryKey: getCurrentUserKey() })
    },
    onError: (err: any) => {
      if (err.toString().includes('_config_speed')) {
        queryClient.invalidateQueries({ queryKey: getCurrentUserKey() })

        return pushNotification({
          type: 'error',
          title: 'Can not set "speed" for this AI',
        })
      }

      if (err.toString().includes('config_aspect_value_is_not')) {
        queryClient.invalidateQueries({ queryKey: getCurrentUserKey() })

        return pushNotification({
          type: 'error',
          title: 'Can not set "aspect" for DALL-E',
        })
      }

      pushNotification({
        type: 'error',
        title: 'Error updating profile.',
        subtitle: 'Try again later.',
      })

      return err
    },
  })

export const useLogoutUser = () =>
  useMutation(api.logout, {
    onSuccess: (data: any) => {
      const [error] = data

      if (error) {
        throw Error(error)
      }

      window.pusherInstance?.disconnect()
      window.pusherInstance = null
      localStorage.removeItem('token')
      // redirect to main page after logout
      window.location.href = '/'
    },
    onError: (err: any) => err,
  })
