import { config } from 'src/config/config'
import { apiCall } from 'src/utils/networking'

export const api = {
  sendPrompt: async () =>
    apiCall(`${config.baseAPIUrl}/formula/text`, {
      method: 'POST',
      body: JSON.stringify({
        prompt: 'Say hello world in Russian',
      }),
    }),

  sendDallePrompt: async (prompt: string, taskId) =>
    apiCall(`${config.baseAPIUrl}/task/run/${taskId}?${prompt}`, {
      method: 'POST',
    }),

  // eslint-disable-next-line camelcase
  verifyPromptBeforeRun: async (taskId, prompt, context = 'none', context_id = null) =>
    apiCall(`${config.baseAPIUrl}/task/run/${taskId}`, {
      method: 'POST',
      body: JSON.stringify({
        input: {
          data: prompt,
          context,
          // eslint-disable-next-line camelcase
          context_id,
        },
      }),
    }),
}
