import { useQuery } from 'react-query'
import { getCurrentConfig } from 'src/queries/reactQueryKeys'
import { api } from './api'

export const useGetConfig = () =>
	useQuery(getCurrentConfig(), async () => {

		const response = await api.getConfig()
		const [error, data] = response as any[]

		if (error) {
			throw new Error(error)
		}

		return data
	})
