import { config } from 'src/config/config'
import { apiCall } from 'src/utils/networking'

export const api = {
  getTaskStats: async ({ id }) =>
    apiCall(`${config.baseAPIUrl}/stat/task/${id}`, {
      method: 'POST',
    }),

  getAccountStats: async ({ id }) =>
    apiCall(`${config.baseAPIUrl}/stat/account/${id}`, {
      method: 'POST',
    }),

  getAccountStatsSummary: async ({ id }) =>
    apiCall(`${config.baseAPIUrl}/stat/summary/${id}`, {
      method: 'POST',
    }),
}
