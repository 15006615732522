import { useInfiniteQuery, useQuery } from 'react-query'
import { getSpaceDetails, getSpaceDetailsList } from 'src/queries/reactQueryKeys'
import { pushNotification } from 'src/services/notifications/notificationService'
import { api } from './api'

export const useGetSpaceDetails = (spaceId) =>
  useQuery(
    getSpaceDetails(spaceId),
    async () => {
      try {
        if (!spaceId) return []

        const response = await api.getSpaceDetails({ spaceId })
        const [error, data] = response as any[]

        if (error) {
          throw new Error(error)
        }

        return data
      } catch (error) {
        if (error?.toString().includes('e_space_not_found')) {
          // show notification
          pushNotification({
            type: 'error',
            title: 'Space not found.',
          })

          return { notFound: true }
        }

        return null
      }
    },
    {
      enabled: !!spaceId,
    },
  )

export const useGetSpaceDetailsList = (spaceId, onPrivate) =>
  useInfiniteQuery(
    getSpaceDetailsList(spaceId),
    async () => {
      try {
        const response = await api.getSpaceDetailsList({ spaceId })
        const [error, data] = response as any[]

        if (error) {
          if (error.toString().includes('e_space_permissions_denied')) {
            onPrivate && onPrivate()
          }

          throw new Error(error)
        }

        return data
      } catch (e) {
        return []
      }
    },

    {
      getNextPageParam: (lastPage: any) =>
        // lastPage?.length >= 20 ? pages.length * 20 : false

        lastPage?.pagination?.next_page ?? undefined,
      onError: (error: any) => error,
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: 0,
      enabled: !!spaceId,
      keepPreviousData: false,
    },
  )
