import { useState } from 'react'
import { Card } from 'src/components/Card'
import { Button } from 'src/core/ds/button'
import { Input } from 'src/core/ds/input'
import { useCreateTeam } from 'src/modules/teams/query'
import styles from './index.module.scss'

export const maxWidthValidation = {
  title: 60,
  description: 155,
  prompt: 1500,
  context: 2500,
  info: 4096,
}

export function PublicDetails({ onNext }) {
  const [name, setName] = useState('')

  const { mutate, isLoading } = useCreateTeam(onNext)

  const handleChange = (event) => {
    const { value } = event.target
    setName(value)
  }

  const onCreateTeam = async () => {
    console.log(onNext)
    await mutate({ name })
  }

  const isNameInvalid = name.length < 4 || name.length > maxWidthValidation.title

  return (
    <div>
      <h2 className={styles.wizardTitle}>Name your team</h2>

      <div className={styles.splitDetails}>
        <div className={styles.detailsForm}>
          <Card>
            <div className={styles.formControl}>
              <Input
                id="title"
                value={name}
                onChange={handleChange}
                type="text"
                label="Name"
                // error={isNameInvalid ? 'error' : undefined}
                description="Start with an emoji for attention-grabbing."
                isRequired
              />
              <div className={styles.details}>
                <div style={{ marginRight: 'auto' }}>(min: 4)&nbsp;</div>
                {`${name.length} / ${maxWidthValidation.title}`}
              </div>
            </div>
          </Card>
        </div>
      </div>

      <div className={styles.actionFooter}>
        <Button
          isLoading={isLoading}
          disabled={isNameInvalid || isLoading}
          onClick={onCreateTeam}
          type="button"
          label="Continue"
        />
      </div>
    </div>
  )
}
