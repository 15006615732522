import { useEffect, useState } from 'react'
import { config } from 'src/config/config'
import { Button } from 'src/core/ds/button'
import { ButtonVariants } from 'src/core/ds/button/types'
import { Icon, IconNames } from 'src/core/ds/icon'
import { useCounter } from 'src/hooks/useCounter'
import { invalidateTaskHistory } from 'src/modules/asideHistory/query'
import { useGetCurrentUser } from 'src/modules/currentUser/query'
import { api } from 'src/modules/StreamForm/api'
import { pushNotification } from 'src/services/notifications/notificationService'
import { PLAUSIBLE_EVENTS } from 'src/services/plausible/consts'
import { firePlausibleEvent } from 'src/services/plausible/plausibleService'
import { useStreamStore } from 'src/services/store/StreamStore'
import { useUIStore } from 'src/services/store/UIStore'
import { ImageCardSkeleton } from 'src/skeletons/ImageCardSkeleton'
import styles from './index.module.scss'

// let eventSource
let channel

const taskId = config.taskImageAIId
const currentTaskAIId = config.ImageAIId

const proOptions = [
  { value: 1, label: 'Stable Diffusion 2.1' },
  { value: 2, label: 'Dall-E 2' },
  // { value: 3, label: 'Midjourney (beta)' },
]

const nonProOptions = [
  { value: 1, label: 'Stable Diffusion 2.1' },
  { value: 2, label: 'Dall-E 2', isDisabled: true },
  // { value: 3, label: 'Midjourney (beta)', isDisabled: true },
]

const foundOption = (options, value) => options.find((element) => +element.value === +value) || undefined

export function SmallImageGenerator() {
  const userInput = useStreamStore((state) => state.prompt.text)
  const isDalleModel = true
  const isPROAccount = useUIStore((state) => state.isPROAccount)
  const updateUIStore = useUIStore((state) => state.updateUIStore)
  const updateStreamStatus = useStreamStore((state) => state.updateStatus)
  // const updatePromptInput = useStreamStore((state) => state.updatePrompt)

  console.log('userInput', userInput)
  // const streamStatus = useStreamStore((state) => state.status)

  // const resetData = useStreamStore((state) => state.resetData)
  const { increment } = useCounter()
  const images = useStreamStore((state) => state.images)

  const setImages = useStreamStore((state) => state.updateImages)
  // const updateFormData = useStreamStore((state) => state.updateFormData)
  const [limitError, setLimitError] = useState<any>(null)

  const options = isPROAccount ? proOptions : nonProOptions
  const { data: currentUser } = useGetCurrentUser()

  // const { data: config, isLoading } = useGetConfig()
  // const currentSettingsList = (config && config[currentTaskAIId]) || {}
  const currentUserConfig = (currentUser && currentUser?.config && currentUser?.config[currentTaskAIId]) || {}

  useEffect(() => {
    // if (true) return undefined

    if (!userInput) return undefined

    updateStreamStatus('processing')

    createRequst()

    return undefined
  }, [userInput])

  // const resetDialog = () => {
  //   updateStreamStatus('idle')
  //   updatePromptInput({
  //     text: '',
  //     id: '',
  //   })
  //   resetData()
  //   setImages(null)
  //   updateFormData(null)
  // }

  useEffect(() => {
    const type = 'image'
    const channelString = `${type}-${currentUser?.id.toString()}-${taskId.toString()}`

    return () => window?.pusherInstance?.unsubscribe(channelString)
  }, [])

  // useEffect(
  //   () => () => {
  //     // reset all on component leave
  //     resetDialog()
  //   },
  //   [],
  // )

  const createRequst = async () => {
    setLimitError(false)
    increment()
    channel?.unbind()
    updateStreamStatus('processing')

    try {
      const prompt = userInput
      // const prompt = 'data[image]=test'
      const type = isDalleModel ? 'image' : 'text'

      if (isDalleModel) {
        setImages([] as any)
      }

      const channelString = `${type}-${currentUser?.id.toString()}-${taskId.toString()}`

      channel = window?.pusherInstance?.subscribe(channelString)
      channel?.bind('response', (data) => {
        setImages(data.response)

        if (data?.response?.length === 0) {
          pushNotification({
            type: 'error',
            title: 'Internal error.',
          })
        }
      })

      channel?.bind('done', () => {
        channel.unbind()
        updateStreamStatus('completed')
        // setImages(null)
        invalidateTaskHistory(taskId)
        firePlausibleEvent(PLAUSIBLE_EVENTS.SOLUTION_STREAM_STOPPED)
      })
      const response: any = await api.verifyPromptBeforeRun(taskId.toString(), prompt)
      const [error] = response
      // console.log('data', data)

      if (error) {
        channel.unbind()
        updateStreamStatus('error')
      }
    } catch (err) {
      if (err?.toString().includes('e_request_data_too_long')) {
        const errorString = isPROAccount
          ? 'Your input is too long. 3000 is a Max.'
          : 'Your input is too long. 200 is a Max.\n To increase the limit to 3000 – upgrade to PRO.'
        pushNotification({
          type: 'error',
          title: 'Error occured.',
          subtitle: errorString,
        })
      } else if (err?.toString().includes('e_request_premium_forbidden')) {
        pushNotification({
          type: 'error',
          title: 'Forbidden.',
          subtitle: 'You need PRO account to use this prompt.',
        })
      } else if (err?.toString().includes('e_request_too_many_usages')) {
        // const errorString = isPROAccount
        //   ? 'Your limits are: Text AI – 20 / 3 hours,\n Image AI – 10 / 3 hours'
        //   : 'Your limits are: Text AI – 10 / day,\n Image AI – 5 / day. To increase the limits, please use a PRO account.'
        setLimitError(true)
        firePlausibleEvent(isPROAccount ? PLAUSIBLE_EVENTS.LIMITS_PRO : PLAUSIBLE_EVENTS.LIMITS_FREE)
        // pushNotification({
        //   type: 'error',
        //   title: 'Out of limits.',
        //   subtitle: errorString,
        // })
      } else {
        pushNotification({
          type: 'error',
          title: 'Internal error.',
          subtitle: 'Try again later or refresh the page.',
        })
      }

      updateStreamStatus('error')
    }
  }

  console.log(foundOption, options, currentUserConfig)

  const model = foundOption(options, currentUserConfig.model)

  return (
    <div className={styles.dashboard}>
      <div className={styles.contentSmall}>
        <p className={styles.subtitle}>
          Generating an image using&nbsp;
          {model?.label}
          &nbsp;AI
        </p>

        {limitError && (
          <div className={styles.limitErrorWrapper}>
            <div className={styles.limitError}>
              <Icon color="var(--color-secondary)" name={IconNames.alertCircle} />
              <div>
                <span className={styles.errorTitle}>Beyond the Limits</span>
                {!isPROAccount && <p>Your current limits are: Text AI – 10/day, Image AI – 5/day</p>}
                {isPROAccount && <p>Wait 3 hours and try again.</p>}
                {!isPROAccount && (
                  <div className={styles.errorAction}>
                    <Button
                      onClick={() => {
                        updateUIStore('isPricesPopupOpen', true)
                        firePlausibleEvent(PLAUSIBLE_EVENTS.CLICK_BUY_CREDITS)
                      }}
                      variant={ButtonVariants.unstyled}
                      type="button"
                      label="Upgrade to PRO"
                    />
                    &nbsp;and increase limits to: Text AI – 30/3 hours, Image AI – 15/3 hours with better AI Model.
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        <div className={styles.list}>
          {/* {streamStatus === 'processing' && <ImageCardSkeleton />} */}
          {!limitError && images?.length === 0 && <ImageCardSkeleton />}
          {images?.length > 0 &&
            images?.map((image) => (
              <div key={image} className={styles.listItem}>
                <img loading="lazy" src={image} alt={userInput} />
                <div className={styles.floatBox}>
                  <a rel="noreferrer" href={image} download target="_blank" className={styles.download}>
                    <Icon name={IconNames.download} />
                  </a>

                  {model && <span className={styles.model}>{model.label}</span>}
                  <p className={styles.inputText}>{userInput}</p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
