import { Link } from '@tanstack/react-location'
import { AnimatePresence, motion } from 'framer-motion'
import { Button } from 'src/core/ds/button'
import { IconNames } from 'src/core/ds/icon'
import { useStreamStore } from 'src/services/store/StreamStore'
import { useGetCurrentUser } from '../currentUser/query'
import { useGetTeamDetails } from '../teams/query'
import { ChatItemTeam } from './components/ChatItemTeam'
import { TeamList } from './components/TeamList'
import styles from './index.module.scss'
import { useGetTaskHistory } from './query'
import { summarizeHistoryByMonths } from './utils'

export function AsideTeamHistory({ taskId, historyId, teamsList, teamId }: any) {
  const { data: currentUser } = useGetCurrentUser()
  const { data: teamDetails } = useGetTeamDetails(teamId)
  const {
    fetchNextPage,
    hasNextPage,

    isFetchingNextPage,
    data: historyItems,
  } = useGetTaskHistory(taskId)

  const teamCreatorId = teamDetails?.account?.id?.toString() || ''
  const isTeamAdmin = teamCreatorId === currentUser?.id?.toString()

  // const { data: currentUser } = useGetCurrentUser()
  const resetData = useStreamStore((state) => state.resetData)
  const updateStreamStatus = useStreamStore((state) => state.updateStatus)
  const updatePromptInput = useStreamStore((state) => state.updatePrompt)
  const updateFormData = useStreamStore((state) => state.updateFormData)
  const updateImages = useStreamStore((state) => state.updateImages)

  const resetDialog = () => {
    updateStreamStatus('idle')
    updatePromptInput({
      text: '',
      id: '',
    })
    resetData()
    updateFormData(null)
    updateImages(null)
  }

  const summary = summarizeHistoryByMonths(historyItems)

  if (!currentUser) return null

  return (
    <div className={styles.wrapper}>
      <TeamList isTeamAdmin={isTeamAdmin} teamId={teamId} teamDetails={teamDetails} teamsList={teamsList} />
      {teamId && (
        <div className={styles.chatList}>
          {currentUser && historyId && (
            <Link activeOptions={{ exact: true }} to={`/teams/${teamId}/${taskId}`}>
              <Button type="button" onClick={resetDialog} isIconOnLeft label="New request" icon={IconNames.noteAdd} />
            </Link>
          )}

          <span className={styles.historyTitle}>History</span>

          <div className={styles.chatList}>
            {(Object.keys(summary) || []).map((month) => (
              <div className={styles.section} key={month}>
                <div className={styles.month}>{month}</div>
                {summary[month].items.map((item) => (
                  <AnimatePresence key={item.id.toString()}>
                    <motion.div
                      transition={{ duration: 0.3, delay: 0.1 }}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                    >
                      <ChatItemTeam teamId={teamId} historyId={historyId} item={item} taskId={taskId} />
                    </motion.div>
                  </AnimatePresence>
                ))}
              </div>
            ))}
            {(Object.keys(summary) || []).length === 0 && <span className={styles.noItems}>No history items.</span>}
            {hasNextPage && (
              <div className={styles.tableActions}>
                <Button
                  type="button"
                  label="Load more"
                  isLoading={isFetchingNextPage}
                  disabled={!hasNextPage || isFetchingNextPage}
                  onClick={fetchNextPage}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
