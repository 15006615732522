import styles from './index.module.scss'

export type Props = {
  currentIndex?: number
  tabs: any[]
  onChangeTab?: any
}

export function TabsStatic({ currentIndex = 0, tabs, onChangeTab }: Props) {
  return (
    <div className={styles.container}>
      <ul className={styles.tabContainer}>
        {tabs?.map((tab, index) => (
          <button
            key={index}
            className={currentIndex === index ? styles.tabActive : undefined}
            type="button"
            onClick={() => onChangeTab(index)}
          >
            {tab}
          </button>
        ))}
      </ul>
    </div>
  )
}
