import { Link, useNavigate } from '@tanstack/react-location'
import { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { SingleTask } from 'src/components/SingleTask/SingleTask'
import { Button } from 'src/core/ds/button'
import { IconNames } from 'src/core/ds/icon'
import { Input } from 'src/core/ds/input'
import { Selector } from 'src/core/ds/selector'
import { TabsLinks } from 'src/core/ds/tabsLinks'
import { TabsStatic } from 'src/core/ds/tabsStatic'
import { useDebounce } from 'src/hooks/useDebounce'
import { api } from 'src/modules/Spotlight/api'
import { PLAUSIBLE_EVENTS } from 'src/services/plausible/consts'
import { firePlausibleEvent } from 'src/services/plausible/plausibleService'
import { useUIStore } from 'src/services/store/UIStore'
import { CardSkeleton } from 'src/skeletons/CardSkeleton'
import { useGetAIModels } from '../ai/query'
import { useGetCurrentUser } from '../currentUser/query'
import { useAddTaskToFavourites, useRemoveTaskToFavourites } from '../favouriteTasks/query'
import { useGetTags } from '../tags/query'
import styles from './index.module.scss'

// const tabs = ['Marketplace', 'My solutions']

const namesMap = {
  ChatGPT: 'Text AI',
  'DALL·E 2': 'Image AI',
}

const tabs = [
  { link: '/marketplace/trending', label: 'Trending' },
  { link: '/marketplace', label: 'Marketplace' },
  { link: '/marketplace/personal', label: 'My prompts' },
]

const sortOptions = [
  { value: 'popular', label: 'Popular' },
  { value: 'recent', label: 'Recent' },
  { value: 'alphabet', label: 'Alphabet' },
]

export function TasksList({
  isPersonalView,
  sortingState,
  onSortChange,
  tasksQuery,
  taskId,
  categoryId = 'all',
  currentTabIndex = 0,
  fetchIdentifier,
  isTagsVisible = false,
}) {
  const navigate = useNavigate()

  const [searchInput, setSearchInput] = useState('')
  const debouncedValue = useDebounce(searchInput, 500)

  const selectedAIModelId = useUIStore((state) => state.selectedAIModelId)
  const selectedAIModel = useUIStore((state) => state.selectedAIModel)
  const openAddToSpaceModal = useUIStore((state) => state.openAddToSpaceModal)
  const updateUIStore = useUIStore((state) => state.updateUIStore)
  const [searchResults, setSearchResults] = useState<any>(null)

  const { data: currentUser } = useGetCurrentUser()
  const { data: AIModels } = useGetAIModels()
  const { mutate: addToFavMutate } = useAddTaskToFavourites(categoryId.toString(), currentUser?.id.toString())
  const { data: tagsList } = useGetTags()

  const { mutate: removeFromFavMutate } = useRemoveTaskToFavourites(categoryId.toString(), currentUser?.id.toString())
  // const { data: categoryInfo } = useGetCategory(categoryId.toString())
  const tabsAI = AIModels?.map((model) => namesMap[model.name]) || []

  const {
    data: allTasks,
    isLoading,
    fetchNextPage,
    hasNextPage,

    isFetchingNextPage,
  } = tasksQuery(selectedAIModelId, fetchIdentifier, sortingState)
  const data: any = allTasks?.pages?.flatMap((page) => page?.items ?? page)

  const currentCategory =
    tagsList && categoryId && tagsList?.find((tag) => tag?.id.toString() === categoryId?.toString())

  const categoryName = categoryId === 'all' ? 'Marketplace' : currentCategory?.name || ''
  const categoryDescription = categoryId === 'all' ? 'Explore various options.' : currentCategory?.description || ''

  const onAddFavorite = useCallback(
    async (event, id) => {
      event.preventDefault()
      await addToFavMutate(id)
    },
    [addToFavMutate],
  )

  useEffect(() => {
    if (debouncedValue.length < 3) {
      setSearchResults(null)

      return
    }

    api
      .searchItems({ searchInput: debouncedValue })
      .then((res: any) => {
        const [error, response] = res

        if (error) {
          console.log('error', error)
        }

        if (response?.items) {
          setSearchResults(response.items)
        }

        if (response?.length === 0) {
          setSearchResults([])
        }
      })
      .catch((err) => {
        console.log('error', err)
      })
  }, [debouncedValue])

  const onRemoveFavorite = useCallback(
    async (event, id) => {
      event.preventDefault()
      console.log(taskId)
      await removeFromFavMutate(id)
    },
    [removeFromFavMutate],
  )

  const onTabChange = (index) => {
    updateUIStore('selectedAIModelId', AIModels[index].id.toString())
    updateUIStore('selectedAIModel', index)
    console.log(taskId)

    if (!window.location.pathname.includes('marketplace')) {
      navigate({ to: `/marketplace` })
    }
  }

  const isSearchView = debouncedValue.length > 2

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>{`${categoryName} AI Prompts | Zentask.ai`}</title>
        <meta name="description" content={categoryDescription} />
        <meta property="og:title" content={`${categoryName} AI Prompts | Zentask.ai`} />
      </Helmet>
      <div className={styles.title}>
        <div className={styles.titleContent}>
          <h1>
            {tabs[currentTabIndex].label}

            {categoryName ? (
              <span className={styles.catName}>
                &nbsp;|&nbsp;
                {categoryName}
              </span>
            ) : undefined}
          </h1>
          {categoryDescription && <div className={styles.catDescription}>{categoryDescription}</div>}
        </div>
        <TabsLinks currentIndex={currentTabIndex} tabs={tabs} />
      </div>

      {isTagsVisible && (
        <div className={styles.filtersArea}>
          <div className={styles.filters}>
            <Selector
              hasDefault={false}
              onChange={onSortChange}
              label="Sort by"
              options={sortOptions}
              selected={sortingState}
            />
            <Input
              icon={IconNames.search}
              type="text"
              onChange={(e) => setSearchInput(e.target.value)}
              placeholder="Search"
              value={searchInput}
            />
          </div>
          {/* <TabsLinks currentIndex={currentTabIndex} tabs={tabs} /> */}
        </div>
      )}

      {isTagsVisible && (
        <div className={styles.tagsListWrapper}>
          <div className={styles.tagsList}>
            <TabsStatic onChangeTab={onTabChange} currentIndex={selectedAIModel} tabs={tabsAI} />
            {/* <TabsStatic onChangeTab={onTabChange} currentIndex={selectedAIModel} tabs={tabsAI} />
            <label htmlFor="sort" className={styles.sortIcon}>
              <span className={styles.selectedSort}>{sortingState}</span>
              <Icon name={IconNames.sort} />

              <select name="sort" id="sort" onChange={onSortChange}>
                {sortOptions.map((option) => (
                  <option key={option.label} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </label> */}
            <div className={styles.deli} />
            <Link
              onClick={() => firePlausibleEvent(PLAUSIBLE_EVENTS.CLICK_TAG_LINK)}
              activeOptions={{ exact: true }}
              className={styles.tagItem}
              to="/marketplace"
            >
              All
            </Link>
            {tagsList?.map((tag) => (
              <Link
                onClick={() => firePlausibleEvent(PLAUSIBLE_EVENTS.CLICK_TAG_LINK)}
                className={styles.tagItem}
                to={`/marketplace/${tag.id}/`}
                key={tag.id.toString()}
              >
                <span>{tag.emoji}</span>
                <p>{tag.name}</p>
              </Link>
            ))}
          </div>
        </div>
      )}

      {/* {isLoading && (
        <div className={styles.list}>
          <Loader isBig />
        </div>
      )} */}

      {isLoading && (
        <div className={styles.list}>
          <CardSkeleton />
          <CardSkeleton />
          <CardSkeleton />
          <CardSkeleton />
          <CardSkeleton />
        </div>
      )}

      {isSearchView && (
        <div className={styles.list}>
          {(searchResults || []).map((item: any) => (
            <SingleTask
              key={item.id.toString()}
              withStatus={isPersonalView}
              isAuth={!!currentUser}
              onRemoveFavorite={onRemoveFavorite}
              onAddToSpace={openAddToSpaceModal}
              onAddFavorite={onAddFavorite}
              task={item}
            />
          ))}
          {searchResults?.length === 0 && <div>Nothing found.</div>}
        </div>
      )}

      {!isLoading && !isSearchView && (
        <div className={styles.list}>
          {(data || []).map((item: any) => (
            <SingleTask
              key={item.id.toString()}
              withStatus={isPersonalView}
              onRemoveFavorite={onRemoveFavorite}
              onAddFavorite={onAddFavorite}
              isAuth={!!currentUser}
              onAddToSpace={openAddToSpaceModal}
              task={item}
            />
          ))}
          {data?.length === 0 && <div>Nothing to show.</div>}
          {!isLoading && currentUser && (
            <Link to="/marketplace/create" className={styles.createOne}>
              <p>Can&apos;t find the perfect prompt?</p>
              <div>Create your own!</div>
            </Link>
          )}
          {hasNextPage && (
            <div className={styles.feedActions}>
              <Button
                type="button"
                label="Load more"
                isLoading={isFetchingNextPage}
                disabled={!hasNextPage || isFetchingNextPage}
                onClick={fetchNextPage}
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}
