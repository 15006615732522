import { Link } from '@tanstack/react-location'
// import { useGetTaskInfo } from 'src/modules/tasks/query'
import { DateFormats, formatDate } from 'src/utils/dates'
import styles from './index.module.scss'

export const extractInput = (input: any) => {
  let string = ''
  const keys = Object.keys(input) || []

  keys.forEach((key) => {
    string += `${input[key]} `
  })

  return string
}

export function ChatItemTeam({ teamId, item, taskId, historyId }: any) {
  const link = `/teams/${teamId}/${taskId}/history/${item?.id.toString()}`

  return (
    //        eslint-disable-next-line
    // @ts-ignore

    <Link to={link} className={`${styles.wrapper} ${Number(historyId) === Number(item.id) ? styles.active : ''}`}>
      <div className={styles.info}>
        <span>{item?.input?.data || item?.input?.text || extractInput(item?.input)}</span>
      </div>
      <div className={styles.date}>
        {/* eslint-disable-next-line */}
        {/* @ts-ignore */}
        {formatDate(Number(item.created_at * 1000) as Date, DateFormats.datePickerFullDate)}
      </div>
    </Link>
  )
}
