import { useInfiniteQuery, useQuery } from 'react-query'
import { getTrendingTasksList } from 'src/queries/reactQueryKeys'
import { api } from './api'

export const useGetTrendingTasks = () =>
  useQuery(getTrendingTasksList(), async () => {
    const response = await api.getTrendingTasks()
    const [error, data] = response as any[]

    if (error) {
      throw new Error(error)
    }

    return data
  })

export const useGetTrendingTasksInfinite = () =>
  useInfiniteQuery(
    ['rendingInfinite'],
    async () => {
      const response = await api.getTrendingTasks()
      const [error, data] = response as any[]

      if (error) {
        throw new Error(error)
      }

      return data
    },

    {
      getNextPageParam: (lastPage: any) =>
        // lastPage?.length >= 20 ? pages.length * 20 : false

        lastPage?.pagination?.next_page ?? undefined,
      onError: (error: any) => console.log('WTF', error),
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: 0,
      keepPreviousData: false,
    },
  )
