import { stringify } from 'lossless-json'
import { config } from 'src/config/config'
import { apiCall } from 'src/utils/networking'

export const api = {
  createNewTask: async ({ taskDTO, isPublic = true }) =>
    apiCall(`${config.baseAPIUrl}/task/save?is_public=${isPublic}`, {
      method: 'POST',
      body: stringify(taskDTO),
    }),
}
