import { useMutation, useQuery } from 'react-query'
import { queryClient } from 'src/queries/reactQueryClient'
import { getFavouritesList, getMyPersonalTasksList } from 'src/queries/reactQueryKeys'
import { pushNotification } from 'src/services/notifications/notificationService'
// import { PLAUSIBLE_EVENTS } from 'src/services/plausible/consts'
// import { firePlausibleEvent } from 'src/services/plausible/plausibleService'
import { api } from './api'

export const useAddTaskToFavourites = (categoryId, accountId) =>
  useMutation(api.addTaskToFavourite, {
    onSuccess: (data: any) => {
      const [error] = data

      if (error) {
        throw Error(error)
      }

      pushNotification({
        type: 'success',
        title: 'Task added to favourites',
      })

      // queryClient.invalidateQueries({ queryKey: getTasksList(categoryId) })
      queryClient.invalidateQueries({ queryKey: getFavouritesList() })
      queryClient.invalidateQueries({ queryKey: getMyPersonalTasksList(accountId) })
      // firePlausibleEvent(PLAUSIBLE_EVENTS.LOGIN)
    },
    onError: (err: any) => {
      if (err.toString().includes('e_favorite_exists')) {
        pushNotification({
          type: 'error',
          title: 'Task already added to favourites',
        })
      }

      if (err.toString().includes('e_account_token_empty')) {
        return []
      }

      return err
    },
  })

export const useRemoveTaskToFavourites = (categoryId, accountId) =>
  useMutation(api.removeTaskToFavourite, {
    onSuccess: (data: any) => {
      const [error] = data

      if (error) {
        throw Error(error)
      }

      pushNotification({
        type: 'success',
        title: 'Task removed from favourites',
      })

      // queryClient.invalidateQueries({ queryKey: getTasksList(categoryId) })
      queryClient.invalidateQueries({ queryKey: getFavouritesList() })
      queryClient.invalidateQueries({ queryKey: getMyPersonalTasksList(accountId) })
      // firePlausibleEvent(PLAUSIBLE_EVENTS.LOGIN)
    },
    onError: (err: any) => {
      if (err.toString().includes('e_favorite_missing')) {
        pushNotification({
          type: 'error',
          title: 'Task already removed from favourites',
        })
      }

      return err
    },
  })

export const useGetFavouriteTasks = () =>
  useQuery(getFavouritesList(), async () => {
    const token = localStorage.getItem('token')

    if (!token) return null

    const response = await api.getFavouriteTasks()
    const [error, data] = response as any[]

    if (error) {
      throw new Error(error)
    }

    return data.items
  })
