import { useQuery } from 'react-query'
import { getBlogList, getCategoriesList } from 'src/queries/reactQueryKeys'
import { api } from './api'

export const useGetCategoriesList = () =>
  useQuery(getCategoriesList(), async () => {
    const response = await api.getCategoriesList()
    const [error, data] = response as any[]

    if (error) {
      throw new Error(error)
    }

    return data
  })

export const useGetBlogList = () =>
  useQuery(getBlogList(), async () => {
    const response = await api.getBlogList()
    const [error, data] = response as any[]

    if (error) {
      throw new Error(error)
    }

    return data.items
  })
