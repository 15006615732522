import { Button } from 'src/core/ds/button'
import { ButtonVariants } from 'src/core/ds/button/types'
import { Input } from 'src/core/ds/input'
import { Textarea } from 'src/core/ds/textarea'
// import { useCreateTemplateStore } from 'src/services/store/CreateTemplateStore'
import styles from './index.module.scss'

export function Prompt({ currentFormState, updateFormState, onNext, onPrev }) {
  // const updatePrompt = useCreateTemplateStore((state) => state.updatePrompt)

  const prompt = currentFormState?.prompt || ''

  const onUpdateTextAreaPrompt = (event) => {
    updateFormState('prompt', event.target.value)
  }

  const handleChange = (event) => {
    const { name, value } = event.target

    updateFormState(name, value)
  }

  const isNextButtonDisabled =
    prompt.length < 6 ||
    (prompt.length > 4000 && currentFormState?.hint?.length < 6) ||
    currentFormState?.hint?.length > 100

  return (
    <div>
      <h2 className={styles.wizardTitle}>Prompt</h2>
      <div className={styles.wizardDescription}>Add instructions for your AI model.</div>

      <div className={styles.promptDetails}>
        <div className={styles.promptControl}>
          <Textarea
            name="prompt"
            value={prompt}
            onChange={onUpdateTextAreaPrompt}
            rows={3}
            isRequired
            description="To give instructions to AI Model for best results, be clear and specific with your prompts, and avoid using ambiguous or confusing language. Additionally, provide enough context and detail so that AI Model can understand the intent behind your request."
            placeholder="You are a marketing expert. Help me with:"
            label="Prompt"
            error={prompt.length > 4000}
          />
          <div className={styles.details}>{`${prompt.length} / 4000`}</div>
        </div>

        <div className={styles.promptControl}>
          <Input
            name="hint"
            value={currentFormState?.hint}
            onChange={handleChange}
            isRequired
            description="Assist the user in understanding what they should type into the input."
            error={currentFormState?.hint > 100 ? 'error' : ''}
            placeholder="[topic] [niche] [target] etc"
            label="Hint"
          />
          <div className={styles.details}>
            <div style={{ marginRight: 'auto' }}>(min: 6)&nbsp;</div>
            {`${currentFormState?.hint?.length} / 100`}
          </div>
        </div>

        <h2 className={styles.previewTitle}>Form preview</h2>
        <div className={styles.form}>
          <form>
            <Textarea value="" placeholder={currentFormState?.hint} />
            <Button disabled variant={ButtonVariants.unstyled} type="button" label="Generate" />
          </form>
        </div>
      </div>

      <div className={styles.actionFooter}>
        <Button variant={ButtonVariants.secondary} onClick={onPrev} type="button" label="Go back" />
        <Button disabled={isNextButtonDisabled} onClick={onNext} type="button" label="Continue" />
      </div>
    </div>
  )
}
