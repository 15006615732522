import { useMatch } from '@tanstack/react-location'
import { AsideZenSpaces } from 'src/components/AsideZenSpaces/AsideZenSpaces'
import { breaks } from 'src/constants/breaks'
import { useMedia } from 'src/hooks/useMedia'
import { TasksList } from 'src/modules/tasksList'
import { useGetTasks } from 'src/modules/tasksList/query'
import { useUIStore } from 'src/services/store/UIStore'
import styles from './index.module.scss'

export function AllTasksPage() {
  const {
    params: { taskId, categoryId },
  } = useMatch()
  const updateUIStore = useUIStore((state) => state.updateUIStore)

  const marketplaceSorting = useUIStore((state) => state.marketplaceSorting)

  const onSortChange = (event) => {
    // let target
    console.log(taskId)

    updateUIStore('marketplaceSorting', event.target.value)
  }

  const screenWidth = useMedia()
  const isVisibleOnDesktop = screenWidth > breaks.tablet

  return (
    <div className={styles.dashboard}>
      <TasksList
        isPersonalView={false}
        fetchIdentifier={categoryId?.toString() || 'all'}
        sortingState={marketplaceSorting}
        onSortChange={onSortChange}
        tasksQuery={useGetTasks}
        currentTabIndex={1}
        taskId={taskId}
        isTagsVisible
        categoryId={categoryId}
      />
      {isVisibleOnDesktop && <AsideZenSpaces key="zen" />}
    </div>
  )
}
