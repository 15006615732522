import React from 'react'

export type Props = {
  currentIndex?: number
  children: any
}

export function Tabber({ children, currentIndex = 0 }: Props) {
  if (!children) return null

  const currentChild = React.Children.toArray(children)[currentIndex]

  if (React.isValidElement(currentChild)) {
    return React.cloneElement(currentChild)
  }

  return <div>{currentChild}</div>
}
