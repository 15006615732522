import { useEffect, useState } from 'react'
import Select from 'react-select'
import { useShareHistoryItem } from 'src/components/StreamHistory/query'
// import { v4 as uuidv4 } from 'uuid'
import { config } from 'src/config/config'
import { Button } from 'src/core/ds/button'
import { ButtonVariants } from 'src/core/ds/button/types'
import { Icon, IconNames } from 'src/core/ds/icon'
import { Input } from 'src/core/ds/input'
import { Modal } from 'src/core/ds/modal'
import { Textarea } from 'src/core/ds/textarea'
import { useCounter } from 'src/hooks/useCounter'
import { invalidateTaskHistory, useGetTaskHistory } from 'src/modules/asideHistory/query'
import { useGetCurrentUser, useUpdateProfile } from 'src/modules/currentUser/query'
import { api } from 'src/modules/StreamForm/api'
import { useGetTaskInfo } from 'src/modules/tasks/query'
import { pushNotification } from 'src/services/notifications/notificationService'
import { PLAUSIBLE_EVENTS } from 'src/services/plausible/consts'
import { firePlausibleEvent } from 'src/services/plausible/plausibleService'
import { useStreamStore } from 'src/services/store/StreamStore'
import { useUIStore } from 'src/services/store/UIStore'
import { ImageCardSkeleton } from 'src/skeletons/ImageCardSkeleton'
import { DateFormats, formatDate } from 'src/utils/dates'
import { copyToClipboard } from 'src/utils/strings'
import styles from './index.module.scss'

// let eventSource
let channel
let currentResponseId = null

const taskId = config.taskImageAIId
const currentTaskAIId = config.ImageAIId

const speedOptions = [
  { value: 1, label: 'Normal (default)' },
  { value: 2, label: 'Relax' },
  { value: 3, label: 'Turbo' },
]

const aspectOptions = [
  { value: 1, label: '1:1 (default)' },
  { value: 2, label: '9:7' },
  { value: 3, label: '7:9' },
  { value: 4, label: '12:5' },
  { value: 5, label: '5:12' },
  { value: 6, label: '19:13' },
  { value: 7, label: '13:19' },
  { value: 8, label: '16:9' },
  { value: 9, label: '9:16' },
]

const styleOptions = [
  { value: 0, label: 'None' },
  { value: 1, label: 'A realistic photo' },
  { value: 2, label: 'Abstract painting' },
  { value: 3, label: 'Academicism painting' },
  { value: 4, label: 'Art deco illustration' },
  { value: 5, label: 'Avant-garde painting' },
  { value: 6, label: 'Black and white photo' },
  { value: 7, label: 'Classicism painting' },
  { value: 8, label: 'Cyberpunk' },
  { value: 9, label: 'Digital art behance' },
  { value: 10, label: 'Fashion photo' },
  { value: 11, label: 'Gothic painting' },
  { value: 12, label: 'Macro photo' },
  { value: 13, label: 'Minimalistic Geometry' },
  { value: 14, label: 'Movie still' },
  { value: 15, label: 'Pixel art' },
  { value: 16, label: 'Polaroid' },
  { value: 17, label: 'Pop-art' },
  { value: 18, label: 'Sticker illustration' },
  { value: 19, label: 'Surrealism painting' },
  { value: 20, label: 'Tattoo art' },
  { value: 21, label: 'Urban portrait' },
  { value: 22, label: 'Vintage' },
  { value: 23, label: 'Watercolor art' },
]

const proOptions = [
  { value: 2, label: 'Dall-E 2' },
  { value: 6, label: 'Dall-E 3' },
  { value: 1, label: 'Stable Diffusion 2.1' },
  { value: 5, label: 'Stable Diffusion XL v1.0' },
  // { value: 3, label: 'Midjourney v5.2 (beta)' },
  // { value: 4, label: 'Midjourney Niji 5 (beta)' },
]

const nonProOptions = [
  { value: 2, label: 'Dall-E 2', isDisabled: true },
  { value: 6, label: 'Dall-E 3', isDisabled: true },
  { value: 1, label: 'Stable Diffusion 2.1' },
  { value: 5, label: 'Stable Diffusion XL v1.0', isDisabled: true },
  // { value: 3, label: 'Midjourney v5.2 (beta)', isDisabled: true },
  // { value: 4, label: 'Midjourney Niji 5 (beta)', isDisabled: true },
]

const foundOption = (options, value) => options.find((element) => +element.value === +value) || undefined

export function Text2Ai() {
  const isDalleModel = true
  const isPROAccount = useUIStore((state) => state.isPROAccount)
  const updateUIStore = useUIStore((state) => state.updateUIStore)
  const updateStreamStatus = useStreamStore((state) => state.updateStatus)
  const updatePromptInput = useStreamStore((state) => state.updatePrompt)
  const updateStreamData = useStreamStore((state) => state.updateData)
  const streamStatus = useStreamStore((state) => state.status)
  // const promptInput = useStreamStore((state) => state.prompt)
  const resetData = useStreamStore((state) => state.resetData)
  const { increment } = useCounter()
  const images = useStreamStore((state) => state.images)
  const resetStreamData = useStreamStore((state) => state.resetData)
  const setImages = useStreamStore((state) => state.updateImages)
  const updateFormData = useStreamStore((state) => state.updateFormData)
  const [limitError, setLimitError] = useState<any>(null)
  const [textInput, setTextInput] = useState('')

  const [historyId, setHistoryId] = useState(null)
  const [isHareHistoryModalOpen, setIsHareHistoryModalOpen] = useState(false)

  const shareLink = `https://zentask.ai/shared/${historyId}`

  const onSuccessShare = () => {
    setIsHareHistoryModalOpen(true)
  }

  const { isLoading: isLoadingShare, mutate: mutateShare } = useShareHistoryItem(onSuccessShare)

  const onShareHistory = async (historyId) => {
    setHistoryId(historyId)
    await mutateShare({ historyId })
  }

  const options = isPROAccount ? proOptions : nonProOptions
  const { data: currentUser } = useGetCurrentUser()
  const { data: solutionDetails } = useGetTaskInfo({
    id: taskId,
    onError: () => undefined,
  })

  const {
    fetchNextPage,
    hasNextPage,

    isFetchingNextPage,
    data: historyItems,
  } = useGetTaskHistory(taskId)

  // const { data: config, isLoading } = useGetConfig()
  // const currentSettingsList = (config && config[currentTaskAIId]) || {}
  const currentUserConfig = (currentUser && currentUser?.config && currentUser?.config[currentTaskAIId]) || {}

  const selectedModelId = currentUserConfig?.model
  const isDalleModelSelected = selectedModelId === 2
  const isMidjourneyModelSelected = selectedModelId === 3 || selectedModelId === 4
  const { mutate, isLoading: isLoadingUpdate } = useUpdateProfile()

  // console.log('historyItems', historyItems)

  const onUpdateModelSettings = async (type, value) => {
    let configObject = {}

    if (type === 'model') {
      configObject = {
        update: {
          config: {
            ...currentUser?.config,
            [currentTaskAIId]: { [type]: value, style: 0 },
          },
        },
      }
    } else {
      configObject = {
        update: {
          config: {
            ...currentUser?.config,
            [currentTaskAIId]: { ...currentUserConfig, [type]: value },
          },
        },
      }
    }

    // const params = createParamsString({ config: configObject })
    await mutate(configObject)
  }

  const onSubmit = async () => {
    const isValid = textInput.length > 1 && textInput.length <= 3000
    // const newPrompt = textInput

    // if (!isEnoughBalance) return
    if (!currentUser) return

    if (!isValid) return

    resetStreamData()
    // const uniqueID = uuidv4()
    updateFormData({ request: textInput })
    // updatePromptInput({
    //   text: newPrompt,
    //   id: uniqueID,
    // })
    firePlausibleEvent(PLAUSIBLE_EVENTS.SUBMIT_SOLUTION)
    setTextInput('')
    createRequst()
  }

  // useEffect(() => {
  //   if (!promptInput.text.length) return undefined

  //   updateStreamStatus('processing')

  //   createRequst()

  //   return undefined
  // }, [promptInput])

  const resetDialog = () => {
    updateStreamStatus('idle')
    updatePromptInput({
      text: '',
      id: '',
    })
    resetData()
    setImages(null)
    updateFormData(null)
    currentResponseId = null
  }

  useEffect(() => {
    const type = 'image'
    const channelString = `${type}-${currentUser?.id.toString()}-${taskId.toString()}`

    return () => window?.pusherInstance?.unsubscribe(channelString)
  }, [])

  useEffect(
    () => () => {
      // reset all on component leave
      resetDialog()
    },
    [],
  )

  useEffect(() => {
    resetDialog()
  }, [])

  const createRequst = async () => {
    setLimitError(false)
    setHistoryId(null)
    increment()
    channel?.unbind()
    updateStreamStatus('processing')

    try {
      // eslint-disable-next-line
      // @ts-ignore
      const prompt = textInput || window?.lastImageRequest
      // const prompt = 'data[image]=test'
      window.lastImageRequest = prompt
      const type = isDalleModel ? 'image' : 'text'

      if (isDalleModel) {
        setImages([] as any)
      }

      const channelString = `${type}-${currentUser?.id.toString()}-${taskId.toString()}`

      channel = window?.pusherInstance?.subscribe(channelString)
      channel?.bind('response', (data) => {
        if (isDalleModel) {
          setImages(data.response)

          if (data?.response?.length === 0) {
            pushNotification({
              type: 'error',
              title: 'Internal error.',
            })
          }
        } else if (data?.id === currentResponseId) {
          updateStreamData(data.response)
        }
      })

      channel?.bind('done', () => {
        // channel.unbind_all()
        // channel.unsubscribe()
        channel.unbind()
        updateStreamStatus('completed')
        setImages(null)
        invalidateTaskHistory(taskId)
        firePlausibleEvent(PLAUSIBLE_EVENTS.SOLUTION_STREAM_STOPPED)
      })
      const response: any = await api.verifyPromptBeforeRun(taskId.toString(), prompt)
      const [error, data] = response
      // console.log('data', data)

      if (data && data?.id) {
        // console.log('SET!')
        currentResponseId = data?.id
      }

      // console.log(currentResponseId)

      if (error) {
        channel.unbind()
        updateStreamStatus('error')
      }
    } catch (err) {
      if (err?.toString().includes('e_request_data_too_long')) {
        const errorString = isPROAccount
          ? 'Your input is too long. 3000 is a Max.'
          : 'Your input is too long. 200 is a Max.\n To increase the limit to 3000 – upgrade to PRO.'
        pushNotification({
          type: 'error',
          title: 'Error occured.',
          subtitle: errorString,
        })
      } else if (err?.toString().includes('e_request_premium_forbidden')) {
        pushNotification({
          type: 'error',
          title: 'Forbidden.',
          subtitle: 'You need PRO account to use this solution.',
        })
      } else if (err?.toString().includes('violates_guidelines')) {
        pushNotification({
          type: 'error',
          title: 'Your request is against the AI guidelines.',
          subtitle: 'Please, try to reword your request.',
        })
      } else if (err?.toString().includes('banned_words')) {
        pushNotification({
          type: 'error',
          title: 'Bad words.',
          subtitle: 'The words you are using are inappropriate for the requests made to the selected AI.',
        })
      } else if (err?.toString().includes('e_request_too_many_usages')) {
        // const errorString = isPROAccount
        //   ? 'Your limits are: Text AI – 20 / 3 hours,\n Image AI – 10 / 3 hours'
        //   : 'Your limits are: Text AI – 10 / day,\n Image AI – 5 / day. To increase the limits, please use a PRO account.'
        setLimitError(true)
        firePlausibleEvent(isPROAccount ? PLAUSIBLE_EVENTS.LIMITS_PRO : PLAUSIBLE_EVENTS.LIMITS_FREE)
        // pushNotification({
        //   type: 'error',
        //   title: 'Out of limits.',
        //   subtitle: errorString,
        // })
      } else {
        pushNotification({
          type: 'error',
          title: 'Internal error.',
          subtitle: 'Try again later or refresh the page.',
        })
      }

      updateStreamStatus('error')
      setImages(null)
    }
  }

  const onItemShareCopyLink = () => {
    copyToClipboard(
      shareLink,
      pushNotification({
        type: 'success',
        title: 'Copied to clipboard',
      }),
    )
  }

  const onTextCopy = (copy) => {
    firePlausibleEvent(PLAUSIBLE_EVENTS.COPY_RESPONSE)
    copyToClipboard(
      copy,
      pushNotification({
        type: 'success',
        title: 'Copied to clipboard',
      }),
    )
  }

  const items: any = historyItems?.pages?.flatMap((page) => page?.items ?? page)

  return (
    <div className={styles.dashboard}>
      <Modal
        withCloseButton
        title="Share history"
        onClose={() => setIsHareHistoryModalOpen(false)}
        isOpen={isHareHistoryModalOpen}
        body={
          // eslint-disable-next-line
          <div className={styles.modalWrapper}>
            <Input
              label="Your link"
              action={
                // eslint-disable-next-line
                <button className={styles.action} onClick={onItemShareCopyLink} type="button">
                  <Icon name={IconNames.copy} />
                </button>
              }
              name="shareLink"
              value={shareLink}
            />
          </div>
        }
      />

      <div className={styles.settings}>
        <h2>Settings</h2>
        <div className={styles.form}>
          <div className={styles.formControl}>
            {/* eslint-disable-next-line */}
            <label htmlFor={'model'}>AI Model</label>
            <Select
              className="basic-multi-select"
              classNamePrefix="select_custom"
              defaultValue={foundOption(options, currentUserConfig.model)}
              isSearchable
              onChange={(value) => onUpdateModelSettings('model', value.value)}
              name="model"
              options={options}
            />
          </div>

          <div key={selectedModelId} className={styles.formControl}>
            {/* eslint-disable-next-line */}
            <label htmlFor={'style'}>Image style</label>
            <Select
              className="basic-multi-select"
              classNamePrefix="select_custom"
              defaultValue={foundOption(styleOptions, currentUserConfig.style)}
              isSearchable
              onChange={(value) => onUpdateModelSettings('style', value.value)}
              name="style"
              options={styleOptions}
              isDisabled={!isPROAccount}
            />
          </div>

          {!isDalleModelSelected && (
            <div className={styles.formControl}>
              {/* eslint-disable-next-line */}
              <label htmlFor={'aspect'}>Aspect ratio</label>
              <Select
                className="basic-multi-select"
                classNamePrefix="select_custom"
                defaultValue={foundOption(aspectOptions, currentUserConfig.aspect)}
                isSearchable
                onChange={(value) => onUpdateModelSettings('aspect', value.value)}
                name="aspect"
                options={aspectOptions}
                isDisabled={!isPROAccount}
              />
            </div>
          )}

          {isMidjourneyModelSelected && (
            <div className={styles.formControl}>
              {/* eslint-disable-next-line */}
              <label htmlFor={'speed'}>Speed</label>
              <Select
                className="basic-multi-select"
                classNamePrefix="select_custom"
                defaultValue={foundOption(speedOptions, currentUserConfig.speed)}
                isSearchable
                onChange={(value) => onUpdateModelSettings('speed', value.value)}
                name="speed"
                options={speedOptions}
                isDisabled={!isPROAccount}
              />
            </div>
          )}

          <div className={styles.formControl}>
            {/* eslint-disable-next-line */}
            <label htmlFor={'model'}>Your prompt</label>
            <Textarea value={textInput} onChange={(e) => setTextInput(e.target.value)} isRequired rows={3} />
          </div>

          <div className={styles.formControl}>
            {currentUser && (
              <Button
                isLoading={isLoadingUpdate || streamStatus === 'processing'}
                disabled={!textInput || isLoadingUpdate || streamStatus === 'processing'}
                onClick={onSubmit}
                label="Generate image"
                type="button"
              />
            )}

            {streamStatus === 'completed' && isDalleModel && (
              <div className={styles.regen}>
                <Button
                  variant={ButtonVariants.unstyled}
                  icon={IconNames.refresh}
                  type="button"
                  label="Regenerate"
                  iconColor="var(--color-text)"
                  onClick={createRequst}
                  disabled={isLoadingUpdate}
                />
              </div>
            )}
          </div>

          {!isPROAccount && (
            <div className={styles.upgradeBlock}>
              <Button
                onClick={() => {
                  updateUIStore('isPricesPopupOpen', true)
                  firePlausibleEvent(PLAUSIBLE_EVENTS.CLICK_BUY_CREDITS)
                }}
                variant={ButtonVariants.unstyled}
                type="button"
                label="⚡️ Upgrade to PRO"
              />
              and get DALLE-2 and SD XL.
              <br />
              Increase the limits up to 15 images / 3 hours.
            </div>
          )}
        </div>
      </div>
      <div className={styles.content}>
        <h1>Text to image</h1>
        <p className={styles.subtitle}>Generate stunning images using the latest AI models.</p>

        {limitError && (
          <div className={styles.limitErrorWrapper}>
            <div className={styles.limitError}>
              <Icon color="var(--color-secondary)" name={IconNames.alertCircle} />
              <div>
                <span className={styles.errorTitle}>Beyond the Limits</span>
                {!isPROAccount && <p>Your current limits are: Text AI – 10/day, Image AI – 5/day</p>}
                {isPROAccount && <p>Wait 3 hours and try again.</p>}
                {!isPROAccount && (
                  <div className={styles.errorAction}>
                    <Button
                      onClick={() => {
                        updateUIStore('isPricesPopupOpen', true)
                        firePlausibleEvent(PLAUSIBLE_EVENTS.CLICK_BUY_CREDITS)
                      }}
                      variant={ButtonVariants.unstyled}
                      type="button"
                      label="Upgrade to PRO"
                    />
                    &nbsp;and increase limits to: Text AI – 30/3 hours, Image AI – 15/3 hours with better AI Model.
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        <div className={styles.list}>
          {/* {streamStatus === 'processing' && <ImageCardSkeleton />} */}
          {images?.length === 0 && <ImageCardSkeleton />}
          {images?.length > 0 &&
            images?.map((image) => (
              <div key={image} className={styles.listItem}>
                <img loading="lazy" src={image} alt="" />
              </div>
            ))}
          {items?.map((item) => {
            const responseItems = JSON.parse(item?.response || '[]') || []
            // console.log('responseItems', responseItems)

            return responseItems?.map((img) => (
              <div key={img} className={styles.listItem}>
                <img loading="lazy" src={img} alt={item?.input?.data} />
                <div className={styles.floatBox}>
                  <a title="download" rel="noreferrer" href={img} download target="_blank" className={styles.download}>
                    <Icon name={IconNames.download} />
                  </a>
                  <div className={styles.shareHistory}>
                    <Button
                      label=""
                      icon={IconNames.share}
                      tooltipOnLeft
                      type="button"
                      isLoading={isLoadingShare}
                      tooltip="Share history"
                      variant={ButtonVariants.unstyled}
                      onClick={() => onShareHistory(item.id.toString())}
                    />
                  </div>
                  <div className={styles.copy}>
                    <Button
                      label=""
                      icon={IconNames.copy}
                      type="button"
                      tooltipOnLeft
                      tooltip="Copy request"
                      variant={ButtonVariants.unstyled}
                      onClick={() => onTextCopy(item?.input?.data || '')}
                    />
                  </div>

                  {item?.config?.model?.option && <span className={styles.model}>{item?.config?.model?.option}</span>}
                  <p className={styles.inputText}>{item?.input?.data}</p>

                  <div className={styles.date}>
                    {/* eslint-disable-next-line */}
                    {/* @ts-ignore */}
                    {formatDate(Number(item?.created_at * 1000) as Date, DateFormats.datePickerFullDate)}
                  </div>
                </div>
              </div>
            ))
          })}

          {(JSON.parse((solutionDetails && solutionDetails?.history?.response) || '[]') || []).map((image, index) => (
            <div key={index} className={styles.listItem}>
              <img loading="lazy" src={image} alt="preview" />
              <div className={styles.floatBox}>
                <a rel="noreferrer" href={image} download target="_blank" className={styles.download}>
                  <Icon name={IconNames.download} />
                </a>

                <p className={styles.inputText}>Preview</p>

                <div className={styles.date}>12/05/2023</div>
              </div>
            </div>
          ))}
        </div>

        <div className={styles.more}>
          {hasNextPage && (
            <div className={styles.tableActions}>
              <Button
                type="button"
                label="Load more"
                isLoading={isFetchingNextPage}
                disabled={!hasNextPage || isFetchingNextPage}
                onClick={fetchNextPage}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
